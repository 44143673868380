import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {DeleteMessageInput, GetMessagesInput, ItemsList, Message, SetMessageStatusInput} from "../models";

@Injectable()
export class MessageApi {
    constructor(private http2Talos: Http2Talos) {
    }


    /**
     *
     * @param userId
     * @param input
     * @param requireSession
     */
    public getMessages(userId: string, input: GetMessagesInput, requireSession: boolean = true): Promise<Array<Message>> {
        return this.http2Talos.get({
            requireSession: requireSession,
            path: `/messages/${userId}/messages`,
            queryParams: input
        });
    }

    /**
     *
     * @param userId
     * @param input
     * @param requireSession
     */
    public setStatus(userId: string, input: SetMessageStatusInput, requireSession: boolean = true): Promise<any> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/messages/${userId}/messages/status`,
            input: input
        });
    }

    /**
     *
     * @param userId
     * @param input
     * @param requireSession
     */
    public deleteMessages(userId: string, input: DeleteMessageInput, requireSession: boolean = true): Promise<any> {
        return this.http2Talos.delete({
            requireSession: requireSession,
            path: `/messages/${userId}/messages`,
            input: input
        });
    }

} // END CLASS
