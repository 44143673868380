import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { get, isArray, isNil, isNull, isString } from "lodash";
import { Observable, Subject } from "rxjs/index";
import { takeUntil } from "rxjs/internal/operators";
import { AppGlobalsService } from "src/services/appGlobals.service";
import { KpDictionary } from "src/services/kp_dictionary/kp.dictionary";
import { UtilsService } from "src/services/utils.service";
import { DecimalDTO, ResourceDTO } from "src/talosApi";
import {
  SalesOrderDetailsDTO,
  SalesOrderDTO,
} from "src/talosApi/api/SalesOrderApi";
import { TAConstants } from "src/talosApi/settings";
import { ImageItem } from "../../../../../src/shared/image/image.item.dto";
import { AlertDef } from "../../../../../src/shared/interfaces/Alert";
import {
  BRAND_PRODUCT_PURHCASE_TYPES,
  ExtendedCategoryDTO,
  LoyaltyBrandProductDTO,
  RewardsService,
} from "../rewards/rewards.service";
import { CartService } from "./cart.service";
import RESOURCE_TYPES = TAConstants.Resource_Types;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import METADATA_KEY = TAConstants.METADATA_KEY;
import { isNullOrUndefined } from "util";

@Injectable({
  providedIn: "root",
})
export class CartPresenter {
  destroy: Subject<void> = new Subject();

  private cartCountObserver: Subject<number> = new Subject();
  public cartCountObserver$: Observable<number> = this.cartCountObserver.pipe();

  /* Observer of Cart Modification (Add/Update etc.) in Progress. */
  private cartObserver: Subject<Cart> = new Subject();
  public cartObserver$: Observable<Cart> = this.cartObserver.asObservable();

  private modifyinCart: Subject<boolean> = new Subject();
  public modifyinCart$: Observable<boolean> = this.modifyinCart.pipe();

  private cartItemAdded: Subject<CartItem> = new Subject();
  public cartItemAdded$: Observable<CartItem> = this.cartItemAdded.pipe();

  private cartItemsAdded: Subject<Array<CartItem>> = new Subject();
  public cartItemsAdded$: Observable<Array<CartItem>> =
    this.cartItemsAdded.pipe();

  private finalizingCart: Subject<boolean> = new Subject();
  public finalizingCart$: Observable<boolean> = this.finalizingCart.pipe();

  private cartFinalized: Subject<SalesOrderDTO> = new Subject();
  public cartFinalized$: Observable<SalesOrderDTO> = this.cartFinalized.pipe();

  private cartError: Subject<any> = new Subject();
  public cartError$: Observable<any> = this.cartError.pipe();

  private productCategories: KpDictionary<ExtendedCategoryDTO> = null;

  /**
   * Reward config of cart presenter
   */
  private _rewardConfig = {};

  /**
   * Root category id of cart presenter
   */
  private _rootCategoryId: number = null;

  constructor(
    private utilService: UtilsService,
    public translateSrv: TranslateService,
    public cartService: CartService,
    private appGlobalsSrv: AppGlobalsService
  ) {
    /* Attach to Service Observables. */
    this.cartService.cartObserver$.pipe().subscribe(async (salesOrder) => {
      const salesOrderDetailsDTOList = get(
        salesOrder,
        "salesOrderDetailsDTOList",
        null
      );
      const count = isArray(salesOrderDetailsDTOList)
        ? salesOrderDetailsDTOList.length
        : 0;
      if (count) await this.getCategories();
      this.ended(this.cartObserver, this.salesOrderToCart(salesOrder));
      this.ended(this.cartCountObserver, count);
    });
    /* Handle Cart Modification Actions */
    this.cartService.cartItemAdded$
      .pipe(takeUntil(this.destroy))
      .subscribe((salesOrderDetailes) => {
        this.ended(
          this.cartItemAdded,
          this.salesOrderDetailsFormat(salesOrderDetailes)
        );
      });

    this.cartService.cartItemsAdded$
      .pipe(takeUntil(this.destroy))
      .subscribe((salesOrdersDetailes) => {
        this.ended(
          this.cartItemsAdded,
          this.salesOrdersDetailsFormat(salesOrdersDetailes)
        );
      });
    /* Handle Cart Modification Actions */
    this.cartService.addingCartItem$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.modifyinCart.next(value);
      });
    this.cartService.removingCartItem$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.modifyinCart.next(value);
      });
    this.cartService.updateCartItemObs$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.modifyinCart.next(value);
      });
    this.cartService.finalizingCart$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.finalizingCart.next(value);
      });
    this.cartService.cartFinalized$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.cartFinalized.next(value);
      });
    /* Handle Cart Errors. */
    this.cartService.errorObserver$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.ended(this.cartError, value);
      });
  }

  /**
   * Get Categories of Products.
   */
  private async getCategories() {
    if (this.appGlobalsSrv && this.appGlobalsSrv.config) {
      try {
        this._rewardConfig =
          this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
          {};
        if (this._rewardConfig) {
          this._rootCategoryId = !isNaN(
            parseInt(
              this._rewardConfig[
                APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
              ]
            )
          )
            ? parseInt(
                this._rewardConfig[
                  APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
                ]
              )
            : null;
        }
      } catch (e) {
        logger.error(e);
      }
    }
    if (this._rootCategoryId && !this.productCategories) {
      await this.utilService
        .getRootCategoryTree(this._rootCategoryId, false, true, false)
        .then((cat) => {
          logger.log(cat);
          this.productCategories = cat.childrenMapped;
        })
        .catch((error) => {
          logger.log(error);
        });
    }
  }

  /**
   *
   * @param item
   * @param categories
   * @returns
   */
  private productSubCategory(
    item: SalesOrderDetailsDTO,
    categories: KpDictionary<ExtendedCategoryDTO>
  ): string | null {
    let ret: string | null = null;
    try {
      if (isNil(item) || isNil(categories)) return null;
      if (!categories.Count()) return null;
      if (isNil(item.subCategoryIds)) return null;
      if (!isArray(item.subCategoryIds)) return null;
      if (isArray(item.subCategoryIds) && !item.subCategoryIds.length)
        return null;
      item.subCategoryIds.forEach((categoryId) => {
        if (categories.ContainsKey(categoryId)) ret = categoryId;
      });
    } catch (e) {
      logger.error(e);
    }
    return ret;
  }

  /**
   *
   * @param item
   * @returns
   */
  private getItemNormalPrice(
    item: SalesOrderDetailsDTO | SalesOrderDTO
  ): number {
    if (!item || isNil(item.totalAmount)) return null;
    return item.totalAmount.value / Math.pow(10, item.totalAmount.scale);
  }

  /**
   *
   * @param input
   * @returns
   */
  public salesOrderToCart(input: SalesOrderDTO): Cart {
    if (isNull(input) || isNull(input.salesOrderId)) return;
    let cartItems: Array<CartItem> = [];
    if (
      input &&
      input.salesOrderDetailsDTOList &&
      input.salesOrderDetailsDTOList.length
    ) {
      input.salesOrderDetailsDTOList.map((sod) => {
        const fsode = this.salesOrderDetailsFormat(sod);
        cartItems.push(fsode);
      });
    }
    return {
      salesOrderId: input.salesOrderId,
      totalAmount: input.totalAmount,
      normalAmount: this.getItemNormalPrice(input),
      items: cartItems.length > 0 ? cartItems : null,
      hasError: this.cartHasError(input),
    };
  }

  private salesOrdersDetailsFormat(
    values: Array<SalesOrderDetailsDTO>
  ): Array<CartItem> {
    if (!values || values.length == 0) return null;
    const cartItems = values.map((v) => {
      return this.salesOrderDetailsFormat(v);
    });
    return cartItems;
  }

  private salesOrderDetailsFormat(sod: SalesOrderDetailsDTO): CartItem {
    const resourcesMapped = this.utilService.formatItemResources(sod);
    const metadataMapped = this.utilService.formatItemMetadata(sod);
    /* Associate Category of Brand Product. */
    let categoryName: string = null;
    const _subCategoryId = this.productSubCategory(
      sod as any,
      this.productCategories
    );
    if (
      !isNil(_subCategoryId) &&
      this.productCategories.ContainsKey(_subCategoryId)
    ) {
      const _assocCategory = this.productCategories.Item(
        _subCategoryId
      ) as ExtendedCategoryDTO;
      if (!isNil(_assocCategory)) {
        let name = this.utilService.getItemResource(
          _assocCategory,
          RESOURCE_TYPES.NAME,
          this.translateSrv.currentLang
        );
        if (isString(name)) categoryName = name;
      }
    }
    let coupon: CartItemCoupon;
    if (sod.brandProductCoupons && sod.brandProductCoupons.length) {
      const c = sod.brandProductCoupons[0];
      coupon = {
        id: c.couponId,
        image: c.image,
        code: `${this.translateSrv.instant("COUPON")} ${c.code}`,
        name: `${this.utilService.getItemResource(
          { resourcesMapped: resourcesMapped },
          RESOURCE_TYPES.NAME,
          this.translateSrv.currentLang
        )}`,
        category: `${categoryName}`,
        description: `${this.utilService.getItemResource(
          { resourcesMapped: resourcesMapped },
          RESOURCE_TYPES.DESCRIPTION,
          this.translateSrv.currentLang
        )}`,
        printTitle: `${this.utilService.getItemResource(
          { resourcesMapped: resourcesMapped },
          RESOURCE_TYPES.REWARD_DESCRIPTION,
          this.translateSrv.currentLang
        )}`,
        printDescription: `${this.utilService.getItemResource(
          { resourcesMapped: resourcesMapped },
          RESOURCE_TYPES.CONTENT_1,
          this.translateSrv.currentLang
        )}`,
        isDCS: metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_EXTERNAL_SYSTEM
        )
          ? metadataMapped.Item[METADATA_KEY.BRAND_PRODUCT_EXTERNAL_SYSTEM] === "7"
          : false,
      };
    }
    let redeemOptions = [];
    if (metadataMapped.ContainsKey(METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS)) {
      try {
        const tmp_val = JSON.parse(
          metadataMapped.Item(METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS)
        );
        if (!isNullOrUndefined(tmp_val.type)) {
          if (isArray(tmp_val.type)) {
            tmp_val.type.forEach((val) => {
              if (val in BRAND_PRODUCT_PURHCASE_TYPES) {
                redeemOptions.push(val);
              } else {
                logger.warn(
                  metadataMapped.Item(METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS)
                );
              }
            });
          }
        }
      } catch (e) {
        logger.error(
          metadataMapped.Item(METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS)
        );
      }
    }
    logger.log("COUPON", coupon);
    return {
      salesOrderDetailsId: sod.salesOrderDetailsId,
      itemId: sod.itemId,
      subcategoryIds: sod.subCategoryIds,
      itemTypeId: sod.itemTypeId,
      name: this.utilService.getItemResource(
        { resourcesMapped: resourcesMapped },
        RESOURCE_TYPES.NAME,
        this.translateSrv.currentLang
      ),
      categoryName: categoryName,
      image: this.utilService.getItemMappedResource(
        { resourcesMapped: resourcesMapped },
        RESOURCE_TYPES.IMAGE,
        this.translateSrv.currentLang
      ),
      tag: null,
      priceText:
        this.getItemNormalPrice(sod) > 0
          ? `${this.getItemNormalPrice(sod)} ${this.translateSrv.instant(
              "PUNTI"
            )}`
          : null,
      totalAmount: sod.totalAmount,
      normalPrice: this.getItemNormalPrice(sod),
      errorCode: sod.errorCode,
      resourcesMapped: resourcesMapped,
      metadataMapped: metadataMapped,
      alert: this.getItemAlert(sod),
      coupon: coupon,
      colorName: this.utilService.getItemResource(
        { resourcesMapped: resourcesMapped },
        RESOURCE_TYPES.COLOR_NAME,
        this.translateSrv.currentLang
      ),
      redeemOptions: redeemOptions,
      quantity: sod.quantity,
      quantityLabel:
        this.translateSrv.instant("CART_ITEMS_QUANTITY") + " " + sod.quantity,
      quantityEnabled: false,
    };
  }

  private getItemAlert(item: SalesOrderDetailsDTO): AlertDef | null {
    if (!isNil(item) && !isNull(item.errorCode)) {
      let message = this.translateSrv.instant(
        `CART_NOTE_ERROR_${item.errorCode}_MESSAGE`
      );
      if (message === `CART_NOTE_ERROR_${item.errorCode}_MESSAGE`) {
        message = this.translateSrv.instant(`CART_NOTE_ERROR_DEFAULT`);
      }
      return {
        variant: "warning",
        message: message,
        code: item.errorCode,
      };
    }
    return null;
  }

  public simulateAddProduct(value: CartItem) {
    if (value) this.cartItemAdded.next(value);
  }

  /**
   *
   * @param observer
   * @param result
   */
  private ended(observer: Subject<any>, result: any) {
    if (observer) observer.next(result);
  }

  /**
   *
   * @param cart
   * @returns
   */
  private cartHasError(cart: SalesOrderDTO): boolean {
    let ret = false;
    if (isNull(cart)) ret = true;
    else {
      if (!isNil(cart.errorCode)) ret = true;
      if (
        isArray(cart.salesOrderDetailsDTOList) &&
        cart.salesOrderDetailsDTOList.length
      ) {
        cart.salesOrderDetailsDTOList.map((sod) => {
          if (!isNil(sod.errorCode)) ret = true;
        });
      }
    }
    return ret;
  }

  /**
   *
   * @param createNewIfEmpty
   */
  public async listActiveCart(
    createNewIfEmpty: boolean = false,
    forceRefresh: boolean = false
  ) {
    this.cartService.listActiveCart(createNewIfEmpty, forceRefresh);
  }

  ngOnDestroy(): void {
    this.cartObserver.complete();
  }
}

export type Cart = {
  salesOrderId: string;
  totalAmount: DecimalDTO;
  normalAmount: number;
  items: Array<CartItem> | null;
  hasError: boolean;
};

export type CartItem = Pick<LoyaltyBrandProductDTO, "subcategoryIds"> & {
  salesOrderDetailsId: string;
  itemId: string;
  itemTypeId: number;
  name: string;
  totalAmount: DecimalDTO;
  normalPrice: number | null;
  priceText: string | null;
  categoryName: string | null;
  colorName: string | null;
  quantity?: number;
  quantityLabel?: string | null;
  image: ImageItem | null;
  tag: AlertDef | null;
  errorCode: number | null;
  resourcesMapped?: KpDictionary<KpDictionary<ResourceDTO>>;
  metadataMapped?: KpDictionary<string>;
  coupon?: CartItemCoupon;
  redeemOptions?: Array<number>;
  alert: AlertDef | null;
  quantityEnabled?: boolean;
};

export type CartItemCoupon = {
  id?: string;
  code?: string;
  name?: string;
  image?: Array<string> | string;
  category?: string;
  description?: string;
  printTitle?: string;
  printDescription?: string;
  // Property is needed in case: 
  // Call pdfService.captureVoucherScreen() and pass coupon data
  // Without product
  isDCS?: boolean;
};
