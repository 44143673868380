import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-icon-item-filter-tag-ang",
  templateUrl: "./icon-item-filter-tag-ang.component.html",
  styleUrls: ["./icon-item-filter-tag-ang.component.scss"],
})
export class IconItemFilterTagAngComponent implements OnInit {
  @Input()
  tagItems: Array<FilterTagItem> = [];

  @Output()
  selectedItemChanged = new EventEmitter();

  selectedItem: FilterTagItem = {};

  constructor() {}

  ngOnInit() {
    if (this.tagItems) {
      const selected = this.tagItems.find((item: FilterTagItem) => {
        return item.selected == true;
      });

      if (selected) {
        this.selectedItem = selected;
      }
    }
  }

  onChangeObj(newItem: FilterTagItem) {
    this.selectedItem = newItem;
    if (this.selectedItemChanged) {
      this.selectedItemChanged.emit({ id: this.selectedItem.tag });
    }
  }
  //
  // byId(item1: FilterTagItem, item2: FilterTagItem) {
  //     return item1.tag == item2.tag;
  // }
}

export interface FilterTagItem {
  name?: string;
  count?: number;
  tag?: FilterTag | number;
  value?: string;
  selected?: boolean;
  showCounter?: boolean;
  disabled?: boolean;
}

export enum FilterTag {
  ALL = 10,
  MAT_ART_DESIGN = 11,
  MAT_SCIENCE = 12,
  MAT_INNOVATION = 13,
  MAT_QUIZ = 14,
  MAT_SURVEY = 15,
  MAT_PRODUCT = 16,
  REW_IQOS_MONEY = 21,
  REW_PRODUCT = 22,
  REW_SERVICE = 23,
  REW_SPECIAL_EVENT = 24,
  REW_COUPON = 25,
  LEVEL_UPGRADE = 26,
  BONUS_POINT = 27,
  NEW_EVENT = 28,
  REDEEMED_VOUCHER = 29,
  TR_READING_WATCHING = 30,
  TR_ANSWERING = 31,
  TR_SOCIAL_CONNECTION = 32,
  TR_DEM = 33,
  TR_SHARE = 34,
  TR_PURCHASE = 35,
  TR_WELCOME = 36,
  TR_USER_PROFILE = 37,
  TR_EXTERNAL_ACTIVITY = 38,
  TR_BENVENUTO_EXTRA = 39,
  TR_BUON_COMPLEANNO = 40,
  TR_PUNTI_EXTRA = 41,
  TR_MISSIONI = 42, 
  TR_INVITE = 43, 
  TR_ANNIVERSARIO_E_COMPLEANNO = 44,
  QUICK_REPLY = 60,
}
