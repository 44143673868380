import {Injectable} from "@angular/core";
import {QuizzesService} from "../../../services/talos/quizzes.service";
import {EventService} from "../../../services/talos/event.service";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import Settings = TAConstants.Settings;
import Events_Types = TAConstants.Events_Types;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import {EventRewardRulesDTO} from "../../../talosApi/models/EventRewardRulesDTO";
import Resource_Types = TAConstants.Resource_Types;
import {ResourcesServices} from "../../../services/talos/resources.services";
import {QuizDTO} from "../../../talosApi/models/QuizDTO";
import {UserAnswerDTO} from "../../../talosApi/models/UserAnswerDTO";
import {StartQuizInput} from "../../../talosApi/models/StartQuizInput";
import {AnswerQuestionInput} from "../../../talosApi/models/AnswerQuestionInput";
import {QuestionDTO} from "../../../talosApi/models/QuestionDTO";
import {AnswerQuestionsInput} from "../../../talosApi/models/AnswerQuestionsInput";
import {UserService} from "../../../services/talos/user.service";
import {AchievementService} from "../../../services/talos/achievement.service";
import {AchievementDTO} from "../../../talosApi/models/AchievementDTO";
import {CheckInDTO} from "../../../talosApi/models/CheckInDTO";
import {CheckInInput} from "../../../talosApi/models/CheckInInput";
import {CheckInService} from "../../../services/talos/checkIn.service";
import {IEventInput} from "../../../talosApi/api/EventApi";
import {GetAchievementsParams} from "../../../talosApi/api/AchievementApi";
import {EventAchievementAction} from "../../../talosApi/models/eventAchievementActionDTO";
import {UtilsService} from "../../../services/utils.service";
import METADATA_KEY = TAConstants.METADATA_KEY;
import {RewardDTO} from "../../../talosApi/models/RewardDTO";
import {ItemItemTypeDTO} from "../../../talosApi/models/ItemItemTypeDTO";
import {BrandsService} from "../../../services/talos/brands.service";
import {DetailedBrandProductCouponDTO} from "../../../talosApi/models/DetailedBrandProductCouponDTO";
import {ItemsList} from "../../../talosApi/models/ItemsList";
import {DetailedBrandProductDTO} from "../../../talosApi/models/DetailedBrandProductDTO";
import {to} from "../../../../../src/utils/utils";

@Injectable()
export class QrSurveyService {

    private quizById: Map<string, QuizDTO> = new Map<string, QuizDTO>();
    achievement: QRAchievement;
    endTime: Date;
    categoryId: number;

    constructor(private achievementSrv: AchievementService, private quizzesSrv: QuizzesService, private eventSrv: EventService,
                private resourcesSrv: ResourcesServices, private userSrv: UserService, private checkInSrv: CheckInService,
                private utilsSrv: UtilsService, private brandsSrv: BrandsService) {

    }

    getAchievement(categoryId: number): Promise<QRAchievement> {
        return new Promise((resolve, reject) => {
            const params: GetAchievementsParams = {
                categoryIds: [categoryId],
                filterOverlappingAchievements: false,
                achievementTypeIds: [4],
                active: true,
                currentlyActive: true,
                matchingLevel: false,
                includeDetails: true,
                rangeFrom: 0,
                rangeTo: 1,
                resources: true,
                metadatas: true,
                languageIds: TAConstants.Settings.LANGUAGES
            }
            this.achievementSrv.getAchievements(params)
                .then((result: Array<AchievementDTO>) => {
                    if (result && result.length > 0) {
                        this.setupAchievementProperties(result[0]);
                        resolve(result[0]);
                        return;
                    }

                    resolve(undefined);

                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getQuizById(quizId: string): Promise<QuizDTO> {
        return new Promise((resolve, reject) => {

            const quiz = this.quizById[quizId];
            if (quiz) {
                resolve(quiz);
                return;
            }
            this.quizzesSrv.listUserQuiz([quizId], null, null, Settings.GAME_TYPE, 0, 1)
                .then((result: Array<QuizDTO>) => {
                    if (result && result.length > 0) {
                        this.quizById[quizId] = result[0];
                        resolve(result[0]);
                        return;
                    }

                    resolve(null)
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getEventRewardsRules(): Promise<Map<number, string>> {
        return new Promise((resolve, reject) => {
            this.eventSrv.getEventRewardRules(Settings.GAME_TYPE,
                [Events_Types.QUIZ_COMPLETION, Events_Types.POLL_SURVEY_COMPLETED], true, LANGUAGES).then((result: Array<EventRewardRulesDTO>) => {
                const eventRewards = new Map<number, string>();
                if (result) {
                    result.forEach((e: EventRewardRulesDTO) => {
                        eventRewards[e.eventTypeId] = (e.rewards && e.rewards.length > 0) ? this.resourcesSrv.getResourcesBasic(e.rewards[0], Resource_Types.NAME) : "";
                    });
                }
                resolve(eventRewards);
            }).catch(err => {
                reject(err);
            })
        });
    }

    startQuiz(quizDTO: QuizDTO): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!quizDTO) {
                reject("No Quiz");
                return;
            }
            const startQuizInput: StartQuizInput = {userId: TAValues.UserId, quizId: quizDTO.id, quizGameType: 1};
            this.quizzesSrv.startQuiz(startQuizInput)
                .then((userQuizId: string) => {
                    if (!userQuizId) {
                        resolve(null);
                        return;
                    }
                    // quizDTO.userQuiz = {userQuizId: userQuizId, quizId: quizDTO.id, userId: TAValues.UserId};
                    resolve(userQuizId);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    answerQuiz(quizDTO: QuizDTO, userQuizId: string): Promise<Array<UserAnswerDTO>> {
        return new Promise((resolve, reject) => {
            // if (!quizDTO) {
            //     reject("No Quiz");
            //     return;
            // }
            //
            // const answers = new Array<AnswerQuestionInput>();
            // if (quizDTO && quizDTO.questions) {
            //     quizDTO.questions.forEach((question: QuestionDTO) => {
            //         if (question.answered) {
            //             const a: AnswerQuestionInput = this.createAnswer(question.id, question.selectedAnswer.id, "", userQuizId, "10");
            //             answers.push(a);
            //         }
            //     })
            // }
            // const answerQuestionsInput: AnswerQuestionsInput = {answers: answers};
            // this.quizzesSrv.answerQuiz(answerQuestionsInput)
            //     .then((finalResult: Array<UserAnswerDTO>) => {
            //         resolve(finalResult);
            //     })
            //     .catch(err => {
            //         reject(err);
            //     })
        });
    }

    synchronizeQuiz(quizDTO: QuizDTO, userQuizId: string): Promise<Array<UserAnswerDTO>> {
        return new Promise((resolve, reject) => {
            // if (!quizDTO) {
            //     reject("No Quiz");
            //     return;
            // }
            //
            // const answers = new Array<AnswerQuestionInput>();
            // if (quizDTO && quizDTO.questions) {
            //     quizDTO.questions.forEach((question: QuestionDTO) => {
            //         if (question.answered && question.current) {
            //         // if (question.answered) {
            //             const a: AnswerQuestionInput = this.createAnswer(question.id, question.selectedAnswer.id, "", userQuizId, "10");
            //             answers.push(a);
            //         }
            //     })
            // }
            // const answerQuestionsInput: AnswerQuestionsInput = {answers: answers};
            // this.quizzesSrv.answerQuiz(answerQuestionsInput)
            //     .then((finalResult: Array<UserAnswerDTO>) => {
            //         resolve(finalResult);
            //     })
            //     .catch(err => {
            //         reject(err);
            //     })
        });
    }

    // startAndAnswerQuiz(quizDTO: QuizDTO): Promise<Array<UserAnswerDTO>> {
    //     return new Promise((resolve, reject) => {
    //         if (!quizDTO) {
    //             reject("No Quiz");
    //             return;
    //         }
    //         const startQuizInput: StartQuizInput = {userId: TAValues.UserId, quizId: quizDTO.id, quizGameType: 1};
    //         this.quizzesSrv.startQuiz(startQuizInput)
    //             .then((userQuizId: string) => {
    //                 if (!userQuizId) {
    //                     resolve(null);
    //                     return;
    //                 }
    //                 const answers = new Array<AnswerQuestionInput>();
    //                 if (quizDTO && quizDTO.questions) {
    //                     quizDTO.questions.forEach((question: QuestionDTO) => {
    //                         if (question.answered) {
    //                             const a: AnswerQuestionInput = this.createAnswer(question.id, question.selectedAnswer.id, "", userQuizId, "10");
    //                             answers.push(a);
    //                         }
    //                     })
    //                 }
    //                 const answerQuestionsInput: AnswerQuestionsInput = {answers: answers};
    //                 this.quizzesSrv.answerQuiz(answerQuestionsInput)
    //                     .then((finalResult: Array<UserAnswerDTO>) => {
    //                         resolve(finalResult);
    //                     })
    //                     .catch(err => {
    //                         reject(err);
    //                     })
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             })
    //     });
    // }

    createAnswer(questionId: string, answerId: string, answerText: string, userQuizId: string, timePassed: string): AnswerQuestionInput {
        return {
            questionId: questionId,
            answerId: answerId,
            answerText: answerText,
            userQuizId: userQuizId,
            timePassed: timePassed
        }
    }

    public checkIn(input: CheckInInput): Promise<CheckInDTO> {
        return new Promise<any>((resolve, reject) => {
            this.checkInSrv.checkIn(input).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error)
            });
        });
    }

    public addEvent(input: IEventInput): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.eventSrv.addEvent(input).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error)
            });
        });
    }

    public updateUserStatus(): Promise<any> {
        return this.userSrv.updateUserStatus();
    }

    private setupAchievementProperties(a: QRAchievement) {

        if (a && a.userAchievement) {
            if (a.userAchievement.useAchievementId && a.userAchievement.useAchievementId != "")
                a.missionStarted = true;

            if (a.userAchievement.eventAchievementActions) {
                a.userAchievement.eventAchievementActions.forEach((e: EventAchievementAction) => {
                    switch (e.eventTypeId) {
                        case Events_Types.CHECKIN_MOCK: {
                            if (e.completed == true) {
                                a.checkInCompleted = true;
                            }
                            break;
                        }
                        case Events_Types.CHECKIN_SUBMITTED: {
                            if (e.completed == true) {
                                a.checkInCompleted = true;
                            }
                            break;
                        }
                        case Events_Types.FACEBOOK_SHARE: {
                            if (e.completed == true) {
                                a.shareCompleted = true;
                            }
                            if (e.rewards && e.rewards.length > 0)
                                a.facebookReward = e.rewards[0];
                            break;
                        }
                        case Events_Types.SHARE_MOCK: {
                            if (e.completed == true) {
                                a.shareCompleted = true;
                            }
                            if (e.rewards && e.rewards.length > 0)
                                a.mainReward = e.rewards[0];
                            break;
                        }
                        case Events_Types.POLL_SURVEY_COMPLETED: {

                            const quizMetadata = this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.EVENT_ACHIEVEMENT_ACTION_ITEM_TYPE, e.metadata);
                            if (quizMetadata != "") {
                                try {
                                    const quizJSON = JSON.parse(quizMetadata);
                                    a.quizId = quizJSON.itemId;
                                } catch (er) {

                                }
                            }
                            if (e.completed == true) {
                                a.quizCompleted = true;
                            }
                            break;
                        }
                        default: {
                            //statements;
                            break;
                        }
                    }
                })
            }

        }

        this.achievement = a;
    }

    getCouponByCategoryId(categoryId: number): Promise<DetailedBrandProductCouponDTO> {
        return new Promise((resolve, reject) => {
            this.brandsSrv.getUserBrandProductCoupons(
                TAValues.UserId, {
                    categoryIds: [categoryId.toString()],
                    friendsOnly: false,
                    rangeFrom: 0,
                    rangeTo: 10,
                    includeQRCode: false,
                    matchingLevel: false,
                    includeDetails: true,
                    includeBom: false,
                    resources: true,
                    languageIds: LANGUAGES
                }).then((res) => {
                if (res && res.length > 0) {
                    resolve(res[0]);
                    return;
                }

                resolve(null);
            }).catch(err => {
                reject(err);
            })
        });
    }

    getBrandProductById(brandProductId: string): Promise<DetailedBrandProductDTO> {
        return new Promise(async (resolve, reject) => {
            const _asyncGetBrandProductsByCategoryId = await to(this.brandsSrv.asyncGetBrandProductsByCategoryId(
                this.userSrv.user.user.userId,
                [brandProductId],
                null,
                null,
                null,
                true,
                false,
                true,
                true,
                false,
                false,
                LANGUAGES,
                null,
                0,
                1
            ));

            if (_asyncGetBrandProductsByCategoryId && _asyncGetBrandProductsByCategoryId.data && _asyncGetBrandProductsByCategoryId.data.length > 0) {
                resolve(_asyncGetBrandProductsByCategoryId.data[0]);
                return;
            }

            resolve(null);
        });
    }
}

export interface QRAchievement extends AchievementDTO {
    missionStarted?: boolean;
    quizCompleted?: boolean;
    checkInCompleted?: boolean;
    shareCompleted?: boolean;
    quizId?: string;
    facebookReward?: RewardDTO;
    mainReward?: RewardDTO;

}