/*
 * @Author: Peter Fousteris (petfoust@gmail.com)
 * @Date: 2019-09-18 17:37:51
 * @Last Modified by: Peter Fousteris (petfoust@gmail.com)
 * @Last Modified time: 2019-09-27 15:13:41
 */

import { Injectable } from "@angular/core";
import { TAConstants } from "../../../talosApi/settings";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import { AppGlobalsService } from "../../../services/appGlobals.service";
import Resource_Types = TAConstants.Resource_Types;
import { UploadContentDTO } from "../../../talosApi/models/UploadContentDTO";
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import {
  UploadContentsService,
  ExtendedUploadContentDTO,
} from "../../../services/talos/uploadContents.service";
import { to } from "../../../../../src/utils/utils";
import { isNullOrUndefined, isArray, isNull } from "util";
import { ExtendedCategoryDTO } from "../rewards/rewards.service";
import { UtilsService } from "../../../services/utils.service";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { AsyncCategoryService } from "../../../services/asyncTalos/async.category.service";
import { ExtendedUploadContentReferenceDTO } from "../../../talosApi/models/UploadContentReferenceDTO";
import {
  ItemsList,
  ITalosApiModel,
  ResourceDTO,
} from "../../../talosApi/models";
import { KpDictionary } from "../../../services/kp_dictionary/kp.dictionary";
import { ReferenceService } from "src/services/talos/reference.service";
import { ExtendedCityDTO } from "src/talosApi/models/CityDTO";
import Item_type = TAConstants.ITEM_TYPE;

@Injectable()
export class GalleryService {
  private galleryConfig = {};

  constructor(
    private appGlobalsSrv: AppGlobalsService,
    private uploadContentsSrv: UploadContentsService,
    private utilService: UtilsService,
    private resourceService: ResourcesServices,
    private asyncCategoryService: AsyncCategoryService,
    private referenceService: ReferenceService
  ) {
    if (appGlobalsSrv.config) {
      this.galleryConfig =
        appGlobalsSrv.config[APPLICATION_SETTING_KEYS.GALLERY_CONFIG] || null;
    }
  }

  /*
   * Counts upload content by reference
   * @param refItemIds
   * @param refItemType
   * @param [active]
   * @returns upload content by reference
   */
  private async CountUploadContentByReference(
    refItemIds: Array<string>,
    refItemType: number,
    active: boolean = true
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      let _ret: KpDictionary<number> = new KpDictionary<number>();
      for await (const itemId of refItemIds) {
        const _perItem = await to(
          this.uploadContentsSrv.getUploadContentByReferenceAndUploadContentType(
            [itemId],
            refItemType,
            UPLOAD_CONTENT_TYPE.GENERAL,
            false,
            [],
            active,
            true,
            null,
            false,
            0,
            1
          )
        );
        if (isNullOrUndefined(_perItem.data)) reject(null);
        else {
          const _data = _perItem.data;
          _ret.Add(
            itemId,
            _data.hasOwnProperty("X-Talos-Item-Count")
              ? parseInt(_data["X-Talos-Item-Count"])
              : 0
          );
        }
      }
      resolve(_ret);
    });
  }

  /*
   * Counts upload content by reference
   * @param refItemIds
   * @param refItemType
   * @param [active]
   * @returns upload content by reference
   */
  private async CountUploadContentBySingleReference(
    refItemId: string,
    refItemType: number,
    active: boolean = true
  ): Promise<{}> {
    return new Promise<{}>(async (resolve, reject) => {
      if (!refItemId) reject(null);
      if (!refItemType) reject(null);
      const _perItem = await to(
        this.uploadContentsSrv.getUploadContentByReferenceAndUploadContentType(
          [refItemId],
          refItemType,
          UPLOAD_CONTENT_TYPE.GENERAL,
          false,
          [],
          active,
          true,
          null,
          false,
          0,
          1
        )
      );
      if (isNullOrUndefined(_perItem.data)) reject(null);
      else {
        if (_perItem.data.hasOwnProperty("X-Talos-Item-Count")) {
          resolve({
            refItemId: refItemId,
            count: parseInt(_perItem.data["X-Talos-Item-Count"]),
          });
        } else reject(null);
      }
    });
  }

  /**
   * Gets city counters
   * @param input
   * @returns city counters
   */
  public async getCityCounters(
    input: ExtendedCityDTO
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      if (isNullOrUndefined(input)) reject(null);
      else {
        let _cityIds: Array<string> = input.Keys();
        if (!_cityIds.length) reject(null);
        let promises: Array<Promise<any>> = [];
        _cityIds.forEach((element) => {
          promises.push(
            to(
              this.CountUploadContentBySingleReference(
                element,
                Item_type.CITY,
                true
              )
            )
          );
        });
        /* Run In Parallel. */
        await Promise.all(promises.map((p) => p.catch((e) => e)))
          .then((results) => {
            if (!results) reject(null);
            let _resp: KpDictionary<number> = new KpDictionary<number>();
            results.forEach((result) => {
              if (isNullOrUndefined(result.data)) {
                /* If Any Failed. */
                reject(null);
                return;
              } else {
                _resp.Add(result.data.refItemId.toString(), result.data.count);
              }
            });
            logger.log("_counters", _resp);
            resolve(_resp);
          })
          .catch((e) => logger.log(e));
      }
    });
  }

  /**
   * Gets city counters
   * @param input
   * @returns city counters
   */
  public async getCategoryCounters(
    input: Array<string>
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      if (isNullOrUndefined(input) || !isArray(input) || !input.length)
        reject(null);
      else {
        let promises: Array<Promise<any>> = [];
        input.forEach((element) => {
          promises.push(
            to(
              this.CountUploadContentBySingleReference(
                element,
                Item_type.CATEGORY,
                true
              )
            )
          );
        });
        /* Run In Parallel. */
        await Promise.all(promises.map((p) => p.catch((e) => e)))
          .then((results) => {
            if (!results) reject(null);
            let _resp: KpDictionary<number> = new KpDictionary<number>();
            results.forEach((result) => {
              if (isNullOrUndefined(result.data)) {
                /* If Any Failed. */
                reject(null);
                return;
              } else {
                _resp.Add(result.data.refItemId.toString(), result.data.count);
              }
            });
            logger.log("_counters", _resp);
            resolve(_resp);
          })
          .catch((e) => logger.log(e));
      }
    });
  }

  /**
   * Gets product
   * @returns product
   */
  public async getUploadContent(
    uploadContentId: string,
    currentDataBlock: KpDictionary<GalleryUploadContentDTO>
  ): Promise<GalleryUploadContentDTO> {
    return new Promise<GalleryUploadContentDTO>(async (resolve, reject) => {
      /* Try to get from Page Block. */
      if (!uploadContentId) reject(null);
      else {
        if (!isNullOrUndefined(currentDataBlock)) {
          if (currentDataBlock.ContainsKey(uploadContentId)) {
            resolve(currentDataBlock.Item(uploadContentId));
            return;
          }
        } else {
        }
      }
      reject(null);
    });
  }

  /**
   * Gets Upload Content by
   * categories
   * @param categoryIds
   * @returns Upload Content by categories
   */
  private async getUploadContentByReference(
    rootRefItemId: string,
    rootItemTypeId: number,
    refItemIds: Array<string>,
    refItemType: number,
    orderType: string = null,
    active: boolean = true,
    matchingLevel: boolean = false,
    rangeFrom?: number,
    rangeTo?: number
  ): Promise<KpDictionary<ExtendedUploadContentDTO>> {
    return new Promise<KpDictionary<ExtendedUploadContentDTO>>(
      async (resolve, reject) => {
        let _ret: KpDictionary<ExtendedUploadContentDTO> =
          new KpDictionary<ExtendedUploadContentDTO>();
        const _getUploadContentByCategoryUploadContentType = await to(
          this.uploadContentsSrv.getUploadContentByReferenceAndUploadContentType(
            refItemIds,
            refItemType,
            UPLOAD_CONTENT_TYPE.GENERAL,
            true,
            TAConstants.Settings.LANGUAGES,
            active,
            matchingLevel,
            orderType,
            false,
            rangeFrom,
            rangeTo
          )
        );
        if (
          isNullOrUndefined(_getUploadContentByCategoryUploadContentType.data)
        )
          reject(null);
        const content =
          _getUploadContentByCategoryUploadContentType.data as ItemsList<UploadContentDTO>;
        await content.map(async (respItem: ExtendedUploadContentDTO) => {
          respItem.resourcesMapped =
            this.utilService.formatItemResources(respItem);
          let _referencesMapped: KpDictionary<ExtendedUploadContentReferenceDTO> =
            new KpDictionary<ExtendedUploadContentReferenceDTO>();
          if (isArray(respItem.references) && respItem.references.length) {
            await respItem.references.map(
              (reference: ExtendedUploadContentReferenceDTO) => {
                if (
                  !_referencesMapped.ContainsKey(
                    reference.itemIdForResourceLookup
                  )
                ) {
                  _referencesMapped.Add(
                    reference.itemIdForResourceLookup,
                    reference
                  );
                }
              }
            );
          }
          _ret.Add(respItem.uploadContentId, {
            ...respItem,
            referencesMapped: _referencesMapped,
          });
        });
        resolve(_ret);
      }
    );
  }

  // /**
  //  * Gets referenced resources
  //  * @param input
  //  */
  // private async getReferencedResources(
  //     input: KpDictionary<ExtendedUploadContentDTO>,
  //     resourcesTypeIds:Array<number>,
  // ): Promise<KpDictionary<ExtendedUploadContentDTO>> {
  //     return new Promise<KpDictionary<ExtendedUploadContentDTO>>(async (resolve, reject) => {
  //         if (!input.Count()) reject(input);
  //         const _allContent = input.Values();
  //         let allReferences: Array<ITalosApiModel> = [];
  //         let itemReferences: object = {};
  //         await _allContent.map(async item => {
  //             if (!isNullOrUndefined(item.references)) {
  //                 if (isArray(item.references) && item.references.length) {
  //                     await item.references.map(referenceItem => {
  //                         if (!isNullOrUndefined(referenceItem.itemIdForResourceLookup) && !isNullOrUndefined(referenceItem.itemTypeIdForResourceLookup)) {
  //                             allReferences.push(referenceItem);
  //                             if (!itemReferences.hasOwnProperty(referenceItem.itemId))
  //                                 itemReferences[referenceItem.itemId] = [];
  //                             itemReferences[referenceItem.itemId].push(item.itemIdForResourceLookup);
  //                         }
  //                     });
  //                 }
  //             }
  //         });
  //         const _searchEtag = await to(this.resourceService.searchEtag(
  //             allReferences,
  //             TAConstants.Settings.LANGUAGES,
  //             resourcesTypeIds
  //         ));
  //         if (isNullOrUndefined(_searchEtag.data)) reject(input);
  //         else {
  //             const _resources = _searchEtag.data as ItemsList<ResourceDTO>;
  //             if (_resources.length) {
  //                 _resources.map((resource: ResourceDTO) => {
  //                     if (!isNullOrUndefined(resource.textResource) && !isNullOrUndefined(resource.languageIsoCode)) {
  //                         if (resource.languageIsoCode.length) {
  //                             if (itemReferences.hasOwnProperty(resource.itemId)) {
  //                                 itemReferences[resource.itemId].map(refItem => {
  //                                     let _refItem = input.Item(refItem);
  //                                     _refItem.references
  //                                     .filter(_reference => _reference.itemId === resource.itemId)
  //                                     .map(_reference => {
  //                                         if (_reference.resources ===  null)
  //                                             _reference.resources = []
  //                                         _reference.resources.push(resource);
  //                                     })
  //                                 });
  //                             }
  //                         }
  //                     }
  //                 });
  //                 resolve(input);
  //             } else reject(input);
  //         }
  //     });
  // }

  /**
   * Gets categories tree
   * @param rootCategoryId
   * @param [resources]
   * @returns categories tree
   */
  public async;
  getCategoriesTree(rootCategoryId: number): Promise<ExtendedCategoryDTO> {
    return new Promise<ExtendedCategoryDTO>(async (resolve, reject) => {
      if (!rootCategoryId) reject(null);
      else {
        const _asyncCategoryService = await to(
          this.utilService.getRootCategoryTree(rootCategoryId, true)
        );
        if (isNullOrUndefined(_asyncCategoryService.data)) reject(null);
        const _rootTree = _asyncCategoryService.data as ExtendedCategoryDTO;
        logger.log("_rootTree", _rootTree);
        if (!_rootTree.children.length) reject(null);
        else resolve(_rootTree);
      }
    });
  }

  /**
   * Gets categories tree
   * @param rootCategoryId
   * @param [resources]
   * @returns categories tree
   */
  public async getCities(
    resources: boolean = true,
    metadata: boolean = false,
    details: boolean = true
  ): Promise<ExtendedCityDTO> {
    return new Promise<ExtendedCityDTO>(async (resolve, reject) => {
      const _getCountryCities = await to(
        this.referenceService.getCountryCities(
          TAConstants.Settings.IT_COUNTRY_ID,
          resources,
          TAConstants.Settings.LANGUAGES,
          metadata,
          details
        )
      );
      if (isNullOrUndefined(_getCountryCities.data)) reject(null);
      const _cities = _getCountryCities.data as KpDictionary<ExtendedCityDTO>;
      logger.log("_cities", _cities);
      if (!_cities.Count()) reject(null);
      else resolve(_cities);
    });
  }

  /**
   * Gets content selection
   * @param contentRootCategoryId
   * @param cities
   * @param [orderType]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @param [refItemTypeId]
   * @param [refItemId]
   * @returns content selection
   */
  public async getContentSelection(
    contentRootCategoryId: string,
    cities: KpDictionary<ExtendedCityDTO>,
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number,
    refItemTypeId?: string,
    refItemId?: string
  ): Promise<KpDictionary<GalleryUploadContentDTO>> {
    return new Promise<KpDictionary<GalleryUploadContentDTO>>(
      async (resolve, reject) => {
        const _getCurrentData = await to(
          this.getUploadContentByReference(
            contentRootCategoryId,
            ITEM_TYPE.CATEGORY,
            !isNullOrUndefined(refItemTypeId)
              ? [refItemId]
              : [contentRootCategoryId],
            isNullOrUndefined(refItemTypeId)
              ? ITEM_TYPE.CATEGORY
              : Item_type.CITY,
            orderType,
            true,
            false,
            rangeFrom,
            rangeTo
          )
        );
        if (isNullOrUndefined(_getCurrentData.data)) reject(null);
        else {
          logger.log("_getCurrentData", _getCurrentData.data);
          let _uploadContents: KpDictionary<ExtendedUploadContentDTO> =
            _getCurrentData.data;
          /* Response Data. */
          let _formattedUploadContents: KpDictionary<GalleryUploadContentDTO> =
            new KpDictionary<GalleryUploadContentDTO>();
          _uploadContents
            .Values() /* Parse Response. */
            .map((item) => {
              _formattedUploadContents.Add(item.uploadContentId, {
                ...this.formatGalleryItems(item, cities),
                categoryId: contentRootCategoryId,
                subcategoryIds: null,
              });
            });
          if (_formattedUploadContents.Count) {
            resolve(_formattedUploadContents);
          } else reject(null);
        }
      }
    );
  }

  /**
   * Formats gallery items
   * @param input
   * @param cities
   * @returns gallery items
   */
  public formatGalleryItems(
    input: ExtendedUploadContentDTO,
    cities: KpDictionary<ExtendedCityDTO>
  ): ExtendedUploadContentDTO {
    if (isNullOrUndefined(cities) || !cities.Count()) return null;
    else if (isNullOrUndefined(input)) return null;
    else {
      let _tmp = [];
      cities.Values().map((city) => {
        if (input.referencesMapped.ContainsKey(city.cityId.toString())) {
          _tmp.push(city.cityId.toString());
        }
      });
      input.cityIds = _tmp;
      return input;
    }
  }

  /**
   * Gets gallery items
   * @param [orderType]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @param [categoryId]
   */
  public async getGalleryItems(
    contentRootCategoryId: string,
    subCategoriesRootCategoryId: string,
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number,
    refItemTypeId?: string,
    refItemId?: string
  ): Promise<GalleryPageData> {
    return new Promise<GalleryPageData>(async (resolve, reject) => {
      /* Response Data. */
      let _formattedUploadContents: KpDictionary<GalleryUploadContentDTO> =
        new KpDictionary<GalleryUploadContentDTO>();
      let _categoriesRefactored: KpDictionary<ExtendedCategoryDTO> =
        new KpDictionary<ExtendedCategoryDTO>();
      let _citiesRefactored: KpDictionary<ExtendedCityDTO> =
        new KpDictionary<ExtendedCityDTO>();
      /* Fetch All Categories (Under Root Category). */
      const [_subCategories, _cityRefereces] = [
        await to(
          this.utilService.getRootCategoryTree(subCategoriesRootCategoryId)
        ),
        await to(
          this.referenceService.getCountryCities(
            TAConstants.Settings.IT_COUNTRY_ID,
            true,
            TAConstants.Settings.LANGUAGES,
            false,
            true
          )
        ),
      ];
      if (isNullOrUndefined(_cityRefereces.data)) reject(null);
      else {
        const cities = _cityRefereces.data as KpDictionary<ExtendedCityDTO>;
        /* Extract/Format Data. */
        const _cityIds: Array<string> = cities.Keys();
        /* Set Root Tree. */
        let _rootTree: ExtendedCategoryDTO = null;
        let _childCategoryIds: Array<string> = [];
        if (!_cityIds.length) reject(null);
        else {
          if (!isNull(_subCategories.data)) {
            _rootTree = _subCategories.data as ExtendedCategoryDTO;
            _childCategoryIds = _rootTree.children.map((child) =>
              child.id.toString()
            );
          }
          /* Count Upload Content of Each Reference. */
          const [_countByReferenceCity, _countByReferenceCategory] = [
            await to(
              this.CountUploadContentByReference(_cityIds, Item_type.CITY, true)
            ),
            await to(
              this.CountUploadContentByReference(
                _childCategoryIds,
                Item_type.CATEGORY,
                true
              )
            ),
          ];
          if (isNullOrUndefined(_countByReferenceCity.data)) reject(null);
          else {
            const _countersCity =
              _countByReferenceCity.data as KpDictionary<number>;
            let _countersCategory = new KpDictionary<number>();
            if (!isNullOrUndefined(_countByReferenceCategory.data)) {
              _countersCategory =
                _countByReferenceCategory.data as KpDictionary<number>;
            }
            const _getCurrentData = await to(
              this.getUploadContentByReference(
                contentRootCategoryId,
                ITEM_TYPE.CATEGORY,
                !isNullOrUndefined(refItemTypeId)
                  ? [refItemId]
                  : [contentRootCategoryId],
                isNullOrUndefined(refItemTypeId)
                  ? ITEM_TYPE.CATEGORY
                  : Item_type.CITY,
                orderType,
                true,
                false,
                rangeFrom,
                rangeTo
              )
            );
            if (isNullOrUndefined(_getCurrentData.data)) reject(null);
            else {
              logger.log("_getCurrentData", _getCurrentData.data);
              let _uploadContents: KpDictionary<ExtendedUploadContentDTO> =
                _getCurrentData.data;
              if (_uploadContents.Count) {
                /* Fromat Data per Category. */
                if (_childCategoryIds.length) {
                  let _tmpDictionary: KpDictionary<ExtendedUploadContentDTO> =
                    new KpDictionary<ExtendedUploadContentDTO>();
                  for (const _ChildCategoryId of _childCategoryIds) {
                    /* Format Output. */
                    _uploadContents
                      .Values() /* Parse Response. */
                      .map((item) => {
                        if (
                          item.referencesMapped.ContainsKey(
                            _ChildCategoryId.toString()
                          )
                        ) {
                          _formattedUploadContents.Add(item.uploadContentId, {
                            ...item,
                            cityIds: [],
                            categoryId: _ChildCategoryId.toString(),
                            subcategoryIds: null,
                          });
                          _tmpDictionary.Add(
                            item.uploadContentId.toString(),
                            _formattedUploadContents.Item(
                              item.uploadContentId.toString()
                            )
                          );
                        }
                      });
                    _categoriesRefactored.Add(_ChildCategoryId.toString(), {
                      ..._rootTree.childrenMapped.Item(
                        _ChildCategoryId.toString()
                      ),
                      ...{
                        galleryItems: _tmpDictionary,
                        itemCount: _countersCategory.Item(
                          _ChildCategoryId.toString()
                        ),
                      },
                    });
                  }
                }
                /* Format Data per City. */
                for (const _cityId of _cityIds) {
                  /* Format Output. */
                  _uploadContents
                    .Values() /* Parse Response. */
                    .map((item) => {
                      if (item.referencesMapped.ContainsKey(_cityId)) {
                        if (
                          !_formattedUploadContents.ContainsKey(
                            item.uploadContentId
                          )
                        ) {
                          _formattedUploadContents.Add(item.uploadContentId, {
                            ...item,
                            cityIds: [],
                            categoryId: null,
                            subcategoryIds: null,
                          });
                        }
                        _formattedUploadContents
                          .Item(item.uploadContentId)
                          .cityIds.push(_cityId);
                      }
                    });
                  _citiesRefactored.Add(_cityId, {
                    ...cities.Item(_cityId),
                    ...{
                      itemCount: _countersCity.Item(_cityId),
                    },
                  });
                }
              }
              logger.log({
                galleryItems: _formattedUploadContents,
                categories: _categoriesRefactored,
                cities: _citiesRefactored,
              });
              resolve({
                galleryItems: _formattedUploadContents,
                categories: _categoriesRefactored,
                cities: _citiesRefactored,
              });
            }
          }
        }
      }
    });
  }
}

export interface GalleryPageData {
  galleryItems: KpDictionary<GalleryUploadContentDTO>;
  categories: KpDictionary<ExtendedCategoryDTO>;
  cities: KpDictionary<ExtendedCityDTO>;
}

export interface GalleryUploadContentDTO extends ExtendedUploadContentDTO {
  categoryId?: string;
  cityIds?: Array<string>;
  subcategoryIds?: Array<string>;
}
