/**
 * Created by papadopoulos on 21/05/2019.
 */
import {Injectable} from "@angular/core";
import {ShortifyApi} from "../../sharedApi/api/ShortifyApi";
import {UtilsService} from "../utils.service";
import {TAValues} from "../../talosApi/settings";
import {IShortify} from "../../sharedApi/models/IShortify";


@Injectable()
export class ShortifyService {

    constructor(private api: ShortifyApi, private utilsService: UtilsService) {
    }

    getShortifyLink(link: string): Promise<String> {
        return new Promise((resolve, reject) => {

            const fUserId = this.utilsService.stringToBase64('', TAValues.UserId);
            const fLink = this.utilsService.stringToBase64('', link);

            this.api.getShortifyUrl(fUserId, fLink)
                .then((response: IShortify) => {
                    if (response && response.status && response.content && response.content.url) {
                        resolve(response.content.url);
                    } else {
                        reject("ERROR")
                    }
                }).catch(reject);
        });
    }
}