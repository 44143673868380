import {Injectable} from "@angular/core";
import {ItemService} from "../../../services/talos/item.service";
import {TAValues} from "../../../talosApi/settings";

@Injectable({
    providedIn: 'root'
})
export class FeedbackContainerService {
    constructor(private itemSrv: ItemService) {

    }

    rateAndPost(itemId: string, itemTypeId: number, comment: string, rate: number): Promise<boolean> {
        return new Promise((resolve, reject) => {

            const promises = [];
            if (rate && rate > 0) {
                promises.push(this.itemSrv.postRating(itemTypeId, itemId, rate));
            }
            if (comment && comment != '') {
                promises.push(this.itemSrv.postComment(itemTypeId, itemId, TAValues.UserId, {comment: comment}));
            }
            if (promises.length == 0) {
                reject("ERROR");
                return;
            }
            Promise.all(promises).then(result => {
                resolve(true);
            }).catch(err => {
                reject(err);
            })
        });
    }
}