import { ImageItem } from "../shared/image/image.item.dto";
import { ButtonVariant } from "../shared/interfaces/IActionButton";

/*
 * @Author: Peter Fousteris (petfoust@gmail.com)
 * @Date: 2019-05-21 15:33:17
 * @Last Modified by: Peter Fousteris (petfoust@gmail.com)
 * @Last Modified time: 2021-06-29 13:18:26
 */

export const getButtonVariantClassName = (variant?: ButtonVariant) => {
  if (!variant) return "";
  return `btn-${variant}`;
};

export function format(first: string, middle: string, last: string): string {
  return (
    (first || "") + (middle ? ` ${middle}` : "") + (last ? ` ${last}` : "")
  );
}

export enum DisplayModes {
  GRID = 0,
  LIST = 1,
}

/**
 * Event types
 */
export enum EventTypes {
  DEFAULT = 0,
  CARD_LIKE = 1,
  PLAY_VIDEO = 2,
  REDEEM = 3,
}

/**
 * Card types
 */
export enum CardTypes {
  VECTICAL = 0,
  HORIZONTAL = 1,
  ARTICLE = 2,
}

/**
 * Box directions
 */
export enum BoxDirections {
  LEFT = 1,
  RIGHT = 0,
}

/**
 * Box types
 */
export enum BoxTypes {
  IMAGE_IN_BOX = 0,
  BOX_IN_IMAGE = 1,
  VERTICAL_CENTER_CARD = 2,
}

/**
 * Mobile box types
 */
export enum MobileBoxTypes {
  IMAGE_BOX = 0,
  TITLE_IMAGE_BOX = 1,
  BOX_IMAGE = 2,
  TITLE_BOX_IMAGE = 3,
}

export enum BoxSize {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4,
}

export const EVENT_DEFINITIONS = Object.freeze({
  ACTION_BUTTON_DEFAULT: {
    NAME: "ACTION_BUTTON_CLICK",
    ID: 0,
  },
  CARD_BODY_LIKE: {
    NAME: "CARD_BODY_LIKE",
    ID: 1,
  },
  CARD_LIKE: {
    NAME: "CARD_LIKE",
    ID: 2,
  },
  CARD_ACTION: {
    NAME: "CARD_ACTION",
    ID: 3,
  },
  CARD_BODY_ARTICLE_READ: {
    NAME: "CARD_BODY_ARTICLE_READ",
    ID: 4,
  },
  CARD_ARTICLE_READ: {
    NAME: "CARD_ARTICLE_READ",
    ID: 5,
  },
  REWARD_CATALOG_CARD: {
    NAME: "CATALOG_CLICK",
    ID: 6,
  },
  QUIZ_BODY_ANSWER_REVEAL: {
    NAME: "QUIZ_BODY_ANSWER_REVEAL",
    ID: 7,
  },
  CARD_QUIZ_BODY_ANSWER_REQUEST: {
    NAME: "QUIZ_BODY_ANSWER_REQUEST",
    ID: 8,
  },
  CARD_ACTION_DEFAULT: {
    NAME: "CARD_ACTION_DEFAULT",
    ID: 10,
  },
  SELECT_ACTION_DEFAULT: {
    NAME: "SELECT_ITEM_CHANGE",
    ID: 11,
  },
  DISPLAY_CHANGE_MODE: {
    NAME: "DISPLAY_CHANGE_MODE",
    ID: 12,
  },
  CARD_BODY_VIDEO_PLAY: {
    NAME: "CARD_BODY_VIDEO_PLAY",
    ID: 13,
  },
  CARD_VIDEO_PLAY: {
    NAME: "CARD_VIDEO_PLAY",
    ID: 14,
  },
  CARD_BODY_REDIRECT: {
    NAME: "CARD_BODY_REDIRECT",
    ID: 15,
  },
  CARD_REDIRECT: {
    NAME: "CARD_REDIRECT",
    ID: 16,
  },
  CARD_BODY_CLOSE: {
    NAME: "CARD_BODY_CLOSE",
    ID: 17,
  },
  CARD_CLOSE: {
    NAME: "CARD_CLOSE",
    ID: 18,
  },
  CARD_BODY_VIDEO_WATCHED: {
    NAME: "CARD_BODY_VIDEO_WATCHED",
    ID: 19,
  },
  CARD_VIDEO_WATCHED: {
    NAME: "CARD_VIDEO_WATCHED",
    ID: 20,
  },
  DISPLAY_MODES_SHOW_MOBILE: {
    NAME: "DISPLAY_MODES_SHOW_MOBILE",
    ID: 21,
  },
  DISPLAY_MODES_HIDE_MOBILE: {
    NAME: "DISPLAY_MODES_HIDE_MOBILE",
    ID: 22,
  },
  WHATS_APP_SHARE: {
    NAME: "WHATS_APP_SHARE",
    ID: 23,
  },
  EMAIL_SHARE: {
    NAME: "EMAIL_SHARE",
    ID: 24,
  },
  SMS_SHARE: {
    NAME: "SMS_SHARE",
    ID: 25,
  },
  COPY_TO_CLIPBOARD: {
    NAME: "COPY_TO_CLIPBOARD",
    ID: 26,
  },
  FACEBOOK_SHARE: {
    NAME: "FACEBOOK_SHARE",
    ID: 27,
  },
  COPY_TO_CLIPBOARD_UNICODE: {
    NAME: "COPY_TO_CLIPBOARD_UNICODE",
    ID: 28
  },
  /* REFACTORED. 
        Start From 100
    */
  SHOW_CODE_MODAL: {
    NAME: "SHOW_CODE_MODAL",
    ID: 100,
    PRODUCED_ID: 101,
  },
  SHOW_CODE_MODAL_CLICKED: {
    NAME: "SHOW_CODE_MODAL_CLICKED",
    ID: 101,
    PRODUCED_ID: null,
  },
  REDIR_TO_URL: {
    NAME: "REDIR_TO_URL",
    ID: 102,
    PRODUCED_ID: 103,
  },
  REDIR_TO_URL_CLICKED: {
    NAME: "REDIR_TO_URL_CLICKED",
    ID: 103,
    PRODUCED_ID: null,
  },
  COPY_TO_CLIPBOARD1: {
    NAME: "COPY_TO_CLIPBOARD",
    ID: 104,
    PRODUCED_ID: 105,
  },
  COPY_TO_CLIPBOARD_CLICKED: {
    NAME: "COPY_TO_CLIPBOARD_CLICKED",
    ID: 105,
    PRODUCED_ID: null,
  },
  SHOW_MORE_RECORDS: {
    NAME: "SHOW_MORE_RECORDS",
    ID: 106,
    PRODUCED_ID: 107,
  },
  SHOW_MORE_RECORDS_CLICKED: {
    NAME: "SHOW_MORE_RECORDS_CLICKED",
    ID: 107,
    PRODUCED_ID: null,
  },
  SHOW_REWARD_MODAL: {
    NAME: "SHOW_REWARD_MODAL",
    ID: 108,
    PRODUCED_ID: 109,
  },
  SHOW_REWARD_MODAL_CLICKED: {
    NAME: "SHOW_REWARD_MODAL_CLICKED",
    ID: 109,
    PRODUCED_ID: null,
  },
  DOWNLOAD_COUPON_PDF: {
    NAME: "DOWNLOAD_COUPON_PDF",
    ID: 110,
    PRODUCED_ID: 116,
  },
  REDIR_TO_APPLICATION_PAGE: {
    NAME: "REDIR_TO_APPLICATION_PAGE",
    ID: 111,
    PRODUCED_ID: null,
  },
  OPEN_SLIDE_SHOW: {
    NAME: "OPEN_SLIDE_SHOW",
    ID: 112,
    PRODUCED_ID: 113,
  },
  OPEN_SLIDE_SHOW_CLICKED: {
    NAME: "OPEN_SLIDE_SHOW_CLICKED",
    ID: 113,
    PRODUCED_ID: null,
  },
  MENU_TO_APPLICATION_PAGE: {
    NAME: "MENU_TO_APPLICATION_PAGE",
    ID: 114,
    PRODUCED_ID: null,
  },
  MENU_TOOLTIP_ACTION: {
    NAME: "MENU_TOOLTIP_ACTION",
    ID: 115,
    PRODUCED_ID: null,
  },
  DOWNLOAD_COUPON_PDF_CLICKED: {
    NAME: "DOWNLOAD_COUPON_PDF_CLICKED",
    ID: 116,
    PRODUCED_ID: null,
  },
  REDIR_TO_APPLICATION_TAB: {
    NAME: "REDIR_TO_APPLICATION_TAB",
    ID: 117,
    PRODUCED_ID: null,
  },
  PENDING_POINTS_CLOSED: {
    NAME: "PENDING_POINTS_CLOSED",
    ID: 118,
    PRODUCED_ID: null,
  },
  REDEEM_PRODUCT_CLICKED: {
    NAME: "REDEEM_PRODUCT_CLICKED",
    ID: 119,
  },
  NOTIFICATION_CLICKED: {
    NAME: "NOTIFICATION_CLICKED",
    ID: 201,
  },
  PAGINATION_CHANGE: {
    NAME: "PAGINATION_CHANGE",
    ID: 202,
  },
  VIDEO_WATCHED: {
    NAME: "VIDEO_WATCHED",
    ID: 203,
  },
  SHOW_MAP_MODAL_CLICKED: {
    NAME: "SHOW_MAP_MODAL_CLICKED",
    ID: 204,
  },
  SHOW_MAP_MODAL: {
    NAME: "SHOW_MAP_MODAL",
    ID: 205,
  },
  BANNER_CLOSE: {
    NAME: "BANNER_CLOSE",
    ID: 210
  },
  BANNER_REDIRECT: {
    NAME: "BANNER_REDIRECT",
    ID: 211
  },
  BANNER_SLIDE_CHANGED: {
    NAME: "BANNER_SLIDE_CHANGED",
    ID: 230
  }
});

export enum WindowTypes {
  DESKTOP,
  MOBILE,
  TABLET,
}

export const MOBILE_SWITCH: number = 575;
export const TABLET_SWITCH: number = 968;

/**
 * Determines whether descendant is
 * @param parent
 * @param child
 * @returns
 */
export function isDescendant(parent, child) {
  let node = child.parentNode;
  while (node != null) {
    if (node == parent) return true;
    node = node.parentNode;
  }
  return false;
}
export function test1(el) {
  var rect = el.getBoundingClientRect();

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left <
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */ &&
    rect.top <
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */
  );
}

/**
 * Gets window type
 * @returns window type
 */
export function getWindowType(): WindowTypes {
  let ret = WindowTypes.DESKTOP;
  if (window.innerWidth <= MOBILE_SWITCH) {
    ret = WindowTypes.MOBILE;
  }
  return ret;
}

/**
 * Props exists
 * @param obj
 * @param key
 * @returns
 */
export function propExists(obj, key) {
  var properties = key.split(".");
  for (var i = 0; i < properties.length; i++) {
    if (!obj || !obj.hasOwnProperty(properties[i])) {
      return false;
    }
    obj = obj[properties[i]];
  }
  return true;
}

/**
 * Props value
 * @param obj
 * @param key
 * @returns
 */
export function propValue(obj, key) {
  var properties = key.split(".");
  for (var i = 0; i < properties.length; i++) {
    if (!obj || !obj.hasOwnProperty(properties[i])) {
      return undefined;
    }
    obj = obj[properties[i]];
  }
  return obj;
}

/**
 * Gets item cssproperties
 * @param obj
 * @param key
 * @returns
 */
export function getItemCSSProperties(obj, key) {
  let ret: { [key: string]: string } = {};
  if (propValue(obj, `${key}.css_properties`) !== undefined)
    ret = propValue(obj, `${key}.css_properties`);
  return ret;
}

/**
 * Images item to csstag
 * @param obj
 * @returns
 */
export function ImageItemToCSSTag(obj: ImageItem) {
  if (!propExists(obj, "path")) return null;
  if (propValue(obj, "path") === null) return null;
  if (!propValue(obj, "path").length) return null;
  return {
    "background-image": `url("${obj.path}")`,
  };
}

/**
 * Determines whether text item rendered is
 * @param textItem
 * @returns
 */
export function isTextItemRendered(obj, key) {
  if (propValue(obj, `${key}.rendered`) === false) return false;
  return true;
}

/**
 * Determines whether item rendered is
 * @param obj
 * @param key
 * @returns
 */
export function isItemRendered(obj, key) {
  if (propValue(obj, `${key}.rendered`) === false) return false;
  return true;
}

/**
 * Determines whether descendant2 is
 * @param parent
 * @param child
 * @returns
 */
export function isDescendant2(parent, child) {
  return (
    child.compareDocumentPosition(parent) & Node.DOCUMENT_POSITION_CONTAINS
  );
}

/**
 * Gets random int
 * @param max
 * @returns
 */
export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

/**
 * Randoms date
 * @param start
 * @param end
 * @returns
 */
export function randomDate(start, end) {
  var d = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function to(promise: Promise<any>): Promise<any> {
  return promise
    .then((data) => {
      return { data: data };
    })
    .catch((err) => {
      return { error: err };
    });
}

export function _isNullOrUndefined(object: any) {
  return object === null || object === undefined;
}

export function handleScroll(enable: boolean) {
  var viewsHtml = document.getElementsByTagName("html");
  if (viewsHtml) {
    for (var i = 0; i < viewsHtml.length; i++) {
      viewsHtml[i].setAttribute("class", enable ? "" : "remove-scroll");
    }
  }

  var viewsBody = document.getElementsByTagName("body");
  if (viewsBody) {
    for (var i = 0; i < viewsBody.length; i++) {
      viewsBody[i].setAttribute("class", enable ? "" : "remove-scroll");
    }
  }
}

/**
 * Detects valid event id
 * @param id
 * @returns
 */
export function detectValidEventId(id: number) {
  if (!id) return null;
  for (const key in EVENT_DEFINITIONS) {
    if (EVENT_DEFINITIONS.hasOwnProperty(key)) {
      const def = EVENT_DEFINITIONS[key];
      if (!_isNullOrUndefined(def.ID)) {
        if (def.ID === id) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * Detects valid event id
 * @param id
 * @returns
 */
export function detectEventChain(id: number) {
  if (!id) return null;
  if (!detectValidEventId(id)) return null;
  for (const key in EVENT_DEFINITIONS) {
    if (EVENT_DEFINITIONS.hasOwnProperty(key)) {
      const def = EVENT_DEFINITIONS[key];
      if (!_isNullOrUndefined(def.ID)) {
        if (def.ID === id) {
          if (!_isNullOrUndefined(def.PRODUCED_ID)) {
            if (detectValidEventId(def.PRODUCED_ID)) {
              return def.PRODUCED_ID;
            }
          }
        }
      }
    }
  }
  return null;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function rafAsync() {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve); //faster than set time out
  });
}

export function checkElement(selector) {
  if (document.getElementById(selector) === null) {
    return rafAsync().then(() => checkElement(selector));
  } else {
    return Promise.resolve(document.getElementById(selector));
  }
}

export async function checkElementAs(selector) {
  const querySelector = document.getElementById(selector);
  while (querySelector === null) {
    await rafAsync();
  }
  return querySelector;
}

/**
 * Gets event definition
 * @param id
 */
export function getEventDefinition(id: number) {
  if (!id) return null;
  if (!detectValidEventId(id)) return null;
  for (const key in EVENT_DEFINITIONS) {
    if (EVENT_DEFINITIONS.hasOwnProperty(key)) {
      const def = EVENT_DEFINITIONS[key];
      if (!_isNullOrUndefined(def.ID)) {
        if (def.ID === id) {
          return def;
        }
      }
    }
  }
  return null;
}

export interface IShareButton {
  id?: number;
  enabled?: boolean;
  image?: string;
  text?: string;
}
