import {Injectable} from "@angular/core";
import {GetCountryStatesQuery, GetStateCitiesQuery, IReferenceMetadataQP, ReferenceApi} from "../../talosApi/api/referenceApi";
import {ResourcesServices} from "./resources.services";
import {TAConstants} from "../../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import _ from 'lodash';
import {IReferenceMetadataDTO} from "../../talosApi/models/IReferenceMetadataDTO";
import {StateDTO, ItemsList, CityDTO} from "src/talosApi";
import {to} from "../../../../src/utils/utils";
import {isNullOrUndefined, isArray} from "util";
import {KpDictionary} from "../../../../src/utils/kp_dictionary/kp.dictionary";
import {ExtendedCityDTO} from "src/talosApi/models/CityDTO";
import {UtilsService} from "../utils.service";
import {HttpHeaders} from "@angular/common/http";

@Injectable()
export class ReferenceService {

    private referencesMetadataArray: Map<string, IReferenceMetadataDTO[]> = new Map<string, IReferenceMetadataDTO[]>();

    constructor(private referenceApi: ReferenceApi,
                private resourcesSrv: ResourcesServices,
                private utilService: UtilsService,) {
    }

    /**
     * List a state's cities
     * @param {GetStateCitiesQuery} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<CityDTO>>}
     */
    public getStateCities(queryParams?: GetStateCitiesQuery, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<CityDTO>> {
        return new Promise((resolve, reject) => {
            this.referenceApi.getStateCities(queryParams, requireSession, headers)
                .then((data) => {
                    resolve(data);
                }).catch((err) => reject(err));
        });
    }

    /**
     * List states
     * @param {GetCountryStatesQuery} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<StateDTO>>}
     */
    public getCountryStates(queryParams?: GetCountryStatesQuery, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<StateDTO>> {
        return new Promise((resolve, reject) => {
            this.referenceApi.getCountryStates(queryParams, requireSession, headers)
                .then((data) => {
                    resolve(data);
                }).catch((err) => reject(err));
        });
    }

    public getReferencesMetadata(valuePoolKeyId: string, input: IReferenceMetadataQP): Promise<any[]> {
        return new Promise((resolve, reject) => {

            const items: IReferenceMetadataDTO[] = this.referencesMetadataArray.get(valuePoolKeyId);
            if (!_.isNil(items)) {
                resolve(items);
                return;
            }

            this.referenceApi.getReferenceMetadata(valuePoolKeyId, input)
                .then((referencesMetadata) => {
                    this.resourcesSrv.getResources(referencesMetadata, 'itemIdForResourceLookup', [Resource_Types.NAME], 'itemTypeIdForResourceLookup')
                        .then((result) => {
                            this.referencesMetadataArray.set(valuePoolKeyId, referencesMetadata);
                            resolve(referencesMetadata);
                        })
                        .catch((error) => {
                            reject(error);
                        })

                })
                .catch((error) => {
                    logger.log(error);
                });
        });


    }

    public async getCountryCities(countryId: string,
                                  resources: boolean = true,
                                  languageIds: Array<string>,
                                  metadatas: boolean = false,
                                  includeDetails: boolean = true,
                                  rangeFrom?: number,
                                  rangeTo?: number,) {
        return new Promise<KpDictionary<ExtendedCityDTO>>(async (resolve, reject) => {
            if (!languageIds.length) resources = false;
            const _getUploadContentsByReference = await to(this.referenceApi.getCountryCities({
                countryIds: [countryId],
                resources: resources,
                languageIds: languageIds,
                rangeFrom: rangeFrom ? rangeFrom : 0,
                rangeTo: rangeTo ? rangeTo : -1,
                includeDetails: includeDetails,
                metadatas: metadatas,
            }));
            if (isNullOrUndefined(_getUploadContentsByReference.data)) reject(null);
            else {
                let _resp: KpDictionary<ExtendedCityDTO> = new KpDictionary<ExtendedCityDTO>();
                await _getUploadContentsByReference.data.map(async (state: StateDTO) => {
                    if (!isNullOrUndefined(state.cities) && isArray(state.cities) && state.cities.length) {
                        await state.cities.map((city: CityDTO) => {
                            if (!_resp.ContainsKey(city.cityId.toString())) {
                                _resp.Add(city.cityId.toString(), {
                                    ...city,
                                    resourcesMapped: this.utilService.formatItemResources(city)
                                })
                            }
                        });
                    }
                });
                if (_resp.Count()) resolve(_resp);
                else reject(null);
            }
        });
    }


} // END CLASS
