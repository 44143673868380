import {Http2Talos} from "../http2Talos";
import {Injectable} from "@angular/core";
import {FilterContentIndexedDataInput} from "../models/FilterContentIndexedDataInput";
import {GetIndexedContentQuery} from "./IndexedDataApi";
import {IndexedDataContent} from "../models/IndexedDataContent";
import {HttpHeaders} from "@angular/common/http";

@Injectable()
export class RecommendationsApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * GEt Recommendation Content
     * @param {string} userId
     * @param {FilterESContentDataInput} input
     * @param {GetIndexedContentQuery} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<IndexedDataContent>>}
     */
    public getRecommendationContent(userId: string, input?: FilterESContentDataInput, queryParams?: GetIndexedContentQuery, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<IndexedDataContent>> {
        return this.http2Talos.post({
            requireSession: requireSession,
            headers: headers,
            path: `/recommendations/es/${userId}/content`,
            input: input,
            queryParams: queryParams
        });
    }
}

export interface FilterESContentDataInput {
    itemIds?: Array<string>;
    itemTypeIds?: Array<number>;
    categoryIds?: Array<string>;
    criterionIds?: Array<string>;
    associatedItemIds?: Array<string>;
    priceFrom?: number;
    priceTo?: number;
    unitTypeId?: number;
    contentReferencedTypes?: Array<ESContentReferencedTypeDTO>;
    dataFilters?: Array<DataFiltering>;
    rangeFrom?: number;
    rangeTo?: number;
    sortOrderTypes?: Array<string>;
    countMode?: boolean;
    userRewarded?: boolean;
    currentlyRewarding?: boolean;
    currentlyActive?: boolean;
    matchingLevel?: boolean;
    matchingRules?: boolean;
}

export interface ESContentReferencedTypeDTO {
    itemTypeId?: number;
    typeId?: string;
}

export interface DataFiltering {
    property?: string;
    propertyValue?: string;
    mode?: number;
}