import { Injectable } from "@angular/core";
import { Http2Talos } from "../http2Talos";
import { IndirectUserDataDTO } from "../models/IndirectUserDataDTO";

@Injectable()
export class IndirectDataApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Get Indexed User Data
   * @param userId
   * @param mode
   * @param queryParams
   * @param requireSession_
   * @returns {Promise<Array<IndirectUserDataDTO>>}
   */
  public getIndirectUserData(
    userId: string,
    mode: number,
    queryParams?: GetIndirectUserDataQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndirectUserDataDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession_,
      path: `/indirect_data/${userId}/${mode}/users/data`,
      queryParams: queryParams,
    });
  }
}

export interface GetIndirectUserDataQuery {
  periodType?: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY" | "ALL_TIME";
  itemIds?: Array<string>;
  itemTypeId?: number;
  rangeFrom?: number;
  rangeTo?: number;
  resources?: boolean;
  languageIds?: Array<string>;
  metadatas?: boolean;
  statistics?: boolean;
}
