import { Http2Talos } from "../http2Talos";
import { Injectable } from "@angular/core";
import { DetailedBrandProductCouponDTO } from "../models/DetailedBrandProductCouponDTO";
import { TAConstants, TAValues } from "../settings";
import {
  AuthenticateKeyInput,
  AuthenticateUserInput,
  AuthenticateUserResponse,
  AuthorizationKeyInput,
  AuthorizationKeyResponse,
  GetStatisticsInput,
  GuestInfoInput,
  ItemsList,
  PurchaseCouponInput,
  TransactionHistoryInput,
  User,
  UserProfileUpdateInput,
} from "../models";
import { HttpHeaders } from "@angular/common/http";
import { SsoAuthedicationInput } from "../models/SsoAuthedicationinput";
import { SaveUserMetadataInput } from "../models/SaveUserMetadataInput";
import { BadgeDTO } from "../models/BadgesDTO";
import { ActionConstrainDTO } from "../models/ActionConstrainDTO";
import { BalanceDTO } from "../models/BalanceDTO";
import Unit_Types = TAConstants.Unit_Types;
import { UserLevelsDTO } from "../models/UserLevelsDTO";
import { UserHistoryResponse } from "../models/userHistoryResponse";
import { EWalletDTO } from "../models/EWalletDTO";
import { ExtSystemInfoDTO } from "../models/ExtSystemInfoDTO";
import Settings = TAConstants.Settings;
import { ProfileFilter } from "../models/ProfileFilter";
import { NewUserInput } from "../models/NewUserInput";
import { UserMaxObtainablePointsDTO, UserPendingPointsDTO } from "../models/UserPendingPointsDTO";
import { SendVirtualAmountInput } from "../models/SendVirtualAmountInput";
import { FilterUsersIndexedDataInput } from "../models/FilterUsersIndexedDataInput";
import { ESUserDTO } from "../models/ESUserDTO";
import { TransactionGroupByGroupByDTO } from "../models/TransactionGroupByGroupByDTO";
import { LoginWithAuthKeyInput } from "../models/LoginWithAuthKeyInput";
import { AuthKeyUserResponse } from "../models/AuthKeyUserResponse";
import { EWalletSummaryPerVmTransactionTypeTO } from "../models/EWalletSummaryPerVmTransactionTypeTO";

@Injectable()
export class UserApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Just for testing
   * @param {AuthenticateUserInput} input
   * @return {Promise<AuthenticateUserResponse>}
   */
  public authenticate(
    input: AuthenticateUserInput
  ): Promise<AuthenticateUserResponse> {
    return this.http2Talos.post({
      path: "/users/authenticate",
      input: input,
      requireSession: false,
    });
  }

  /**
   * Get User's coupons
   * @param {Array<number>} brandProductCouponStatusIds
   * @param {Array<string>} brandProductIds
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @param {boolean} friendsOnly
   * @return {Promise<Array<DetailedBrandProductCouponDTO>>}
   */
  public getUserCouponRewards(
    brandProductCouponStatusIds?: Array<number>,
    brandProductIds?: Array<string>,
    rangeFrom?: number,
    rangeTo?: number,
    friendsOnly?: boolean
  ): Promise<Array<DetailedBrandProductCouponDTO>> {
    return this.http2Talos.get({
      path: `/users/${TAValues.UserId}/coupons`,
      queryParams: {
        brandProductCouponStatusIds: brandProductCouponStatusIds,
        brandProductIds: brandProductIds,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        friendsOnly: friendsOnly,
      },
    });
  }

  /**
   * @param input
   * @param requireSession_
   * @returns {Observable<AuthenticateUserResponse>}
   */
  public createGuest(
    input: GuestInfoInput,
    requireSession_: boolean = false,
    headers: HttpHeaders = null
  ): Promise<AuthenticateUserResponse> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: "/users/guest/getSession",
      input: input,
      headers: headers,
    });
  }

  /**
   * Upgrade Guest User
   * @param {NewUserInput} input
   * @param {HttpHeaders} headers
   * @param {boolean} requireSession_
   * @return {Promise<AuthenticateUserResponse>}
   */
  public upgradeUser(
    input: NewUserInput,
    headers: HttpHeaders,
    requireSession_: boolean = false
  ): Promise<AuthenticateUserResponse> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: "/users/guest/upgrade",
      input: input,
      headers: headers,
    });
  }

  /**
   *
   * @param {string} username
   * @param {boolean} requireSession_
   * @return {Promise<boolean>}
   */
  public checkUsername(
    username: string,
    requireSession_: boolean = false
  ): Promise<boolean> {
    return this.http2Talos.get({
      requireSession: requireSession_,
      path: `/users/usernameCheck/${username}`,
    });
  }

  /**
   *
   * @param input
   * @returns {Observable<AuthorizationKeyResponse>}
   */
  public getAuthKey(
    input: AuthorizationKeyInput
  ): Promise<AuthorizationKeyResponse> {
    return this.http2Talos.post({
      path: `/users/${input.userId}/authKey`,
      input: input.input,
    });
  }

  /**
   *
   * @param input
   * @returns {Observable<AuthenticateUserResponse>}
   */
  public authenticateKey(
    input: AuthenticateKeyInput
  ): Promise<AuthenticateUserResponse> {
    return this.http2Talos.post({
      path: "/users/authenticateKey/",
      input: input,
    });
  }

  /**
   *
   * @param input
   * @returns {Observable<AuthenticateUserResponse>}
   */
  public authenticateKeyNew(
    input: LoginWithAuthKeyInput
  ): Promise<AuthKeyUserResponse> {
    return this.http2Talos.post({
      path: "/users/login/authKey",
      input: input,
    });
  }

  /**
   *
   * @param userId
   * @param requireSession
   * @param headers
   * @returns {Observable<User>}
   */
  public userProfile(
    userId: string,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<User> {
    return this.http2Talos.get({
      headers: headers,
      requireSession: requireSession,
      path: `/users/${userId}`,
    });
  }

  /**
   * @param input
   * @param requireSession
   * @returns {Observable<any>}
   */
  public changeSessionLang(
    input: any,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/users/session/lang`,
      input: input,
    });
  }

  /**
   * @param userId
   * @param input
   * @param requireSession
   * @returns {Observable<any>}
   */
  public userStats(
    userId: string,
    input: GetStatisticsInput,
    requireSession: boolean = true
  ): Promise<StatsReturn> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/users/${userId}/stats/`,
      input: input,
    });
  }

  /**
   *
   * @param userId
   * @param input
   * @param requireSession
   * @param headers
   * @returns {Observable<any>}
   */
  public updateMyProfile_(
    userId: string,
    input: UserProfileUpdateInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<any> {
    return this.http2Talos.post({
      headers: headers,
      requireSession: requireSession,
      path: `/users/${userId}`,
      input: input,
    });
  }

  /**
   *
   * @param userId
   * @param input
   * @param requireSession
   * @returns {Observable<any>}
   */
  public purchaseCoupon(
    userId: string,
    input: PurchaseCouponInput,
    requireSession: boolean = true
  ): Promise<IPurchases> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/users/purchase/${userId}`,
      input: input,
    });
  }

  /**
   *
   * @param {string} userId
   * @param {string} couponId
   * @param {string} friendId
   * @param {boolean} requireSession
   * @return {Promise<any>}
   */
  public giftCoupon(
    userId: string,
    couponId: string,
    friendId: string,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/users/${userId}/${couponId}/gift/${friendId}`,
    });
  }

  /**
   *
   * @param gameTypeId
   * @param authenticationSourceId
   * @param input
   */
  public ssoAuthentication(
    gameTypeId,
    authenticationSourceId,
    input: SsoAuthedicationInput
  ): Promise<AuthenticateUserResponse> {
    return this.http2Talos.post({
      path: `/users/sso/${gameTypeId}/${authenticationSourceId}`,
      input: input,
    });
  }

  /**
   *
   * @param input
   * @param requireSession
   * @param headers
   * @returns {Observable<any>}
   */
  public saveMetadata(
    input: SaveUserMetadataInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<AuthorizationKeyResponse> {
    return this.http2Talos.post({
      headers: headers,
      requireSession: requireSession,
      path: `/users/saveMetadata`,
      input: input,
    });
  }

  /**
   *
   * @param input
   * @param userId
   * @param requireSession
   * @returns {Observable<any>}
   */
  public cancelCoupon(
    input: ICancelCoupon,
    userId: string,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/users/${userId}/coupon/cancel`,
      input: input,
    });
  }

  /**
   *
   * @param {string} userId
   * @return {Promise<any>}
   */
  public getUserLevel(userId: string): Promise<UserLevelsDTO> {
    return this.http2Talos.get({
      path: `/users/${userId}/level`,
    });
  }

  public getLevel(): Promise<UserLevelsDTO> {
    return this.http2Talos.get({
      path: `/users/level`,
    });
  }

  /**
   *
   * @param userId
   * @param input
   * @param {boolean} requireSession
   * @returns {Observable<ItemsList<BadgeDTO>>}
   */
  public userBadges(
    userId: string,
    input: IAchievementsQP,
    requireSession: boolean = true
  ): Promise<ItemsList<BadgeDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/users/${userId}/achievements`,
      queryParams: input,
    });
  }

  /**
   * Users achievements
   * @param userId
   * @param input
   * @param [requireSession]
   * @returns achievements
   */
  public async userAchievements(
    userId: string,
    input: AchievementQ,
    requireSession: boolean = true
  ): Promise<ItemsList<BadgeDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/achievements/${userId}`,
      queryParams: input,
    });
  }

  /**
   *
   * @param userId
   * @param input
   * @param {boolean} requireSession
   * @returns {Observable<any>}
   */
  public uploadImgUser(
    userId: string,
    input: string,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/users/${userId}/avatars`,
      input: { imageData: input },
    });
  }

  /**
   * @description Use this ApiCall to get the uniq code.
   * @param input
   * @param requireSession
   * @returns {Observable<R>}
   */
  public actionConstraint(
    input: IActionConstraint,
    requireSession: boolean = true
  ): Promise<ItemsList<ActionConstrainDTO[]>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/users/actionConstraint`,
      queryParams: input,
    });
  }

  /**
   *
   * @param userId
   * @param input
   * @param requireSession
   * @returns {Observable<BalanceDTO>}
   */
  public userBalance(
    userId: string,
    input: IBalance,
    requireSession: boolean = true
  ): Promise<BalanceDTO[]> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/users/${userId}/balance`,
      queryParams: input,
    });
  }

  /**
   * Get User Balance Type
   * @param userId
   * @param queryParams
   * @param requireSession
   * @returns {Observable<Array<EWalletSummaryPerVmTransactionTypeTO>>}
   */
  public userBalanceType(
    userId: string,
    queryParams: BalanceTypeQ,
    requireSession: boolean = true
  ): Promise<Array<EWalletSummaryPerVmTransactionTypeTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/users/${userId}/balance/type`,
      queryParams: queryParams,
    });
  }

  /**
   *
   * @param userId_
   * @param requireSession
   */
  public userLevel(
    userId_: string,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/users/${userId_}/level`,
    });
  }

  public transactionsHistory(
    userId: string,
    queryParams: TransactionHistoryInput,
    requireSession: boolean = true
  ): Promise<Array<EWalletDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      queryParams: queryParams,
      path: `/users/${userId}/transactions`,
    });
  }

  public transactionsHistoryGroupBy(
    userId: string,
    queryParams: TransactionHistoryInput,
    requireSession: boolean = true
  ): Promise<Array<TransactionGroupByGroupByDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      queryParams: queryParams,
      path: `/users/${userId}/transactions/groupBy`,
    });
  }

  public unregisterUser(
    userId: string,
    requireSession_: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/users/${userId}/unregister`,
    });
  }

  /**
   *
   * @param {string} userId
   * @param {ExtSystemInfoDTO} input
   * @param {boolean} requireSession_
   * @return {Promise<any>}
   */
  public saveExternalUser(
    userId: string,
    input: ExtSystemInfoDTO,
    requireSession_: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/users/external/${userId}`,
      input: input,
    });
  }

  /**
   * Get Indexed Users
   * @param {Array<ProfileFilter>} profileFilters
   * @param {boolean} guest
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @param {boolean} requireSession_
   * @return {Promise<any>}
   */
  public getIndexedUsers(
    profileFilters: Array<ProfileFilter>,
    guest: boolean,
    rangeFrom: number,
    rangeTo: number,
    requireSession_: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/indexed_data/users`,
      input: {
        gameTypeId: Settings.GAME_TYPE_ID,
        guest: guest,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        profileFilters: profileFilters,
      },
    });
  }

  /**
   * Get Indexed Data New
   * @param {FilterUsersIndexedDataInput} input
   * @param {boolean} requireSession_
   * @return {Promise<any>}
   */
  public getIndexedUsersnew(
    input?: FilterUsersIndexedDataInput,
    requireSession_: boolean = true
  ): Promise<Array<ESUserDTO>> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/indexed_data/users`,
      input: input,
    });
  }

  /**
   * Convert balance
   * @param {string} userId
   * @param {ConvertInput} input
   * @param {boolean} requireSession_
   * @return {Promise<any>}
   */
  public convertBalance(
    userId: string,
    input: ConvertInput,
    requireSession_: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/users/${userId}/convert`,
      input: input,
    });
  }

  /**
   * Get Pending Points
   * @param {string} userId
   * @param {PendingPointsQ} queryParams
   * @param {boolean} requireSession_
   * @return {Promise<UserPendingPointsDTO>}
   */
  public getPendingPoints(
    userId: string,
    queryParams: PendingPointsQ,
    requireSession_: boolean = true
  ): Promise<UserPendingPointsDTO> {
    return this.http2Talos.get({
      requireSession: requireSession_,
      path: `/users/${userId}/pendingPoints`,
      queryParams: queryParams,
    });
  }

  // This request gives the Yearly Maximum points a user can earn
  // from Asset Purchases and also the current points reached.
  // *** CLIENT NEEDS TO CALCULATE THE PENDING POINTS TO SHOW IN PRODUCT-OVERVIEW.COMPONENT.TS ***
  public getMaxObtainablePoints(
    userId: string,
    actionConstraintId: string
  ): Promise<UserMaxObtainablePointsDTO> {
    return this.http2Talos.get({
      path: `/users/actionConstraint`,
      queryParams: {
        userId: userId,
        gameTypeId: Settings.GAME_TYPE_ID,
        actionConstraintId: actionConstraintId,
        rangeFrom: 0,
        rangeTo: 1,
      }
    });
  }
  //

  /**
   * Tranfer Currency
   * @param {string} userId
   * @param {SendVirtualAmountInput} input
   * @param {boolean} requireSession_
   * @return {Promise<any>}
   */
  public tranferCurrency(
    userId: string,
    input: SendVirtualAmountInput,
    requireSession_: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/users/${userId}/currency`,
      input: input,
    });
  }
} // END CLASS

export interface StatsReturn {
  statistics?: any;
}

export interface IBalance {
  unitTypeId: Array<number | Unit_Types>;
  gameTypeId: string;
}

export interface IActionConstraint {
  userId: string;
  gameTypeId: string;
  actionConstraintId?: number;
  eventTypeId?: number;
  eventProcessingConstraintOnly: boolean;
  rangeFrom: number;
  rangeTo: number;
}

export interface IPurchases {
  brandProductId: string;
  couponCodes: string[];
  couponIds: string[];
}

export interface ICancelCoupon {
  couponIds: string[];
  comment?: string;
  reason?: number;
  unitTypeId?: number;
  chargeBack?: boolean;
}

export interface IAchievementsQP {
  achievementTypeTypeIds?: Array<number>;
  achievementTypeIds?: Array<string>;
  dateFrom?: string;
  dateTo?: string;
  gameTypeId: string;
  includeDetails?: boolean;
  matchLevel?: boolean;
  isOnlyNewAchievements?: boolean;
  filterOverlappingAchievements?: boolean;
}

export interface AchievementQ {
  achievementIds?: Array<string>;
  achievementTypeIds?: Array<number>;
  resources?: boolean;
  metadatas?: boolean;
  languageIds?: Array<string>;
  rangeFrom?: number;
  rangeTo?: number;
  activeFrom?: string;
  activeTo?: string;
  active?: boolean;
  currentlyActive?: boolean;
  matchingLevel?: boolean;
  includeDetails?: boolean;
  filterOverlappingAchievements?: boolean;
}

export interface ConvertInput {
  points?: number;
  fromUnitTypeId?: number;
  toPoints?: number;
  toUnitTypeId?: number;
}

export interface PendingPointsQ {
  excludeZeroAmounts?: boolean;
  orderType?: string;
  eventTypeIds?: Array<number>;
  resources?: boolean;
  metadatas?: boolean;
  languageIds?: Array<string>;
  rangeFrom?: number;
  rangeTo?: number;
}

export interface BalanceTypeQ {
  gameTypeId?: string;
  unitTypeIds?: Array<number>;
  vmTransactionTypeIds?: Array<number>;
  rangeFrom?: number;
  rangeTo?: number;
}
