import { Http2Talos } from "../http2Talos";
import { Injectable } from "@angular/core";
import { ESUserDTO } from "../models/ESUserDTO";
import { FilterUsersIndexedDataInput } from "../models/FilterUsersIndexedDataInput";
import { FilterContentIndexedDataInput } from "../models/FilterContentIndexedDataInput";
import { IndexedDataContent } from "../models/IndexedDataContent";
import { IndirectUserDataDTO } from "../models/IndirectUserDataDTO";

@Injectable()
export class IndexedDataApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Get Indexed Users
   * @param {FilterUsersIndexedDataInput} input
   * @param {boolean} requireSession_
   * @return {Promise<Array<ESUserDTO>>}
   */
  public getIndexedUsers(
    input?: FilterUsersIndexedDataInput,
    requireSession_: boolean = true
  ): Promise<Array<ESUserDTO>> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/indexed_data/users`,
      input: input,
    });
  }

  /**
   * Get Indexed Content
   * @param {FilterContentIndexedDataInput} input
   * @param {GetIndexedContentQuery} queryParams
   * @param {boolean} requireSession_
   * @return {Promise<Array<IndexedDataContent>>}
   */
  public getIndexedContent(
    input?: FilterContentIndexedDataInput,
    queryParams?: GetIndexedContentQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndexedDataContent>> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/indexed_data/content`,
      input: input,
      queryParams: queryParams,
    });
  }

  /**
   * Get Indexed Content
   * @param {FilterContentIndexedDataInput} input
   * @param {GetIndexedContentQuery} queryParams
   * @param {boolean} requireSession_
   * @return {Promise<Array<IndexedDataContent>>}
   */
  public getRecomendationContent(
    input?: FilterContentIndexedDataInput,
    queryParams?: GetIndexedContentQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndexedDataContent>> {
    return this.http2Talos.post({
      requireSession: requireSession_,
      path: `/recommendations/indexedData/content`,
      input: input,
      queryParams: queryParams,
    });
  }
}

export interface GetIndexedContentQuery {
  resources?: boolean;
  languageIds?: Array<string>;
  metadatas?: boolean;
  statistics?: boolean;
}
