import {Component, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {BalanceDTO} from "../../../talosApi/models/BalanceDTO";
import {TAConstants} from "../../../talosApi/settings";
import Unit_Types = TAConstants.Unit_Types;
import {IReferenceLevel} from "../../pages/profile/profile.component";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../services/talos/user.service";
import {UtilsService} from "../../../services/utils.service";
import {ProfileService} from "../../pages/profile/profile.service";
import {Options} from "ng5-slider";
import {ImageItem} from "../../../../../src/shared/image/image.item.dto";
import {EVENT_DEFINITIONS} from "../../../../../src/utils/utils";
import {FeedbackContainerService} from "./feedback-container.service";

@Component({
    selector: 'app-feedback-container',
    templateUrl: './feedback-container.component.html',
    styleUrls: ['./feedback-container.component.scss']
})
export class FeedbackContainerComponent implements OnInit {

    @Input() hasFeedback: boolean = false;
    @Input() itemId: string;
    @Input() itemTypeId: number;
    @Input() header: string;
    arrowBtnIcon: ImageItem = null;
    rateSelected: number;
    comment: string;
    listenerFn: () => void;
    feedbackProgress = false;

    constructor(public mainContainer: ElementRef,
                public renderer: Renderer2,
                private feedbackSrv: FeedbackContainerService) {
    }

    ngOnInit() {
        this.arrowBtnIcon = {
            path: 'assets/img/arrow_icon.png',
            altText: '',
            default: 'assets/img/arrow_icon.png'
        };

        this.listenerFn = this.renderer.listen(this.mainContainer.nativeElement, EVENT_DEFINITIONS.ACTION_BUTTON_DEFAULT.NAME, this.eventFired.bind(this));
    }

    rateClicked(index: number) {
        this.rateSelected = index;
    }

    public eventFired(event): void {
        try {
            if (!event || !event.detail)
                return;

            switch (event.detail.action_id) {
                case EVENT_DEFINITIONS.CARD_BODY_LIKE.ID:
                    this.submitFeedback();
                    break;
            }
        } catch {
        }
    }

    async submitFeedback() {
        this.feedbackProgress = true;
        const result = await this.feedbackSrv.rateAndPost(this.itemId, this.itemTypeId, this.comment, this.rateSelected).catch(err => {
        });
        if (result) {
            this.hasFeedback = true;
        }
        this.feedbackProgress = false;
    }

    ngOnDestroy() {
        if (this.listenerFn) {
            this.listenerFn();
        }
    }
} // END CLASS
