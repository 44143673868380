import {Injectable} from "@angular/core";
import {IRefer, IReferGroup} from "../../../models/IRefer";
import {UserService} from "../../../services/talos/user.service";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import * as _ from "lodash";
import {GuestInfoInput} from "../../../talosApi/models/GuestInfoInput";
import {TranslateService} from "@ngx-translate/core";
import {KeyValueDTO} from "../../../talosApi/models/KeyValueDTO";
import {ConvertInput, ICancelCoupon} from "../../../talosApi/api/UserApi";
import {NewUserInput} from "../../../talosApi/models/NewUserInput";
import {AppGlobalsService} from "../../../services/appGlobals.service";
import {AuthenticateUserResponse} from "../../../talosApi/models/AuthenticateUserResponse";
import {BrandsService} from "../../../services/talos/brands.service";
import {IListUserBrandProductCoupons, IPurchaseInput} from "../../../talosApi/api/BrandApi";
import {IEventInput} from "../../../talosApi/api/EventApi";
import {EventService} from "../../../services/talos/event.service";
import {DetailedBrandProductCouponDTO} from "../../../talosApi/models/DetailedBrandProductCouponDTO";
import {UtilsService} from "../../../services/utils.service";
import {ItemService} from "../../../services/talos/item.service";
import {GetMultipleMetadataResponse} from "../../../talosApi/models/GetMultipleMetadataResponse";
import {HttpHeaders} from "@angular/common/http";
import {IReferGroupUI, IReferGroupUser} from "../../../models/IReferGroupUI";
import {ProfileFilter} from "../../../talosApi/models/ProfileFilter";
import {environment} from "../../../environments/environment";
import moment from 'moment';
import {DetailedBrandProductDTO} from "../../../talosApi/models/DetailedBrandProductDTO";
import {GroupByDTO} from "../../../talosApi/models/GroupByDTO";
import {GetGroupByReferrals} from "../../../talosApi/api/ReferralApi";
import {ReferralService} from "../../../services/talos/referral.service";
import {ReferralDTO} from "../../../talosApi/models/ReferralDTO";
import {ListReferralInput} from "../../../talosApi/models/ListReferralInput";
import {EventItemRewardsDTO} from "../../../talosApi/models/EventItemRewardsDTO";
import METADATA_KEY = TAConstants.METADATA_KEY;
import ROLE_IDS = TAConstants.ROLE_IDS;
import Unit_Types = TAConstants.Unit_Types;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import COUPON_STATUSES = TAConstants.COUPON_STATUSES;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Events_Types = TAConstants.Events_Types;
import Settings = TAConstants.Settings;
import ORDER_TYPE = TAConstants.ORDER_TYPE;
import BRAND_PRODUCT_TYPES = TAConstants.BRAND_PRODUCT_TYPES;
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;

const PHONE_PREFIX = "0039";

@Injectable()
export class InviteService {
    inviteConfig;

    constructor(public userSrv: UserService, public brandsSrv: BrandsService, public translate: TranslateService,
                public appGlobalsSrv: AppGlobalsService, public eventSrv: EventService, public utilsSrv: UtilsService, public itemSrv: ItemService,
                private referralSrv: ReferralService) {
        if (appGlobalsSrv.config) {
            this.inviteConfig = appGlobalsSrv.config[APPLICATION_SETTING_KEYS.INVITE_CONFIG] || null;
        }
    }

    getInviteConfig() {
        if (this.inviteConfig)
            return this.inviteConfig;

        if (this.appGlobalsSrv.config)
            this.inviteConfig = this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.INVITE_CONFIG] || null;

        return this.inviteConfig
    }

    createGuest(metadata: Array<KeyValueDTO>, authenticateFlag: boolean): Promise<AuthenticateUserResponse> {
        return new Promise((resolve, reject) => {
            const input: GuestInfoInput = {
                username: "",
                password: "",
                languageCode: this.translate.currentLang,
                info: "",
                latitude: 0,
                longitude: 0,
                clientIPAddress: "",
                nationalityIsoCode: "it",
                authenticateFlag: authenticateFlag,
                metadatas: metadata
            };
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append(TAConstants.TAHeaders.APPLICATION_ID, Settings.APPLICATION_ID_REFERRAL);
            this.userSrv.createGuest(input, headers).then((resGuestUser) => {
                resolve(resGuestUser);
            }).catch(err => {
                reject(err);
            })
        });
    }

    singleRefer(userId: string, refer: IRefer, isGroup: boolean): Promise<IGroupUserResponces | AuthenticateUserResponse> {
        return new Promise((resolve, reject) => {
            const config = this.getInviteConfig();
            const roleId = config ? config[APPLICATION_SETTING_KEYS.INVITE_ASSIGN_REFER_USER_ROLE] : "";

            const getUsername = (): string => {
                let result = roleId + "_" + PHONE_PREFIX + refer.phone;

                const excludeNumbers = this.appGlobalsSrv.config.excludeNumbers;
                if (excludeNumbers && excludeNumbers != "") {
                    const mobileNumbers = excludeNumbers.split(",");

                    mobileNumbers.forEach(function (m) {
                        if (m === refer.phone) {
                            result = roleId + "_" + Date.now().toString() + "_" + PHONE_PREFIX + refer.phone;
                        }
                    })
                }

                return result;
            }

            const username = getUsername();

            const createGuest = () => {
                return new Promise((res, rej) => {
                    const metadata: Array<KeyValueDTO> = [];
                    metadata.push({
                        key: METADATA_KEY.METADATA_KEY_ASSOCIATE_USER_ID,
                        value: userId.toUpperCase()
                    })
                    if (!_.isNil(refer.name)) {
                        metadata.push({
                            key: METADATA_KEY.PERSONAL_NAME,
                            value: refer.name
                        })
                    }
                    if (!_.isNil(refer.surname)) {
                        metadata.push({
                            key: METADATA_KEY.PERSONAL_SURNAME,
                            value: refer.surname
                        })
                    }
                    if (!_.isNil(refer.phone)) {
                        metadata.push({
                            key: METADATA_KEY.USER_PHONE,
                            value: PHONE_PREFIX + refer.phone
                        })
                    }
                    if (!_.isNil(refer.email)) {
                        metadata.push({
                            key: METADATA_KEY.PERSONAL_EMAIL,
                            value: refer.email
                        })
                    }
                    if (!_.isNil(refer.cap)) {
                        metadata.push({
                            key: METADATA_KEY.METADATA_KEY_POSTAL_CODE,
                            value: refer.cap
                        })
                    }
                    if (!_.isNil(refer.role)) {
                        metadata.push({
                            key: METADATA_KEY.ROLE_ID,
                            value: String(refer.role)
                        })
                    }
                    if (!_.isNil(refer.benefit)) {
                        metadata.push({
                            key: METADATA_KEY.METADATA_KEY_NOTE,
                            value: refer.benefit
                        })
                    }

                    if (metadata.length == 0) {
                        isGroup ? resolve({phone: refer.phone, result: false}) : reject("GENERIC");
                        return;
                    }

                    this.createGuest(metadata, true).then((resGuestUser) => {
                        res(resGuestUser);
                    }).catch(err => {
                        isGroup ? resolve({phone: refer.phone, result: false}) : rej("GENERIC");
                    })
                });
            }

            const purchaseCoupon = () => {
                return new Promise((res, rej) => {

                    const dealId = config ? (isGroup ? config[APPLICATION_SETTING_KEYS.INVITE_ASSIGN_GROUP_DEAL_ID] : config[APPLICATION_SETTING_KEYS.INVITE_ASSIGN_DEAL_ID]) : null;
                    if (_.isNil(dealId)) {
                        isGroup ? resolve({phone: refer.phone, result: false}) : rej("GENERIC");
                        return;
                    }
                    const input: IPurchaseInput = {
                        brandProductId: dealId,
                        quantity: 1,
                        amountsToPay: [{amount: 0, unitTypeId: Unit_Types.IQOS_CLUB_IT_POINTS}]
                    }
                    this.brandsSrv.purchaseDealCoupon(TAValues.UserId, input).then((resPurchase: any) => {

                        if (resPurchase && resPurchase.couponIds && resPurchase.couponIds.length > 0) {
                            res({'id': resPurchase.couponIds[0]});
                            return;
                        }

                        rej("NO_MORE_CODES");
                    }).catch(err => {
                        isGroup ? resolve({phone: refer.phone, result: false}) : rej("NO_MORE_CODES");
                        return;
                    })
                });
            }

            const giftCoupon = (couponId, friendId) => {
                return new Promise((res, rej) => {
                    this.brandsSrv.giftCoupon(TAValues.UserId, couponId, friendId).then((resGift) => {
                        res(resGift);
                    }).catch(err => {
                        isGroup ? resolve({phone: refer.phone, result: false}) : rej("GENERIC");
                        return;
                    })
                });
            }

            const sendEvent = (input: IEventInput) => {
                return new Promise((res, rej) => {
                    this.eventSrv.addEvent(input).then((resEvent) => {
                        res(resEvent);
                    }).catch((error) => {
                        isGroup ? resolve({phone: refer.phone, result: false}) : rej("GENERIC");
                    })
                });
            }

            const upgradeUser = (sessionId: string) => {
                return new Promise((res, rej) => {
                    const input: NewUserInput = {
                        username: username,
                        nickname: username,
                        password: "User1234",
                        email: username + "@italy-pmi.com",
                        mobile: PHONE_PREFIX + refer.phone,
                        preferedLanguageCode: this.translate.currentLang
                    };
                    this.userSrv.upgradeUser(input, sessionId).then((resUpgrade) => {
                        res(resUpgrade);
                    }).catch(err => {
                        isGroup ? resolve({phone: refer.phone, result: false}) : rej("GENERIC");
                        return;
                    })
                });
            }

            const cancelCoupon = (couponId) => {
                return new Promise((res, rej) => {
                    const input: ICancelCoupon = {couponIds: [couponId], comment: 'COUPON CANCELLED'};
                    this.brandsSrv.cancelCouponUser(input, TAValues.UserId).then((resCancel) => {
                        res();
                    }).catch(err => {
                        rej("GENERIC");
                        return;
                    })
                });
            }

            this.checkIfUserIsReferred(username).then(() => {
                createGuest().then((resGuestUser: AuthenticateUserResponse) => {
                    purchaseCoupon().then((resPurchase: { id: string }) => {
                        if (_.isNil(resPurchase) || _.isNil(resPurchase.id)) {
                            isGroup ? resolve({phone: refer.phone, result: false}) : reject("NO_MORE_CODES");
                            return;
                        }

                        giftCoupon(resPurchase.id, resGuestUser.userId).then((resGift) => {
                            if (isGroup) {

                                // HINT: add the inputs when is group.
                                const properties = "userId=" + resGuestUser.userId + "\ncouponId=" + resPurchase.id;
                                const eventInput: IEventInput = {
                                    eventProperties: properties,
                                    eventTypeId: Events_Types.ONE_TO_MANY_USER,
                                    userId: resGuestUser.userId,
                                    gameTypeId: environment.GAME_TYPE_ID,
                                    clientTypeId: TAValues.CLIENT_TYPE_ID,
                                    applicationId: TAValues.APPLICATION_ID,
                                };
                                sendEvent(eventInput).then((resUpgrade) => {
                                    upgradeUser(resGuestUser.userSessionId).then((resUpgrade) => {
                                        isGroup ? resolve({
                                            phone: refer.phone,
                                            result: true,
                                            couponId: resPurchase.id,
                                            userId: resGuestUser.userId
                                        }) : resolve();
                                    }).catch(err => {
                                        cancelCoupon(resPurchase.id);
                                        reject(err);
                                        return;
                                    })
                                }).catch(err => {
                                    cancelCoupon(resPurchase.id);
                                    isGroup ? resolve({
                                        phone: refer.phone,
                                        result: true,
                                        couponId: resPurchase.id,
                                        userId: resGuestUser.userId
                                    }) : reject(err);
                                    return;
                                })
                            } else {
                                upgradeUser(resGuestUser.userSessionId).then((resUpgrade) => {
                                    isGroup ? resolve({phone: refer.phone, result: true}) : resolve();
                                }).catch(err => {
                                    cancelCoupon(resPurchase.id);
                                    isGroup ? resolve({phone: refer.phone, result: false}) : reject(err);
                                    return;
                                })
                            }
                        }).catch(err => {
                            cancelCoupon(resPurchase.id);
                            isGroup ? resolve({phone: refer.phone, result: false}) : reject(err);
                            return;
                        })
                    }).catch(err => {
                        isGroup ? resolve({phone: refer.phone, result: false}) : reject(err);
                        return;
                    })
                }).catch(err => {
                    isGroup ? resolve({phone: refer.phone, result: false}) : reject(err);
                    return;
                })
            }).catch(err => {
                isGroup ? resolve({phone: refer.phone, result: false}) : reject(err);
            });

        });
    }

    checkIfGroupUserIsReferred(mobile: string): Promise<any> {
        return new Promise((res, rej) => {
            const config = this.getInviteConfig();
            const roleId = config ? config[APPLICATION_SETTING_KEYS.INVITE_ASSIGN_REFER_USER_ROLE] : "";


            const getUsername = (): string => {
                let result = roleId + "_" + PHONE_PREFIX + mobile;

                const excludeNumbers = this.appGlobalsSrv.config.excludeNumbers;
                if (excludeNumbers && excludeNumbers != "") {
                    const mobileNumbers = excludeNumbers.split(",");

                    mobileNumbers.forEach(function (m) {
                        if (m === mobile) {
                            result = roleId + "_" + Date.now().toString() + "_" + PHONE_PREFIX + mobile;
                        }
                    })
                }

                return result;
            }

            const username = getUsername();
            // const username = roleId + "_" + PHONE_PREFIX + mobile;

            this.checkIfUserIsReferred(username).then((result) => {
                res(result)
            }).catch(err => {
                rej(err);
            })
        });
    }

    checkIfUserIsReferred(username: string): Promise<any> {
        return new Promise((res, rej) => {
            this.userSrv.checkUsername(username).then((r1) => {
                (r1 == true) ? res() : rej("USER_REFERRED");

            }).catch(err => {
                rej("GENERIC");
                return;
            })
        });
    }

    //TODO: check if the works properly.
    convertIqosMoneyToPoint(pointsToBeConverted): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const input: ConvertInput = {
                points: pointsToBeConverted,
                fromUnitTypeId: Unit_Types.IQON_MONEY,
                toUnitTypeId: Unit_Types.IQOS_CLUB_IT_POINTS
            };
            this.userSrv.convertBalance(TAValues.UserId, input).then((result: any) => {
                this.userSrv.updateUserStatus().then(res => resolve(result)).catch(err => resolve(result));
            }).catch(err => {
                reject(err);
            })
        });
    }

    getSingleCoupons(groupName: string): Promise<Array<IReferGroupUI>> {
        return new Promise((resolve, reject) => {
            const groups: Array<IReferGroupUI> = [];
            const config = this.getInviteConfig();
            const dealId = config ? config[APPLICATION_SETTING_KEYS.INVITE_ASSIGN_DEAL_ID] : null;
            if (_.isNil(dealId)) {
                reject("GENERIC");
                return;
            }
            const input: IListUserBrandProductCoupons = {
                brandProductCouponStatusIds: [COUPON_STATUSES.PURCHASED_SENT_AS_GIFT, COUPON_STATUSES.REDEEMED],
                brandProductIds: [dealId],
                rangeFrom: 0,
                rangeTo: -1,
                metadatas: true
            };
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append(TAConstants.TAHeaders.FILL_RESOURCES, 'metadata');
            this.brandsSrv.getUserBrandProductCoupons(TAValues.UserId, input, headers).then((result: any) => {
                logger.log(result);

                if (_.isNil(result)) {
                    reject("GENERIC");
                    return;
                }

                const couponsByRefUser = {};

                const getUserCouponId = (coupon: DetailedBrandProductCouponDTO): string => {
                    if (_.isNil(coupon) || _.isNil(coupon.metadata)) {
                        return null
                    }

                    return this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.METADATA_KEY_REF_USER_ID, coupon.metadata).toUpperCase();
                }
                result.forEach((c: DetailedBrandProductCouponDTO) => {

                    var metaId = getUserCouponId(c);//c.metadataValue[METADATA_KEY.METADATA_KEY_REF_USER_ID].toUpperCase();
                    var reffs = couponsByRefUser[metaId];
                    if (_.isNil(reffs)) {
                        reffs = couponsByRefUser[metaId] = [];
                    }
                    reffs.push(c);
                })

                this.itemSrv.getMultipleMetadata(ITEM_TYPES.CUSTOM_USER_PROFILE, Object.keys(couponsByRefUser))
                    .then((res: GetMultipleMetadataResponse[]) => {
                        logger.log(res);

                        if (res) {
                            res.forEach((i: GetMultipleMetadataResponse) => {
                                let arr = couponsByRefUser[i.itemId];
                                arr.forEach((c: DetailedBrandProductCouponDTO) => {
                                    const group: IReferGroupUI = {
                                        name: groupName,
                                        invitationDate: c.purchaseDate,
                                        users: [{
                                            status: c.couponStatusId,
                                            name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, i.metadata) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, i.metadata)
                                        }]
                                    }

                                    groups.push(group);
                                })
                            })
                        }

                        resolve(groups);
                    })
                    .catch(err => {
                        reject(err);
                    })
            }).catch(err => {
                reject(err);
            })
        });
    }

    getSingleCouponsNew(users: Array<any>, groupName: string): Promise<Array<IReferGroupUI>> {
        return new Promise((resolve, reject) => {
            const groups: Array<IReferGroupUI> = [];

            if (_.isNil(users) || users.length == 0) {
                resolve([]);
                return;
            }

            this.getUserBalances(users).then((arrayOfResultsBalances: any) => {
                for (let i = 0; i < users.length; i++) {
                    const user = users[i];
                    let balances = [];
                    if (arrayOfResultsBalances[i] instanceof Array) {
                        balances = arrayOfResultsBalances[i];
                    } else {
                        balances.push(arrayOfResultsBalances[i]);
                    }
                    const xp = this.utilsSrv.numberFromBalancesArray(balances, Unit_Types.XP, 'balance');
                    const groupUser: IReferGroupUser = {
                        name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, user.properties) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, user.properties),
                        status: xp > 0 ? COUPON_STATUSES.REDEEMED : COUPON_STATUSES.PURCHASED_SENT_AS_GIFT
                    };

                    const group: IReferGroupUI = {
                        name: groupName,
                        invitationDate: user.userCreatedOn,
                        users: [groupUser]
                    }

                    groups.push(group);
                }

                resolve(groups);
            }, (err) => {
                reject(err);
            });

        });
    }

    getIndexedUsers(assignUserId: string): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
            const userByUserId = {};

            const userProfileFilter: Array<ProfileFilter> = [{
                property: METADATA_KEY.METADATA_KEY_ASSOCIATE_USER_ID,
                propertyValue: assignUserId,
                mode: 1
            }];
            this.userSrv.getIndexedUsers(userProfileFilter, null, 0, -1).then((resUsers) => {
                if (_.isNil(resUsers) || resUsers.length == 0) {
                    resolve([]);
                    return;
                }

                resolve(resUsers);

            }).catch(err => {
                reject(err);
            })

        });
    }

    getUserBalances(users: Array<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            const userByUserId = {};


            const userBalancePromise = (userId) => new Promise((resolve, reject) => {
                this.userSrv.getUserBalances(userId, [Unit_Types.XP]).then((resUserBalances) => {
                    if (_.isNil(resUserBalances) || resUserBalances.length == 0) {
                        resolve([]);
                        return;
                    }

                    resolve(resUserBalances);
                }).catch(err => {
                    reject(err);
                })
            });


            const promises = users.map((u) => {
                userByUserId[u.userId] = u;
                return userBalancePromise(u.userId);
            })

            Promise.all(promises).then((arrayOfResultsBalances: any) => {
                if (_.isNil(arrayOfResultsBalances) || arrayOfResultsBalances.length == 0) {
                    resolve([]);
                    return;
                }

                resolve(arrayOfResultsBalances);
            }, (err) => {
                reject(err);
            });
        });
    }

    getGroupCouponsNew(users: Array<any>): Promise<Array<IReferGroupUI>> {
        return new Promise((resolve, reject) => {

            if (_.isNil(users) || users.length == 0) {
                resolve([]);
                return;
            }

            const groups: Array<IReferGroupUI> = [];
            const userByUserId = {};

            const userPromise = (groupId) => new Promise((resolve, reject) => {
                const userProfileFilter: Array<ProfileFilter> = [{
                    property: METADATA_KEY.METADATA_KEY_ASSOCIATE_USER_ID,
                    propertyValue: groupId,
                    mode: 1
                }];
                this.userSrv.getIndexedUsers(userProfileFilter, null, 0, -1).then((resUsers) => {
                    if (_.isNil(resUsers) || resUsers.length == 0) {
                        resolve([]);
                        return;
                    }

                    this.getUserBalances(resUsers).then((arrayOfResultsBalances: any) => {
                        const iReferGroupUIUsers: Array<IReferGroupUser> = [];
                        for (let i = 0; i < resUsers.length; i++) {
                            const user = resUsers[i];
                            let balances = [];
                            if (arrayOfResultsBalances[i] instanceof Array) {
                                balances = arrayOfResultsBalances[i];
                            } else {
                                balances.push(arrayOfResultsBalances[i]);
                            }
                            const xp = this.utilsSrv.numberFromBalancesArray(balances, Unit_Types.XP, 'balance');
                            const groupUser: IReferGroupUser = {
                                name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, user.properties) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, user.properties),
                                status: xp > 0 ? COUPON_STATUSES.REDEEMED : COUPON_STATUSES.PURCHASED_SENT_AS_GIFT
                            };
                            iReferGroupUIUsers.push(groupUser);
                        }

                        resolve(iReferGroupUIUsers);
                    }, (err) => {
                        reject(err);
                    });
                    // const userBalancePromise = (userId) => new Promise((resolve, reject) => {
                    //     this.userSrv.getUserBalances(userId, [Unit_Types.XP]).then((resUserBalances) => {
                    //         if (_.isNil(resUserBalances) || resUserBalances.length == 0) {
                    //             resolve([]);
                    //             return;
                    //         }
                    //
                    //         resolve(resUserBalances);
                    //     }).catch(err => {
                    //         reject(err);
                    //     })
                    // });
                    //
                    //
                    // const promises = resUsers.map((u) => {
                    //     userByUserId[u.userId] = u;
                    //     return userBalancePromise(u.userId);
                    // })
                    //
                    // Promise.all(promises).then(arrayOfResultsBalances => {
                    //     const iReferGroupUIUsers: Array<IReferGroupUser> = [];
                    //     for (let i = 0; i < resUsers.length; i++) {
                    //         const user = resUsers[i];
                    //         let balances = [];
                    //         if (arrayOfResultsBalances[i] instanceof Array) {
                    //             // balances = arrayOfResultsBalances[i];
                    //         } else {
                    //             balances.push(arrayOfResultsBalances[i]);
                    //         }
                    //         const xp = this.utilsSrv.numberFromBalancesArray(balances, Unit_Types.XP, 'balance');
                    //         const groupUser: IReferGroupUser = {
                    //             name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, user.metadata) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, user.metadata),
                    //             status: xp > 0 ? COUPON_STATUSES.REDEEMED : COUPON_STATUSES.PURCHASED_SENT_AS_GIFT
                    //         };
                    //         iReferGroupUIUsers.push(groupUser);
                    //     }
                    //
                    //     resolve(iReferGroupUIUsers);
                    // }, (err) => {
                    //     reject(err);
                    // });

                }).catch(err => {
                    reject(err);
                })
            });

            const promises = users.map((g) => {
                userByUserId[g.userId] = g;
                return userPromise(g.userId);
            })

            Promise.all(promises).then((arrayOfResultsUsers: Array<Array<IReferGroupUser>>) => {

                for (let i = 0; i < users.length; i++) {
                    const user = users[i];
                    const groupUsers = arrayOfResultsUsers[i];

                    const group: IReferGroupUI = {
                        name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, user.properties),
                        invitationDate: (this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_REFERRED_ON, user.properties)
                            ? moment(this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_REFERRED_ON, user.properties), Settings.DATE_FORMAT).toDate().getTime()
                            : 0),
                        users: groupUsers
                    };
                    groups.push(group);
                }

                resolve(groups);
            }, (err) => {
                reject(err);
            });
        });
    }

    createGroup(group: IReferGroup): Promise<any> {
        return new Promise((resolve, reject) => {

            if (_.isNil(group) || _.isNil(group.users) || group.users.length < 2) {
                reject("GENERIC");
                return;
            }

            const createGroup = () => {
                return new Promise((res, rej) => {
                    const metadata: Array<KeyValueDTO> = [];
                    metadata.push({
                        key: METADATA_KEY.METADATA_KEY_ASSOCIATE_USER_ID,
                        value: TAValues.UserId.toUpperCase()
                    })
                    metadata.push({
                        key: METADATA_KEY.ROLE_ID,
                        value: String(ROLE_IDS.GROUP)
                    })
                    if (!_.isNil(group.name)) {
                        metadata.push({
                            key: METADATA_KEY.PERSONAL_NAME,
                            value: group.name
                        })
                    }
                    if (!_.isNil(group.cap)) {
                        metadata.push({
                            key: METADATA_KEY.METADATA_KEY_POSTAL_CODE,
                            value: group.cap
                        })
                    }
                    if (!_.isNil(group.dateCreated)) {
                        let _tmp = new Date(group.dateCreated.year + '-' + group.dateCreated.month + '-' + group.dateCreated.day + ' ' + group.hour + ':00'.split(' ').join('T'))
                        metadata.push({
                            key: METADATA_KEY.PERSONAL_REFERRED_ON,
                            value: moment(_tmp).format(Settings.DATE_FORMAT)
                        })
                    } else {
                        metadata.push({
                            key: METADATA_KEY.PERSONAL_REFERRED_ON,
                            value: moment(new Date()).format(Settings.DATE_FORMAT)
                        })
                    }

                    if (metadata.length == 0) {
                        reject("GENERIC");
                        return;
                    }

                    this.createGuest(metadata, false).then((resGuestUser) => {
                        res(resGuestUser);
                    }).catch(err => {
                        rej(err);
                    })
                });
            }

            createGroup().then((resGuestUser: AuthenticateUserResponse) => {
                const promises = group.users.map((u) => {
                    u.role = ROLE_IDS.CONSUMER_GODSON;
                    return this.singleRefer(resGuestUser.userId, u, true);
                })

                Promise.all(promises).then((arrayOfResultsUsers: IGroupUserResponces[]) => {

                    logger.log(arrayOfResultsUsers);
                    const userFail: IGroupUserResponces[] = arrayOfResultsUsers.filter(key => key.result === false);
                    //TODO: add event .
                    if (userFail.length > 0) {
                        let arrayResponsesFails: IGroupUserResponces[] = [];
                        arrayResponsesFails.push(...userFail);
                        reject(<IGroupUserResponces[]>arrayResponsesFails);
                        return;
                    }
                    let properties_ = `groupId=${resGuestUser.userId}\nuserCount=${arrayOfResultsUsers.length}\n`;
                    arrayOfResultsUsers.forEach((user) => {
                        properties_ += `userId=${user.userId}\ncoupon=${user.couponId}\n`;
                    });
                    const input_: IEventInput = {
                        eventProperties: properties_,
                        eventTypeId: Events_Types.ONE_TO_MANY_GROUP,
                        userId: resGuestUser.userId,
                        gameTypeId: environment.GAME_TYPE_ID,
                        clientTypeId: TAValues.CLIENT_TYPE_ID,
                        applicationId: TAValues.APPLICATION_ID,
                    };
                    this.eventSrv.addEvent(input_)
                        .then(() => {
                            resolve("OK");
                        })
                        .catch((err) => {
                            logger.log(err);
                        });

                }, (err) => {
                    reject(err);
                });
            }).catch(err => {
                reject(err);
            })
        });
    }

    getReferralCount(referralStatusIds?: Array<number>): Promise<Array<GroupByDTO>> {
        return new Promise((resolve, reject) => {
            const params: GetGroupByReferrals = {
                referralTypeIds: [
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB_ONE_TO_MANY,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING_ONE_TO_MANY,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ONE_TO_MANY,
                    REFERRAL_TYPES.LENDING_REFERRAL
                ], groupByType: "BY_TYPE",
                referralStatusIds: referralStatusIds
            };
            this.referralSrv.groupByReferrals(TAValues.UserId, params)
                .then((result: Array<GroupByDTO>) => {
                    resolve(result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    // getGroupCoupons(): Promise<Array<IReferGroupUI>> {
    //     return new Promise((resolve, reject) => {
    //         const groups: Array<IReferGroupUI> = [];
    //         const userByUserId = {};
    //         const groupProfileFilter: Array<ProfileFilter> = [{
    //             property: METADATA_KEY.ROLE_ID,
    //             propertyValue: String(ROLE_IDS.GROUP),
    //             mode: 1
    //         }, {
    //             property: METADATA_KEY.METADATA_KEY_ASSOCIATE_USER_ID,
    //             propertyValue: TAValues.UserId,
    //             mode: 1
    //         }];
    //         this.userSrv.getIndexedUsers(groupProfileFilter, null, 0, -1).then((resGroups) => {
    //             if (_.isNil(resGroups) || resGroups.length == 0) {
    //                 resolve(groups);
    //                 return;
    //             }
    //
    //             const userPromise = (groupId) => new Promise((resolve, reject) => {
    //                 const userProfileFilter: Array<ProfileFilter> = [{
    //                     property: METADATA_KEY.METADATA_KEY_ASSOCIATE_USER_ID,
    //                     propertyValue: groupId,
    //                     mode: 1
    //                 }];
    //                 this.userSrv.getIndexedUsers(userProfileFilter, null, 0, -1).then((resUsers) => {
    //                     if (_.isNil(resUsers) || resUsers.length == 0) {
    //                         resolve([]);
    //                         return;
    //                     }
    //
    //                     const userBalancePromise = (userId) => new Promise((resolve, reject) => {
    //                         this.userSrv.getUserBalances(userId, [Unit_Types.XP]).then((resUserBalances) => {
    //                             if (_.isNil(resUserBalances) || resUserBalances.length == 0) {
    //                                 resolve([]);
    //                                 return;
    //                             }
    //
    //                             resolve(resUserBalances);
    //                         }).catch(err => {
    //                             reject(err);
    //                         })
    //                     });
    //
    //
    //                     const promises = resUsers.map((u) => {
    //                         userByUserId[u.userId] = u;
    //                         return userBalancePromise(u.userId);
    //                     })
    //
    //                     Promise.all(promises).then(arrayOfResultsBalances => {
    //                         const iReferGroupUIUsers: Array<IReferGroupUser> = [];
    //                         for (let i = 0; i < resUsers.length; i++) {
    //                             const user = resUsers[i];
    //                             let balances = [];
    //                             if (arrayOfResultsBalances[i] instanceof Array) {
    //                                 // balances = arrayOfResultsBalances[i];
    //                             } else {
    //                                 balances.push(arrayOfResultsBalances[i]);
    //                             }
    //                             const xp = this.utilsSrv.numberFromBalancesArray(balances, Unit_Types.XP, 'balance');
    //                             const groupUser: IReferGroupUser = {
    //                                 name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, user.metadata) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, user.metadata),
    //                                 status: xp > 0 ? COUPON_STATUSES.REDEEMED : COUPON_STATUSES.PURCHASED_SENT_AS_GIFT
    //                             };
    //                             iReferGroupUIUsers.push(groupUser);
    //                         }
    //
    //                         resolve(iReferGroupUIUsers);
    //                     }, (err) => {
    //                         reject(err);
    //                     });
    //
    //                 }).catch(err => {
    //                     reject(err);
    //                 })
    //             });
    //
    //             const promises = resGroups.map((g) => {
    //                 userByUserId[g.userId] = g;
    //                 return userPromise(g.userId);
    //             })
    //
    //             Promise.all(promises).then(arrayOfResultsUsers => {
    //
    //                 logger.log(arrayOfResultsUsers);
    //
    //                 // for (let i = 0; i < resGroups.length; i++) {
    //                 //     const group = resGroups[i];
    //                 //     const users: Array<IReferGroupUser> = arrayOfResultsUsers[i];
    //                 //     const g: IReferGroupUI = {
    //                 //         name: this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, group.metadata) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, group.metadata),
    //                 //         users: users,
    //                 //         invitationDate: Number(this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_REFERRED_ON, group.metadata))
    //                 //     };
    //                 //     groups.push(g);
    //                 // }
    //
    //                 resolve(groups);
    //             }, (err) => {
    //                 reject(err);
    //             });
    //         }).catch(err => {
    //             reject(err);
    //         })
    //     });
    // }

    async getReferrals(): Promise<Array<ReferralDTO>> {
        return new Promise<Array<ReferralDTO>>(async (resolve, reject) => {
            const input: ListReferralInput = {
                referrerUserId: TAValues.UserId, rangeTo: 1000, orderType: ORDER_TYPE.BY_ORDERING_ASC,
                referralTypeIds: [
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB_ONE_TO_MANY,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING_ONE_TO_MANY,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ONE_TO_MANY
                ]
            };
            const res = await this.referralSrv.getReferrals(input).catch(err => {
            });

            if (!res) {
                resolve([]);
                return;
            }

            resolve(res);
        });
    }

    async getReferralsNew(): Promise<Array<ListReferral>> {
        return new Promise<Array<ListReferral>>(async (resolve, reject) => {
            const input: ListReferralInput = {
                referrerUserId: TAValues.UserId,
                rangeTo: 1000,
                orderType: ORDER_TYPE.BY_ORDERING_ASC,
                referralTypeIds: [
                    REFERRAL_TYPES.MGM_UNICODE, REFERRAL_TYPES.LENDING_REFERRAL, REFERRAL_TYPES.LIL
                ],
                referralStatusIds: [1, 2, 3, 4, 5],
                includeDetails: true
            };
            const res: Array<ListReferral> | void = await this.referralSrv.getReferrals(input).catch(err => {
            });

            if (!res) {
                resolve([]);
                return;
            }

            const postponed = res.filter(r => {
                return r.referralStatusId == 2;
            })

            if (postponed && postponed.length > 0) {

                const promise = (value: ListReferral): Promise<Array<EventItemRewardsDTO>> => {
                    let eventProperties: string = "";
                    if (value.referralProperties && value.referralProperties.length > 0) {
                        value.referralProperties.forEach(p => {
                            eventProperties += `${p.key}=${p.value}\n`;
                        })
                    }
                    const input: IEventInput = {
                        userId: TAValues.UserId,
                        eventTypeId: Events_Types.IQOS_CLUB_IT_MGM_REFERRAL_POINTS_AWARD,
                        eventProperties: eventProperties,
                        gameTypeId: Settings.GAME_TYPE,
                        clientTypeId: TAValues.CLIENT_TYPE_ID,
                        withNoRewards: false,
                        applicationId: TAValues.APPLICATION_ID
                    }
                    return this.eventSrv.evaluateEvent(null, input);
                }

                const promises = postponed.map(p => {
                    return promise(p);
                })

                Promise.all(promises).then(promRes => {
                    logger.log(promRes);
                    for (let i = 0; i < promRes.length; i++) {
                        const r = promRes[i];
                        if (r && r.length > 0 && postponed[i]) {
                            const item = res.find(it => {
                                return it.referralId == postponed[i].referralId;
                            })
                            if (item) {
                                item.eventItemReward = r[0];
                            }
                        }
                    }
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
                return;
            }

            resolve(res);
        });
    }

    getIqosMoney(): Promise<Array<DetailedBrandProductDTO>> {
        return new Promise((resolve, reject) => {

            const rootCategoryId = this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.IQOS_MONEY_CATEGORY_ID];
            if (!rootCategoryId) {
                reject('No main category found!!');
                return;
            }

            this.brandsSrv.getBrandProducts(TAValues.UserId, null, [Number(rootCategoryId)], false, null, null, ORDER_TYPE.BY_PRICE_ASC, true, BRAND_PRODUCT_TYPES.DEALS).then((result: any) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            })

        });
    }

    purchaseIqosMoney(deal: DetailedBrandProductDTO): Promise<any> {
        return new Promise((resolve, reject) => {
            const amount = deal.prices[0].prices[0];
            if (!amount) {
                reject("NO PRICE");
                return;
            }

            let _points = amount.amount.value;
            let _pointsScale = amount.amount.scale;
            const input: IPurchaseInput = {
                brandProductId: deal.brandProductId,
                quantity: 1,
                amountsToPay: [{unitTypeId: amount.unitTypeId, amount: _points / Math.pow(10, _pointsScale)}]
            };
            this.brandsSrv.purchaseDealCoupon(TAValues.UserId, input).then((result: any) => {
                this.userSrv.updateUserStatus().then(res => resolve(result)).catch(err => resolve(result));

            }).catch(err => {
                reject(err);
            })

        });
    }
}

export interface IGroupUserResponces {
    phone: string;
    result: boolean;
    couponId: string;
    userId: string;

}

export interface ListReferral extends ReferralDTO {
    eventItemReward?: EventItemRewardsDTO;
}