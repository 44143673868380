import {User} from "../talosApi/models/User";
import {BalanceDTO} from "../talosApi/models/BalanceDTO";
import {UserLevelsDTO} from "../talosApi/models/UserLevelsDTO";

export class LoyaltyUser {
    user: User;
    userBalances: Array<BalanceDTO>;
    balance: number = 0;
    statusBalance: number = 0;
    balanceTotal: number = 0;
    pendingBalance: number = 0;
    iqosMoney: number = 0;
    balanceExpiring: number = 0;
    balanceToShow: string = "";
    balanceTotalToShow: string = "";
    pendingBalanceToShow: string = "";
    balanceForNextLevel: string = "";
    balanceExpiringToShow: string = "";
    level: UserLevelsDTO;
    unreadMessages: number;
    fullName: string;
    uniqueCode: string;
    unicode: string;
    unlinked: boolean;
    firstlogin: boolean;
    completedOnBoard: boolean;
    promo: string;
    popupDisplayed: any;
    quizCompleted: any;
    hasBought: boolean;
    topBannerCategoryId: string;
    statusLevel: number;
    badgeLevel: number;
    percentageForNextLevel: number;
    isCasting: boolean;
    ownerType?: string;
    loginActionCompleted?: string;
    firstActionCompleted?: string;
    firstVoucherPurchased?: string;
    daysRemaining?: number;

    constructor() {
        this.user = {};
        this.userBalances = [];
        this.balance = 0;
        this.statusBalance = 0;
        this.balanceTotal = 0;
        this.pendingBalance = 0;
        this.iqosMoney = 0;
        this.balanceExpiring = 0;
        this.balanceToShow = "";
        this.balanceTotalToShow = "";
        this.pendingBalanceToShow = "";
        this.balanceForNextLevel = "";
        this.balanceExpiringToShow = "";
        this.level = {};
        this.unreadMessages = 0;
        this.fullName = "";
        this.unlinked = true;
        this.uniqueCode = "";
        this.firstlogin = false;
        this.completedOnBoard = false;
        this.promo = undefined;
        this.popupDisplayed = undefined;
        this.quizCompleted = undefined;
        this.hasBought = false;
        this.topBannerCategoryId = "";
        this.statusLevel = 0;
        this.badgeLevel = 0;
        this.percentageForNextLevel = 0;
        this.isCasting = false;
    }

}