// import {TAConstants, TAValues} from "./settings";
import {Injectable, Injector} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import Moment from "moment";
import {RequestOptionsArgs, ResponseContentType} from "@angular/http";
import {TalosBaseModel} from "./models/TalosBaseModel";
import {HttpObserve} from "@angular/common/http/src/client";
import {TranslateService} from '@ngx-translate/core';
// import {NetWork} from "./network";
import Talos_Code = NetWork.Talos_Code;
import TAHeaders = TAConstants.TAHeaders;
import {NetWork} from "../talosApi/network";
import {TAConstants} from "../talosApi/settings";

@Injectable()
export class Http2Service {

    INACTIVE_USER = [Talos_Code.USER_SESSION_ID_NOT_VALID, Talos_Code.USER_SESSION_PERMISSION_ERROR, Talos_Code.INACTIVE_USER];

    constructor(private http: HttpClient, private injector: Injector, protected translate: TranslateService) {
    }

    private request(request: IHttp2SharedReq): Promise<any> {

        const urlToCall: string = TAConstants.Settings.BASE_SERVER + request.path;

        const options = {
            body: request.input,
            headers: request.headers,
            params: request.queryParams,
            observe: 'response' as HttpObserve,
            responseType: 'text' as 'arraybuffer' | 'blob' | 'json' | 'text'
        };

        return new Promise((resolve, reject) => {

            this.http.request(request.method, urlToCall, options).subscribe(response => {
                //logger.log("response", response);
                try {
                    let js = JSON.parse(response.body);
                    let headers = response.headers;
                    if (headers && headers.has(TAHeaders.ITEM_COUNT)) {
                        if (!js)
                            js = [];
                        js[TAHeaders.ITEM_COUNT] = headers.get(TAHeaders.ITEM_COUNT);
                    }
                    resolve(js);
                } catch (e) {
                    //TODO string
                    resolve(response.body);
                }
            }, error => {
                if (error && error.error && error.error.code && this.INACTIVE_USER.indexOf(error.error.code) >= 0) {

                }
                reject(error || 'Server error');
            });

        });
    }

    public get(request: IHttp2SharedReq): Promise<any> {
        request.method = "GET";
        return this.request(request);
    }

    public post(request: IHttp2SharedReq): Promise<any> {
        request.method = "POST";
        return this.request(request);
    }

    public put(request: IHttp2SharedReq): Promise<any> {
        request.method = "PUT";
        return this.request(request);
    }

    public options(request: IHttp2SharedReq): Promise<any> {
        request.method = "OPTIONS";
        return this.request(request);
    }

    public delete(request: IHttp2SharedReq): Promise<any> {
        request.method = "DELETE";
        return this.request(request);
    }

    public head(request: IHttp2SharedReq): Promise<any> {
        request.method = "HEAD";
        return this.request(request);
    }

    public patch(request: IHttp2SharedReq): Promise<any> {
        request.method = "PATCH";
        return this.request(request);
    }
}

export interface IHttp2SharedReq {
    method?: string,
    path: string,
    queryParams?: any,
    input?: any,
    headers?: HttpHeaders,
    requireSession?: boolean
    timeStamp?: string
}