import {Http2Talos} from "../http2Talos";
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {DecimalDTO} from "../models/DecimalDTO";
import {ResourceDTO} from "../models/ResourceDTO";
import {MetadataDTO} from "../models/MetadataDTO";
import {ItemStatisticsDTO} from "../models/ItemStatisticsDTO";
import {AddressbookDTO} from "../models/AddressbookDTO";
import {DetailedBrandProductCouponDTO} from "../models/DetailedBrandProductCouponDTO";

@Injectable()
export class SalesOrderApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * Creates a SalesOrder
     * @param {string} userId
     * @param {SalesOrderInput} input
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<SalesOrderInput>}
     */
    createSalesORder(userId: string, input: SalesOrderInput, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<SalesOrderDTO>> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/salesorder/${userId}/create`,
            input: input,
            headers: headers
        })
    }

    /**
     * List Sales Orders
     * @param {string} userId
     * @param {ListSalesOrdersParams} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<SalesOrderDTO>>}
     */
    listSalesOrders(userId: string, queryParams: ListSalesOrdersParams, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<SalesOrderDTO>> {
        return this.http2Talos.get({
            requireSession: requireSession,
            path: `/salesorder/${userId}/list`,
            queryParams: queryParams,
            headers: headers
        })
    }

    /**
     * Update a SalesOrder
     * @param {string} userId
     * @param {SalesOrderInput} input
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<SalesOrderDTO>>}
     */
    updateSalesOrders(userId: string, input: SalesOrderInput, queryParamMap: Pick<ListSalesOrdersParams, "resources" | "metadatas" | "languageIds">, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<SalesOrderDTO>> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/salesorder/${userId}/update`,
            input: input,
            headers: headers,
            queryParams: queryParamMap,
        })
    }

    /**
     * Finalize a SalesOrder
     * @param {string} userId
     * @param {FinalizeSalesOrderInput} input
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<SalesOrderDTO>}
     */
    orderSalesOrder(userId: string, input: FinalizeSalesOrderInput, queryParamMap: Pick<ListSalesOrdersParams, "resources" | "metadatas" | "languageIds">, requireSession: boolean = true, headers: HttpHeaders = null): Promise<SalesOrderDTO> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/salesorder/${userId}/order`,
            input: input,
            queryParams: queryParamMap,
            headers: headers
        })
    }
}

export interface ListSalesOrdersParams {

    salesOrderIds?: Array<string>;
    salesOrderTypeIds?: Array<string>;
    createdByUserId?: string;
    fromUserId?: string;
    salesOrderStatusIds?: Array<number>;
    salesOrderDetailsStatusIds?: Array<number>;
    dateCreatedFrom?: number | Date;
    dateCreatedTo?: number | Date;
    unitTypeIds?: Array<number>;
    paymentMethodIds?: Array<number>;
    salesOrderChannelIds?: Array<number>;
    refItemIds?: Array<string>;
    refItemTypeId?: number;
    orderType?: string;
    filteringParams?: Array<string>;
    rangeFrom?: number;
    rangeTo?: number;
    resources?: boolean;
    metadatas?: boolean;
    languageIds?: Array<string>;
}

// export interface SalesOrderDTO {
//     statistics?: Array<ItemStatisticsDTO>;
//     metadata?: Array<MetadataDTO>;
//     resources?: Array<ResourceDTO>;
//     salesOrderId?: string;
//     createdByUserId?: string;
//     fromUserId?: string;
//     toUserId?: string;
//     dateCreated?: string;
//     dateUpdated?: string;
//     updatedUserId?: string;
//     unitTypeId?: number;
//     paymentMethodId?: number;
//     comments?: string;
//     totalDiscountType?: string;
//     totalDiscountAmount?: DecimalDTO;
//     totalTaxAmount?: DecimalDTO;
//     totalAmount?: DecimalDTO;
//     salesOrderStatus?: number;
//     dateSubmitted?: string;
//     dateFulfilled?: string;
//     salesOrderRefNo?: string;
//     revisedSalesOrderId?: string;
//     salesOrderDetailsDTOList?: Array<SalesOrderDetailsDTO>;
//     refItemId?: string;
//     refItemTypeId?: number;
//     itemTypeIdForResourceLookup?: number;
//     itemIdForResourceLookup?: string;
// }
//
export interface SalesOrderDetailsDTO {
    statistics?: Array<ItemStatisticsDTO>;
    metadata?: Array<MetadataDTO>;
    resources?: Array<ResourceDTO>;
    salesOrderDetailsId?: string;
    salesOrderId?: string;
    errorCode?: number;
    itemId?: string;
    itemTypeId?: number;
    subCategoryIds?: Array<string>;
    discountType?: string;
    itemPrice?: DecimalDTO;
    itemDiscountAmount?: DecimalDTO;
    totalDiscountAmount?: DecimalDTO;
    itemTaxAmount?: DecimalDTO;
    totalTaxAmount?: DecimalDTO;
    totalAmount?: DecimalDTO;
    quantity?: number;
    quantityBackordered?: number;
    quantityShipped?: number;
    quantityCancelled?: number;
    dateCreated?: string;
    dateUpdated?: string;
    updatedUserId?: string;
    itemTypeIdForResourceLookup?: number;
    itemIdForResourceLookup?: string;
    brandProductCoupons?: Array<DetailedBrandProductCouponDTO>;
}


export interface SalesOrderDTO {
    salesOrderId?: string;
    createdByUserId?: string;
    fromUserId?: string;
    toUserId?: string;
    dateCreated?: number;
    dateUpdated?: number;
    updatedUserId?: string;
    statusId?: number;
    createdOn?: number | Date;
    updatedOn?: number | Date;
    externalOrderId?: string;
    unitTypeId?: number;
    paymentMethodId?: number;
    comments?: string;
    totalDiscountType?: string;
    totalDiscountAmount?: DecimalDTO;
    totalTaxAmount?: DecimalDTO;
    totalAmount?: DecimalDTO;
    salesOrderStatus?: number;
    dateSubmitted?: string;
    dateFulfilled?: string;
    salesOrderType?: SalesOrderTypeDTO;
    gameTypeId?: string;
    items?: Array<SalesOrderItemsDTO>;
    statistics?: Array<ItemStatisticsDTO>;
    shippingAddressBook?: AddressbookDTO;
    billingAddressBook?: AddressbookDTO;
    salesOrderChannelId?: number;
    externalReferenceId?: string;
    orderedOn?: string;
    errorCode?: number;
    salesOrderDetailsDTOList?: Array<SalesOrderDetailsDTO>;
    metadata?: Array<MetadataDTO>;
    resources?: Array<ResourceDTO>;
    itemIdForResourceLookup?: string;
    itemTypeIdForResourceLookup?: number;
}

export interface SalesOrderTypeDTO {
    salesOrderTypesId?: string;
    name?: string;
    statistics?: Array<ItemStatisticsDTO>;
    metadata?: Array<MetadataDTO>;
    resources?: Array<ResourceDTO>;
}

export interface SalesOrderItemsDTO {
    itemId?: string;
    itemTypeId?: number;
    price?: number;
    quantity?: number;
    categoryId?: string;
    errorCode?: number;
    subCategoryIds?: Array<string>;
    statistics?: Array<ItemStatisticsDTO>;
    metadata?: Array<MetadataDTO>;
    resources?: Array<ResourceDTO>;
}

export interface SalesOrderInput {
    salesOrderDTO?: SalesOrderDTO;
    filteringParams?: Array<string>;
    metadata?: Array<MetadataDTO>;
    resources?: Array<ResourceDTO>;

}

export interface FinalizeSalesOrderInput {
    salesOrderId?: string;
    updatedByUserId?: string;
    additionalProperties?: string;
}

export enum SalesOrderErrorCodes {
    SALES_ORDER_NOT_IN_VALID_STATUS = 12500,
    SALES_ORDER_DETAILS_NOT_IN_VALID_STATUS = 12501,
    SALES_ORDER_DETAILS_ALREADY_INSERTED = 12502,
    SALES_ORDER_DETAILS_WRONG_QUANTITY_SPECIFIED = 12503,
    SALES_ORDER_DETAILS_NOT_FOUND = 12504,
    SALES_ORDER_DETAILS_ITEM_ITEM_TYPE_MISMATCH = 12505,
    SALES_ORDER_MAX_ALLOWED_ITEMS_EXCEEDED = 12506,
    SALES_ORDER_MAX_ORDER_POINTS_EXCEEDED = 12507,
    SALES_ORDER_TYPE_IS_MANDATORY = 12508,
    SALES_ORDER_MAX_ACTIVE_ORDERS_EXCEEDED = 12509,
    SALES_ORDER_DETAILS_LEVEL_MISMATCH = 12510,
    NOT_ENOUGH_BALANCE = 2006,
    COULD_NOT_FIND_PRODUCT = 1027,
    NOT_ENOUGH_AVAILABLE_BRAND_PRODUCT_COUPONS = 2005,
    INTERFACE_ERROR = 1087,
    COULD_NOT_FIND_ENTITY_INSTANCE = 1045,
    USER_NOT_FOUND = 1057,
    ITEM_NOT_FOUND = 1075,
    PRECONDITION_NOT_MET = 2043,
    MAX_QUANTITY_REDEEMED_PER_BRAND_PRODUCT = 2044,
    MAX_QUANTITY_AWARDED_PER_BRAND_PRODUCT = 2040,
    MAX_QUANTITY_PER_PURCHASE_REACHED = 2037,
    MAX_QUANTITY_PURCHASED_PER_BRAND_PRODUCT = 2038,
    ITEM_NOT_ACTIVE  = 2036,
    DCS_ERROR = 11106,
    DCS_ACCESS_ERROR = 11107,
    DCS_USER_NOT_FOUND = 11112,
    DCS_ORDER_ERROR = 11113,
    GENERIC = 1,
}