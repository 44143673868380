/*
 https://angular.io/docs/ts/latest/cookbook/ngmodule-faq.html#!#q-for-root
 CoreModule
 Create a CoreModule with providers for the singleton services you load when the application starts.
 Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
 Consider making CoreModule a pure services module with no declarations.
 */
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { Http2Talos } from "./http2Talos";
import { HttpClientModule } from "@angular/common/http";
import {
  AppSettingsApi,
  BrandApi,
  CategoryApi,
  FavoriteApi,
  ItemApi,
} from "./api";
import { UploadContentsApi } from "./api/UploadContentsApi";
import { UserApi } from "./api/UserApi";
import { QuizApi } from "./api/QuizApi";
import { EventApi } from "./api/EventApi";
import { PmiApi } from "./api/PmiApi";
import { MessageApi } from "./api/MessageApi";
import { MiddleApi } from "./api/middleApi";
import { ReferenceApi } from "./api/referenceApi";
import { TokenApi } from "./api/TokenApi";
import { AchievementApi } from "./api/AchievementApi";
import { CheckInApi } from "./api/CheckInApi";
import { ReferralApi } from "./api/ReferralApi";
import { InAppMessagesApi } from "./api/InAppMessagesApi";
import { TournamentsApi } from "./api/TournamentsApi";
import { SalesOrderApi } from "./api/SalesOrderApi";
import { IndexedDataApi } from "./api/IndexedDataApi";
import { RecommendationsApi } from "./api/RecommendationsApi";
import { IndirectDataApi } from "./api/IndirectDataApi";
import { UserV2Api } from "./api/UserV2Api";

@NgModule({
  imports: [HttpClientModule],
  providers: [
    Http2Talos,
    AppSettingsApi,
    CategoryApi,
    ItemApi,
    UploadContentsApi,
    UserApi,
    UserV2Api,
    QuizApi,
    FavoriteApi,
    EventApi,
    QuizApi,
    PmiApi,
    BrandApi,
    MessageApi,
    MiddleApi,
    ReferenceApi,
    TokenApi,
    AchievementApi,
    CheckInApi,
    ReferralApi,
    InAppMessagesApi,
    TournamentsApi,
    SalesOrderApi,
    IndexedDataApi,
    IndirectDataApi,
    RecommendationsApi,
  ],
})
export class TalosApiModule {
  constructor(@Optional() @SkipSelf() parentModule: TalosApiModule) {
    if (parentModule) {
      throw new Error(
        "Http2Talos Module is already loaded. Import it in the AppModule only"
      );
    }
  }
}
