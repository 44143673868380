import {Injectable} from "@angular/core";
import {CategoryService} from "../../../services/talos/category.service";
import {UploadContentsService} from "../../../services/talos/uploadContents.service";
import {AppGlobalsService} from "../../../services/appGlobals.service";
import {CategoryDTO} from "../../../talosApi/models/CategoryDTO";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import {UploadContentDTO} from "../../../talosApi/models/UploadContentDTO";
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import {EventRewardRulesDTO} from "../../../talosApi/models/EventRewardRulesDTO";
import Events_Types = TAConstants.Events_Types;
import Settings = TAConstants.Settings;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import {ResourcesServices} from "../../../services/talos/resources.services";
import {EventService} from "../../../services/talos/event.service";
import {IEventInput} from "../../../talosApi/api/EventApi";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import {ItemService} from "../../../services/talos/item.service";
import STATISTIC_TYPES = TAConstants.STATISTIC_TYPES;

@Injectable()
export class TestimonialDetailService {

    materialConfig = {};

    constructor(private categoryService: CategoryService, private uploadContentsService: UploadContentsService,
                private resourcesSrv: ResourcesServices, private eventSrv: EventService, private appGlobalsSrv: AppGlobalsService,
                private itemSrv: ItemService) {
        if (appGlobalsSrv.config)
            this.materialConfig = appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] || {};
    }

    getArticleCategory(categoryId: number): Promise<CategoryDTO> {
        return new Promise((resolve, reject) => {
            if (!categoryId) {
                reject('No category found!!');
                return;
            }

            this.categoryService.getCategoryById(categoryId, false, false, false, [Resource_Types.NAME]).then((category: CategoryDTO) => {
                resolve(category)
            }).catch(err => {
                reject(err);
            })
        });
    }

    getArticleById(articleId: string): Promise<UploadContentDTO> {
        return new Promise((resolve, reject) => {
            if (!articleId) {
                reject('No article found!!');
                return;
            }

            this.uploadContentsService.getUploadContents([articleId], UPLOAD_CONTENT_TYPE.GENERAL, null, 0, -1, true, null,
                null, true, false, false, [Resource_Types.NAME, Resource_Types.SHORT_DESCRIPTION, Resource_Types.DESCRIPTION, Resource_Types.URL_PATH, Resource_Types.VIDEO_URL], true).then((items: Array<UploadContentDTO>) => {
                if (items && items.length > 0) {
                    resolve(items[0]);
                    return;
                }

                resolve(null)
            }).catch(err => {
                reject(err);
            })
        });
    }

    getRelatedItems(categoryIds: Array<string>, rangeFrom: number, rangeTo: number): Promise<Array<UploadContentDTO>> {
        return new Promise((resolve, reject) => {
            this.uploadContentsService.getUploadContents(null, UPLOAD_CONTENT_TYPE.GENERAL, null, rangeFrom, rangeTo, true, categoryIds,
                ITEM_TYPE.CATEGORY, true, false, false, [Resource_Types.NAME, Resource_Types.SHORT_DESCRIPTION, Resource_Types.DESCRIPTION, Resource_Types.URL_PATH, Resource_Types.VIDEO_URL], true).then((items: Array<UploadContentDTO>) => {
                if (items) {
                    resolve(items);
                    return;
                }

                resolve([])
            }).catch(err => {
                reject(err);
            })
        });
    }

    getEventRewardsRules(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.eventSrv.getEventRewardRules(Settings.GAME_TYPE,
                [Events_Types.ITEM_VIEWED], true, LANGUAGES).then((result: Array<EventRewardRulesDTO>) => {
                let r = "";
                if (result && result.length > 0) {
                    const e = result[0];
                    r = (e.rewards && e.rewards.length > 0) ? this.resourcesSrv.getResourcesBasic(e.rewards[0], Resource_Types.NAME) : ""
                }
                resolve(r);
            }).catch(err => {
                reject(err);
            })
        });
    }

    sendWatchedEvent(itemId: string, itemTypeId: string): Promise<string> {
        return new Promise((resolve, reject) => {

            this.itemSrv.submitStatistics(itemId, Number(itemTypeId), STATISTIC_TYPES.ITEM_VIEWED, 1).then((result: any) => {
                resolve("SUCCESS");
            }).catch(err => {
                reject(err);
            })
        });
    }

    getArticleWatchedThreshold(categoryId: string): number {
        let result = -1;
        if (this.materialConfig && this.materialConfig[APPLICATION_SETTING_KEYS.WATCHED_THRESHOLD]) {
            result = this.materialConfig[APPLICATION_SETTING_KEYS.WATCHED_THRESHOLD][categoryId] || -1;
        }
        return result;
    }
}