import { Injectable } from "@angular/core";
import { isArray, isEqual, isNil, isNull, isString } from "lodash";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SalesOrderService } from "src/services/talos/salesOrder.service";
import {
  SalesOrderDetailsDTO,
  SalesOrderDTO,
  SalesOrderErrorCodes,
  SalesOrderItemsDTO,
} from "src/talosApi/api/SalesOrderApi";
import { TAConstants, TAValues } from "src/talosApi/settings";
import UNIT_TYPES = TAConstants.Unit_Types;
import SALES_ORDER_PROCESS_MODES = TAConstants.SALES_ORDER_PROCESS_MODES;
import SALES_ORDER_DETAILS_STATUSES = TAConstants.SALES_ORDER_DETAILS_STATUSES;
import SALES_ORDER_STATUSES = TAConstants.SALES_ORDER_STATUSES;
import Settings = TAConstants.Settings;
import { GetCountryStatesQuery } from "../../../talosApi/api/referenceApi";
import { StateDTO } from "../../../talosApi/models/StateDTO";
import { ReferenceService } from "../../../services/talos/reference.service";
import { AddressbookDTO } from "../../../talosApi/models/AddressbookDTO";
import { UserService } from "../../../services/talos/user.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BrandsService } from "../../../services/talos/brands.service";
import { CartItem, CartItemCoupon } from "./cart.presenter";
import { IListUserBrandProductCoupons } from "../../../talosApi/api/BrandApi";
import { DetailedBrandProductCouponDTO } from "../../../talosApi/models/DetailedBrandProductCouponDTO";
import {
  BRAND_PRODUCT_PURHCASE_TYPES,
  ExtendedCategoryDTO,
  LoyaltyBrandProductDTO,
  RewardsService,
} from "../rewards/rewards.service";
import { KpDictionary } from "../../../../../src/utils/kp_dictionary/kp.dictionary";
import { MetadataDTO } from "../../../talosApi/models/MetadataDTO";
import { SaveUserMetadataInput } from "../../../talosApi/models/SaveUserMetadataInput";
import METADATA_KEY = TAConstants.METADATA_KEY;
import CART_STATUS = TAConstants.CART_STATUS;
import { to } from "../../../../../src/utils/utils";
import { isNullOrUndefined } from "util";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";

@Injectable({
  providedIn: "root",
})
export class CartService {
  /* Current Cart Id. */
  private salesOrderId: string | null = null;
  private activeSalesOrder: SalesOrderDTO | null = null;

  /* Observer of Action Errors Object. */
  private errorObserver: Subject<CartError> = new Subject();
  public errorObserver$: Observable<CartError> = this.errorObserver.pipe();

  /* Observer of SalesOrderDTO Object. */
  private cartObserver: Subject<SalesOrderDTO> = new Subject();
  public cartObserver$: Observable<SalesOrderDTO> =
    this.cartObserver.asObservable();

  /* Observers of SalesOrderDTO Actions in Progress. */
  private addingCartItem: Subject<boolean> = new Subject();
  public addingCartItem$: Observable<boolean> = this.addingCartItem.pipe();

  private removingCartItem: Subject<boolean> = new Subject();
  public removingCartItem$: Observable<boolean> = this.removingCartItem.pipe();

  private updateCartItemObs: Subject<boolean> = new Subject();
  public updateCartItemObs$: Observable<boolean> =
    this.updateCartItemObs.pipe();

  private finalizingCart: Subject<boolean> = new Subject();
  public finalizingCart$: Observable<boolean> = this.finalizingCart.pipe();

  /* Observable for SalesOrderDTO update. */
  private cartItemAdded: Subject<SalesOrderDetailsDTO> = new Subject();
  public cartItemAdded$: Observable<SalesOrderDetailsDTO> =
    this.cartItemAdded.asObservable();

  private cartItemsAdded: Subject<Array<SalesOrderItemsDTO>> = new Subject();
  public cartItemsAdded$: Observable<Array<SalesOrderItemsDTO>> =
    this.cartItemsAdded.asObservable();

  // private _cartItems: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  // public cartItems$: Observable<any[]> = this._cartItems.asObservable();

  /* Observable for finalized Cart. */
  private cartFinalized: Subject<SalesOrderDTO> = new Subject();
  public cartFinalized$: Observable<SalesOrderDTO> =
    this.cartFinalized.asObservable();

  public cartToShow: SalesOrderDTO;
  /**
   * States  of cart service
   */
  private states: Array<StateDTO>;
  public mustCheckOrder: boolean;

  constructor(
    public salesOrderService: SalesOrderService,
    private referenceSrv: ReferenceService,
    private userSrv: UserService,
    private brandsSrv: BrandsService,
    private http: HttpClient,
    private recommendationsSrv: RecommendationsService
  ) {}

  // public loadCartItems() {
  //   return this._cartItems.getValue();
  // }

  public async checkCart(salesOrderId: string): Promise<SalesOrderDTO> {
    return new Promise<SalesOrderDTO>((resolve, reject) => {
      this.salesOrderService
        .listSalesOrders(TAValues.UserId, {
          salesOrderIds: [salesOrderId],
          rangeFrom: 0,
          rangeTo: 1,
        })
        .then((resp) => {
          if (resp && resp.length > 0) {
            // this._cartItems.next(resp);
            resolve(resp[0]);
            return;
          }

          resolve(null);
        })
        .catch((err) => {
          logger.log(err);
          reject(err);
        });
    });
  }

  public async listActiveCart(
    createNewIfEmpty: boolean = false,
    forceRefresh: boolean = false
  ) {
    if (!forceRefresh && !isNull(this.activeSalesOrder)) {
      this.updateCartObservable(this.activeSalesOrder);
      return;
    }
    if (forceRefresh) this.activeSalesOrder = null;
    await this.salesOrderService
      .listSalesOrders(TAValues.UserId, {
        salesOrderDetailsStatusIds: [
          SALES_ORDER_DETAILS_STATUSES.INSERTED,
          SALES_ORDER_DETAILS_STATUSES.RESERVED,
        ],
        salesOrderTypeIds: [
          Settings.IQOS_CLUB_IT_SALES_ORDER_TYPE_ID,
          Settings.IQOS_CLUB_IT_DCS_ORDER_TYPE_ID,
          Settings.IQOS_CLUB_IT_DCS_VERIFY_ORDER_TYPE_ID,
        ],
        salesOrderStatusIds: [SALES_ORDER_STATUSES.PENDING],
        filteringParams: [
          SALES_ORDER_PROCESS_MODES.INCLUDE_DETAILS,
          // SALES_ORDER_PROCESS_MODES.RESERVE,
          // SALES_ORDER_PROCESS_MODES.VALIDATE
        ],
        rangeFrom: 0,
        rangeTo: 1,
        resources: true,
        metadatas: true,
        languageIds: Settings.LANGUAGES,
      })
      .then(async (resp) => {
        if (isArray(resp) && resp.length === 1) {
          this.setCartOrError(resp[0], "list", false);
          if (!this.isUpdateOk(resp[0])) this.updateCartObservable(resp[0]);
        } else {
          if (createNewIfEmpty) await this.createNewCart();
          else this.updateCartObservable(null);
        }
      })
      .catch((err) => {
        logger.log(err);
        this.updateCartObservable(null);
      });
  }

  public async createNewCart(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.salesOrderService
        .createSalesORder(
          TAValues.UserId,
          {
            salesOrderDTO: {
              unitTypeId: UNIT_TYPES.IQOS_CLUB_IT_POINTS,
              salesOrderType: {
                salesOrderTypesId: Settings.IQOS_CLUB_IT_SALES_ORDER_TYPE_ID,
              },
              gameTypeId: Settings.GAME_TYPE,
            },
          },
          true
        )
        .then(async (resp) => {
          if (isArray(resp) && resp.length === 1) {
            this.updateCartObservable(resp[0]);
            resolve(true);
          } else {
            this.updateCartObservable(null);
            reject(false);
          }
        })
        .catch((err) => {
          logger.error(err);
          this.updateCartObservable(null);
          const errorCode = this.getResponseError(err);
          if (errorCode) this.setCartErrorCode(CartErrorType.CART, errorCode);
          reject(false);
        });
    });
  }

  public async addMultipleItems(
    salesOrderDetailsDTOList: Array<AddCartItemInput>,
    expired: boolean = false
  ) {
    this.addingCartItem.next(true);
    let failed = false;
    if (!this.salesOrderId || expired) {
      logger.log("Check if Cart exists. (Not listed)");
      await this.listActiveCart(true, true).catch((error) => {
        failed = true;
      });
    }
    if (!failed) {
      await this.salesOrderService
        .updateSalesOrders(
          TAValues.UserId,
          {
            filteringParams: [SALES_ORDER_PROCESS_MODES.INCLUDE_DETAILS],
            salesOrderDTO: {
              salesOrderId: this.salesOrderId,
              salesOrderDetailsDTOList: salesOrderDetailsDTOList,
            },
          },
          {
            resources: true,
            metadatas: true,
            languageIds: TAConstants.Settings.LANGUAGES,
          }
        )
        .then(async (resp) => {
          this.setCartOrError(resp[0], "add", false);
          if (this.isUpdateOk(resp[0])) {
            this.cartItemsAdded.next(resp[0].salesOrderDetailsDTOList);
            // this._cartItems.next([
            //   ...this._cartItems.getValue(),
            //   resp[0].salesOrderDetailsDTOList,
            // ]);
            // TODO
            // const item_added = resp[0].salesOrderDetailsDTOList.find(
            //   (sod) => sod.itemId === input.itemId
            // );
            // if (item_added) this.cartItemAdded.next(item_added);
          }
        })
        .catch(async (err) => {
          logger.log(err);
          const errorCode = this.getResponseError(err);
          if (
            !expired &&
            [SalesOrderErrorCodes.SALES_ORDER_NOT_IN_VALID_STATUS].includes(
              errorCode
            )
          ) {
            logger.log(
              "Failed order. Try to create new Cart and Push the Item."
            );
            await this.addMultipleItems(salesOrderDetailsDTOList, true);
          } else {
            this.setCartErrorCode(CartErrorType.CALL, errorCode);
            this.updateCartObservable(null);
          }
        });
    }
    this.addingCartItem.next(false);
  }

  public async addCartItem(input: AddCartItemInput, expired: boolean = false) {
    this.addingCartItem.next(true);
    let failed = false;
    if (!this.salesOrderId || expired) {
      logger.log("Check if Cart exists. (Not listed)");
      await this.listActiveCart(true, true).catch((error) => {
        failed = true;
      });
    }
    if (!failed) {
      await this.salesOrderService
        .updateSalesOrders(
          TAValues.UserId,
          {
            filteringParams: [SALES_ORDER_PROCESS_MODES.INCLUDE_DETAILS],
            salesOrderDTO: {
              salesOrderId: this.salesOrderId,
              salesOrderDetailsDTOList: [input],
            },
          },
          {
            resources: true,
            metadatas: true,
            languageIds: TAConstants.Settings.LANGUAGES,
          }
        )
        .then(async (resp) => {
          this.setCartOrError(resp[0], "add", false);
          if (this.isUpdateOk(resp[0])) {
            const item_added = resp[0].salesOrderDetailsDTOList.find(
              (sod) => sod.itemId === input.itemId
            );
            if (item_added) {
              this.cartItemAdded.next(item_added);
              // this._cartItems.next([...this._cartItems.getValue(), item_added]);
            }
          }
        })
        .catch(async (err) => {
          logger.log(err);
          const errorCode = this.getResponseError(err);
          if (
            !expired &&
            [SalesOrderErrorCodes.SALES_ORDER_NOT_IN_VALID_STATUS].includes(
              errorCode
            )
          ) {
            logger.log(
              "Failed order. Try to create new Cart and Push the Item."
            );
            await this.addCartItem(input, true);
          } else {
            this.setCartErrorCode(CartErrorType.CALL, errorCode);
            this.updateCartObservable(null);
          }
        });
    }
    this.addingCartItem.next(false);
  }

  public isRedeemable(items: CartItem[], product?: any): boolean {
    if (product) {
      if (
        items &&
        items.length > 0 &&
        product.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
        )
      ) {
        let redeemOptions = [];
        // get redeemOptions of product to be added
        try {
          const tmp_val = JSON.parse(
            product.metadataMapped.Item(
              METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
            )
          );
          if (!isNullOrUndefined(tmp_val.type)) {
            if (isArray(tmp_val.type)) {
              tmp_val.type.forEach((val) => {
                if (val in BRAND_PRODUCT_PURHCASE_TYPES) {
                  redeemOptions.push(val);
                } else {
                  logger.warn(
                    product.metadataMapped.Item(
                      METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
                    )
                  );
                }
              });
            }
          }
        } catch (e) {
          logger.error(
            "Product to be added has no redeem options",
            product.metadataMapped.Item(
              METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
            )
          );
          return true;
        }
        if (redeemOptions && redeemOptions.length === 1) {
          // in case there is a cart item with 2 redeemOptions
          if (items.some((ci) => ci.redeemOptions.length === 2)) return true;
          const found = items
            .filter((ci) => ci.redeemOptions && ci.redeemOptions.length === 1)
            .some((ci) => ci.redeemOptions[0] === redeemOptions[0]);
          // dummy error code for invalid item due to its redeem options
          if (!found) this.setCartErrorCode(CartErrorType.CART_ITEM, 9999);
          return found;
        }
      }
      return true;
    } else {
      // return false only if all items in cart have one option and this option is not the same
      if (items.some((i) => i.redeemOptions.length === 2)) return true;
      const filteredOptions = items
            .filter((ci) => ci.redeemOptions && ci.redeemOptions.length === 1).map(ci => ci.redeemOptions[0])
      const getUniqueOptions = new Set(filteredOptions);
      const oneOptionLeft: boolean = getUniqueOptions.size == 1;
      if (!oneOptionLeft) this.setCartErrorCode(CartErrorType.CART_ITEM, 9999);
      return oneOptionLeft;
    }
  }
  
  /**
   *
   * @param input
   */
  public async removeCartItem(input: RemoveCartItemInput) {
    this.updateCartItem(
      {
        ...input,
        ...{
          quantity: 0,
        },
      },
      "remove",
      false
    );
  }

  public async updateCartItemGen(input: RemoveCartItemInput, quantity: number) {
    this.updateCartItem(
      {
        ...input,
        ...{
          quantity: quantity,
        },
      },
      "update",
      false
    );
  }

  public async finalize(input: ShippingInput) {
    this.finalizeCart(input);
  }

  private finalizeCart(input: ShippingInput): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.finalizingCart.next(true);

      let failed = false;
      if (!this.salesOrderId) {
        failed = true;
      }

      if (!failed) {
        this.mustCheckOrder = false;
        let addressToSave;
        if (
          input.salesOrderTypesId == Settings.IQOS_CLUB_IT_DCS_ORDER_TYPE_ID ||
          input.salesOrderTypesId ==
            Settings.IQOS_CLUB_IT_DCS_VERIFY_ORDER_TYPE_ID
        ) {
          if (input.shippingAddressBook) {
            addressToSave = JSON.stringify(input.shippingAddressBook);
            input.shippingAddressBook.addressStreet =
              input.shippingAddressBook.addressStreet +
              " " +
              input.shippingAddressBook.addressNo;
            input.shippingAddressBook.addressNo = null;
            input.shippingAddressBook.addressTitle = null;
          }
          if (input.billingAddressBook) {
            input.billingAddressBook.addressStreet =
              input.billingAddressBook.addressStreet +
              " " +
              input.billingAddressBook.addressNo;
            input.billingAddressBook.addressNo = null;
            input.billingAddressBook.addressTitle = null;
          }
        }
        await this.updateCartItem({}, "shipping", false, input)
          .then(async (resp) => {
            await this.salesOrderService
              .orderSalesOrder(
                TAValues.UserId,
                {
                  salesOrderId: this.salesOrderId,
                  updatedByUserId: TAValues.UserId,
                },
                {
                  resources: true,
                  metadatas: true,
                  languageIds: TAConstants.Settings.LANGUAGES,
                }
              )
              .then(async (resp) => {
                //salesOrderStatus
                this.setCartOrError(resp, "shipping", false, true);
                // failed = !this.isUpdateOk(resp) || !(resp.salesOrderStatus == CART_STATUS.COMPLETED || resp.salesOrderStatus == CART_STATUS.PUSHED_TO_EXTERNAL_SYSTEM);
                failed =
                  !this.isUpdateOk(resp) ||
                  !(
                    resp.salesOrderStatus == CART_STATUS.COMPLETED ||
                    resp.salesOrderStatus ==
                      CART_STATUS.PUSHED_TO_EXTERNAL_SYSTEM ||
                    (input.salesOrderTypesId ==
                      Settings.IQOS_CLUB_IT_DCS_VERIFY_ORDER_TYPE_ID &&
                      resp.salesOrderStatus == CART_STATUS.PARTIALLY_COMPLETED)
                  );
                if (!failed) {
                  // if(resp.salesOrderStatus == CART_STATUS.)
                  this.mustCheckOrder =
                    resp.salesOrderStatus ==
                    CART_STATUS.PUSHED_TO_EXTERNAL_SYSTEM;
                  if (
                    (input.salesOrderTypesId ==
                      Settings.IQOS_CLUB_IT_DCS_ORDER_TYPE_ID ||
                      input.salesOrderTypesId ==
                        Settings.IQOS_CLUB_IT_DCS_VERIFY_ORDER_TYPE_ID) &&
                    addressToSave
                  ) {
                    const metadataToSave: MetadataDTO[] = [];
                    metadataToSave.push({
                      key: METADATA_KEY.PERSONAL_ADDRESS,
                      value: addressToSave,
                    });
                    const inputMetadata: SaveUserMetadataInput = {
                      userId: TAValues.UserId,
                      userMetadataToSave: metadataToSave,
                    };

                    this.userSrv
                      .saveMetadata(inputMetadata, true)
                      .then((result) => {
                        this.userSrv
                          .getUserProfile(TAValues.UserId)
                          .then((result) => logger.log(result))
                          .catch((err) => {});
                      })
                      .catch((err) => logger.log(err));
                  }
                  this.userSrv
                    .updateUserStatus()
                    .then((result) => logger.log(result))
                    .catch((err) => logger.log(err));
                  this.cartToShow = resp;
                  this.updateCartObservable(null);
                  this.cartFinalized.next(resp);
                }
              })
              .catch((err) => {
                logger.error(err);
                failed = true;
                this.setCartErrorCode(
                  CartErrorType.CALL,
                  this.getResponseError(err)
                );
              });
          })
          .catch((err) => {
            logger.error(err);
            failed = true;
            this.setCartErrorCode(
              CartErrorType.CALL,
              this.getResponseError(err)
            );
          });
      }

      this.finalizingCart.next(false);

      if (failed) reject(false);
      else resolve(true);
    });
  }

  /**
   *
   * @param input
   */
  private async updateCartItem(
    input: AddOrRemoveCartItemInput,
    action: "add" | "remove" | "shipping" | "update",
    createIfEmpty: boolean = true,
    shippingInput: ShippingInput = null
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (action == "add") this.addingCartItem.next(true);
      if (action == "remove") this.removingCartItem.next(true);
      if (action == "update") this.updateCartItemObs.next(true);
      let shipping = action == "shipping";
      let failed = false;
      /* Create new Cart only on Add action and on Empty salesOrderId. */
      if (!this.salesOrderId && action === "add" && createIfEmpty) {
        await this.createNewCart().catch((error) => {
          failed = true;
          logger.error("Could not Create empty Cart!");
        });
      }
      if (!this.salesOrderId) {
        failed = true;
      }
      if (!failed) {
        await this.salesOrderService
          .updateSalesOrders(
            TAValues.UserId,
            {
              filteringParams: shipping
                ? [SALES_ORDER_PROCESS_MODES.INCLUDE_DETAILS]
                : [
                    SALES_ORDER_PROCESS_MODES.INCLUDE_DETAILS,
                    // SALES_ORDER_PROCESS_MODES.RESERVE,
                    // SALES_ORDER_PROCESS_MODES.VALIDATE
                  ],
              salesOrderDTO: {
                salesOrderId: this.salesOrderId,
                updatedUserId: TAValues.UserId,
                salesOrderDetailsDTOList: shipping ? null : [input],
                shippingAddressBook: shippingInput
                  ? shippingInput.shippingAddressBook
                  : null,
                billingAddressBook: shippingInput
                  ? shippingInput.billingAddressBook
                  : null,
                comments: shippingInput ? shippingInput.comments : null,
                salesOrderType: {
                  salesOrderTypesId: shippingInput
                    ? shippingInput.salesOrderTypesId
                    : Settings.IQOS_CLUB_IT_SALES_ORDER_TYPE_ID,
                },
              },
            },
            {
              resources: true,
              metadatas: true,
              languageIds: TAConstants.Settings.LANGUAGES,
            }
          )
          .then(async (resp) => {
            this.setCartOrError(resp[0], action, false);
            failed = !this.isUpdateOk(resp[0]);
            // this._cartItems.next(resp);
          })
          .catch((err) => {
            logger.error(err);
            failed = true;
            reject(err);
            return;
          });
      }
      if (action == "add") this.addingCartItem.next(false);
      if (action == "remove") this.removingCartItem.next(false);
      if (action == "update") this.updateCartItemObs.next(false);
      if (failed) reject(false);
      else resolve(true);
    });
  }

  /**
   *
   * @param input
   * @returns
   */
  private updateCartObservable(input: SalesOrderDTO | null) {
    if (isNull(input) || isNull(input.salesOrderId)) {
      this.activeSalesOrder = null;
      this.cartObserver.next(null);
      this.salesOrderId = null;
      return;
    }
    this.activeSalesOrder = input;
    this.cartObserver.next(input);
    this.salesOrderId = input.salesOrderId;
  }

  /**
   *
   * @param salesOrder
   * @returns
   */
  private isUpdateOk(
    salesOrder: SalesOrderDTO,
    action?: "add" | "remove" | "shipping" | "list" | "update",
    itemId?: string
  ) {
    if (!isNull(salesOrder)) {
      if (salesOrder.errorCode) {
        return false;
      } else if (
        isArray(salesOrder.salesOrderDetailsDTOList) &&
        salesOrder.salesOrderDetailsDTOList.length
      ) {
        const faultItem = salesOrder.salesOrderDetailsDTOList.find(
          (so) => !isNil(so.errorCode)
        );
        if (action === "remove") {
          if (
            this.activeSalesOrder &&
            !isEqual(
              salesOrder.salesOrderDetailsDTOList,
              this.activeSalesOrder.salesOrderDetailsDTOList
            )
          ) {
            return true;
          }
        }
        if (faultItem && faultItem.errorCode) {
          return false;
        }
        return true;
      }
      return true;
    }
    return false;
  }

  /**
   *
   * @param input
   */
  private setCartOrError(
    salesOrder: SalesOrderDTO,
    action: "add" | "remove" | "shipping" | "list" | "update",
    init: boolean,
    preventUpdate?: boolean
  ): void {
    if (this.isUpdateOk(salesOrder, action)) {
      if (!preventUpdate) this.updateCartObservable(salesOrder);
      return;
    }
    if (!isNull(salesOrder)) {
      if (salesOrder.errorCode) {
        this.setCartErrorCode(CartErrorType.CART, salesOrder.errorCode);
      } else if (
        isArray(salesOrder.salesOrderDetailsDTOList) &&
        salesOrder.salesOrderDetailsDTOList.length
      ) {
        const faultItem = salesOrder.salesOrderDetailsDTOList.find(
          (so) => !isNil(so.errorCode)
        );
        if (faultItem && faultItem.errorCode) {
          this.setCartErrorCode(CartErrorType.CART_ITEM, faultItem.errorCode);
        }
      }
    }
  }

  /**
   *
   * @param type
   * @param errorCode
   */
  private setCartErrorCode(type: CartErrorType, errorCode: number) {
    this.errorObserver.next({
      type,
      errorCode,
    });
  }

  private getResponseError(resp: any): number {
    if (resp && resp.error && isString(resp.error)) {
      try {
        const parsed = JSON.parse(resp.error);
        if (parsed && parsed.code) return parsed.code;
      } catch (err) {
        logger.error(err);
      }
    }
    return 1;
  }

  public getStates(): Promise<Array<StateDTO>> {
    return new Promise((resolve, reject) => {
      if (this.states) {
        resolve(this.states);
        return;
      }

      const url =
        "https://www.mystartapp.org/pub/iqosclub/states.json?" +
        new Date().getTime();
      this.http.request("GET", url).subscribe(
        (response) => {
          this.states = response as Array<StateDTO>;
          resolve(response as Array<StateDTO>);
        },
        (error) => {
          reject(error);
        }
      );
      // const query: GetCountryStatesQuery = {
      //     rangeTo: -1,
      //     // resources: true,
      //     // metadatas: true,
      //     includeDetails: true,
      //     countryIds: [Settings.IT_COUNTRY_ID],
      //     // languageIds: TAConstants.Settings.LANGUAGES,
      // }
      // this.referenceSrv.getCountryStates(query).then(res => {
      //     this.states = res;
      //     resolve(res);
      // }).catch(err => {
      //     reject(err);
      // })
    });
  }

  public getCouponImage(couponId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!couponId || couponId == "") {
        reject("ERROR");
        return;
      }

      this.brandsSrv
        .getCouponQrCode(TAValues.UserId, couponId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getCouponsDetails(coupons: Array<CartItemCoupon>): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!coupons || coupons.length == 0) {
        resolve(true);
        return;
      }

      const ids = coupons.map((c) => {
        return c.id;
      });

      if (ids && ids.length > 0) {
        const input: IListUserBrandProductCoupons = {
          brandProductCouponIds: ids,
          includeQRCode: true,
          includeDetails: true,
          resources: true,
          languageIds: TAConstants.Settings.LANGUAGES,
        };
        const res: any = await this.brandsSrv
          .getUserBrandProductCoupons(TAValues.UserId, input)
          .catch((err) => {});
        if (res) {
          res.forEach((c) => {
            const coupon = coupons.find((coup) => {
              return coup.id == c.couponId;
            });
            if (coupon) {
              coupon.image = c.image;
              // coupon.
            }
          });
        }
      }
      resolve(true);
    });
  }

  private couponSubCategory(
    item: DetailedBrandProductCouponDTO,
    categories: KpDictionary<ExtendedCategoryDTO>
  ): string | null {
    let ret: string | null = null;
    // try {
    //     if (isNil(item) || isNil(categories)) return null;
    //     if (!categories.Count()) return null;
    //     if (isNil(item.subCategoryIds)) return null;
    //     if (!isArray(item.subCategoryIds)) return null;
    //     if (isArray(item.subCategoryIds) && !item.subCategoryIds.length) return null;
    //     item.subCategoryIds.forEach(categoryId => {
    //         if (categories.ContainsKey(categoryId))
    //             ret = categoryId;
    //     });
    // } catch (e) {
    //     logger.error(e);
    // }
    return ret;
  }

  public getQuantityEnable(ids: Array<string>): Promise<Map<string, boolean>> {
    return new Promise(async (resolve, reject) => {
      const result: Map<string, boolean> = new Map<string, boolean>();

      const input: FilterESContentDataInput = {
        itemIds: ids,
        itemTypeIds: [ITEM_TYPES.BRAND_PRODUCT],
        rangeFrom: 0,
        rangeTo: ids.length,
        currentlyActive: true,
        matchingRules: false,
      };
      const products = await this.recommendationsSrv
        .getRecommendationContent(TAValues.UserId, input, {})
        .catch((err) => {});

      if (products) {
        products.forEach((p) => {
          result.set(p.itemId, true);
          if (p.associatedItems) {
            const limit = p.associatedItems.find((a) => {
              return a.itemTypeId == ITEM_TYPES.BRAND_PRODUCT_TRANSACTION_LIMIT;
            });
            if (limit) {
              result.set(p.itemId, false);
            }
          }
        });
      }

      resolve(result);
    });
  }
}

export type AddCartItemInput = Pick<
  SalesOrderDetailsDTO,
  "itemId" | "itemTypeId" | "quantity" | "salesOrderDetailsId"
>;

export type RemoveCartItemInput = AddCartItemInput &
  Pick<SalesOrderDetailsDTO, "salesOrderDetailsId">;

export type AddOrRemoveCartItemInput = AddCartItemInput | RemoveCartItemInput;

export type CartError = null | {
  type: CartErrorType;
  errorCode: number;
};

export enum CartErrorType {
  CART_ITEM,
  CART,
  CALL,
}

export interface ShippingInput {
  shippingAddressBook?: AddressbookDTO;
  billingAddressBook?: AddressbookDTO;
  comments?: string;
  salesOrderTypesId?: string;
  salesOrderChannelId?: 0 | 1; // 0 = Store, 1 = DCS
}
