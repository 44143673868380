import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { AuthUnlinkedGuard } from "./auth-unlinked.guard";
import { AuthExternalGuard } from "./auth-external.guard";
import { AuthCastingGuard } from "./auth-casting.guard";

const routes: Routes = [
  { path: "", redirectTo: "loading", pathMatch: "full" },
  // { path: "", redirectTo: "login", pathMatch: "full" },
  // { path: "login", loadChildren: "./pages/login/login.module#LoginModule" },
  {
    path: "loading",
    loadChildren: "./pages/loading/loading.module#LoadingModule",
  },
  {
    path: "overview",
    loadChildren: "./pages/overview/overview.module#OverviewModule",
    canActivate: [AuthGuard],
  },
  {
    path: "faqs",
    loadChildren: "./pages/faqs/faqs.module#FaqsModule",
    canActivate: [AuthGuard],
  },
  {
    path: "negozio",
    loadChildren: "./pages/negozio/negozio.module#NegozioModule",
    canActivate: [AuthGuard],
  },
  {
    path: "scegliere-iqos",
    loadChildren:
      "./pages/scegliere-iqos/scegliere-iqos.module#ScegliereIqosModule",
    canActivate: [AuthGuard],
  },
  {
    path: "iqos-club",
    loadChildren: "./pages/iqos-club/iqos-club.module#IqosClubModule",
    canActivate: [AuthGuard],
  },
  {
    path: "invite-friends",
    loadChildren:
      "./pages/invite-friends/invite-friends.module#InviteFriendsModule",
    canActivate: [AuthGuard],
  },
  {
    path: "invita-amici",
    loadChildren: "./pages/invita-amici/invita-amici.module#InvitaAmiciModule",
    canActivate: [AuthGuard],
  },
  {
    path: "iluma-invita",
    loadChildren: "./pages/iluma-invita/iluma-invita.module#IlumaInvitaModule",
    canActivate: [AuthGuard],
  },
  {
    path: "club-invite",
    loadChildren: "./pages/club-invite/club-invite.module#ClubInviteModule",
    canActivate: [AuthGuard],
  },
  {
    path: "guadagna-punti",
    loadChildren:
      "./pages/guadagna-punti/guadagna-punti.module#GuadagnaPuntiModule",
    canActivate: [AuthGuard],
  },
  {
    path: "tutti-contenuti",
    loadChildren:
      "./pages/tutti-contenuti/tutti-contenuti.module#TuttiContenutiModule",
    canActivate: [AuthGuard],
  },

  {
    path: "iqos-iluma",
    loadChildren: "./pages/iluma/iluma.module#IlumaModule",
    canActivate: [AuthGuard],
  },

  {
    path: "material",
    loadChildren: "./pages/material/material.module#MaterialModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "rewards",
    loadChildren: "./pages/rewards/rewards.module#RewardsModule",
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    loadChildren: "./pages/profile-new/profile-new.module#ProfileNewModule",
    canActivate: [AuthGuard],
  },
  // {
  //   path: "profile",
  //   loadChildren: "./pages/profile/profile.module#ProfileModule",
  //   canActivate: [AuthGuard],
  // },
  {
    path: "how-to",
    loadChildren: "./pages/how-to/how-to.module#HowToModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "quiz/:slug/detail",
    loadChildren:
      "./pages/quizzes/quiz-detail/quiz-detail.module#QuizDetailModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "quiz/:slug",
    loadChildren: "./pages/quizzes/quiz/quiz.module#QuizModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "article-detail/:slug",
    loadChildren:
      "./pages/article-detail/article-detail.module#ArticleDetailModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "video-detail/:slug",
    loadChildren: "./pages/video-detail/video-detail.module#VideoDetailModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "testimonial-detail/:categoryBaseId/:categoryId/:articleId",
    loadChildren:
      "./pages/testimonial-detail/testimonial-detail.module#TestimonialDetailModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "quick-reply/:slug",
    loadChildren: "./pages/quick-reply/quick-reply.module#QuickReplyModule",
    canActivate: [AuthUnlinkedGuard],
  },
  // {path: 'invite-pages', loadChildren: './pages/invite/invite.module#InviteModule', canActivate: [AuthUnlinkedGuard]},
  {
    path: "invite-list",
    loadChildren:
      "./pages/invite-list-page/invite-list-page.module#InviteListPageModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "invite",
    loadChildren: "./pages/invite-page/invite-page.module#InvitePageModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "unique-code",
    loadChildren: "./pages/unique-code/unique-code.module#UniqueCodeModule",
    canActivate: [AuthGuard],
  },
  {
    path: "iqos-master",
    loadChildren: "./pages/iqos-master/iqos-master.module#IqosMasterModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "gallery/:slug",
    loadChildren: "./pages/gallery/gallery.module#GalleryModule",
    canActivate: [AuthGuard],
  },
  {
    path: "donate",
    loadChildren: "./pages/donate/donate.module#DonateModule",
    canActivate: [AuthGuard],
  },
  {
    path: "community",
    loadChildren: "./pages/community/community.module#CommunityModule",
    canActivate: [AuthCastingGuard],
  },
  {
    path: "community/:langCode",
    loadChildren: "./pages/community/community.module#CommunityModule",
    canActivate: [AuthCastingGuard],
  },
  {
    path: "together-x",
    loadChildren: "./pages/together-x/together-x.module#TogetherXModule",
    canActivate: [AuthCastingGuard],
  },
  // {
  //     path: 'casting',
  //     loadChildren: './pages/casting/casting.module#CastingModule', canActivate: [AuthCastingGuard],
  // },
  // {
  //     path: 'casting/:langCode',
  //     loadChildren: './pages/casting/casting.module#CastingModule', canActivate: [AuthCastingGuard],
  // },
  {
    path: "purchase",
    loadChildren: "./pages/purchase/purchase.module#PurchaseModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "notifications",
    loadChildren:
      "./pages/notifications/notifications.module#NotificationsModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "qr-survey-intro/:slug",
    loadChildren:
      "./pages/qr-survey/qr-survey-intro/qr-survey-intro.module#QrSurveyIntroModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "qr-survey-detail",
    loadChildren:
      "./pages/qr-survey/qr-survey-detail/qr-survey-detail.module#QrSurveyDetailModule",
    canActivate: [AuthExternalGuard],
  },
  {
    path: "qr-survey-survey",
    loadChildren:
      "./pages/qr-survey/qr-survey-survey/qr-survey-survey.module#QrSurveySurveyModule",
    canActivate: [AuthExternalGuard],
  },
  {
    path: "qr-survey-gps",
    loadChildren:
      "./pages/qr-survey/qr-survey-gps/qr-survey-gps.module#QrSurveyGpsModule",
    canActivate: [AuthExternalGuard],
  },
  {
    path: "qr-survey-coupon",
    loadChildren:
      "./pages/qr-survey/qr-survey-coupon/qr-survey-coupon.module#QrSurveyCouponModule",
    canActivate: [AuthExternalGuard],
  },
  {
    path: "cart-preview",
    loadChildren:
      "./pages/cart/cart-preview/cart-preview.module#CartPreviewModule",
    canActivate: [AuthGuard],
  },
  {
    path: "cart-confirm",
    loadChildren:
      "./pages/cart/cart-confirm/cart-confirm.module#CartConfirmModule",
    canActivate: [AuthGuard],
  },
  {
    path: "cart-result",
    loadChildren:
      "./pages/cart/cart-result/cart-result.module#CartResultModule",
    canActivate: [AuthGuard],
  },
  {
    path: "motivation",
    loadChildren: "./pages/motivation/motivation.module#MotivationModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "sustainability",
    loadChildren:
      "./pages/sustainability/sustainability-home/sustainability-home.module#SustainabilityHomeModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "sustainability/manifest",
    loadChildren:
      "./pages/sustainability/sustainability-manifest/sustainability-manifest.module#SustainabilityManifestModule",
    canActivate: [AuthUnlinkedGuard],
  },
  {
    path: "sustainability/:id",
    loadChildren:
      "./pages/sustainability/sustainability-details/sustainability-details.module#SustainabilityDetailsModule",
    canActivate: [AuthUnlinkedGuard],
  },

  // { path: '', redirectTo: 'loading', pathMatch: 'full' },
  // // {path: '', redirectTo: 'login', pathMatch: 'full'},
  // // {path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  // {path: 'loading', loadChildren: () => import('./pages/loading/loading.module').then(m => m.LoadingModule)},
  // {
  //     path: 'overview',
  //     loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewModule),
  //     canActivate: [AuthGuard]
  // },
  // {
  //     path: 'material',
  //     loadChildren: () => import('./pages/material/material.module').then(m => m.MaterialModule),
  //     canActivate: [AuthUnlinkedGuard]
  // },
  // {path: 'rewards', loadChildren: () => import('./pages/rewards/rewards.module').then(m => m.RewardsModule), canActivate: [AuthGuard]},
  // {path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard]},
  // {path: 'how-to', loadChildren: () => import('./pages/how-to/how-to.module').then(m => m.HowToModule), canActivate: [AuthUnlinkedGuard]},
  // {
  //     path: 'quiz/:slug/detail',
  //     loadChildren: () => import('./pages/quizzes/quiz-detail/quiz-detail.module').then(m => m.QuizDetailModule),
  //     canActivate: [AuthUnlinkedGuard]
  // },
  // {path: 'quiz/:slug', loadChildren: () => import('./pages/quizzes/quiz/quiz.module').then(m => m.QuizModule), canActivate: [AuthUnlinkedGuard]},
  // {
  //     path: 'article-detail/:slug',
  //     loadChildren: () => import('./pages/article-detail/article-detail.module').then(m => m.ArticleDetailModule),
  //     canActivate: [AuthUnlinkedGuard]
  // },
  // {
  //     path: 'video-detail/:slug',
  //     loadChildren: () => import('./pages/video-detail/video-detail.module').then(m => m.VideoDetailModule),
  //     canActivate: [AuthUnlinkedGuard]
  // },
  // {
  //     path: 'testimonial-detail/:categoryBaseId/:categoryId/:articleId',
  //     loadChildren: () => import('./pages/testimonial-detail/testimonial-detail.module').then(m => m.TestimonialDetailModule),
  //     canActivate: [AuthUnlinkedGuard]
  // },
  // {path: 'invite-pages', loadChildren: () => import('./pages/invite/invite.module').then(m => m.InviteModule), canActivate: [AuthUnlinkedGuard]},
  // {path: 'invite', loadChildren: () => import('./pages/invite-page/invite-page.module').then(m => m.InvitePageModule), canActivate: [AuthUnlinkedGuard]},
  // {path: 'unique-code', loadChildren: () => import('./pages/unique-code/unique-code.module').then(m => m.UniqueCodeModule), canActivate: [AuthGuard]},
  // {
  //     path: 'iqos-master',
  //     loadChildren: () => import('./pages/iqos-master/iqos-master.module').then(m => m.IqosMasterModule), canActivate: [AuthUnlinkedGuard]
  // },
  // {
  //     path: 'gallery/:slug',
  //     loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule), canActivate: [AuthGuard]
  // },
  // {
  //     path: 'donate',
  //     loadChildren: () => import('./pages/donate/donate.module').then(m => m.DonateModule), canActivate: [AuthGuard],
  // },
  // {
  //     path: 'community',
  //     loadChildren: () => import('./pages/community/community.module').then(m => m.CommunityModule), canActivate: [AuthUnlinkedGuard],
  // },
  // {
  //     path: 'purchase',
  //     loadChildren: () => import('./pages/purchase/purchase.module').then(m => m.PurchaseModule), canActivate: [AuthUnlinkedGuard]
  // },
  // {
  //     path: 'notifications',
  //     loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthUnlinkedGuard]
  // },
  // {
  //     path: 'qr-survey-intro/:slug',
  //     loadChildren: () => import('./pages/qr-survey/qr-survey-intro/qr-survey-intro.module').then(m => m.QrSurveyIntroModule), canActivate: [AuthUnlinkedGuard]
  // },
  // {
  //     path: 'qr-survey-detail',
  //     loadChildren: () => import('./pages/qr-survey/qr-survey-detail/qr-survey-detail.module').then(m => m.QrSurveyDetailModule), canActivate: [AuthExternalGuard]
  // },
  // {
  //     path: 'qr-survey-survey',
  //     loadChildren: () => import('./pages/qr-survey/qr-survey-survey/qr-survey-survey.module').then(m => m.QrSurveySurveyModule), canActivate: [AuthExternalGuard]
  // },
  // {
  //     path: 'qr-survey-gps',
  //     loadChildren: () => import('./pages/qr-survey/qr-survey-gps/qr-survey-gps.module').then(m => m.QrSurveyGpsModule), canActivate: [AuthExternalGuard]
  // },
  // {
  //     path: 'qr-survey-coupon',
  //     loadChildren: () => import('./pages/qr-survey/qr-survey-coupon/qr-survey-coupon.module').then(m => m.QrSurveyCouponModule), canActivate: [AuthExternalGuard]
  // },

  {
    path: "**",
    redirectTo: "loading",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
