/**
 * Created by georgefloros on 16/05/2017.
 */

/*
 Generated class for the AppSettings provider.

 See https://angular.io/docs/ts/latest/guide/dependency-injection.html
 for more info on providers and Angular 2 DI.
 */


import {Injectable} from "@angular/core";
import _ from 'lodash';
import {TAConstants} from "../../talosApi/settings";
import {AppGlobalsService} from "../appGlobals.service";
import {TournamentDTOS} from "../../talosApi/models/TournamentDTOS";
import {
    ConvertToTournamentParticipationsInput,
    GetTournamentRankingQuery,
    GetTournamentsQuery, GetTournamentWinnersQuery, GetUserTournamentStatisticsQuery,
    IGetTournamentUserRankingQuery,
    TournamentsApi
} from "../../talosApi/api/TournamentsApi";
import Settings = TAConstants.Settings;
import {UserRankingDTOS} from "../../talosApi/models/UserRankingDTOS";
import {TournamentUserStatisticsDTO} from "../../talosApi/models/TournamentUserStatisticsDTO";
import {TournamentWinnersDTO} from "../../talosApi/models/TournamentWinnersDTO";

@Injectable({providedIn: 'root'})
export class TournamentsService {

    constructor(private api: TournamentsApi) {
    }

    /**
     * Get tournaments
     * @param {GetTournamentsQuery} query
     * @return {Promise<Array<TournamentDTOS>>}
     */
    async getTournaments(query: GetTournamentsQuery): Promise<Array<TournamentDTOS>> {
        return new Promise<Array<TournamentDTOS>>((resolve, reject) => {
            this.api.getTournaments(query)
                .then((response: Array<TournamentDTOS>) => {
                    resolve(response);
                }).catch(reject);
        });
    }

    // /**
    //  * Get Tournament Ranking
    //  *
    //  * @param {string} tournamentId
    //  * @param {GetTournamentRankingQuery} query
    //  * @return {Promise<Array<UserRankingDTOS>>}
    //  */
    // async getTournamentRanking(tournamentId: string, query?: GetTournamentRankingQuery): Promise<Array<UserRankingDTOS>> {
    //     return new Promise((resolve, reject) => {
    //         this.api.getTournamentRanking(tournamentId, query)
    //             .then((response: Array<UserRankingDTOS>) => {
    //                 resolve(response);
    //             }).catch(reject);
    //     });
    // }
    //
    // /**
    //  * Get User Ranking
    //  *
    //  * @param {string} userId
    //  * @param {string} tournamentId
    //  * @return {Promise<UserRankingDTOS>}
    //  */
    // async getUserTournamentRanking(userId: string, tournamentId: string, query: IGetTournamentUserRankingQuery): Promise<UserRankingDTOS> {
    //     return new Promise((resolve, reject) => {
    //         this.api.getUserTournamentRanking(userId, tournamentId, query)
    //             .then((response: UserRankingDTOS) => {
    //                 resolve(response);
    //             }).catch(reject);
    //     });
    // }
    //
    /**
     * Get User tournament statistics
     *
     * @param {string} userId
     * @param {GetUserTournamentStatisticsQuery} query
     * @return {Promise<Array<TournamentUserStatisticsDTO>>}
     */
    getUserTournamentStatistics(userId: string, query: GetUserTournamentStatisticsQuery): Promise<Array<TournamentUserStatisticsDTO>> {
        return new Promise((resolve, reject) => {
            this.api.getUserTournamentStatistics(userId, query)
                .then((response: Array<TournamentUserStatisticsDTO>) => {
                    resolve(response);
                }).catch(reject);
        });
    }

    //
    // /**
    //  * Get Tournament Winners
    //  * @param {GetTournamentWinnersQuery} query
    //  * @return {Promise<Array<TournamentWinnersDTO>>}
    //  */
    // async getTournamentWinners(query: GetTournamentWinnersQuery): Promise<Array<TournamentWinnersDTO>> {
    //     return new Promise((resolve, reject) => {
    //         this.api.getTournamentWinners(query)
    //             .then((response: Array<TournamentWinnersDTO>) => {
    //                 resolve(response);
    //             }).catch(reject);
    //     });
    // }

    /**
     * Convert Tournament Points
     * @param {string} userId
     * @param {string} tournamentId
     * @param {ConvertToTournamentParticipationsInput} input
     * @return {Promise<any>}
     */
    convertTournamentPoints(userId: string, tournamentId: string, input: ConvertToTournamentParticipationsInput): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.convertTournamentPoints(userId, tournamentId, input)
                .then((response) => {
                    resolve(response);
                }).catch(reject);
        });
    }
}
