import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {GroupByDTO} from "../models/GroupByDTO";
import {ListReferralInput} from "../models/ListReferralInput";
import {ReferralDTO} from "../models/ReferralDTO";

@Injectable()
export class ReferralApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * Get Referrals
     * @param {ListReferralInput} input
     * @param {boolean} requireSession
     * @return {Promise<Array<ReferralDTO>>}
     */
    getReferrals(input?: ListReferralInput, requireSession: boolean = true): Promise<Array<ReferralDTO>> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/referral`,
            input: input
        });
    }

    /**
     * Group By Referrals
     * @param {string} userId
     * @param {GetGroupByReferrals} queryParams
     * @param {boolean} requireSession
     * @return {Promise<Array<GroupByDTO>>}
     */
    groupByReferrals(userId: string, queryParams?: GetGroupByReferrals, requireSession: boolean = true): Promise<Array<GroupByDTO>> {
        return this.http2Talos.get({
            requireSession: requireSession,
            path: `/referral/${userId}/groupByReferrals`,
            queryParams: queryParams
        });
    }
}// END CLASS

export interface GetGroupByReferrals {
    referralIds?: Array<string>;
    excludeReferralIds?: Array<string>;
    referralTypeIds?: Array<string>;
    referralGroupIds?: Array<string>;
    initiatorUserId?: string;
    referredUserId?: string;
    code?: string;
    referralStatusIds?: Array<number>;
    referralPropertiesFilters?: Array<string>;
    dateFrom?: Date | number;
    dateTo?: Date | number;
    groupByIds?: Array<string>;
    groupByType?: string;
}