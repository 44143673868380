import {Injectable} from "@angular/core";
import {ItemApi} from "../../talosApi/api/ItemApi";
import {CategoryApi} from "../../talosApi/api/CategoryApi";
import {CategoryDTO} from "../../talosApi/models/CategoryDTO";
import {GetMultipleMetadataResponse, ItemsList} from "../../talosApi/models";
import {TAConstants} from "../../talosApi/settings";
import _ from "lodash";
import {ResourcesServices} from "../talos/resources.services";
import {AppSettingsService} from "../talos/appSettings.service";
import ITEM_TYPE = TAConstants.ITEM_TYPE;


@Injectable()
export class AsyncCategoryService {

    public categoryById: Map<number, CategoryDTO> = new Map<number, CategoryDTO>();
    public categoryByIdLength: Map<number, CategoryDTO> = new Map<number, CategoryDTO>();

    constructor(private categoryApi: CategoryApi,
                private resourcesSrv: ResourcesServices,
                private appSettingsSrv: AppSettingsService,
                private itemApi: ItemApi) {
    }

    /**
     *
     * @param {number} categoryId
     * @param {boolean} metadata
     * @param {boolean} metadataParent
     * @param {boolean} leaves
     * @param {number[]} resources
     * @param {number[]} resourcesParent
     * @return {Promise<CategoryDTO>}
     */
    public async getCategoryById(categoryId: number, metadata: boolean = false, metadataParent: boolean = false, leaves: boolean = true, resources?: number[], resourcesParent?: number[]): Promise<CategoryDTO> {
        return new Promise(async (resolve, reject) => {
            let category = this.categoryById.get(categoryId);
            if (!_.isNil(category)) {
                resolve(category);
            }
            await this.getCategoriesByParent(
                categoryId,
                2,
                leaves,
                0,
                -1,
                '_category_' + categoryId + '_',
                resources,
                metadata,
                metadataParent
            ).then(
                (category_: CategoryDTO) => {
                    this.categoryById.set(categoryId, category_);
                    resolve(category_);
                }, error => {
                    reject(error);
                });
        });

    }

    /**
     * Gets categories by root id
     * @param categoryId
     * @param [metadata]
     * @param [leaves]
     * @param [withResources]
     * @param [languageIds]
     * @returns categories by root id
     */
    public getCategoriesByRootId(categoryId: string | number,
                                 metadata: boolean = false,
                                 leaves: boolean = true,
                                 withResources: boolean = true,
                                 languageIds: Array<string> = []): Promise<CategoryDTO> {
        return new Promise((resolve, reject) => {
            /* Get Tree. */
            let category = this.categoryByIdLength.get(Number(categoryId));
            if (!_.isNil(category)) {
                resolve(category);
                return;
            }
            this.categoryApi.getCategories(
                {
                    depth: 3,
                    withleaves: leaves,
                    resources: withResources,
                    metadatas: metadata,
                    languageIds: languageIds
                },
                categoryId,
            )
                .then((data) => {
                    if (!data.length) resolve(null);
                    let c = data[0];
                    this.categoryByIdLength.set(c.id, c);
                    resolve(c);
                }).catch((err) => reject(null));
        });
    }

    /**
     *
     * @param categoryId
     * @param depth
     * @param withLeaves
     * @param rangeFrom
     * @param rangeTo
     * @param suffix
     * @param applicationSettingsVersion
     * @param applicationSettingsResourcesVersion
     * @param resources
     * @param includeMetadata
     * @param metadataParent
     * @param force_
     * @returns {any}
     */
    private async getCategoriesByParent(categoryId: number,
                                        depth?: number,
                                        withLeaves?: boolean,
                                        rangeFrom?: number,
                                        rangeTo?: number,
                                        suffix: string = '',
                                        resources?: Array<number>,
                                        includeMetadata?: boolean,
                                        metadataParent?: boolean,
                                        force_: boolean = false): Promise<CategoryDTO> {

        return new Promise(async (resolve, reject) => {
            let categories: Array<CategoryDTO> = [];
            let parentCategory: CategoryDTO = undefined;
            logger.log(categoryId, depth, withLeaves, rangeFrom, rangeTo);
            await this.categoryApi.get(categoryId, depth, withLeaves, rangeFrom, rangeTo)
                .then(async (items: ItemsList<CategoryDTO>) => {
                        parentCategory = items[0];
                        if (items.count === 0) {
                            resolve(null);
                        }

                        if (_.isNil(parentCategory.children) == false) {
                            categories = categories.concat(parentCategory.children);
                        }

                        if (_.isNil(parentCategory.leaves) === false) {
                            categories = categories.concat(parentCategory.leaves);
                        }
                        const categoryIds: Array<string> = categories.map((category) => {
                            return category.itemIdForResourceLookup;
                        });

                        if (includeMetadata) {
                            await this.itemApi.asyncGetMultipleMetadata(ITEM_TYPE.CATEGORY, metadataParent ? [parentCategory.id.toString()] : categoryIds)
                                .then(async (metadata: GetMultipleMetadataResponse[]) => {
                                    if (_.isNil(metadata) === false) {
                                        metadata.forEach((metadatum) => {
                                            const category = categories.find((category) => ((metadataParent) ? parentCategory.itemIdForResourceLookup : category.itemIdForResourceLookup) == metadatum.itemId);
                                            category && (category.metadata = metadatum.metadata);
                                            parentCategory.metadata = metadatum.metadata;
                                        });
                                    }
                                    categories = withLeaves ? parentCategory.leaves : parentCategory.children;
                                    categories = categories || [];
                                    await this.resourcesSrv.getResources([...categories, parentCategory], 'itemIdForResourceLookup', resources, 'itemTypeIdForResourceLookup')
                                        .then(next => {
                                            if (_.isBoolean(next) === false) {
                                                resolve(parentCategory);
                                                return;
                                            }
                                        }, error => {
                                            reject(error);
                                        }).catch((error) => {
                                            reject(error);
                                        });
                                }).catch(() => {
                                });
                        } else {
                            categories = withLeaves ? parentCategory.leaves : parentCategory.children;
                            categories = categories || [];
                            await this.resourcesSrv.getResources([...categories, parentCategory], 'itemIdForResourceLookup', resources, 'itemTypeIdForResourceLookup')
                                .then(next => {
                                    if (_.isBoolean(next) === false) {

                                        resolve(parentCategory);
                                        return;
                                    }
                                }, error => {
                                    reject(error);
                                }).catch((error) => {
                                    reject(error);
                                });
                        }
                    },
                    error => {
                        reject(error);
                    });
        });
    }
}