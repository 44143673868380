import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {CheckInInput} from "../models/CheckInInput";
import {CheckInDTO} from "../models/CheckInDTO";

@Injectable()
export class CheckInApi {

    /**
     * Creates an instance of token api.
     * @param http2Talos
     */
    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * Check in
     * @param {string} userId
     * @param {CheckInInput} input
     * @param {boolean} requireSession
     * @return {Promise<CheckInDTO>}
     */
    checkIn(userId: string, input: CheckInInput, requireSession: boolean = true): Promise<CheckInDTO> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/checkIn/${userId}`,
            input: input
        });
    }
}