/**
 * Created by georgefloros on 16/05/2017.
 */

import {ITalosApiModel} from "./TalosApiModel.interface"
import {AuthenticateUserInput} from "./AuthenticateUserInput"
import {AuthenticateUserResponse} from "./AuthenticateUserResponse"
import {AuthorizationKeyInput} from "./AuthorizationKeyInput"
import {AuthorizationKeyResponse} from "./AuthorizationKeyResponse"
import {AuthenticateKeyInput} from "./AuthenticateKeyInput"
import {User} from "./User"
import {KeyValueDTO} from "./KeyValueDTO"
import {AppSettingsDTO} from "./AppSettingsDTO"
import {BrandUserDTOS} from "./BrandUserDTOS"
import {ResourceDTO} from "./ResourceDTO"
import {ItemStatisticsDTO} from "./ItemStatisticsDTO"
import {MetadataDTO} from "./MetadataDTO"
import {VirtualItemsDTOS} from "./VirtualItemsDTOS"
import {StBasilCategoryDTO} from "./StBasilCategoryDTO"
import {GuestInfoInput} from "./GuestInfoInput"
import {NewUserInput} from "./NewUserInput"
import {ListCouponsInput} from "./ListCouponsInput"
import {CouponInfoDTOS} from "./CouponInfoDTOS"
import {PostcodeDTO} from "./PostcodeDTO"
import {CityDTO} from "./CityDTO"
import {CountryDTO} from "./CountryDTO"
import {StateDTO} from "./StateDTO"
import {StatesInput} from "./StatesInput"
import {ItemStatistics} from "./ItemStatistics"
import {GetStatisticsInput} from "./GetStatisticsInput"
import {GetMessagesInput} from "./GetMessagesInput"
import {SetMessageStatusInput} from "./SetMessageStatusInput"
import {DeleteMessageInput} from "./DeleteMessageInput"
import {Message} from "./Message"
import {UserProfileUpdateInput} from "./UserProfileUpdateInput"
import {DecimalDTO} from "./DecimalDTO"
import {DetailedBrandProductDTO} from "./DetailedBrandProductDTO"
import {BrandProductItemDTO} from "./BrandProductItemDTO"
import {AmountDTO} from "./AmountDTO"
import {PriceDTO} from "./PriceDTO"
import {UserByUsernameResponse} from "./UserByUsernameResponse"
import {GetMultipleMetadataResponse} from "./GetMultipleMetadataResponse"
import {GetVItemsInput} from "./GetVItemsInput"
import {ItemsList} from "./ItemsList"
import {PurchaseCouponInput} from "./PurchaseCouponInput"
import {DetailedBrandProductCouponDTO} from "./DetailedBrandProductCouponDTO"
import {QuizDTO} from "./QuizDTO"
import {QuestionDTO} from "./QuestionDTO"
import {AnswerDTO} from "./AnswerDTO"
import {TransactionHistoryInput} from "./TransactionHistoryInput";
import {UserLevelsDTO} from "./UserLevelsDTO";
import {PropertyDTO} from "./PropertyDTO";
import {StartQuizInput} from "./StartQuizInput";

export {
    ITalosApiModel,
    AuthenticateUserInput,
    AuthenticateUserResponse,
    AuthorizationKeyInput,
    AuthorizationKeyResponse,
    AuthenticateKeyInput,
    User, KeyValueDTO,
    AppSettingsDTO,
    CouponInfoDTOS,
    BrandUserDTOS,
    ResourceDTO,
    ItemStatisticsDTO,
    MetadataDTO,
    VirtualItemsDTOS,
    ItemsList,
    GuestInfoInput,
    NewUserInput,
    ListCouponsInput,
    PostcodeDTO,
    CityDTO,
    CountryDTO,
    StateDTO,
    StatesInput,
    ItemStatistics,
    GetStatisticsInput,
    GetMessagesInput,
    SetMessageStatusInput,
    DeleteMessageInput,
    Message,
    UserProfileUpdateInput,
    DecimalDTO,
    DetailedBrandProductDTO,
    BrandProductItemDTO,
    AmountDTO,
    PriceDTO,
    UserByUsernameResponse,
    GetMultipleMetadataResponse,
    GetVItemsInput,
    PurchaseCouponInput,
    DetailedBrandProductCouponDTO,
    QuizDTO, QuestionDTO, AnswerDTO,
    TransactionHistoryInput,
    UserLevelsDTO,
    PropertyDTO,
    StartQuizInput



}
