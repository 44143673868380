/**
 * Created by georgefloros on 02/04/2017.
 */
export namespace NetWork {

  export enum Status {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    BAD_REQUEST = 400 ,
    NOT_FOUND = 404,
    FORBIDDEN = 403,
    NO_CONNECTION = 0,
    SERVICE_UNAVAILABLE = 503
  }

  export enum Talos_Code {
    GENERIC = 1 ,
    GUEST_EXISTS = 1051,
    EXCEEDS_DAILY_LIMIT_OF_CHECKINS = 10002,
    PRIZE_NOT_FOUND_OR_MORE_ONE_FOUND = 10003,
    USER_NOT_IN_AVAILABLE_UNIVERSITY_POD = 10004,
    NICKNAME_ALREADY_USED = 1050,
    USERNAME_ALREADY_USED = 1014,
    EMAIL_ALREADY_USED =  1020,
    VITEM_PURCHASE_NOT_ENOUGH_BALANCE = 11,
    PRIZE_WON = 1,
    PRIZE_NOT_WON = 2,
    PRIZES_ALREADY_WON = 3,
    PRIZES_OUT_OF_STOCK = 4,
    PRIZES_NOT_FOUND = 5,
    MISSION_COMPLETED = 0,
    MISSION_TIME_FRAME_BEFORE_DATE = 1,
    MISSION_TIME_FRAME_AFTER_DATE = 2,
    MISSION_ACTION_ALREADY_COMPLETED = 1071,
    APPLE_STORE_RECEIPT_VERIFICATION_FAILED = 12,
    RESOURCE_FETCHING_ERROR = 13,
    MULTIPLAYER_MESSAGE_DELIVERY_FAILURE = 14,
    USER_ALREADY_HAS_VITEM = 15,
    INVALID_APPLICATION_OR_MAGIC_KEY = 16,
    LOGIN_KEY_INVALID = 18,
    APPLICATION_REQUEST_NOT_VALID = 19,
    USER_SESSION_ID_NOT_VALID = 20,
    USER_SESSION_OWNED_BY_OTHER_USER = 21,
    VITEM_OUTDATED = 22,
    VITEM_OUTOFSTOCK = 23,
    VITEM_LEVEL_MISMATCH = 24,
    CAN_NOT_FIND_USER = 1008,
    TOURNAMENT_REGISTRATION_DATE_PASSED = 1009,
    TOURNAMENT_INVITATION_REQUIRED = 1010,
    USER_USERGROUP_NOT_ALLOWED = 1011,
    USER_ALREADY_REGISTERED = 1012,
    CAN_NOT_FIND_TOURNAMENT = 1013,
    USER_USERNAME_ALREADY_USED = 1014,
    USER_BIRTHDATE_NOT_CORRECT = 1015,
    NEW_USER_AVATAR_NOT_SET = 1017,
    NEW_USER_USERNAME_NOT_SET = 1018,
    NEW_USER_PASSWORD_NOT_SET = 1019,
    USER_EMAIL_ALREADY_USED = 1020,
    USER_EMAIL_WRONG_FORMAT = 1021,
    USER_USERNAME_INVALID = 1022,
    PURCHASE_IDENTIFIER_MISSING = 1023,
    COULD_NOT_FIND_PURCHASE = 1024,
    COULD_NOT_FIND_PAYMENT_METHOD_COUNTRY_SPECIFIC_PROPERTIES = 1025,
    COULD_NOT_FIND_PURCHASE_WITH_EXTERNAL_INFO = 1026,
    COULD_NOT_FIND_PRODUCT = 1027,
    COULD_NOT_LIST_USER_VIRTUAL_ITEMS = 1028,
    COULD_NOT_FIND_LANGUAGE = 1029,
    COULD_NOT_FIND_UNIT_TYPE = 1030,
    INTERNAL_TRANSACTION_IN_WRONG_STATUS = 1031,
    PRODUCTS_APPLE_ITEM_ID_DOES_NOT_MATCH = 1032,
    APPLE_PURCHASE_RECEIPT_COULD_NOT_BE_VALIDATED = 1033,

    FOUND_PURCASE_WITH_SAME_EXTERNAL_REFERENCE = 1034,

    COULD_NOT_FIND_SYSTEM_EVENT_TYPE = 1035,

    COULD_NOT_FIND_GAME = 1036,

    COULD_NOT_FIND_USER_SESSION = 1042,

    SINGLE_PLAYER_GAME_PLAYED_IN_TOURNAMENT = 1043,

    COULD_NOT_FIND_APPLICATION = 1044,

    COULD_NOT_FIND_ENTITY_INSTANCE = 1045,

    ENTITY_INSTANCE_WITH_SAME_ID_EXISTS = 1046,

    ENTITY_INSTANCE_WITH_SAME_NAME_EXISTS = 1047,

    USER_SESSION_PERMISSION_ERROR = 1048,

    WRONG_USERNAME_OR_PASSWORD = 1049,

    USER_NICKNAME_ALREADY_USED = 1050,

    ENTITY_INSTANCE_WITH_SAME_REFERENCES_EXISTS = 1051,

    MISSING_PARAMETER = 1052,

    DELETE_PROHIBITED_IN_USE = 1053,

    NEW_USER_NICKNAME_NOT_SET = 1054,

    LOCKED_USER = 1055,

    USER_PASSWORD_INVALID = 1056,

    USER_NOT_FOUND = 1057,

    WRONG_PASSWORD = 1058,

    USER_MUST_BE_ACTIVATED = 1059,

    WRONG_FORMAT = 1060,

    EXTERNAL_ACCOUNTS_SYSTEM_ERROR = 1061,

    EXTERNAL_ACCOUNTS_SYSTEM_MISCONFIGURATION = 1062,
    CARD_NUMBER_ALREADY_USED = 1063,
    USER_DOES_NOT_HAVE_VITEM = 1064,
    MORE_THAN_1_ITEM_FOUND_WITH_SPECIFIED_PROPERTIES = 1065,
    NO_ITEM_FOUND_WITH_SPECIFIED_PROPERTIES = 1066,
    ACTION_LIMIT_REACHED = 1067,
    USERS_NOT_IN_THE_SAME_USERGROUP = 1068,
    NOT_ALLOWED_TO_SEND_GIFT_TO_OWN = 1069,
    CMS_CONFIGURATION_ERROR = 1070,
    ACTION_ALREADY_COMPLETED = 1071,
    GAME_TYPE_INVALID = 1072,
    ITEM_BELONGS_TO_OTHER_USER = 1073,
    ITEM_LOCKED = 1074,
    ITEM_NOT_FOUND = 1075,
    NO_PARAMS_IN_TOKEN = 1078,
    TOKEN_ALREADY_USED = 1079,
    TOKEN_HAS_EXPIRED = 1080,
    ITEM_ALREADY_ACTIVE = 1081,
    NOT_ALLOWED_TO_STEAL_FROM_OWN = 1082,
    ITEM_ALREADY_DELETED = 1083,
    USER_LOGIN_PROHIBITED_IN_GAME_TYPE = 1084,

    VITEM_IS_NOT_A_COUPON = 2000,

    STORE_FOR_COUPON_NOT_FOUND = 2001,

    COUPON_COULD_NOT_BE_ASSIGNED_TO_USER = 2002,

    COUPON_NOT_FOUND = 2003,
    WAGE_VALIDATION_LIMIT_EXCEPTION = 2004,
    NOT_ENOUGH_AVAILABLE_BRAND_PRODUCT_COUPONS = 2005,
    NOT_ENOUGH_BALANCE_FOR_PURCHASE = 2006,
    NOT_ALLOWED_TO_INSERT_COMMENT_WITHOUT_RATING = 2007,
    COUPON_ALREADY_REDEEMED = 2008,
    NOT_ALLOWED_TO_REDEEM_COUPON = 2009,
    NOT_ALLOWED_TO_RATE_COMMENT_EXPIRED_OR_NOT_REDEEMED_COUPON = 2010,
    EMAIL_SHARE_ERROR = 2011,
    TRANSACTION_REJECTED_BY_ISSUER_CARD_BANK = 2012,
    CREDIT_CARD_DETAILS_NOT_VALID = 2013,
    ADDRESS_NOT_MAPPED_TO_STORE = 2014,
    RATING_VALUE_NOT_VALID = 2015,
    NOT_ALLOWED_TO_CANCEL_COUPON = 2016,
    NOT_ALLOWED_TO_ASSIGN_POINTS_FROM_IN_STORE_PURCHASES = 2017,
    BRAND_USER_NOT_VALID = 2018,
    BRAND_USER_NOT_ASSOCIATED_WITH_STORE = 2019,
    COUPON_STATUS_IS_NOT_PURCHASED = 2020,
    INSTALLMENTS_ERROR = 2021,

    COULD_NOT_LIST_CODE_UNIT_AMOUNTS = 3000,
    CODE_ALREADY_ASSIGNED = 3001,
    CODE_ALREADY_PURCHASED = 3002,
    CODE_NOT_FOUND = 3003,

    COULD_NOT_LIST_STORE_RATES = 4000,

    ANSWER_WAS_GIVEN_WITH_NO_TEXT = 5000,
    USER_HAS_ANSWERED_THIS_QUESTION = 5001,
    USER_HAS_STARTED_THIS_QUIZ = 5002,
    USER_HAS_NOT_STARTED_THIS_QUIZ = 5003,

    USER_HAS_ALREADY_BEEN_REFERENCED = 6000,

    USER_HAS_EXCEEDED_ALLOWED_VOTES = 6100,
    USER_HAS_EXCEEDED_DAILY_AVATAR_UPLOADS = 6101,

    INSUFFICIENT_BALANCE_FOR_REGISTERING_IN_COMPETITION = 6200,
    ALREADY_REGISTERED_IN_COMPETITION = 6201,

    POST_LIKE_ALREADY_SUBMITTED = 6300,
    POSTS_LIMIT_REACHED = 6301,
    POST_COMMENT_IS_NULL = 6302,
    CHECKIN_CURRENTLY_NOT_ALLOWED = 6303,

    EXTERNAL_DATA_VALIDATION_ERROR = 6400,
    EXTERNAL_PERMISSION_FETCHING_ERROR = 6401,

    USER_HAS_NO_PERMISSION_TO_SUBMIT_POST = 6402,
    USER_BANNED_BY_ADMINISTRATOR = 6403,
    INSUFFICIENT_BALANCE_FOR_PURCHASE = 6404,

    PRICELIST_FOR_SERVICE_NOT_FOUND = 7001,
    PRICELIST_NOT_FOUND = 7002,
    PRICE_NOT_FOUND_FOR_SERVICE_AND_BRAND_PRODUCT = 7003,          // means missing price or product not available for service
    PRICE_NOT_FOUND_FOR_SERVICE_AND_BRAND_PRODUCT_ITEM = 7004,     // means missing price or item not available for service
    BRAND_PRODUCT_NOT_FOUND = 7005,
    BRAND_PRODUCT_ITEM_NOT_FOUND_IN_BRAND_PRODUCT_BOM = 7006,
    BRAND_PRODUCT_NOT_AVAILABLE_IN_STORE = 7007,
    ORDER_NOT_FOUND = 7008,
    EMPTY_PRODUCT_CATALOG = 7009,

    PH_ERROR_IN_PIZZA_CONFIGURATION = 7500,
    PH_ERROR_IN_ORDER_HISTORY_NOT_VALID_PRODUCTS_IN_ORDER = 7501,
    PH_ERROR_IN_CREATE_MY_OWN_PIZZA_CONFIGURATION = 7502,
    PH_ERROR_IN_CREATE_50_50_PIZZA_CONFIGURATION = 7503,
    PH_ERROR_IN_PRODUCT_CONFIGURATION = 7504,

    RECOMMENDATION_ENGINE_NOT_INITIALISED = 8001,

    BOOST_CONFIGURATION_ERROR = 8100,

    AMOUNT_REQUIRES_RECEIPT_SUBMISSION = 9001,
    MORE_THAN_1_USER_FOUND_WITH_CARD_STARTING_FROM = 9002,

    MORE_THAN_1_USER_FOUND_WITH_STUDENT_DETAILS = 10000,
    FAR_FROM_DEPARTMENT = 10001,
    EXCEEDS_MAX_DAILY_CHECKINS = 10002,
    PRIZE_NOT_FOUND = 10003,
    NOT_IN_DEPARTMENT = 10004,

    SIEBEL_PARTNER_NOT_EXISTS = 11000,
    SIEBEL_BAD_VOUCHER_STATUS = 11001,
    SIEBEL_VOUCHER_PRODUCT_NOT_EXISTS = 11002,
    SIEBEL_VOUCHER_NUMBER_NOT_EXISTS = 11003,
    SIEBEL_VOUCHER_PRODUCT_NOT_VALID = 11004,
    SIEBEL_PROCESSOR_ERROR = 11005,
    SIEBEL_GENERIC_ERROR = 11006,

    ETISALAT_SEGMENT_ERROR = 12006,

    INACTIVE_USER = 1085,
  }
}
