import {
  GetGroupByReferrals,
  ReferralApi,
} from "../../talosApi/api/ReferralApi";
import { Injectable } from "@angular/core";
import { GroupByDTO } from "../../talosApi/models/GroupByDTO";
import { ListReferralInput } from "../../talosApi/models/ListReferralInput";
import { ReferralDTO } from "../../talosApi/models/ReferralDTO";
import { TAConstants, TAValues } from "../../talosApi/settings";
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Resource_Types = TAConstants.Resource_Types;
import { OverviewContent } from "src/app/pages/overview/overview.component";
import { AppGlobalsService } from "../appGlobals.service";
import { UploadContentsService } from "./uploadContents.service";
import { ResourcesServices } from "./resources.services";

@Injectable()
export class ReferralService {
  constructor(
    private referralApi: ReferralApi,
    private appGlobalsSrv: AppGlobalsService,
    private uploadContentsSrv: UploadContentsService,
    private resourcesSrv: ResourcesServices,

  ) {}

  /**
   * Get Referrals
   * @param {ListReferralInput} queryParams
   * @return {Promise<Array<ReferralDTO>>}
   */
  getReferrals(queryParams: ListReferralInput): Promise<Array<ReferralDTO>> {
    return new Promise<Array<ReferralDTO>>((resolve, reject) => {
      this.referralApi
        .getReferrals(queryParams)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Group By Referrals
   * @param {string} userID
   * @param {GetGroupByReferrals} input
   * @return {Promise<Array<GroupByDTO>>}
   */
  groupByReferrals(
    userID: string,
    input: GetGroupByReferrals
  ): Promise<Array<GroupByDTO>> {
    return new Promise<Array<GroupByDTO>>((resolve, reject) => {
      this.referralApi
        .groupByReferrals(userID, input)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOverviewReferral(): Promise<OverviewContent> {
    return new Promise(async (resolve, reject) => {
      const config =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.OVERVIEW_REFERRAL];
      if (config) {
        const input: ListReferralInput = {
          referrerUserId: TAValues.UserId,
          rangeTo: 1,
          referralTypeIds: [
            REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD,
            REFERRAL_TYPES.MGM_UNICODE,
            REFERRAL_TYPES.LENDING_REFERRAL,
            REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB,
            REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB_ONE_TO_MANY,
            REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING,
            REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING_ONE_TO_MANY,
            REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ONE_TO_MANY,
          ],
          referralStatusIds: [3, 4],
        };
        const referrals = await 
          this.getReferrals(input)
          .catch((err) => {
            reject(err);
          });
        const id =
          referrals && referrals.length > 0 ? config.mgm : config.noMgm;
        if (id) {
          const items =
            await this.uploadContentsSrv.getUploadContentByIdsContentType(
              [id],
              UPLOAD_CONTENT_TYPE.GENERAL,
              true,
              TAConstants.Settings.LANGUAGES,
              true,
              true,
              true,
              null,
              0,
              1
            );

          if (items && items.length > 0) {
            const i = items[0];
            const referral = {
              ...i,
              title: this.resourcesSrv.getResourcesBasic(
                i,
                Resource_Types.TITLE
              ),
              description: this.resourcesSrv.getResourcesBasic(
                i,
                Resource_Types.DESCRIPTION
              ),
              image: this.resourcesSrv.getResourcesBasic(
                i,
                Resource_Types.IMAGE_LARGE
              ),
              imageMobile: this.resourcesSrv.getResourcesBasic(
                i,
                Resource_Types.IMAGE
              ),
              action: this.resourcesSrv.getResourcesBasic(
                i,
                Resource_Types.ACTION
              ),
              actionLabel: this.resourcesSrv.getResourcesBasic(
                i,
                Resource_Types.ACTION_TYPE_DESCRIPTION
              ),
            };
            resolve(referral);
          } else {
            reject("no content");
          }
        }
      }
    });
  }
}
