import { Injectable } from "@angular/core";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import { Message } from "../../../talosApi/models/Message";
import { MessagesService } from "../../../services/talos/messages.service";
import { AppSettingsService } from "../../../services/talos/appSettings.service";
import * as _ from "lodash";
import { EWalletDTO } from "../../../talosApi/models/EWalletDTO";
import { UserService } from "../../../services/talos/user.service";
import { UserHistoryResponse } from "../../../talosApi/models/userHistoryResponse";
import { TransactionHistoryInput } from "../../../talosApi/models/TransactionHistoryInput";
import * as Moment from "moment";
import { MetadataDTO, User } from "../../../talosApi/models";
import { EventService } from "../../../services/talos/event.service";
import { IEventInput } from "../../../talosApi/api/EventApi";
import { environment } from "../../../environments/environment";
import { UploadContentDTO } from "../../../talosApi/models/UploadContentDTO";
import { FavoriteDTO } from "../../../talosApi/models/FavoriteDTO";
import { InputGetFavorite } from "../../../talosApi/api/FavoriteApi";
import { FavoriteService } from "../../../services/talos/favorite.service";
import { UploadContentsService } from "../../../services/talos/uploadContents.service";
import { CategoryDTO } from "../../../talosApi/models/CategoryDTO";
import { AppGlobalsService } from "../../../services/appGlobals.service";
import { CategoryService } from "../../../services/talos/category.service";
import { FilterTag } from "../../shared/components/icon-item-filter-tag-ang/icon-item-filter-tag-ang.component";
import { EventRewardRulesDTO } from "../../../talosApi/models/EventRewardRulesDTO";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { SortTag } from "../../shared/components/icon-item-shorter-ang/icon-item-shorter-ang.component";
import { UtilsService } from "../../../services/utils.service";
import { DetailedBrandProductDTO } from "../../../talosApi/models/DetailedBrandProductDTO";
import { MiddleApi } from "../../../talosApi/api/middleApi";
import {
  IReferenceLevelInput,
  ReferenceApi,
} from "../../../talosApi/api/referenceApi";
import { BrandsService } from "../../../services/talos/brands.service";
import { DetailedBrandProductCouponDTO } from "../../../talosApi/models/DetailedBrandProductCouponDTO";
import { CategoryItemDTO } from "../../../talosApi/models/CategoryItemDTO";
import { IListUserBrandProductCoupons } from "../../../talosApi/api/BrandApi";
import { ItemService } from "../../../services/talos/item.service";
import { ItemMetadataDTO } from "../../../talosApi/models/ItemMetadataDTO";
import { ExtSystemInfoDTO } from "../../../talosApi/models/ExtSystemInfoDTO";
import { TranslateService } from "@ngx-translate/core";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Unit_Types = TAConstants.Unit_Types;
import Events_Types = TAConstants.Events_Types;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Resource_Types = TAConstants.Resource_Types;
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import Settings = TAConstants.Settings;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import Favorites_Status = TAConstants.Favorites_Status;
import LEVELS_RESOURCES_TYPE_ID = TAConstants.LEVELS_RESOURCES_TYPE_ID;
import COUPON_STATUSES = TAConstants.COUPON_STATUSES;
import ITEM_METADATA = TAConstants.ITEM_METADATA;
import Transactions_Types = TAConstants.Transactions_Types;
import { RewardsService } from "../rewards/rewards.service";
import { to } from "../../../../../src/utils/utils";
import { isNullOrUndefined } from "util";
import { IndexedDataContent } from "../../../talosApi/models/IndexedDataContent";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";
import { KpDictionary } from "../../../../../src/utils/kp_dictionary/kp.dictionary";
import ORDER_TYPE = TAConstants.ORDER_TYPE;
import { IReferenceLevel } from "./profile-new.component";

@Injectable()
export class ProfileNewService {
  materialConfig;
  messageStorage: Map<string, IMessageCategory> = new Map<
    string,
    IMessageCategory
  >();

  constructor(
    public messagesSrv: MessagesService,
    public appSetingsSrv: AppSettingsService,
    public eventSrv: EventService,
    public userSrv: UserService,
    public favoriteSrv: FavoriteService,
    public uploadContentsSrv: UploadContentsService,
    public appGlobalsSrv: AppGlobalsService,
    public MiddleApi: MiddleApi,
    public referenceApi: ReferenceApi,
    public itemSrv: ItemService,
    public categoryService: CategoryService,
    public resourcesSrv: ResourcesServices,
    public utilsSrv: UtilsService,
    public brandsService: BrandsService,
    public rewardService: RewardsService,
    public translateSrv: TranslateService,
    private recommendationsSrv: RecommendationsService
  ) {
    if (appGlobalsSrv.config)
      this.materialConfig =
        appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] || null;
  }

  getMaterialConfig() {
    if (this.materialConfig) return this.materialConfig;

    if (this.appGlobalsSrv.config)
      this.materialConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] ||
        null;

    return this.materialConfig;
  }

  /**
   *
   * @param rangeFrom
   * @param rangeTo
   */
  getMessages(rangeFrom: number, rangeTo: number): Promise<Array<Message>> {
    return new Promise((resolve, reject) => {
      const folderId = this.appSetingsSrv.getValue(
        APPLICATION_SETTING_KEYS.USER_MESSAGING_FOLDER
      );
      if (_.isNil(folderId)) {
        resolve([]);
        return;
      }

      const getMessageImage = (message: Message): string => {
        if (message.properties) {
          var image = message.properties.filter((p) => p.type == 0);

          if (image && image.length > 0) {
            return image[0].value;
          }
        }

        return "";
      };
      this.messagesSrv
        .getMessages(folderId, rangeFrom, rangeTo)
        .then((result: Array<Message>) => {
          if (result) {
            result.forEach((m: IMessageCategory) => {
              let title: string;
              let category: string;
              let message: string;

              try {
                const title_: string[] = m.content
                  .match(/<messagetitle>(.*?)<\/messagetitle>/g)
                  .map((val) => val.replace(/<\/?messagetitle>/g, ""));
                title = title_[0];
                const category_: string[] = m.content
                  .match(/<category>(.*?)<\/category>/g)
                  .map((val) => val.replace(/<\/?category>/g, ""));
                category = category_[0];
                const message_: string[] = m.content
                  .match(/<message>(.*?)<\/message>/g)
                  .map((val) => val.replace(/<\/?message>/g, ""));
                message = message_[0];

                // set the storage
                this.messageStorage.set(category, m);
              } catch (e) {
                title = "";
                category = "";
                message = "";
              }
              m.title = title.toUpperCase();
              m.category = category;
              m.message = message;
              m.image = getMessageImage(m);
              m.formatedDate = Moment(m.messageDate)
                .locale(this.translateSrv.currentLang)
                .calendar(); //moment().subtract(1, 'days').calendar();
            });
          }

          resolve(result);
        }, reject);
    });
  }

  /**
   *
   * @param rangeFrom
   * @param rangeTo
   * @param descendingOrder
   */
  getHistoryTransaction(
    rangeFrom: number,
    rangeTo: number,
    descendingOrder: boolean
  ): Promise<Array<EWalletDTO>> {
    return new Promise((resolve, reject) => {
      const input: TransactionHistoryInput = {
        unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        descendingOrder: descendingOrder,
        sortByDate: true,
      };
      this.userSrv
        .getHistoryTransaction(TAValues.UserId, input)
        .then((result: Array<EWalletDTO>) => {
          resolve(result);
        }, reject);
    });
  }

  /**
   *
   * @param userId
   * @param messageId
   * @param status
   */
  setReadMessage(
    userId: string,
    messageId: string,
    status: number
  ): Promise<Message> {
    return new Promise<Message>((resolve, reject) => {
      this.messagesSrv
        .setStatus(TAValues.UserId, [messageId], status)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   */
  addEventOptout(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const input: IEventInput = {
        userId: TAValues.UserId,
        eventTypeId: Events_Types.OPT_OUT_USER_ID,
        gameTypeId: environment.GAME_TYPE_ID,
        clientTypeId: TAValues.CLIENT_TYPE_ID,
        withNoRewards: false,
        applicationId: TAValues.APPLICATION_ID,
      };

      this.eventSrv
        .addEvent(input)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @description unregister the user.
   */
  unregisterTheUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userSrv
        .unRegisterUser()
        .then((unregister) => {
          resolve(unregister);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param user
   * @param allowCommunication
   */
  unSubscribeUser(user: User, allowCommunication: boolean): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.userSrv
        .unsubscribeUser(user, allowCommunication, true)
        .then((userUnsub) => {
          resolve(userUnsub);
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  // private getFavoriteFiltersCounts(filters: Array<CategoryDTO>): Promise<Array<CategoryDTO>> {
  //     return new Promise((resolve, reject) => {
  //
  //         let categoriesToRequest = new Array<CategoryDTO>();
  //
  //         filters.forEach((f: CategoryDTO) => {
  //             const count = this.materialCountById[Number(categoryId + f.itemIdForResourceLookup)];
  //             if (count) {
  //                 f["total-count"] = count;
  //             } else {
  //                 categoriesToRequest.push(f);
  //             }
  //         });
  //
  //         if (categoriesToRequest.length == 0) {
  //             resolve(filters);
  //             return;
  //         }
  //
  //         const promises = categoriesToRequest.map((f: CategoryDTO) => {
  //             return this.getMaterialItems([categoryId, f.itemIdForResourceLookup], 0, 1, false);
  //         })
  //
  //         Promise.all(promises).then(arrayOfResults => {
  //             for (let i = 0; i < categoriesToRequest.length; i++) {
  //                 if (categoriesToRequest[i] && arrayOfResults[i]) {
  //                     categoriesToRequest[i]['total-count'] = arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT];
  //                     this.materialCountById[Number(categoryId + categoriesToRequest[i].itemIdForResourceLookup)] = Number(arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT]);
  //                 }
  //             }
  //             resolve(filters);
  //         }, (err) => {
  //             logger.log(err);
  //             resolve(filters);
  //         });
  //     });
  // }

  /**
   *
   */
  getFavoritesFilters(): Promise<Array<CategoryDTO>> {
    return new Promise((resolve, reject) => {
      const materialConfig = this.getMaterialConfig();
      var mainCategoryId =
        materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_FILTER_CATEGORY_ID];
      if (!mainCategoryId) {
        reject("No main category found!!");
        return;
      }

      this.categoryService
        .getCategoryById(mainCategoryId, false, false, false, [
          Resource_Types.NAME,
        ])
        .then((category: CategoryDTO) => {
          if (category && category.children) {
            resolve(category.children);
            // this.getFavoriteFiltersCounts(category.children).then((items: Array<CategoryDTO>) => {
            //     resolve(items);
            // }).catch(err => {
            //     reject(err);
            // });
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param {Array<string>} categoryIds
   * @param {string} sort
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<Array<UploadContentDTO>>}
   */
  getFavorites(
    categoryIds: Array<string>,
    sort: string,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      const input_: InputGetFavorite = {
        rangeFrom: 0,
        rangeTo: -1,
        statusIds: [Favorites_Status.Active],
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT], //FIXME: i am not sour for this item type.
      };

      this.favoriteSrv
        .getFavorite(TAValues.UserId, input_)
        .then((allFavorites: FavoriteDTO[]) => {
          logger.log(allFavorites);
          if (_.isNil(allFavorites) || allFavorites.length == 0) {
            resolve([]);
            return;
          }
          logger.log(allFavorites);
          const itemIds = allFavorites.map((f: FavoriteDTO) => {
            return f.itemId;
          });

          this.uploadContentsSrv
            .getUploadContents(
              itemIds,
              UPLOAD_CONTENT_TYPE.GENERAL,
              sort,
              rangeFrom,
              rangeTo,
              true,
              categoryIds,
              ITEM_TYPE.CATEGORY,
              true,
              false,
              false,
              [
                Resource_Types.NAME,
                Resource_Types.SHORT_DESCRIPTION,
                Resource_Types.DESCRIPTION,
                Resource_Types.URL_PATH,
                Resource_Types.VIDEO_URL,
              ],
              true,
              true
            )
            .then((uploadContents: Array<UploadContentDTO>) => {
              if (_.isNil(uploadContents)) {
                resolve([]);
                return;
              }

              resolve(uploadContents);
            })
            .catch((error) => {
              logger.log(error);
              reject(error);
            });
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   *
   * @param {FilterTag} filterType
   * @param {SortTag} sortType
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<Array<UploadContentDTO>>}
   */
  getFavoriteItemsBySelections(
    filterType: FilterTag,
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UploadContentDTO>> {
    const categoryIds: Array<string> = [];
    if (filterType != FilterTag.ALL) {
      const filtercategoryId = this.getCategoryIdByType(
        filterType,
        APPLICATION_SETTING_KEYS.MATERIAL_FILTER_TYPE_IDS
      );
      if (filtercategoryId) categoryIds.push(filtercategoryId);
    }

    const sort = this.utilsSrv.getOrderStringBySortType(sortType);
    return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
    //return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
  }

  /**
   *
   * @param categoryIds
   * @param rangeFrom
   * @param rangeTo
   * @param requestResources
   */
  getMaterialItems(
    categoryIds: Array<string>,
    rangeFrom: number,
    rangeTo: number,
    requestResources: boolean = true
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      this.uploadContentsSrv
        .getUploadContents(
          null,
          UPLOAD_CONTENT_TYPE.GENERAL,
          null,
          rangeFrom,
          rangeTo,
          true,
          categoryIds,
          ITEM_TYPE.CATEGORY,
          true,
          false,
          false,
          [
            Resource_Types.NAME,
            Resource_Types.SHORT_DESCRIPTION,
            Resource_Types.DESCRIPTION,
            Resource_Types.URL_PATH,
          ],
          requestResources
        )
        .then((items: Array<UploadContentDTO>) => {
          if (items) {
            resolve(items);
            return;
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param type
   * @param appSettingKey
   */
  getCategoryIdByType(type: number, appSettingKey: string): string {
    let result = null;
    const materialConfig = this.getMaterialConfig();
    let materialTypeIds: Array<any> = materialConfig[appSettingKey];
    if (materialTypeIds) {
      materialTypeIds.forEach((item) => {
        for (let key in item) {
          if (item[key] == type) result = key;
        }
      });
    }
    return result;
  }

  /**
   *
   * @param categoryId
   * @param appSettingKey
   */
  getTypeByCategoryId(categoryId: string, appSettingKey: string): number {
    let result = -1;
    const materialConfig = this.getMaterialConfig();
    let materialTypeIds: Array<any> = materialConfig[appSettingKey];
    if (materialTypeIds) {
      materialTypeIds.forEach((item) => {
        if (item.hasOwnProperty(categoryId)) result = item[categoryId];
      });
    }
    return result;
  }

  countBenefits(): Promise<number> {
    return new Promise(async (resolve, reject) => {
      const rootCategoryId =
        this.appGlobalsSrv.config[
          APPLICATION_SETTING_KEYS.BENEFITS_CATEGORY_ID
        ];
      if (!rootCategoryId) {
        reject(null);
      } else {
        const _CountBrandProductsByCategories = await to(
          this.rewardService.CountBrandProductCouponsByCategoryAlt(
            rootCategoryId,
            [],
            false
          )
        );
        logger.log(
          "_CountBrandProductsByCategories",
          _CountBrandProductsByCategories
        );
        if (isNullOrUndefined(_CountBrandProductsByCategories.data)) {
          /* If Any Failed. */
          reject(null);
        } else {
          resolve(_CountBrandProductsByCategories.data.count);
        }
      }
    });
  }

  getBenefits(
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<DetailedBrandProductDTO>> {
    return new Promise((resolve, reject) => {
      const rootCategoryId =
        this.appGlobalsSrv.config[
          APPLICATION_SETTING_KEYS.BENEFITS_CATEGORY_ID
        ];
      if (!rootCategoryId) {
        reject("No main category found!!");
        return;
      }

      this.categoryService
        .getCategoryById(rootCategoryId, false, false, true, [])
        .then((category: CategoryDTO) => {
          if (!category || !category.leaves || category.leaves.length == 0) {
            resolve([]);
            return;
          }

          const statusIds = [
            COUPON_STATUSES.AVAILABLE,
            // COUPON_STATUSES.CANCELLED, COUPON_STATUSES.CANCELLED_CHARGED_BACK, COUPON_STATUSES.CANCELLED_CLEARED,
            COUPON_STATUSES.PURCHASED,
            COUPON_STATUSES.PURCHASED_SENT_AS_GIFT_NOT_FOUND,
            COUPON_STATUSES.PURCHASED_SENT_AS_GIFT,
            COUPON_STATUSES.PURCHASED_SENT_AS_GIFT_NOT_NTERESTED,
            COUPON_STATUSES.REDEEMED,
            COUPON_STATUSES.REDEEMED_CLEARED,
            COUPON_STATUSES.REDEEMED_CLEARED_FAILED,
            COUPON_STATUSES.RESERVED,
            COUPON_STATUSES.VERIFIED,
          ];
          const brandProductIds = category.leaves.map((c: CategoryItemDTO) => {
            return c.itemId;
          });

          if (!brandProductIds) {
            resolve([]);
            return;
          }

          const input: IListUserBrandProductCoupons = {
            includeDetails: true,
            includeBom: true,
            categoryIds: [rootCategoryId],
            includeQRCode: true,
            brandProductCouponStatusIds: statusIds,
            rangeFrom: rangeFrom,
            rangeTo: rangeTo,
            brandProductIds: brandProductIds,
            matchingLevel: false,
          };

          this.brandsService
            .getUserBrandProductCoupons(this.userSrv.user.user.userId, input)
            .then((coupons: any) => {
              if (!coupons || coupons.length == 0) {
                resolve([]);
                return;
              }

              const dealIds = coupons.map(
                (coupon: DetailedBrandProductCouponDTO) => {
                  return coupon.brandProductId;
                }
              );

              if (!dealIds || dealIds.length == 0) {
                resolve([]);
                return;
              }

              this.brandsService
                .getBrandProductsBenefit(
                  TAValues.UserId,
                  dealIds,
                  rangeFrom,
                  -1,
                  [
                    Resource_Types.NAME,
                    Resource_Types.SHORT_DESCRIPTION,
                    Resource_Types.DESCRIPTION,
                    Resource_Types.CONTENT_1,
                    Resource_Types.IMAGE,
                  ]
                )
                .then((result: Array<DetailedBrandProductDTO>) => {
                  if (!result) {
                    resolve([]);
                    return;
                  }
                  const deals = result.map((b: DetailedBrandProductDTO) => {
                    const coupon = (
                      coupons as Array<DetailedBrandProductCouponDTO>
                    ).filter((c: DetailedBrandProductCouponDTO) => {
                      return c.brandProductId === b.brandProductId;
                    });

                    if (coupon && coupon.length > 0) {
                      b["couponCode"] = coupon[0].code;
                      b.activeSince = coupon[0].purchaseDate;
                    }

                    return b;
                  });
                  resolve(result);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });

      // this.brandsService.getUserBrandProductCoupons(
      //     this.userSrv.user.user.userId, {
      //         includeDetails: true,
      //         includeBom: true,
      //         categoryIds: [rootCategoryId],
      //         includeQRCode: true,
      //         brandProductCouponStatusIds: [],
      //         rangeFrom: 0,
      //         rangeTo: -1
      //     })
      //     .then((result: ItemsList<DetailedBrandProductCouponDTO>) => {
      //         if (!result || !result.items || result.items.length == 0) {
      //             resolve([]);
      //             return;
      //         }
      //
      //     }).catch(err => {
      //     reject(err);
      // })
    });
  }

  /**
   *
   * @param code
   * @param size
   */
  async getQrImage(code: string, size: number) {
    // return this.http.post(`https://friendsplus.mystartapp.org/user/qrcode?text=${code}&size=${size}`,{}, {responseType: 'text' }).toPromise();
    return await this.MiddleApi.getQrCode(code, size).catch((err) => {
      logger.log(err);
    });
  }

  /**
   * @description get the ref for the resources images and
   * we need to get from this the percentages.(with the balances.)
   */
  getLevelRef(): Promise<IReferenceLevel> {
    return new Promise((resolve, reject) => {
      const input: IReferenceLevelInput = {
        resources: true,
      };
      this.referenceApi
        .getReferenceLevels(environment.GAME_TYPE_ID, input)
        .then((result: Array<IReferenceLevel>) => {
          try {
            const referencesLevel: IReferenceLevel = result.find(
              (unitType) =>
                unitType.unitType.unitTypeId === Unit_Types.IQOS_CLUB_IT_LEVEL
            );

            //TODO: i need to get the level
            const levelOne: any = referencesLevel.levels.find(
              (index) => index.index === 1
            );
            const levelTwo: any = referencesLevel.levels.find(
              (index) => index.index === 2
            );
            const levelThree: any = referencesLevel.levels.find(
              (index) => index.index === 3
            );
            const levelFour: any = referencesLevel.levels.find(
              (index) => index.index === 4
            );
            const levelOneResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelOne, 1);
            const levelTwoResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelTwo, 2);
            const levelThreeResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelThree, 3);
            const levelFourResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelFour, 4);
            result.forEach((items) => {
              items.levelResources = [
                levelOneResources,
                levelTwoResources,
                levelThreeResources,
                levelFourResources,
              ];
            });
            const refItemsLevel = result.find(
              (unitType) =>
                unitType.unitType.unitTypeId === Unit_Types.IQOS_CLUB_IT_LEVEL
            );
            resolve(refItemsLevel);
          } catch (e) {
            logger.log(e);
            reject(e);
          }
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   *
   * @param item
   * @param level_
   */
  setImagesLevelsActiveInactive(item: any, level_: number): ILevelImage {
    const levelOneActive = item.resources.find(
      (resourcesTypeId) =>
        resourcesTypeId.resourceTypeId === LEVELS_RESOURCES_TYPE_ID.ACTIVE
    );
    const levelOneInactive = item.resources.find(
      (resourcesTypeId) =>
        resourcesTypeId.resourceTypeId === LEVELS_RESOURCES_TYPE_ID.INACTIVE
    );

    return <ILevelImage>{
      levelImageActive: levelOneActive.textResource,
      levelImageInactive: levelOneInactive.textResource,
      level: level_,
    };
  }

  getReferenceMetadata() {}

  /**
   *
   * @param {FilterTag} filterType
   * @param {SortTag} sortType
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<UserHistoryResponse>}
   */
  getHistoryItemsBySelections(
    filterType: FilterTag,
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<EWalletDTO>> {
    return new Promise((resolve, reject) => {
      const getTransactionTypeId = (): Array<number> => {
        switch (Number(filterType)) {
          case FilterTag.ALL:
            return [];
            break;
          case FilterTag.TR_WELCOME:
            return [Transactions_Types.WELCOME_BONUS];
            break;
          case FilterTag.TR_READING_WATCHING:
            return [Transactions_Types.USER_ACTIVITY];
            break;
          case FilterTag.TR_PURCHASE:
            return [Transactions_Types.DEAL_PURCHASE];
            break;
          case FilterTag.TR_ANSWERING:
            return [Transactions_Types.QUIZ_WON];
            break;
          case FilterTag.TR_SHARE:
            return [Transactions_Types.SHARE_IN_SOCIAL_NETWORKS];
            break;
          case FilterTag.TR_USER_PROFILE:
            return [Transactions_Types.USER_PROFILE_FIELD_UPDATE];
            break;
          case FilterTag.TR_EXTERNAL_ACTIVITY:
            return [Transactions_Types.EXTERNAL_ACTIVITY];
            break;
          case FilterTag.TR_BENVENUTO_EXTRA:
            return [Transactions_Types.BENVENUTO_EXTRA];
            break;
          case FilterTag.TR_BUON_COMPLEANNO:
            return [Transactions_Types.BUON_COMPLEANNO];
            break;
          case FilterTag.TR_PUNTI_EXTRA:
            return [
              Transactions_Types.PUNTI_EXTRA,
              Transactions_Types.COUPON_CHARGED_BACK,
              Transactions_Types.RESET_BALANCE,
              Transactions_Types.EXTERNAL_ACTIVITY_NEW,
              Transactions_Types.AMOUNT_ADDED_BY_ADMIN,
              Transactions_Types.E_WALLET_TRANSACTION_ROLLED_BACK,
              Transactions_Types.INSTANT_REWARD,
            ];
            break;
        }
        return [];
      };

      const orderType = this.utilsSrv.getOrderStringBySortType(sortType);
      const input: TransactionHistoryInput = {
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        orderType: orderType,
        includeDetails: true,
        resources: true,
        metadatas: true,
        languageIds: LANGUAGES,
        excludeZeroAmounts: true,
        transactionTypeId: getTransactionTypeId(),
        //descendingOrder: sortType == SortTag.RECENT,
        unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
      };
      this.userSrv
        .getHistoryTransaction(TAValues.UserId, input)
        .then((result: Array<EWalletDTO>) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
    // const categoryIds: Array<string> = [];
    // if (filterType != FilterTag.ALL) {
    //     const filtercategoryId = this.getCategoryIdByType(filterType, APPLICATION_SETTING_KEYS.MATERIAL_FILTER_TYPE_IDS);
    //     if (filtercategoryId)
    //         categoryIds.push(filtercategoryId);
    // }
    //
    // const sort = this.utilsSrv.getOrderStringBySortType(sortType);
    // return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
    // //return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
  }

  async getMetadataForExprationDay(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.itemSrv
        .getItemMetadata()
        .then((metadata) => {
          logger.log(metadata);
          const metadataItemId: ItemMetadataDTO = metadata.find(
            (itemId) => itemId.itemId === Settings.GAME_TYPE_ID
          );
          const metadataExp: MetadataDTO = metadataItemId.metadata.find(
            (key) => key.key === ITEM_METADATA.KEY_BALANCE_EXPIRATION_POLICY
          );
          const value: IItemExpirationDate = !_.isNil(metadata)
            ? JSON.parse(metadataExp.value)
            : "";

          try {
            const arrayDay: string[] = value.expirationDate.split("T");
            const splitDay: string[] = arrayDay[0].split("-");
            // [0]yyyy [1]mm [2]dd
            const year: string = splitDay[0];
            const month: string = splitDay[1];
            const day: string = splitDay[2];
            resolve(`${day} / ${month} / ${year}`);
          } catch (e) {
            resolve("");
          }
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   * Get Event reward rules
   * @param {Array<number>} eventTypeIds
   * @return {Promise<Map<number, string>>}
   */
  getEventRewardsRules(
    eventTypeIds: Array<number>
  ): Promise<Map<number, string>> {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .getEventRewardRules(Settings.GAME_TYPE, eventTypeIds, true, LANGUAGES)
        .then((result: Array<EventRewardRulesDTO>) => {
          const eventRewards = new Map<number, string>();
          if (result) {
            result.forEach((e: EventRewardRulesDTO) => {
              let testString =
                e.rewards && e.rewards.length > 0
                  ? this.resourcesSrv.getResourcesBasic(
                      e.rewards[0],
                      Resource_Types.NAME
                    )
                  : "";
              if (testString == "") {
                testString =
                  e.rewards && e.rewards.length > 1
                    ? this.resourcesSrv.getResourcesBasic(
                        e.rewards[1],
                        Resource_Types.NAME
                      )
                    : "";
              }
              if (testString != "") {
                eventRewards[e.eventTypeId] = testString;
              }
            });
          }
          resolve(eventRewards);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get Event reward rules
   * @param {Array<number>} eventTypeIds
   * @return {Promise<Map<number, string>>}
   */
  saveExternalUser(
    authenticationSource: number,
    accessToken: string,
    accessTokenSecret?: string,
    facebookName?: string,
    facebookImage?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const input: ExtSystemInfoDTO = {
        authenticationSource: authenticationSource,
        authToken: accessToken,
        authTokenSecret: accessTokenSecret,
        userId: facebookName,
      };

      const success = () => {
        this.userSrv.getUserProfile(TAValues.UserId);
        resolve("OK");
      };

      this.userSrv
        .saveExternalUser(TAValues.UserId, input)
        .then((result: any) => {
          if (!facebookImage) {
            success();
            return;
          }

          success();

          // this.userSrv.updatePicUrl(TAValues.UserId, facebookImage).then((res:any) => {
          //     success();
          // }).catch(err => {
          //     reject(err);
          // });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async getBenefitsNew(
    rangeFrom: number,
    rangeTo: number,
    categoryId: number
  ): Promise<KpDictionary<IndexedDataContent>> {
    return new Promise<KpDictionary<IndexedDataContent>>(
      async (resolve, reject) => {
        let benefits = new KpDictionary<IndexedDataContent>();
        const input: FilterESContentDataInput = {
          categoryIds: [String(categoryId)],
          rangeFrom: rangeFrom,
          rangeTo: rangeTo,
          itemTypeIds: [107],
          currentlyActive: true,
          matchingRules: false,
          matchingLevel: true,
          sortOrderTypes: [ORDER_TYPE.BY_ORDERING_DESC],
        };
        const result = await this.recommendationsSrv
          .getRecommendationContent(TAValues.UserId, input, {
            resources: true,
            languageIds: TAConstants.Settings.LANGUAGES,
            metadatas: true,
          })
          .catch((err) => {});
        if (!result) {
          reject(null);
          return;
        }
        result.map((respItem: IndexedDataContent) => {
          // respItem = this.formatProduct(respItem);
          // if (respItem.bundledProducts && isArray(respItem.bundledProducts)) {
          //     let formattedBundles: Array<LoyaltyBrandProductDTO> = [];
          //     respItem.bundledProducts.forEach(pb => {
          //         formattedBundles.push({
          //             ...this.formatProduct(pb as LoyaltyBrandProductDTO),
          //             isBundle: true,
          //             isListed: false,
          //         });
          //     });
          //     respItem.bundledProducts = formattedBundles;
          // }
          benefits.Add(respItem.itemId, respItem);
        });
        if (result[TAConstants.TAHeaders.ITEM_COUNT])
          benefits[TAConstants.TAHeaders.ITEM_COUNT] =
            result[TAConstants.TAHeaders.ITEM_COUNT];
        resolve(benefits);
      }
    );
  }
} // END CLASS

export interface ILevelImage {
  level: number;
  levelImageActive: string;
  levelImageInactive: string;
}

export interface IItemExpirationDate {
  batchSize?: number;
  expirationDate?: string;
  expiredPointsUnitTypeId?: number;
  tournamentId?: string;
  unitTypeId?: number;
}

export interface IMessageCategory extends Message {
  title?: string;
  category?: string | number;
  message?: string;
}
