import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-iqos-footer',
    templateUrl: './iqos-footer.component.html',
    styleUrls: ['./iqos-footer.component.scss']
})
export class IqosFooterComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    toggleMenu(event) {
        var target = event.target || event.srcElement || event.currentTarget;
        if (!target)
            return;

        target.classList.toggle('active');

        const ul = target.parentElement.getElementsByTagName("ul");
        if (ul.length > 0) {
            ul[0].classList.toggle('force-show');
        }

        // var c = target.getElementsByTagName("h");
        // if(c.length>0){
        //     c[0].classList.toggle('active');
        // }
    }

    navigateTo(value: string){
        this.router.navigate([value]);
    }

}
