import {
  ChangeMessageStatusInput,
  GetInAppMessagesQuery,
  InAppMessagesApi,
} from "../../talosApi/api/InAppMessagesApi";
import { Injectable } from "@angular/core";
import { InAppMessageDTO } from "../../talosApi/models/InAppMessageDTO";
import { BehaviorSubject, Observable } from "rxjs";
import { TAValues } from "src/talosApi/settings";

@Injectable()
export class AlertService {
  private alertDataSubject = new BehaviorSubject<AlertData>(undefined);
  public alert$: Observable<AlertData> = this.alertDataSubject.asObservable();

  constructor(private api: InAppMessagesApi) {}

  /**
   * Get In App Messages
   * @param {string} userId
   * @param {GetInAppMessagesQuery} queryParams
   * @return {Promise<Array<InAppMessageDTO>>}
   */
  getInAppMessages(
    userId: string,
    queryParams: GetInAppMessagesQuery
  ): Promise<Array<InAppMessageDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .getInAppMessages(userId, queryParams)
        .then((result) => {
          if (result && result.length > 0) {
            const m = result[0];
            let title: string = "";
            let text: string = "";
            let type: string = "";

            const input: ChangeMessageStatusInput = {
              messageIds: result.map((r) => r.id),
              status: 1,
            };
            this.setInAppMessagesStatus(TAValues.UserId, input)
              .then((res) => {})
              .catch((err) => {});

            try {
              const title_: string[] = m.content.match(/<title>(.*?)<\/title>/g)
                ? m.content
                    .match(/<title>(.*?)<\/title>/g)
                    .map((val) => val.replace(/<\/?title>/g, ""))
                : null;
              title = title_ && title_.length > 0 ? title_[0] : null;

              const text_: string[] = m.content.match(/<text>(.*?)<\/text>/g)
                ? m.content
                    .match(/<text>(.*?)<\/text>/g)
                    .map((val) => val.replace(/<\/?text>/g, ""))
                : null;
              text = text_ && text_.length > 0 ? text_[0] : null;
              const type_: string[] = m.content.match(/<type>(.*?)<\/type>/g)
                ? m.content
                    .match(/<type>(.*?)<\/type>/g)
                    .map((val) => val.replace(/<\/?type>/g, ""))
                : null;
              type = type_ && type_.length > 0 ? type_[0] : null;
            } catch (e) {}

            if (Number(type) == 1 && title && title != "" && text && text != "")
              // this.messageObserver.next({ title: title, message: text });
              // this.alertDataSubject.next({
              this.setAlertData({
                title: title,
                message: text,
                image: "-alert",
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Set In App Messages status
   * @param {string} userId
   * @param {ChangeMessageStatusInput} input
   * @return {Promise<any>}
   */
  setInAppMessagesStatus(
    userId: string,
    input: ChangeMessageStatusInput
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .setInAppMessagesStatus(userId, input)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public setAlertData(data: AlertData) {
    this.alertDataSubject.next(data);
  }
}

export interface AlertData {
  title: string;
  message: string;
  image: "-alert" | "-error" | "-info" | "-info-transparent";
  autoClose?: boolean;
}
