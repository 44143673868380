import {Injectable} from "@angular/core";
import {TAValues} from "../../talosApi/settings";
import {CheckInApi} from "../../talosApi/api/CheckInApi";
import {CheckInInput} from "../../talosApi/models/CheckInInput";
import {CheckInDTO} from "../../talosApi/models/CheckInDTO";

/**
 * Injectable
 */
@Injectable()
export class CheckInService {

    /**
     * Creates an instance of token services.
     * @param api
     * @param translate
     */
    constructor(private api: CheckInApi) {
    }

    /**
     * Check in
     * @param {CheckInInput} input
     * @return {Promise<CheckInDTO>}
     */
    public checkIn(input: CheckInInput): Promise<CheckInDTO> {
        return new Promise<any>((resolve, reject) => {
            this.api.checkIn(TAValues.UserId, input).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error)
            });
        });
    }
}