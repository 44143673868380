import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TAConstants, TAValues} from "../talosApi/settings";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class Http2Integration {

    constructor(private http: HttpClient, private translate: TranslateService) {
    }

    private async request(request: IHttp2NetplanReq): Promise<any> {


        return new Promise(async (resolve, reject) => {


            const fireRequest = async () => {
                const urlToCall: string = TAConstants.Settings.INTEGRATION_SERVER + request.path;

                let headers: HttpHeaders = request.headers;
                if (!headers)
                    headers = new HttpHeaders();

                const token = localStorage.getItem('authkey');
                headers = headers.append(TAConstants.TAHeaders.LANGUAGE, this.translate.currentLang);
                headers = headers.append(TAConstants.TAHeaders.AUTHORIZATION, 'Bearer ' + token);
                headers = headers.append('Content-Type', 'application/json');
                headers = headers.append(TAConstants.TAHeaders.USERNAME, TAConstants.Settings.ADMIN_USERNAME);
                headers = headers.append(TAConstants.TAHeaders.APPLICATION_ID, TAValues.APPLICATION_ID);
                headers = headers.append(TAConstants.TAHeaders.USER_GROUP_ID, TAConstants.Settings.USERGROUP_ID);

                const options = {
                    body: request.input,
                    headers: headers,
                    params: request.queryParams,
                    observe: 'response' as 'body' | 'events' | 'response',
                    responseType: 'text' as 'arraybuffer' | 'blob' | 'json' | 'text'
                };

                this.http.request(request.method, urlToCall, options).subscribe(response => {
                    try {
                        let js = JSON.parse(response.body);
                        resolve(js);
                    } catch (e) {
                        //TODO string
                        resolve(response.body);
                    }
                }, async (error) => {
                    reject(error || 'Server error');
                });
            }

            fireRequest();

        });
    }

    public get(request: IHttp2NetplanReq): Promise<any> {
        request.method = "GET";
        return this.request(request);
    }

    public post(request: IHttp2NetplanReq): Promise<any> {
        request.method = "POST";
        return this.request(request);
    }

    public put(request: IHttp2NetplanReq): Promise<any> {
        request.method = "PUT";
        return this.request(request);
    }

    public options(request: IHttp2NetplanReq): Promise<any> {
        request.method = "OPTIONS";
        return this.request(request);
    }

    public delete(request: IHttp2NetplanReq): Promise<any> {
        request.method = "DELETE";
        return this.request(request);
    }

    public head(request: IHttp2NetplanReq): Promise<any> {
        request.method = "HEAD";
        return this.request(request);
    }
}

export interface IHttp2NetplanReq {
    method?: string,
    path: string,
    queryParams?: any,
    input?: any,
    headers?: HttpHeaders,
    requireSession?: boolean
    timeStamp?: string
}
