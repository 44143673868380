import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {UtilsService} from "../services/utils.service";
import {AppGlobalsService} from "../services/appGlobals.service";
import {InitService} from "../services/talos/init.service";
import {QrSurveyService} from "./pages/qr-survey/qr-survey.service";
import {UserService} from "../services/talos/user.service";

@Injectable({
    providedIn: 'root'
})
export class AuthExternalGuard implements CanActivate {
    constructor(private auth: AuthService, public appGlobals: AppGlobalsService, public initSrv: InitService,
                private myRoute: Router, private utilsSrv: UtilsService, public activatedRoute: ActivatedRoute,
                public qrSurveySrv: QrSurveyService, public userSrv: UserService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // this.appGlobals.hasDonate = false;
        if (this.auth.isLoggednIn() && this.userSrv.user.unlinked == false && this.qrSurveySrv.categoryId != undefined) {

            // var myCustomData = { foo: 'bar' }
            // var event = new CustomEvent('iframeChanged', { detail: myCustomData })
            // window.parent.document.dispatchEvent(event)
            window.scroll(0, 0);


            //Just in case
            var views = document.getElementsByTagName('html');
            if (views) {
                for (var i = 0; i < views.length; i++) {
                    views[i].setAttribute('class', '');
                }
            }

            // window.parent.postMessage({'id':'iframeChanged'}, '*');
            this.initSrv.removeMenu = true;
            return true;
        } else {
            // this.utilsSrv.inactivatedUserPerformed();
            // this.appGlobals.redirectUrl = window.location.pathname;

            this.myRoute.navigate(['/'], {replaceUrl: true});

            this.initSrv.removeMenu = false;
            return false;
        }
    }
}
