/**
 * Created by papadopoulosc on 04/1/2019.
 */
import {Injectable} from "@angular/core";
import {ItemsList} from "../../talosApi/models/ItemsList";
import {Message} from "../../talosApi/models/Message";
import {TAValues} from "../../talosApi/settings";
import {SetMessageStatusInput} from "../../talosApi/models/SetMessageStatusInput";
import {DeleteMessageInput} from "../../talosApi/models/DeleteMessageInput";
import {MessageApi} from "../../talosApi/api/MessageApi";
import {GetMessagesInput} from "../../talosApi/models/GetMessagesInput";

@Injectable()
export class MessagesService {

    constructor(private api: MessageApi) {

    }

    /**
     *
     * @param {string} folderId
     * @param {number} rangeFrom
     * @param {number} rangeTo
     * @return {Promise<ItemsList<Message> | boolean>}
     */
    public getMessages(folderId: string, rangeFrom: number, rangeTo: number): Promise<Array<Message>> {
        return new Promise((resolve, reject) => {

            const input: GetMessagesInput = {folderId: folderId, rangeFrom: rangeFrom, rangeTo: rangeTo};
            this.api.getMessages(TAValues.UserId, input).then((result) => {

                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     *
     * @param {string} messageId
     * @return {Promise<String>}
     */
    public deleteMessages(messageId: string): Promise<String> {
        return new Promise((resolve, reject) => {

            const input: DeleteMessageInput = {messageIds: [messageId]};
            this.api.deleteMessages(TAValues.UserId, input).then((result) => {

                resolve("SUCCESS");
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     *
     * @param userId
     * @param messageIds
     * @param status
     */
    public setStatus(userId: string, messageIds: string[],status: number): Promise<String> {
        return new Promise((resolve, reject) => {

            const input: SetMessageStatusInput = {messageIds: messageIds, status: status};
            this.api.setStatus(TAValues.UserId, input).then((result) => {

                resolve("SUCCESS");
            }).catch(err => {
                reject(err);
            });
        })
    }
}
