import {Injectable} from "@angular/core";
import {FavoriteApi, InputGetFavorite} from "../../talosApi/api/FavoriteApi";
import {FavoriteDTO} from "../../talosApi/models/FavoriteDTO";
import {TAConstants, TAValues} from "../../talosApi/settings";
import * as _ from "lodash";
import Favorites_Status = TAConstants.Favorites_Status;


@Injectable()
export class FavoriteService {

    constructor(private api: FavoriteApi) {

    }

    /**
     *
     * @param {string} userId
     * @param {string} itemId
     * @param {number} itemTypeId
     * @return {Promise<Array<FavoriteDTO>>}
     */
    public addFavorite(userId: string, itemId: string, itemTypeId: number): Promise<Array<FavoriteDTO>> {
        return new Promise((resolve, reject) => {

            this.api.addFavorite(userId, itemId, itemTypeId).then((result) => {

                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }


    public getFavorite(userId: string, input: InputGetFavorite): Promise<Array<FavoriteDTO>> {
        return new Promise((resolve, reject) => {
            this.api.getAllFavorite(userId, input)
                .then((allFavorites: Array<FavoriteDTO>) => {
                    if (_.isNil(allFavorites)) {
                        resolve([]);
                        return;
                    }
                    resolve(allFavorites);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    }

    /**
     *
     * @param {string} userId
     * @param {string} itemId
     * @param {number} itemTypeId
     * @return {Promise<any>}
     */
    public removeFavorite(userId: string, itemId: string, itemTypeId: number): Promise<any> {
        return new Promise((resolve, reject) => {

            const input: InputGetFavorite = {
                itemId: itemId,
                itemTypeIds: [itemTypeId],
                rangeFrom: 0,
                rangeTo: 1,
                statusIds: [Favorites_Status.Active]
            };
            this.getFavorite(userId, input).then((result: Array<FavoriteDTO>) => {
                if (result && result.length > 0) {
                    this.api.removeFavorite(userId, result[0].favoriteId).then((result) => {
                        resolve("OK");
                    }).catch(err => {
                        reject(err);
                    })
                } else {
                    reject("No Item found");
                }
            }).catch(err => {
                reject(err);
            })
        })
    }

}