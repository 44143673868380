import {ReferralApi} from "./api/ReferralApi";
import {Http2Integration} from "./http2Integration";
import {HttpClientModule} from "@angular/common/http";
import {NgModule, Optional, SkipSelf} from "@angular/core";

@NgModule({
    imports: [HttpClientModule],
    providers: [
        Http2Integration,
        ReferralApi,
    ]
})
export class IntegrationApiModule {

    constructor(@Optional() @SkipSelf() parentModule: IntegrationApiModule) {
        if (parentModule) {
            throw new Error(
                'IntegrationApiModule Module is already loaded. Import it in the AppModule only');
        }
    }

}
