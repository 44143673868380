import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {QuizViewComponent} from "./quiz-view.component";
import {FormsModule} from "@angular/forms";

@NgModule({
    declarations: [QuizViewComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        QuizViewComponent
    ]

})
export class QuizViewModule {

}