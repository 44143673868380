import {Injectable} from "@angular/core";
import {UserService} from "../../../services/talos/user.service";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import * as _ from "lodash";
import {Message} from "../../../talosApi/models/Message";
import {MessagesService} from "../../../services/talos/messages.service";
import {AppSettingsService} from "../../../services/talos/appSettings.service";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import * as Moment from 'moment';
import {TranslateService} from "@ngx-translate/core";
import MESSAGE_STATUS = TAConstants.MESSAGE_STATUS;
import {IEventInput} from "../../../talosApi/api/EventApi";
import Events_Types = TAConstants.Events_Types;
import Settings = TAConstants.Settings;
import {EventService} from "../../../services/talos/event.service";
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import {AppGlobalsService} from "../../../services/appGlobals.service";

@Injectable()
export class NotificationsService {

    messageStorage: Map<string, IMessageCategory> = new Map<string, IMessageCategory>();

    constructor(private userSrv: UserService, private messagesSrv: MessagesService, private appSettingsSrv: AppSettingsService,
                private eventSrv: EventService, private translateSrv: TranslateService, private appGlobals: AppGlobalsService) {
    }

    /**
     *
     * @param rangeFrom
     * @param rangeTo
     */
    getMessages(rangeFrom: number, rangeTo: number): Promise<Array<Message>> {
        return new Promise((resolve, reject) => {
            const folderId = this.appSettingsSrv.getValue(APPLICATION_SETTING_KEYS.USER_MESSAGING_FOLDER);
            if (_.isNil(folderId)) {
                resolve([]);
                return;
            }

            const getMessageImage = (message: Message): string => {
                if (message.properties) {
                    var image = message.properties.filter(p => p.type == 0);

                    if (image && image.length > 0) {
                        return image[0].value;
                    }
                }

                return "";
            }
            this.messagesSrv.getMessages(folderId, rangeFrom, rangeTo).then(async (result: Array<Message>) => {
                if (result) {

                    const categoryId = this.appGlobals.config[APPLICATION_SETTING_KEYS.NOTIFICATION_CATEGORY_ID];

                    const addEventMessage = (m: IMessageCategory): Promise<any> => {

                        let properties = (m.itemId ? `itemId=${m.itemId}\nitemTypeId=${ITEM_TYPES.USER_CAMPAIGN}` : null);

                        const eventInput: IEventInput = {
                            eventProperties: properties || null,
                            eventTypeId: Events_Types.IQOS_CLUB_IT_lONG_CONTENT_CHECK,
                            userId: TAValues.UserId,
                            gameTypeId: Settings.GAME_TYPE_ID,
                            clientTypeId: TAValues.CLIENT_TYPE_ID,
                            applicationId: TAValues.APPLICATION_ID,
                        };

                        return this.eventSrv.addEvent(eventInput);
                    };

                    const promises: Array<Promise<string>> = [];
                    const messageIds = [];
                    result.forEach((m: IMessageCategory) => {
                        let title: string;
                        let category: string;
                        let message: string;
                        let itemId: string;

                        try {
                            const title_: string[] = m.content.match(/<messagetitle>(.*?)<\/messagetitle>/g).map(val => val.replace(/<\/?messagetitle>/g, ''));
                            title = title_[0];
                            const category_: string[] = m.content.match(/<category>(.*?)<\/category>/g).map(val => val.replace(/<\/?category>/g, ''));
                            category = category_[0];
                            const message_: string[] = m.content.match(/<message>(.*?)<\/message>/g).map(val => val.replace(/<\/?message>/g, ''));
                            message = message_[0];
                            const itemId_: string[] = m.content.match(/<itemId>(.*?)<\/itemId>/g) ? m.content.match(/<itemId>(.*?)<\/itemId>/g).map(val => val.replace(/<\/?itemId>/g, '')) : null;
                            itemId = itemId_ && itemId_.length > 0 ? itemId_[0] : null;

                            // set the storage
                            this.messageStorage.set(category, m);

                        } catch (e) {
                            title = '';
                            category = '';
                            message = '';
                            itemId = null;
                        }
                        m.title = title;
                        m.category = category;
                        m.message = message;
                        m.itemId = itemId;
                        m.image = getMessageImage(m);
                        m.formatedDate = Moment(m.messageDate).locale(this.translateSrv.currentLang).calendar(); //moment().subtract(1, 'days').calendar();
                        m.altDate = Moment(m.messageDate).locale(this.translateSrv.currentLang).calendar() + ' - ' + Moment(m.messageDate).locale(this.translateSrv.currentLang).format('h:mm a').toUpperCase();
                        if (!m.readFlag) {
                            messageIds.push(m.id.toUpperCase());
                            if (String(m.category) == categoryId)
                                promises.push(addEventMessage(m));
                        }
                    })

                    if (promises && promises.length > 0) {
                        const results = await Promise.all(promises).catch(err => {
                            logger.log(err);
                        });
                        if (results) {

                        }
                    }
                    if (messageIds.length > 0) {
                        this.setReadMessages(TAValues.UserId, messageIds, MESSAGE_STATUS.MESSAGES_READ_STATUS).then(res => {
                            this.userSrv.getUnreadMessages(TAValues.UserId)
                                .then((result) => {
                                    logger.log(result);
                                    // this.profileCom.updateTheUnreadInTabs();
                                })
                                .catch((error) => {
                                    logger.log(error);
                                });
                        }).catch(ex => {

                        })
                    }
                }
                resolve(result);
            }, reject);
        })
    }

    setReadMessages(userId: string, messageIds: string[], status: number): Promise<any> {
        return new Promise<Message>((resolve, reject) => {


            this.messagesSrv.setStatus(TAValues.UserId, messageIds, status)
                .then((result) => {
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                })


        });
    }

    /**
     *
     * @param userId
     * @param messageId
     * @param status
     */
    setReadMessage(userId: string, messageId: string, status: number): Promise<Message> {
        return new Promise<Message>((resolve, reject) => {


            this.messagesSrv.setStatus(TAValues.UserId, [messageId], status)
                .then((result) => {
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                })


        });
    }

    sendClickEvent(value: IMessageCategory) {
        let properties = (value.itemId ? `itemId=${value.itemId}\nitemTypeId=${ITEM_TYPES.USER_CAMPAIGN}` : null);

        const eventInput: IEventInput = {
            eventProperties: properties || null,
            eventTypeId: Events_Types.IQOS_CLUB_IT_SHORT_CONTENT_CHECK,
            userId: TAValues.UserId,
            gameTypeId: Settings.GAME_TYPE_ID,
            clientTypeId: TAValues.CLIENT_TYPE_ID,
            applicationId: TAValues.APPLICATION_ID,
        };

        this.eventSrv.addEvent(eventInput).catch(err => {
        });
    }
}

export interface IMessageCategory extends Message {
    title?: string;
    category?: string | number;
    message?: string;
    itemId?: string;

}