import { environment as ENV } from "./../environments/environment";

/**
 * Created by georgefloros on 30/03/2017.
 */

// export  namespace MockData{
//   export const  LATITUDE:number = 37.95961158406298;
//   export const  LONGITUDE:number = 23.71920496221719;
// }

// export namespace Utils{
//   export const  scaleAmountDTO = (amountDTO:DecimalDTO):number =>  {
//     return (amountDTO.value / Math.pow(10, amountDTO.scale));
//   }
// }

export namespace TAConstants {
  export namespace Order_Stores {
    export const BY_ORDERING_DESC = "BY_ORDERING_DESC";
    export const BY_RECENT = "BY_RECENT";
    export const BY_OLDER = "BY_OLDER";
    export const BY_TOP_RATED = "BY_TOP_RATED";
    export const BY_PROXIMITY = "BY_PROXIMITY"; // sort all contents by distance and then apply other filter params
    export const FILTERED_BY_PROXIMITY = "FILTERED_BY_PROXIMITY"; // filter all contents and then sort by distance
    export const BY_ORDERING_ASC = "BY_ORDERING_ASC";
    export const BY_NAME = "BY_NAME";
  }

  export namespace Status_welcome_page {
    export const HAS_SEEN_WELCOME_PAGES = "hasSeenWelcomePages";
  }

  export namespace BOOLEAN_TYPES {
    export const TRUE = "true";
    export const FALSE = "false";
  }

  export namespace CheckStartNumberForUserPhone {
    export const FIRST_NUMBER = "6";
    export const SECOND_NUMBER = "9";
  }

  export namespace Storage_Token {
    export const AUTH = "storageSspToken";
    export const VIEW_MASTER_VERSION = "viewMasterVersion";
  }

  export enum Events_Types {
    FACEBOOK_SHARE = 85,
    SHARE_MOCK = 752,
    USER_REGISTERED = 50,
    OPT_OUT_USER_ID = 200208,
    ACTION_CONSTRAINT = 201302,
    ITEM_PURCHASED = 104,
    ITEM_VIEWED = 109,
    VIDEO_WATCHED = 700,
    QUIZ_COMPLETION = 13026,
    POLL_SURVEY_COMPLETED = 192,
    ITEM_SMS_SHARED = 257,
    ITEM_WHATSUP_SHARED = 245,
    EMAIL_SHARE = 100034,
    SALESFORCE_CONVERSION_STAGE_COMPLETED = 19002,
    SALESFORCE_FOLLOW_UP_STATUS_COMPLETED = 19003,
    ONE_TO_MANY_USER = 201362,
    ONE_TO_MANY_GROUP = 201368,
    CHALLENGE_ACCEPTED = 5,
    IQOS_CLUB_IT_MGM_REFERRAL_COMPLETED = 19022,
    CHECKIN_MOCK = 751,
    CHECKIN_SUBMITTED = 90,
    START_MISSION = 12031,
    IQOS_CLUB_IT_CLICKED_SHARE_WA_SMS_EMAIL = 19016,
    IQOS_CLUB_IT_INSTANT_NOTIFICATION_WITH_COUPON = 19082,
    MONEY_TRANSFERRED = 71,
    IQOS_CLUB_IT_lONG_CONTENT_CHECK = 19090,
    IQOS_CLUB_IT_SHORT_CONTENT_CHECK = 19089,
    CONTENT_QUIZ_COMPLETION = 4000287,
    IQOS_CLUB_IT_MGM_REFERRAL_POINTS_AWARD = 19023,
  }

  export enum Transactions_Types {
    SHARE_IN_SOCIAL_NETWORKS = 41,
    DEAL_PURCHASE = 44,
    QUIZ_WON = 57,
    WELCOME_BONUS = 65,
    USER_ACTIVITY = 66,
    BALANCE_CONVERSION_TO_TOURNAMENT_PARTICIPATION = 72, // DONA PUNTI
    USER_PROFILE_FIELD_UPDATE = 30,
    EXTERNAL_ACTIVITY = 3,
    BENVENUTO_EXTRA = 13,
    BUON_COMPLEANNO = 68,
    PUNTI_EXTRA = 62,
    MGM = 10,
    IGI_LAU = 11,
    IGI_LAS = 23,
    CGC_LAU = 12,
    CGC_LSD = 22,
    AMOUNT_ADDED_BY_ADMIN = 40,
    COUPON_CHARGED_BACK = 49,
    RESET_BALANCE = 69,
    EXTERNAL_ACTIVITY_NEW = 67, // PURCHASES
    E_WALLET_TRANSACTION_ROLLED_BACK = 51,
    INSTANT_REWARD = 56, // ANNIVERSARIO
    RAKE = 4,
    FRIEND_INVITATION = 12,
    MOBILE_GAME_DOWNLOAD = 16, // MISSIONE AMICI, MISSIONE LOGIN
    SALESFORCE_CLICK_MARKETING_MATERIAL = 26 // EMAILS
  }

  export namespace EventKeys {
    export const ITEM_KEY_VIEWED = "itemViewed";
  }

  export enum StateOfProfileModal {
    EDIT_PROFILE = 0,
    DELETE_PROFILE = 1,
    CONTACT_BY_PHONE = 2,
    CONTACT_BY_FORM = 3,
  }

  export enum StateOfLearningMaterials {
    UPLOAD_CONTENT_IMAGE = 1,
    UPLOAD_CONTENT_REGULAR = 0,
  }

  export namespace ITEM_METADATA {
    export const KEY_BALANCE_EXPIRATION_POLICY: string =
      "balanceExpirationPolicy";
  }

  export enum STATE_FROM_SSO {
    OPEN_RESET_PASS_POP_UP = 1,
  }

  export enum Unit_Types {
    Raffle_Tickets = 103,
    INDY_POINTS = 102,
    IQOS_POINTS = 92,
    IQOS_LEVEL_POINTS = 107,
    XP = 22,
    IQOS_CLUB_IT_POINTS = 126,
    IQOS_CLUB_IT_LEVEL = 127,
    IQON_MONEY = 112,
    IQOS_CLUB_IT_EXPIRING_POINTS = 156,
    IQOS_CLUB_IT_BADGE = 157,
  }

  export enum LEVELS_RESOURCES_TYPE_ID {
    DEFAULT = 1,
    INACTIVE = 325,
    ACTIVE = 326,
    ALT_ACTIVE = 327,
  }

  export namespace Unit_type_displtiption {
    export const IQOS_Level_Points = "IQOS_Level_Points";
    export const IQOS_POINTS = "IQOS_POINTS";
  }

  export namespace User_Stats {
    export const UNREAD_USER_MESSAGES: string = "unreadUserMessages";
    export const COUPON_STATS_PER_GAME_TYPE: string = "couponStatsPerGameType";
    export const COUPON_STATUS: string = "couponStatus";
  }

  export enum Resource_Types {
    NAME = 1,
    DESCRIPTION = 2,
    SHORT_DESCRIPTION = 244,
    THUMBNAIL_URL = 3,
    IMAGE = 4,
    TEXT = 29,
    TERMS_AND_CONDITIONS = 181,
    TERMS_AND_CONDITIONS_WEB = 242,
    TERMS_CONDITIONS_OF_APP = 344,
    TITLE = 250,
    SOURCE_TITLE = 265,
    ACTION = 173,
    UPLOAD_CONTENT = 143,
    THUMBNAIL = 196,
    DETAILS = 228,
    IMAGE_FEATURED_WEB = 233,
    CONTENT_1 = 261,
    CONTENT_2 = 262,
    CONTENT_3 = 263,
    CONTENT_4 = 317,
    CONTENT_5 = 318,
    SUMMARY_TEXT = 319,
    RESPONSIVE_TEXT = 340,
    UPLOAD_CONTENT_1 = 341,
    UPLOAD_CONTENT_2 = 342,
    UPLOAD_CONTENT_3 = 343,
    UPLOAD_CONTENT_4 = 344,
    UPLOAD_CONTENT_5 = 345,
    UPLOAD_CONTENT_6 = 346,
    REWARD_AMOUNT = 338,
    REWARD_IMAGE = 324,
    HEADER_IMAGE = 97,
    BACKGROUND_IMAGE = 33,
    ALPHA_SHOW = 179,
    IMAGE_FEATURED = 231,
    IMAGE_LARGE = 212, // loading
    IMAGE_LARGE_SELECTED = 222,
    IMAGE_WEB = 240,
    IMAGE_URL = 220,
    VIDEO_URL = 130,
    HREF = 34,
    BRAND_STORE_WORKING_HOURS = 257,
    COLOR = 256, // color
    COLOR_NAME = 1022,
    REWARD_DESCRIPTION = 207,
    SCRIPT = 35,
    PRICE = 9,
    STORY_TEXT = 96,
    POST_TO_WALL_TEXT = 30,
    POST_TO_WALL_TITLE = 36,
    POST_TO_WALL_PIC_URL = 185,
    POST_TO_TWITTER_MSG = 187,
    ALLOW_GUEST = 258,
    VITEM_ID = 98,
    URL_PATH = 246,
    SOURCE_URL = 266,
    NOT_CHANGED = 0,
    CHANGED = 1,
    NOT_FOUND = -1,
    HREF_RESULTS = 335,
    POST_TO_TWITTER_SHORT_URL = 186,
    POST_TO_TWITTER_THUMBNAIL = 196,
    TYPE_TEXT = 29,
    IMAGE_SELECTED = 221,
    ACTION_TYPE = 173,
    ACTION_TYPE_DESCRIPTION = 269,
    YD_DEAL_PREVIEW_00 = 223,
    YD_DEAL_PREVIEW_01 = 224,
    YD_DEAL_PREVIEW_02 = 225,
    YD_DEAL_PREVIEW_03 = 226,
    YD_DEAL_PREVIEW_04 = 227,
    FEATURE_IMAGE = 231, // login image
    APP_LABELS = 331,
    GAME_TYPE_LOGO = 160,
    WEB_COMPONENT_CONFG = 349,
    IMAGE_STATE_1 = 325,
    IMAGE_STATE_2 = 326,
    IMAGE_STATE_3 = 327,
    USER_QUIZ_RESULTS_TEXTS = 295,
    QUIZ_RESULT = 298,
    WEB_IMAGE_ARRAY = 245,
    APPLICATION_SLUGS = 350,
    IMAGE_1 = 352,
    IMAGE_2 = 353,
    IMAGE_3 = 354,
    IMAGE_4 = 355,
    MESSAGE_IMAGE = 253,
    JSON_MULTI_CONTENT = 1021,
    CAMPAIGN_TEXT = 1008,
    CHALLENGE_WON_TEXT = 163,
    CHALLENGE_LOST_TEXT = 164,
    TEASER = 238,
    REDIRECT_URL = 310,
    SLIDE_1 = 1013,
    SLIDE_2 = 1014,
    SLIDE_3 = 1015,
    GAME_TYPE_INGAME_IMAGE1 = 156,
    GAME_TYPE_INGAME_IMAGE2 = 157,
    TITLE_COMPLETED = 323,
    BUTTON_THEME = 38,
  }

  export enum Web_Component_Types {
    PERSONAL_REWARD_CATALOG_CARD = 31,
    CARD_BOX_CARD = 32,
  }

  export enum User_Status {
    DEACTIVATED = 0,
    ACTIVE = 1,
    INACTIVE = 2,
    INVITED = 3,
    FACEBOOK_FRIEND_IMPORT = 4,
    BANNED_BY_ADMIN = 5,
    PENDING_ACTIVATION = 6,
    LOCKED = 7,
    UNREGISTERED = 8,
  }

  export enum Favorites_Status {
    Removed = 0,
    Active = 1,
  }

  export namespace VIDEO_WATCH {
    export const YES = "yes";
    export const NO = "no";
  }

  export enum StateSso {
    USER_NEED_TO_ACCEPT_TERMS = 1,
    DISPLAY_TERMS = 0,
    PRIVACY_NOTICE = 2,
    AUTHENTICATION_SOURCE_ID = 7,
  }

  export enum Resource_Item_Types {
    BRAND_STORE = 108,
    UPLOAD_CONTENT = 143,
    ITEM_TYPE_PREDEFINED_VALUE = 78,
  }

  export enum Resource_Status {
    NOT_CHANGED = 0,
    CHANGED = 1,
    NOT_FOUND = -1,
  }

  export enum Client_Types {
    ANDROID = 6,
    IOS = 3,
    PORTAL = 4,
    FACEBOOK = 9,
  }

  export enum External_Application_SourceId {
    FACEBOOK = 1,
    TWITTER = 2,
    SALESFORCE = 6,
    TALOSIM = 7,
    EXTERNAL_SYSTEM_USER = 8,
    INSTAGRAM = 9,
  }

  export namespace External_Application_ID {
    export const FACEBOOK = "IQOS_CLUB_IT_FACEBOOK";
    export const TWITTER = "IQOS_CLUB_IT_TWITTER";
    export const INSTAGRAM = "IQOS_CLUB_IT_INSTAGRAM";
  }

  export enum BUTTON_THEMES {
    WHITE = 1,
    BLACK = 2,
  }

  export enum ITEM_TYPE {
    CATEGORY = 67,
    GAME_TYPE_ID = 3,
    UPLOADCONTENT = 143,
    ACHIEVEMENT_TYPE = 47,
    VIRTUAL_ITEM = 27,
    BRAND_PRODUCT = 107,
    BRAND_PRODUCT_COUPON = 121,
    UPLOAD_CONTENT_COMMENT = 136,
    CITY = 115,
    QUIZ = 53,
  }

  export enum UPLOAD_CONTENT_TYPE {
    USER_NEWS = 8,
    GENERAL = 4,
    VIDEOS = 9,
    TESTIMONIALS = 10,
    BACKGROUND_IMAGE = 13,
    POP_UP_CONTENT = 14,
  }

  export enum StatusOnActiceDirectory {
    OK = 0,
    LOGIN_ERROR = 1,
    FOLLOW_ERROR = 2,
  }

  export enum ErrorCodes {
    CONSTRAINT_ERROR = 1067,
  }

  export enum CategoryTypeMetadata {
    ARTICLES = 0,
    VIDEO = 1,
    FAQS = 2,
  }

  export namespace Language {
    export enum IDs {
      EL = 1,
      EN = 2,
      AR = 6,
      IT = 78,
    }

    export namespace Iso_Code {
      export const ar = "ar";
      export const en = "en";
    }
  }

  export namespace GET_STATS {
    export const UNREAD_MESSAGE: string = "unreadUserMessages";
  }

  export namespace DEFAULT_VIDEO {
    export const TIME_TO_SEE = 30;
  }

  export enum ECATEGORIES_FOR_NOTIFICATIONS {
    LEVEL_UPGRADE = 986,
    BONUS_POINTS = 987,
    NEW_EVENT = 988,
    REDEEMED_VOUCHER = 989,
  }

  export enum SOCIAL_IDS_POINTS {
    FACEBOOK = 109,
    INSTAGRAM = 700,
    TWITTER = 13026,
  }

  export enum NOTIFICATION_CATEGORIES {
    LEVEL = 986,
    BONUS = 987,
    NEW_EVENT = 988,
    REDEEMED_VOUTCHER = 989,
  }

  export namespace V_ITEMS {
    export const MAIN_CATEGORY = "1D337524-F54F-4012-B0D7-B828DC6B211B";
    export const REWARDS = "107DF854-B679-4B21-BDB2-0FBEA1C01B50";
    export const REWARDS_FOR_FREELANCERS =
      "EB7264D5-F41A-4918-B2BA-B2DC99510B54";
    export const INDY_LUCKY_DEAL = "2A5C2D7B-B8ED-4BA9-A127-03BA16630B3C";
  }

  export namespace Value_Pools {
    export const PERSONAL_DEVICE_OWNERSHIP: string =
      "714E6C5C-A90E-42EF-A9CA-E3A5CBC9E6A4";
    export const PERSONAL_EDUCATION: string =
      "B2F1B670-DA14-4CBC-89B1-ADF7BD62ADA7";
    export const PERSONAL_EMPLOYMENT: string =
      "2D268D33-F4B4-434C-A8CC-26D83FED36F1";
    export const PERSONAL_HOBBIES: string =
      "A5F6A081-23C4-4DA1-942C-3E1E5124C73C";
    export const PERSONAL_INCOME: string =
      "F28D33A2-88F0-4A14-8737-DD169501E988";
    export const PERSONAL_MATIRIAL_STATUS: string =
      "93926D18-7E2B-4816-E8FA-88C49EF47A90";
    export const NOTIFICATIONS_CATEGORIES: string =
      "70E7903D-6132-4BEB-B75C-A64D3FE98A5A";
    export const PERSONAL_FAVORITE_COLOR: string =
      "AB4DFBAD-ECDE-419E-8983-3EA49F4F2D10";
    export const PERSONAL_INTERESTS: string =
      "80F929D0-2CE5-4572-F8C6-A481264805E6";
    export const PERSONAL_JOB_SECTOR: string =
      "BE7A8B6B-36D5-4DBB-8F06-0914BA84E3C0";
    export const PERSONAL_JOB_PROFILE: string =
      "FB6B8B87-2E59-4A01-D807-A407F7D86A52";
    export const PERSONAL_EDUCATION_LEVEL: string =
      "9976615C-CB95-4DC8-8BF6-BA914645ABE0";
    export const PERSONALE_CHILDREN: string =
      "7DB84FE7-916B-4AEB-84C0-1E19CD882389";
  }

  export namespace Settings {
    export let MAGIC_KEY = "";
    export const API_VERSION = "v3";
    export const SERVER: string = ENV.URL;
    export const SERVER_V1: string = ENV.URL_V1;
    export const INTEGRATION_SERVER: string = ENV.INTEGRATION_SERVER;
    export const ADMIN_USERNAME: string = ENV.ADMIN_USERNAME;
    export const BASE_SERVER: string = ENV.BASE_URL;
    export const GAME_TYPE_ID: string = ENV.GAME_TYPE_ID;
    export const RESOURCE_SERVER: string = ENV.RESOURCE_SERVER;
    export const USERGROUP_ID: string = ENV.USERGROUP_ID;
    export const IT_COUNTRY_ID: string = "8A8A8081-16A7-7A31-0116-A77AA083000A";
    export const GAME_TYPE: string = ENV.GAME_TYPE_ID; // "197D316E-5CBA-4596-CAA6-6B78565789E4";
    export const APPLICATION_ID_IOS: string = ENV.APPLICATION_ID_IOS;
    export const APPLICATION_ID_ANDROID: string = ENV.APPLICATION_ID_ANDROID;
    export const APPLICATION_ID_PORTAL: string = ENV.APPLICATION_ID_PORTAL;
    export const APPLICATION_ID_REFERRAL: string = ENV.APPLICATION_ID_REFERRAL;
    export const APPLICATION_MAGIC_IOS: string = ENV.APPLICATION_MAGIC_IOS;
    export const APPLICATION_MAGIC_ANDROID: string =
      ENV.APPLICATION_MAGIC_ANDROID;
    export const APPLICATION_MAGIC_PORTAL: string =
      ENV.APPLICATION_MAGIC_PORTAL;
    export const VERSION_ID: string = ENV.VERSION_ID;
    export const TOKEN_EXPIRATION_SEC = 5000000;
    export const CATEGORY_VERSION = "stBasilsCategoryVersion";
    export const MAX_CONTENT_IMAGES = "maxContentImages";
    export const IQOS_CLUB_IT_SALES_ORDER_TYPE_ID =
      "72E28CC8-2B07-4ECF-B0B2-EC2E21DC7491";
    export const IQOS_CLUB_IT_DCS_ORDER_TYPE_ID =
      "92C5C692-DABD-4847-81BA-7AC8403FE6EA";
    export const IQOS_CLUB_IT_DCS_VERIFY_ORDER_TYPE_ID =
      "948479C1-1092-4E82-9037-F4E3D5A7CF8E";
    // export const COMMUNITY_STYLE_URL: string = ENV.community_style_url;
    export const PAGE_SIZE = 8;
    export const DEFAULT_PASSWORD: string = ENV.DEFAULT_PASSWORD; // "User1234";
    export const LANGUAGES: string[] = ["it"];
    export const DATE_FORMAT: string = "YYYY-MM-DD HH:mm:ss";
    export let ENCRYPTION_KEY: string = "AC37A27EF8391CD3AC37A27EF8391AAA";
    export let ENCRYPTION_IV: string = "TestPMIx87654321";
    // export const MINIMUM_LENGTH: number | string = ENV.PASSWORD_MIN_LENGTH * 1;
  }
  export namespace TAHeaders {
    export const APPLICATION_ID = "X-Talos-Application-Id";
    export const USER_GROUP_ID = "X-Talos-UserGroup-Id";
    export const TIMESTAMP = "X-Talos-Timestamp";
    export const SIGNATURE = "X-Talos-Signature";
    export const SESSION_ID = "X-Talos-Session-Id";
    export const LANGUAGE = "Accept-Language";
    export const Content_Type = "Content-Type";
    export const FILL_RESOURCES = "X-Talos-Fill-Resources";
    export const ITEM_COUNT = "X-Talos-Item-Count";
    export const TALOS_VERSION_ID = "X-Talos-Version-Id";
    export const GAME_TYPE_ID = "gametypeid";
    export const AUTHORIZATION = "Authorization";
    export const USERNAME = "X-Talos-Username";
    export const ENCRYPTED = "encrypted";
  }

  export namespace METADATA_KEY {
    export const COMMUNITY_ID = "Personal.CommunitySegment";
    export const ROLE_ID = "Personal.Iqon.role";
    export const PERSONAL_EMAIL = "Personal.email";
    export const USER_EXPERT_ID = "Personal.iqosexpertid";
    export const USER_PHONE = "Personal.mobileNumber";
    export const METADATA_KEY_ASSOCIATE_USER_ID = "Personal.associatedUserId";
    export const PERSONAL_NAME = "Personal.name";
    export const METADATA_KEY_CITY = "Personal.city";
    export const METADATA_KEY_STATE = "Personal.state";
    export const METADATA_KEY_CODE = "Personal.code";
    export const METADATA_KEY_POSTAL_CODE = "Personal.postalCode";
    export const METADATA_KEY_NOTE = "Personal.note";
    export const METADATA_KEY_REF_USER_ID = "refUserId";
    export const PERSONAL_SURNAME = "Personal.surname";
    export const REFERRAL_TYPE = "pmi.canadaReferrerType";
    export const SUBMIT_FORM_BODY = "email.body";
    export const SUBMIT_FORM_SUBJECT = "email.subject";
    export const SUBMIT_FORM_NAME = "campaign.user.name";
    export const METADATA_KEY_TERMS_ACCEPTANCE = "termsAcceptance";
    export const METADATA_KEY_PIN_VERIFIED = "Personal.pin.verified";
    export const METADATA_KEY_PERSONAL_OPTIN = "Personal.optin";
    export const PERSONAL_CODE = "Personal.code";
    export const VIEW_MASTER_DISPLAY_TYPE = "display.type";
    export const VIEW_MASTER_SHOW_PAGER = "showPage";
    export const VIEW_MASTER_COUNT = "count";
    export const VIEW_MASTER_VERSION = "version";
    export const VIEW_MASTER_DISPLAY_ONCE = "display.once";
    export const UPLOAD_CONTENT_DISPLAY_TYPE = "type";
    export const UPLOAD_CONTENT_UI_COMPONENT_TYPE = "UploadContent.type";
    export const PERSONAL_VALIDATE_EMAIL = "Personal.emailValidated";
    export const CATEGORY_TYPE = "CategoryType";
    export const DURATION = "duration";
    export const HAS_SEEN_THE_LEARNING_MATERIAL = "Personal.savegame";
    export const PERSONAL_BIRTHDAY: string = "Personal.birthday";
    export const UPLOAD_CONTENT_SHARE_OPTIONS: string =
      "UploadContent.shareOptions";
    export const UPLOAD_CONTENT_AUTHOR: string = "UploadContent.author";
    export const UPLOAD_CONTENT_PUBLISH_DATE: string =
      "UploadContent.publishDate";
    export const UPLOAD_CONTENT_REWARD_DAYS_LIMIT: string =
      "UploadContent.rewardDaysLimit";
    export const QUIZ_PUBLISH_DATE: string = "Quiz.publishDate";
    export const QUIZ_REWARD_DAYS_LIMIT: string = "Quiz.rewardDaysLimit";
    export const PERSONAL_DEVICE_OWNER_SHIP: string =
      "Personal.deviceOwnership";
    export const PERSONAL_EDUCATION: string = "Personal.education";
    export const PERSONAL_EMPLOYMENT: string = "Personal.employment";
    export const PERSONAL_HOBBIES: string = "Personal.hobbies";
    export const PERSONAL_INCOME: string = "Personal.income";
    export const PERSONAL_MATIRAL_STATUS: string = "Personal.maritalStatus";
    export const BRAND_PRODUCT_EXTERNAL_URL: string =
      "BrandProduct.externalUrl";
    export const BRAND_PRODUCT_COUNT_DOWN: string = "BrandProduct.countdown";
    export const GIFT_CARD_DEAL: string = "giftCardDeal";
    export const BRAND_PRODUCT_CATEGORY: string = "BrandProduct.category";
    export const BRAND_PRODUCT_AVAILABLE_COUPONS_THRESHOLD: string =
      "BrandProduct.availableCouponsThreshold";
    export const BRAND_PRODUCT_THEME: string = "BrandProduct.theme";
    export const BRAND_PRODUCT_IS_NOT_REDEEMABLE: string =
      "BrandProduct.isNotRedeemable";
    export const BRAND_PRODUCT_UNLIMITED_COUPONS: string =
      "BrandProduct.unlimitedCoupons";
    export const PERSONAL_CHILDREN: string = "Personal.children";
    export const PERSONAL_REFERRED_ON: string = "Personal.ReferredOn";
    export const PERSONAL_ID_NUMBER: string = "Personal.idNumber";
    export const PERSONAL_LAU_AMBASSADOR: string = "Personal.lau.ambassador";
    export const PERSONAL_FAVORITE_COLOR: string = "Personal.favoriteColor";
    export const PERSONAL_INTERESTS: string = "Personal.interests";
    export const PERSONAL_JOB_SECTOR: string = "Personal.jobSector";
    export const PERSONAL_JOB_PROFILE: string = "IqosClubIt.jobProfile";
    export const PERSONAL_EDUCATION_LEVEL: string = "Personal.educationLevel";
    export const PERSONAL_USER_DEFINED_NICK_NAME: string =
      "Personal.userDefinedNickName";
    export const PERSONAL_DYNAMIC_SETTINGS: string = "Personal.dynamicSettings";
    export const PERSONAL_BACKGROUND_IMAGE: string = "Profile.background.image";
    export const PERSONAL_ELIGIBLE_FOR_SPECIAL_CATALOGUE: string =
      "Personal.eligibleforspecialcatalogue";
    export const BRAND_PRODUCT_MAX_ALLOWER_PURCHASE_QUANTITY: string =
      "BrandProduct.maxAllowedPurchaseQuantity";
    export const BRAND_PRODUCT_REDEEM_OPTIONS: string =
      "BrandProduct.redeemOptions";
    export const BRAND_PRODUCT_COLOR: string = "BrandProduct.color";
    export const BRAND_PRODUCT_EXPIRATION_DAYS_MARGIN: string =
      "BrandProduct.expirationDaysMargin";
    export const BRAND_PRODUCT_EXTERNAL_SYSTEM: string =
      "BrandProduct.externalSystem";
    export const PERSONAL_PROMO: string = "Personal.promo";
    export const EVENT_ACHIEVEMENT_ACTION_ITEM_TYPE: string =
      "EventAchievementAction.itemItemType";
    export const BUNDLE_BRAND_PRODUCT: string =
      "BrandProduct.bundleBrandProduct";
    export const BRAND_PRODUCT_COUPON_PURCHASE_TYPE: string =
      "BrandProductCoupon.purchaseType";
    export const BRAND_PRODUCT_STORE_TYPE: string = "BrandProduct.storeType";
    export const QUIZ_DYNAMIC_QUESTION_ORDER: string =
      "Quiz.dynamicQuestionOrder";
    export const QUIZ_ANSWER_NEXT_QUESTION: string = "QuizAnswer.nextQuestion";
    export const VERSION: string = "version";
    export const SHOW_PAGE: string = "showPage";
    export const DISPLAY_ONCE: string = "display.once";
    export const DISPLAY_BUTTON: string = "display.button";
    export const DISPLAY_TYPE: string = "display.type";
    export const DISPLAY_SPECIAL_DEAL_BUTTON: string = "display.specialdeal";
    export const POPUP_DISPLAYED: string = "Popup.displayed";
    export const OPEN_POPUP: string = "openPopup";
    export const QUIZ_COMPLETED: string = "Quiz.completed";
    export const QUIZ_ILC_ILUMA_TYPE: string = "Quiz.Ilc.ilumaType";
    export const QUIZ_SHOW_CORRECT_ANSWERS: string = "Quiz.showCorrectAnswers";
    export const QUIZ_SHOW_RESULTS: string = "Quiz.showResults";
    export const QUIZ_QUESTION_ILC_FUNCTIONALITY_TYPE: string =
      "QuizQuestion.Ilc.functionalityType";
    export const QUIZ_QUESTION_SHOW_RESULTS: string =
      "QuizQuestion.showResults";
    export const QUIZ_QUESTION_FUNCTIONALITY_TYPE: string =
      "QuizQuestion.functionalityType";
    export const QUIZ_ANSWER_HIDDEN_ANSWER: string =
      "QuizAnswer.extraHiddenAnswer";
    export const COLOR: string = "color";
    export const TOURNAMENT_USER_VM_TRANSACTION_LIMITS: string =
      "tournament.userVMTransactionLimits";
    export const TOURNAMENT_TARGET_USER_VM_TRANSACTION_LIMITS: string =
      "tournament.targetUserVMTransactionLimits";
    export const TOURNAMENT_GAINED_USER_AMOUNT: string =
      "tournament.gainedUserAmount";
    export const TOURNAMENT_TARGET_USER_ID: string = "tournament.targetUserId";
    export const UPLOAD_CONTENT_REPLY_DATE: string = "UploadContent.replyDate";
    export const PROFILE_FIELDS_CONSENT: string = "profileFieldsConsent";
    export const PERSONAL_ADDRESS: string = "Personal.address";
    export const SALESFORCE_ACCOUNT_SOURCE: string = "Salesforce.accountSource";
    export const QUIZ_ILC_PRESENT_TYPE = "Quiz.ilc.presentType";
    export const QUIZ_CATEGORY_ID = "Quiz.categoryId";
    export const CATEGORY_MIN_LEVEL = "Category.minLevel";
    export const CATEGORY_MAX_LEVEL = "Category.maxLevel";
    export const QUIZ_QUESTION_PRESENTATION_TYPE: string =
      "QuizQuestion.presentationType";
    export const QUIZ_QUESTION_ILC_PRESENTATION_TYPE: string =
      "QuizQuestion.Ilc.presentationType";
    export const OWNED_ASSET_DESC_PROD_VERSIONS: string =
      "IqosClubIt.ownedAssetDescProdVersions";
    export const NUM_OF_KITS_PER_DEVICE_TYPE: string =
      "IqosClubIt.numOfKitsPerDeviceType";
    export const IS_FEARUTE: string = "isFeature";
    export const SALESFORCE_FIRST_PURCHASE_DATE: string =
      "Salesforce.firstPurchaseDate";
    export const QUIZ_REWARD_AMOUNT: string = "Quiz.rewardAmount";
    export const UPLOAD_CONTENT_REWARD_AMOUNT: string =
      "UploadContent.rewardAmount";
    export const GAME_REWARD_AMOUNT: string = "Game.rewardAmount";
    export const CONTENT_REWARD_AMOUNT: string = "Content.rewardAmount";
  }

  export namespace ROLE_IDS {
    export const GROUP = 930;
    export const CONSUMER_GODSON = 658;
  }

  export namespace APPLICATION_SETTING_KEYS {
    export const OTP_RESEND_SMS_TIME = "otpResendSMSTime";
    export const OTP_REQUEST_PIN_ERROR = "otpRequestPinError";
    export const NEGOZIO_FAQS_CATEGORY_ID = "negozioFaqsCategoryId";
    export const NEGOZIO_CATEGORY_ID = "negozioCategoryId";
    export const MOTIVATION_VANTAGGI_CATEGORY_ID = "vantaggiCategoryId";
    export const OVERVIEW_SLIDER_BOX_CATEGORY_ID =
      "overviewSliderBoxCategoryId";
    export const ALLOWED_ROLES_TO_CREATE_CONSUMER =
      "allowedRolesToCreateConsumer";
    export const CONSUMER_ROLE = "consumerRole";
    export const BREAND_ID = "brandId";
    export const REFERRAL_CUSTOMERS_ID = "";
    export const ALLOWED_USER_ROLES_TO_LOGIN = "allowedUserRolesTologin";
    export const CONFIGURATION_SETTINGS = "configurationSettings";
    export const COUNTRY_ID = "countryId";
    export const ASSIGN_BRAND_PRODUCT_ID = "assignBrandProductId";
    export const LIST_BRAND_PRODUCT_IDS = "listBrandProductIds";
    export const USER_MESSAGING_FOLDER = "userMessagingFolder";
    export const REWARDS_VERSION = "rewardsVersion";
    export const REWARDS_RESOURCES_VERSION = "rewardsResourcesVersion";
    export const REWARDS_DEALS_IDS = "rewardDealsIds";
    export const MY_REWARDS_DEALS_IDS = "myRewardDealsIds";
    export const GAME_TYPE_RESOURCES_VERSION = "gameTypeResourcesVersion";
    export const REWARDS_VITEM_CATEGRY = "rewardsVitemCategoryId";
    export const REWARDS_VITEM_SUBCATEGORY = "rewardsVitemSubCategoryId";
    export const COMMON_SETTINGS_FOR_ROLES = "commonSettingsForRoles";
    export const SETTINGS_BY_ROLE = "settignsByRole";
    export const PURCHASE_COUPONS = "purchaseCoupons";
    export const CS_COUPONS_CONFIG = "csCouponsConfig";
    export const PMI_COUPONS_CONFIG = "pmiCouponsConfig";
    export const COUPON_REDEEM_REWARDS_CONFIG = "couponRedeemRewardsConfig";
    export const VERSION = "version";
    export const REFERRAL_TYPE_VERSION = "referralTypeVersion";
    export const FAQS_CATEGORY_ID = "faqCategoryId";
    export const REFERRAL_TYPE_ID = "referralTypeId";
    export const FAQS_VERSION = "faqsVersion";
    export const ASSIGN_ROLE_ON_REGISTER = "assignRoleOnRegister";
    export const ASSIGN_ROLE_ON_REFFERAL = "assignRoleOnReferral";
    export const CHECK_EMAIL_ON_REFERRAL = "checkEmailOnReferral";
    export const REFER_TYPES = "referTypes";
    export const COUPONS_IDS_TO_EDIT_EXPERT_ID = "CouponIdsCanEditExpertId";
    export const ALLOWED_USERS_TO_EDIT_EXPERT_ID = "AllowedUsersToEditExpertId";
    export const PREVENTED_PASSWORDS = "preventedPasswords";
    export const PASSWORD_COMPLEXITY = "passwordComplexity";
    export const VIEW_MASTER_CATEGORY_VERSION = "viewMasterCategoryVersion";
    export const VIEW_MASTER_CATEGORY_RESOURCES_VERSION =
      "viewMasterCategoryResourcesVersion";
    export const SSO_LOGIN = "ssoLogin";
    export const TERMS_ACCEPTANCE = "termsAcceptance";
    export const PROMO_PAGE = "PromoSettings";
    export const WELCOME_PAGE = "WelcomeSettings";
    export const RE_ASSIGNED = "reAssigned";
    export const APPSETTING_CONFIG_KEY = "optinMetadataConfiguration";
    export const ASSIGNED_ROLE_ON_REFER = "assignedRoleOnRefer";
    export const EXCLUDE_MOBILE_NUMBERS = "excludeMobileNumbers";
    export const DEFAULT_PASSWORD = "defaultPassword";
    export const TERMS_ACCEPT_PROFILING_ID = "termsAcceptProfilingId";
    export const CATEGORIES_VERSION = "categoriesVersion";
    export const CATEGORIES_RESOURCES_VERSION = "categoriesResourcesVersion";
    export const UPLOAD_CONTENT_VERSION = "uploadContentVersion";
    export const UPLOAD_CONTENT_RESOURCES_VERSION =
      "uploadContentResourcesVersion";
    export const PROMO_CATEGORY_ID = "categoryId";
    export const LEARNING_MATERIAL = "learningMaterialId";
    export const SIDE_MENU_CATEGORY_ID = "sidemenuId";
    export const RESOURCES_VERSION = "MetadataResourcesVersion";
    export const LEARNING_MATERIAL_CATEGORY_VERSION = "learningMaterialVersion";
    export const LEARNING_MATERIAL_CATEGORY_RESOURCES =
      "learningMaterialResourcesVersion";
    export const SIDE_MENU_CATEGORY_VERSION = "sideMenuCategoryVersion";
    export const SIDE_MENU_CATEGORY_RESOURCES_VERSION =
      "sideMenuCategoryResourcesVersion";
    export const ACTION_CONSTRAIN_ID = "actionConstraintId";
    export const LEARNING_UPLOAD_CONTENT_VERSION =
      "learningUploadContentVersion";
    export const LEARNING_UPLOAD_CONTENT_RESOURCES_VERSION =
      "learningUploadContentResourcesVersion";
    export const ACTION_CONSTRAINT_TYPE_ID = "actionConstraintTypeId";
    export const DUMMY_EMAILS_PASTERNS = "dummyEmails";
    export const EVENT_TYPE_VALIDATE_EMAIL = "eventTypeIdValidateEmail";
    export const CATEGORY_ROOT_ID_LEARN_ABOUT_IQOS =
      "categoryRootIdLearnAboutIqos";
    export const LEARNING_MATERIAL_LIST_VERSION = "learningMaterialListVersion";
    export const LEARNING_MATERIAL_LIST_RESOURCES_VERSION =
      "learningMaterialListResourcesVersion";
    export const ACTIVE_DIRECTORY = "activeDirectory";
    export const WATCHED_THRESHOLD = "watchedThreshold";
    export const USER_EVENT_ACHIEVEMENTS = "userEventAchievementIds";
    export const IQOS_MONEY_UP_CATEGORY = "iqosMoneyUpperCategoryId";

    export const IQOS_COMPONENT_CONFIG = "iqosComponentConfig";
    export const MATERIAL_CONFIG = "materialConfig";
    export const MATERIAL_CATEGORY_ID = "materialCategoryId";
    export const MATERIAL_TYPE_IDS = "materialTypeIds";
    export const MATERIAL_FILTER_CATEGORY_ID = "materialFilterCategoryId";
    export const MATERIAL_FILTER_TYPE_IDS = "materialFilterTypeIds";
    export const MATERIAL_QUIZ_MAIN_CATEGORY_ID = "materialQuizMainCategoryId";
    export const MATERIAL_QUIZ_CONTENT_CATEGORY_ID =
      "materialQuizContentCategoryId";

    export const DONATION_HIDE = "donationHide";
    export const PREVIEW_USER_ID = "previewUserId";

    export const REWARDS_CONFIG = "rewardsConfig";
    export const PROFILE_CONFIG = "profileConfig";
    export const REWARDS_FILTER_CATEGORY_ID = "rewardsFilterCategoryId";
    export const REWARDS_TAGS_CATEGORY_ID = "rewardsTagsCategoryId";
    export const REWARDS_CALL_CENTER_CATEGORY_ID =
      "rewardsCallCaenterCategoryId";
    export const REWARDS_PROMO_CATEGORY_ID = "rewardsPromoCategoryId";
    export const REWARDS_STORE_PROMO_CATEGORY_ID =
      "rewardsStorePromoCategoryId";
    export const REWARDS_REFERRAL_PROMO_CATEGORY_ID =
      "rewardsReferralPromoCategoryId";
    export const REWARDS_FILTER_TYPE_IDS = "rewardsFilterTypeIds";
    export const REWARDS_WITH_DATES = "rewardsWithDateCategoryIds";
    export const REWARD_TICKET_LIMIT = "numOfAvailableCouponsWarnLimit";
    export const REWARD_WITH_WARNING = "rewardsWithWarningCategoryIds";
    export const EXPIRATION_DAY_POINTS: string = "expirationDayPoints";
    export const EXPIRATION_DAY_RANK: string = "expirationRankPoints";
    export const BENEFITS_CATEGORY_ID: string = "benefitsCategoryId";
    export const BENEFITS_FOOTER_ID: string = "benefitsFooterCategoryId";
    export const BANNERS_CATEGORY_ID: string = "bannersCategoryId";
    export const REWARDS_BANNERS_CATEGORY_ID: string =
      "rewardsBannersCategoryId";
    export const HIDE_BENEFITS_TAB = "hideBenefitsTab";
    export const PREDEFINED_TO_HIDE = "predefinedToHide";
    export const BANNERS_PERSONALIZATION_CATEGORY_ID: string =
      "bannersPersonalizationCategoryId";
    export const OVERVIEW_VIDEO_CATEGORY_ID: string = "overviewVideoCategoryId";
    export const OVERVIEW_COMMUNITY_ID: string = "overviewCommunityCategoryId";
    export const OVERVIEW_REFERRAL_ID: string = "overviewReferralCategoryId";
    export const OVERVIEW_MOTIVATION_ID: string =
      "overviewMotivationCategoryId";
    export const OVERVIEW_CONFIGURATOR_ID: string =
      "overviewConfiguratorCategoryId";
    export const OVERVIEW_REFERRAL: string = "overviewReferral";
    export const OVERVIEW_DONATION_ID: string = "overviewDonationCategoryId";
    export const IQOS_MONEY_CATEGORY_ID: string = "iqosMoneyCategoryId";
    export const REWARDS_ADDITIONAL_CATEGORY_ID: string =
      "rewardsAdditionalCategoryIds";
    export const SHARER_URL: string = "sharer_url";
    export const REWARDS_LIMITED_CATEGORY_ID: string =
      "rewardLimitedCategoryId";
    export const REWARDS_STORE_CATEGORY_ID: string = "rewardsStoreCategoryId";
    export const REWARDS_OLD_STORE_CATEGORY_ID: string =
      "rewardsOldStoreCategoryId";
    export const REWARDS_MISSION_CATEGORY_ID: string =
      "rewardsMissionCategoryId";
    export const REWARDS_CONFIGURATOR_CATEGORY_ID: string =
      "rewardsConfiguratorCategoryId";
    export const REWARDS_CONFIGURATOR_LIL_CATEGORY_ID: string =
      "rewardsConfiguratorLilCategoryId";

    export const REWARDS_STORE_MISSION_CATEGORY_ID: string =
      "rewardsMissionStoreCategoryId";
    export const OVERVIEW_SLIDE_TIMER: string = "overviewSlideTimer";
    export const OVERVIEW_DISABLE_PERSONALIZATION: string =
      "overviewDisablePersonalization";

    export const TOP_BANNER_CONFIG: string = "topBannerConfig";

    export const INVITE_CONFIG = "referConfig";
    export const INVITE_ASSIGN_DEAL_ID = "assignDealId";
    export const INVITE_ASSIGN_GROUP_DEAL_ID = "assignGroupDealId";
    export const INVITE_ASSIGN_DEALS = "assignDeals";
    export const INVITE_ASSIGN_REFER_USER_ROLE = "referUserRole";
    export const CONVERSION_RATE = "conversionRate";
    export const TERMS_URL = "termsUrl";

    export const SLIDE_SHOW_CONFIG = "slideShowConfig";
    export const GALLERY_CONFIG = "galleryConfig";
    export const GALLERY_CATEGORY_ID = "galleryCategoryId";
    export const GALLERY_FILTER_CATEGORY_ID = "galleryFilterCategoryId";

    export const WEB_COMPONENT_CONFIFS_CATEGORY_ID =
      "webComponentConfigsCategoryId";
    export const APPLICATION_PAGES_ROOT_CATEGORY_ID =
      "applicationPagesRootCategoryId";
    export const QUIZ_TIME_BETWEEN_QUESTIONS = "quizTimeBetweenQuestions";
    export const TRANSACTION_EVENT_TYPES_TO_DISABLE =
      "transactionEventTypesToDisable";

    export const PROMO_MATERIAL = "promoMaterial";

    export const PROMO_POPUP_CATEGORY_ID = "promoPopupCategoryId";
    export const PROMO_NO_BOUGHT_POPUP_CATEGORY_ID =
      "promoNoBoughtPopupCategoryId";
    export const PROMO_NO_BOUGHT_REFERRAL_POPUP_CATEGORY_ID =
      "promoNoBoughtReferralPopupCategoryId";
    export const DONATE_CATEGORY_ID = "donateCategoryId";
    export const BACKGROUND_CATEGORY_ID = "backgroundCategoryId";
    export const WELCOME_CATEGORY_ID = "welcomeCategoryId";
    export const TOP_BANNER_CATEGORY_ID = "topBannerCategoryId";
    export const DONATE_BUTTONS = "donateButtons";
    export const COUNT_DOWN_POPUP_CATEGORY_ID = "countDownPopupCategoryId";
    export const SCANNED_CODE_POPUP_CATEGORY_ID = "scannedCodePopupCategoryId";
    export const MAIN_BANNER_CATEGORY_ID = "mainBannerCategoryId";
    export const COUNT_DOWN_BANNER_CATEGORY_ID = "countDownBannerCategoryId";
    export const REFER_BENEFIT_CATEGORY_ID = "referBenefitCategoryId";
    export const NOTIFICATION_CATEGORY_ID = "notificationCategoryId";
    export const MOTIVATION_CATEGORIES = "motivationCategories";
    export const MOTIVATION_UPLOAD_CONTENTS_CATEGORY_ID =
      "uploadContentsCategoryId";
    export const MOTIVATION_SLIDE_CATEGORY_ID = "slideCategoryId";
    export const MOTIVATION_BOTTOM_CONTENTS_CATEGORY_ID =
      "bottomContentsCategoryId";
    export const MOTIVATION_QUIZ_CATEGORY_ID = "quizCategoryId";

    export const SUSTAINABILITY_CONFIG = "sustainabilityConfig";
    export const SUSTAINABILITY_HOME_VIDEO = "sustainabilityHomeVideo";
    export const SUSTAINABILITY_PERSONE_CATEGORY_ID =
      "sustainabilityPersoneCategoryId";
    export const SUSTAINABILITY_AMBIENTE_CATEGORY_ID =
      "sustainabilityAmbienteCategoryId";
    export const SUSTAINABILITY_SLUG_TO_CATEGORY_ID =
      "sustainabilitySlugToCategoryId";

    export const COSE_IQOS_CONFIG = "coseIQOSConfig";
    export const COSE_IQOS_VIDEO = "coseIQOSVideo";
    export const COSE_IQOS_HEADER = "coseIQOSHeader";
    export const COSE_IQOS_EARN_POINTS_CATEGORY_ID =
      "coseIQOSEarnPointsCategoryId";
    export const COSE_IQOS_CAROUSEL_CATEGORY_ID = "coseIQOSCarouselCategoryId";
    export const COSE_IQOS_CATALOG_CATEGORY_ID = "coseIQOSCatalogCategoryId";
    export const COSE_IQOS_INITIATIVES_CATEGORY_ID =
      "coseIQOSInitiativesCategoryId";
    export const COSE_IQOS_TESTIMONIALS_CATEGORY_ID =
      "coseIQOSTestimonialsCategoryId";
    export const INVITE_PAGE_VIDEO_CATEGORY_ID = "invitePageVideoCategoryId";
    export const SLUG_TO_CATEGORY_ID = "slugToCategoryId";
    export const STATIC_CONTENT_CATEGORY_ID = "staticContentCategoryId";
    export const QUICK_REPLY_CATEGORY_ID = "quickReplyCategoryId";

    export const REWARDS_VOUCHER_CATEGORY_IDS = "rewardVoucherCategoryIds";
    export const REWARDS_SEGREGATED_CATEGORY_IDS =
      "rewardSegregatedCategoryIds";

    export const INVITE_FRIENDS_DARK = "inviteFriendsDark";
    export const STORE_TYPE_ID_TO_VALUE = "storeTypeIdToValue";

    export const ILUMA_GET_ILUMA_CONFIG = "ilumaGetIlumaConfig";
    export const ILUMA_GET_ILUMA_HEADER_CATEGORY_ID =
      "ilumaGetIlumaHeaderCategoryId";
    export const ILUMA_GET_ILUMA_CATEGORY_ID = "ilumaGetIlumaCatalogCategoryId";
    export const ILUMA_GET_ILUMA_TESTIMONIALS_CATEGORY_ID =
      "ilumaGetIlumaTestimonialsCategoryId";
  }

  export namespace APPLICATION_SETTING_SCOPE_TYPES {
    export const APPLICATION_SETTING_SCOPE_TYPE_GAMETYPE = 2;
    export const APPLICATION_SETTING_SCOPE_TYPE_APPLICATION = 4;
  }

  export namespace IMAGE_TYPE {
    export const JPG = "jpg";
    export const PNG = "png";
    export const GIF = "gif";
  }

  export namespace PRODUCT_STATUS {
    export const EXPIRED = "5EA59325-1DD1-4B71-AFC3-D84B0B1D908E";
    export const IN_REVIEW = "5FE039DF-3C64-4120-AB5F-AC2AFF299863";
    export const IN_STOCK = "5EA59325-1FF1-4B71-AFC3-D84B0B1D908E";
    export const NEW = "08710CDB-29C6-4649-9D1A-07CE05BB1B91";
    export const OUT_OF_STOCK = "D58D5AA9-63E2-4BAC-8A45-EFCA3A1BE509";
    export const PUBLISHED = "55FD490E-EBA9-4EFC-ECCD-98319CDACEBC";
    export const UNPUBLISHED = "17F311A5-59E1-4696-A445-2ACEEC5B2614";
  }

  export namespace COUPON_STATUSES {
    export const AVAILABLE = 1;
    export const PURCHASED = 2; // assigned to user; available to send as gift
    export const REDEEMED = 3;
    export const CANCELLED = 4;
    export const EXPIRED = 5; // assigned to user; has not been sent as gift until expired
    export const VERIFIED = 6;
    export const RESERVED = 7;
    export const EXPIRED_YELLOWS_CHARGED_BACK = 8;
    export const CANCELLED_CHARGED_BACK = 9;
    export const REDEEMED_CLEARED = 10;
    export const CANCELLED_CLEARED = 11;
    export const EXPIRED_REQUESTED_CHARGE_BACK = 12;
    export const EXPIRED_CLEARED = 13;
    export const EXPIRED_YELLOWS_CHARGE_BACK_FAILED = 14;
    export const EXPIRED_CLEARED_FAILED = 15;
    export const REDEEMED_CLEARED_FAILED = 16;
    export const EXPIRED_GIFTCARD = 17;
    export const PURCHASED_SENT_AS_GIFT = 18;
    export const PURCHASED_SENT_AS_GIFT_NOT_NTERESTED = 19;
    export const PURCHASED_SENT_AS_GIFT_NOT_FOUND = 20;
    export const PURCHASED_SENT_AS_GIFT_LENDED = 21;
    export const REDEEMED_PENDING_DELIVERY = 22;
  }

  export namespace BRAND {
    export const BRAND_ID = "6F319B40-BE98-4995-8588-4C29500D44C8";
  }

  export namespace BRAND_STORES {
    export const BRAND_STORE_1 = "1C22DA6A-1A2F-413D-BDE8-6ECCFDA3A60F2";
    export const BRAND_STORE_2 = "C5ED80C4-E7AF-40D5-D392-36A99369C2EC";
  }

  export namespace BRAND_PRODUCTS {
    export const FRIEND_PLUS_CAN_IQOS_COUPON =
      "10F2BEFF-32C9-4655-F209-37440593AF73";
    export const FRIEND_PLUS_CAN_IQOS_FREELANCERS =
      "398BBA27-09D6-4DF7-FDE8-1D8BC113506D";
    export const FRIEND_PLUS_CAN_IQOS_REWARD =
      "75D033AD-5BA0-497C-80B3-1EA0A1CF410E";
    export const FRIEND_PLUS_CAN_IQOS_REWARD_FOR_FREELANCERS =
      "BE0D32ED-BA35-4920-D83F-2B8344C63340";
    export const FRIEND_PLUS_CAN_IQOS_HEETS =
      "EC089207-2240-4CB6-DA62-526180A41752";
  }

  export namespace REFERRAL_TYPES {
    export const IQOS_CLUB_ITALY_COUPON_LEAD =
      "9B0E52C5-C266-4C6A-BDB9-8A0387903074";
    export const MGM_UNICODE = "48E63906-2B58-4628-8000-5038B7A49E9C";
    export const LENDING_REFERRAL = "735343AB-AA7E-4B13-B285-0C1182E3FD90";
    export const IQOS_CLUB_ITALY_COUPON_LEAD_ONE_TO_MANY =
      "4B5A1630-20AC-4C22-A5DD-F51931BDB971";
    export const IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB =
      "6EF54FBD-B3CD-44F3-9875-3031893DE644";
    export const IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING =
      "9916DFBD-F3B5-4957-BCFA-2DB0EAC7ADB4";
    export const IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING_ONE_TO_MANY =
      "0017A589-798A-4E15-B477-444FEE0DB023";
    export const IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB_ONE_TO_MANY =
      "0FD69234-0E5F-41F2-A93E-61614F77215F";
    export const LIL = "511AD0EE-9B48-46F8-9BF5-AA312073CA08";
    export const CLUB_INVITE_REFERRAL = "901B49CC-8A30-4D04-B4BC-69BB2FA39B8F";
    export const ILUMA_GET_ILUMA = "0C80BA08-A5A5-4E56-AE7E-8D8FE37CCBC8";
  }

  export namespace BRAND_PRODUCT_TYPES {
    export const DEALS = "43C0B40B-CA17-423E-98B8-F43461932010";
    export const BENEFITS = "A8C4DC8D-C629-4815-B657-41F84CFB34AC";
  }

  export namespace ORDER_TYPE {
    export const BY_ORDERING_DESC = "BY_ORDERING_DESC";
    export const BY_RECENT = "BY_RECENT";
    export const BY_OLDER = "BY_OLDER";
    export const BY_TOP_RATED = "BY_TOP_RATED";
    export const BY_PROXIMITY = "BY_PROXIMITY";
    export const BY_ORDERING_ASC = "BY_ORDERING_ASC";
    export const BY_NAME = "BY_NAME";
    export const BY_PRICE_ASC = "BY_PRICE_ASC";
    export const BY_PRICE_DESC = "BY_PRICE_DESC";
    export const BY_NOT_VIEWED = "BY_NOT_VIEWED";
  }

  export namespace QUIZ_TYPES {
    export const QUIZ = 2;
    export const SURVEY = 3;
    export const CONTENT_QUIZ = 13;
  }

  export namespace QUIZ_STATUS {
    export const STARTED = 1;
    export const FINISHED = 2;
    export const WIN = 3;
    export const LOSE = 4;
  }

  export namespace TOURNAMENT_TYPES {
    export const COMPETITION = 1;
    export const INTERNAL = 4;
  }
  export namespace ITEM_TYPES {
    export const BRAND_PRODUCT = 107;
    export const BRAND_PRODUCT_COUPON = 121;
    export const BRAND_STORE = 108;
    export const GAME_TYPE = 3;
    export const CUSTOM_USER_PROFILE = 56;
    export const VIRTUAL_ITEM = 27;
    export const PREDEFINED_VALUE = 78;
    export const UPLOAD_CONTENT = 143;
    export const QUIZ = 53;
    export const USER_CAMPAIGN = 60;
    export const BRAND_PRODUCT_TRANSACTION_LIMIT = 225;
    export const REFERRAL = 210;
    export const MOB_RPS_ASSETS = 10;
    export const ACHIEVEMENT_TYPE = 47;
  }
  export namespace MESSAGE_STATUS {
    export const MESSAGES_READ_STATUS = 1;
    export const MESSAGES_COLOR_STATUS_READ = "white";
  }

  export namespace CART_STATUS {
    export const PENDING = 1;
    export const SUBMITTED = 2;
    export const CANCELLED_BY_CUSTOMER = 3;
    export const CANCELLED_BY_SYSTEM = 4;
    export const PROCESSING = 5;
    export const REVISED = 6;
    export const COMPLETED = 7;
    export const PARTIALLY_COMPLETED = 8;
    export const PUSHED_TO_EXTERNAL_SYSTEM = 9;
  }

  export namespace STATISTIC_TYPES {
    export const ITEM_AWARDED = 14;
    export const COUPON_OWNED_SEND_AS_GIFT = 18;
    export const COUPON_SEND_AS_GIFT = 19;
    export const ITEM_VIEWED = 22;
    export const ITEM_WISHLIST_ADDED = 1;
    export const ITEM_WISHLIST_REMOVED = 2;
    export const ITEM_DISLIKE = 3;
    export const ITEM_PURCHASED = 4;
    export const COUPON_RATED = 5;
    export const MERCHANT_RATED = 6;
    export const FAVORITES_ADDED = 7;
    export const FAVORITES_REMOVED = 8;
    export const ITEM_LIKE = 9;
    export const ITEM_RATED = 10;
    export const ITEM_COMMENT_ADDED = 11;
    export const BRAND_STORE_PER_COUPON_RATED = 12;
    export const BRAND_PRODUCTS_PER_BRAND_STORE = 13;
    export const ITEM_TOTAL_RATINGS = 15;
    export const VIDEO_WATCHED = 24;
  }

  export namespace HttpMethods {
    export const POST = "POST";
    export const GET = "GET";
    export const DELETE = "DELETE";
    export const OPTIONS = "OPTIONS";
    export const HEAD = "HEAD";
    export const PATCH = "PATCH";
    export const PUT = "PUT";
  }

  export namespace SALES_ORDER_PROCESS_MODES {
    export const VALIDATE = "VALIDATE";
    export const RESERVE = "RESERVE";
    export const INCLUDE_DETAILS = "INCLUDE_DETAILS";
  }

  export namespace SALES_ORDER_DETAILS_STATUSES {
    export const INSERTED = 1;
    export const ORDERED = 2;
    export const RESERVED = 3;
    export const CANCELED_BY_USER = 5;
  }

  export namespace SALES_ORDER_STATUSES {
    export const PENDING = 1;
    export const SUBMITTED = 2;
    export const CANCELLED_BY_CUSTOMER = 3;
    export const CANCELLED_BY_SYSTEM = 4;
    export const PROCESSING = 5;
    export const REVISED = 6;
    export const COMPLETED = 7;
    export const PARTIALLY_COMPLETED = 8;
    export const EXPIRED = 9;
  }
}

export namespace APP_CONSTANTS {
  export const CARD_ANIMATION_DELAY: number = 130;

  export const CARD_ANIMATION_STEP: number = 80;

  export const DEFAULT_PAGING_STEP: number = 6;
  export const DEFAULT_MATERIAL_PAGING_STEP: number = 6;
}

export namespace TAValues {
  export let MAGIC_KEY: string; // = '44235E58-F717-4E85-F232-EEADE05595A4';
  export let APPLICATION_ID: string; // = '44235E58-F717-4E85-F232-EEADE05595A4';
  export let CLIENT_TYPE_ID: TAConstants.Client_Types =
    TAConstants.Client_Types.PORTAL;
  export let UserId: string; // = 'e5c6108d-93d1-4696-a150-3f2cf3ade33f';//'e57dade0-c2d3-4f1a-b628-8dbdfb87eef2';
  export let UserSessionId: string; // = '21d6e46f-1906-4c79-a1c5-0e70cac3ad22';//'34903527-55dd-469f-a17e-1c467b674ca6';
  export let LANGUAGE_ISO_CODE: string = ENV.DEFAULT_ISO_CODE;
  export let LANGUAGE_ID: TAConstants.Language.IDs | string =
    ENV.DEFAULT_LANG_ID;
  export let APPLICATION_VERSION: string = ENV.APPLICATION_VERSION;
  export let INACTIVE_REDIRECTION_BASE: string = ENV.INACTIVE_REDIRECTION_LINK;
  export let COUPON_REDIRECTION_LINK: string = ENV.COUPON_REDIRECTION_LINK;
  export let COUPON_IQOS_STORE_REDIR_LINK: string =
    ENV.COUPON_IQOS_STORE_REDIR_LINK;
  export let BOUTIQUE_STORE_REDIR_LINL: string = ENV.FIND_STORE_LINK;
  export let COUPON_IQOS_REDIR_LINK: string = ENV.COUPON_IQOS_REDIR_LINK;
  export let CART_MAP_LINK: string = ENV.CART_MAP_LINK;
  export let STORES_MAP_LINK: string = ENV.STORES_MAP_LINK;
  export let CONFIGURATION_LINK: string = ENV.CONFIGURATION_LINK;
  export let CONFIGURATION_ORIGIN: string = ENV.CONFIGURATION_ORIGIN;
  export let INACTIVE_REDIRECTION_LINK: string;
}
