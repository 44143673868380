import { Injectable } from "@angular/core";
import { CategoryService } from "../../../../services/talos/category.service";
import { UploadContentsService } from "../../../../services/talos/uploadContents.service";
import { AppGlobalsService } from "../../../../services/appGlobals.service";
import { CategoryDTO } from "../../../../talosApi/models/CategoryDTO";
import { TAConstants, TAValues } from "../../../../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import { UploadContentDTO } from "../../../../talosApi/models/UploadContentDTO";
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import { QuizDTO } from "../../../../talosApi/models/QuizDTO";
import { QuizzesService } from "../../../../services/talos/quizzes.service";
import { AnswerQuestionInput } from "../../../../talosApi/models/AnswerQuestionInput";
import { StartQuizInput } from "../../../../talosApi/models/StartQuizInput";
import { QuestionDTO } from "../../../../talosApi/models/QuestionDTO";
import { AnswerQuestionsInput } from "../../../../talosApi/models/AnswerQuestionsInput";
import { UserAnswerDTO } from "../../../../talosApi/models/UserAnswerDTO";
import { EventService } from "../../../../services/talos/event.service";
import Settings = TAConstants.Settings;
import Events_Types = TAConstants.Events_Types;
import Order_Stores = TAConstants.Order_Stores;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import { EventRewardRulesDTO } from "../../../../talosApi/models/EventRewardRulesDTO";
import { ResourcesServices } from "../../../../services/talos/resources.services";
import { ListQuizQuery } from "../../../../talosApi/api/QuizApi";
import { AnswerDTO } from "../../../../talosApi/models/AnswerDTO";
import { AnswerQuestionResponse } from "../../../../talosApi/models/AnswerQuestionResponse";
import {
  IEvaluateEventQuery,
  IEventInput,
} from "../../../../talosApi/api/EventApi";
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Unit_Types = TAConstants.Unit_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import { GetUserActionStatusInput } from "../../../../talosApi/models/GetUserActionStatusInput";
import QUIZ_TYPES = TAConstants.QUIZ_TYPES;
import { ItemService } from "../../../../services/talos/item.service";
import { UtilsService } from "src/services/utils.service";
import { ReferenceService } from "src/services/talos/reference.service";
import { IReferenceMetadataDTO } from "src/talosApi/models/IReferenceMetadataDTO";
import { FilterESContentDataInput } from "src/talosApi/api/RecommendationsApi";
import { RecommendationsService } from "src/services/talos/recommendations.service";
import { IndexedDataContent } from "src/talosApi/models/IndexedDataContent";

@Injectable()
export class QuizService {
  constructor(
    private quizzesSrv: QuizzesService,
    private eventSrv: EventService,
    private resourcesSrv: ResourcesServices,
    private itemSrv: ItemService,
    private utilsSrv: UtilsService,
    private referenceSrv: ReferenceService,
    private globals: AppGlobalsService,
    private recommendationsSrv: RecommendationsService
  ) {}

  getQuizById(query: ListQuizQuery): Promise<QuizDTO> {
    return new Promise((resolve, reject) => {
      // const query: ListQuizQuery = {
      //     quizIds: [quizId],
      //     gameTypeId: Settings.GAME_TYPE,
      //     includeQuestions: true,
      //     rangeFrom: 0,
      //     rangeTo: 1,
      //     metadatas: true,
      //     resources: true,
      //     languageIds: TAConstants.Settings.LANGUAGES
      // };

      const valuePoolIdToId = this.globals.config.valuePoolIdToId;
      this.quizzesSrv
        .listQuizNew(query, false)
        .then(async (result: Array<QuizDTO>) => {
          if (result && result.length > 0) {
            const quiz = result[0];

            if (quiz.metadata) {
              quiz.isIluma =
                this.utilsSrv.valueFromKeyValueDTOArray(
                  METADATA_KEY.QUIZ_ILC_ILUMA_TYPE,
                  quiz.metadata
                ) == "true";
              quiz.showResults =
                this.utilsSrv.valueFromKeyValueDTOArray(
                  METADATA_KEY.QUIZ_SHOW_RESULTS,
                  quiz.metadata
                ) == "true";

              quiz.showCorrectAnswers = this.utilsSrv.valueFromKeyValueDTOArray(
                METADATA_KEY.QUIZ_SHOW_CORRECT_ANSWERS,
                quiz.metadata
              );
            }
            if (quiz.questions && quiz.questions.length > 0) {
              quiz.questions.forEach((q) => {
                logger.log(q);
                if (q.metadata && valuePoolIdToId) {
                  const presentationType =
                    this.utilsSrv.valueFromKeyValueDTOArray(
                      METADATA_KEY.QUIZ_QUESTION_ILC_PRESENTATION_TYPE,
                      q.metadata
                    );
                  if (presentationType && presentationType != "")
                    q.presentationType = valuePoolIdToId[presentationType];

                  const functionalityType =
                    this.utilsSrv.valueFromKeyValueDTOArray(
                      METADATA_KEY.QUIZ_QUESTION_ILC_FUNCTIONALITY_TYPE,
                      q.metadata
                    );
                  if (functionalityType && functionalityType != "")
                    q.functionalityType = valuePoolIdToId[functionalityType];

                  const showResults = this.utilsSrv.valueFromKeyValueDTOArray(
                    METADATA_KEY.QUIZ_QUESTION_SHOW_RESULTS,
                    q.metadata
                  );
                  if (showResults && showResults != "")
                    q.showResults = showResults == "true";
                }
                if (q.answers && q.answers.length > 0) {
                  q.answers.forEach((a) => {
                    a.extraHiddenAnswer =
                      this.utilsSrv.valueFromKeyValueDTOArray(
                        METADATA_KEY.QUIZ_ANSWER_HIDDEN_ANSWER,
                        a.metadata
                      );
                  });
                }
              });
            }

            const input: GetUserActionStatusInput = {
              itemIds: [quiz.id],
              itemTypeId: ITEM_TYPES.QUIZ,
              unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
              includeLimitations: true,
            };

            const actions = await this.itemSrv
              .getUserActions(
                quiz.quizType == QUIZ_TYPES.SURVEY
                  ? Events_Types.POLL_SURVEY_COMPLETED
                  : quiz.quizType == QUIZ_TYPES.QUIZ
                  ? Events_Types.QUIZ_COMPLETION
                  : Events_Types.CONTENT_QUIZ_COMPLETION,
                null,
                input
              )
              .catch((err) => {});
            if (actions) {
              actions.forEach((a) => {
                if (
                  a.itemId == quiz.id &&
                  a.actionsStatus &&
                  a.actionsStatus.length > 0
                ) {
                  quiz.actionsStatus = a.actionsStatus[0];
                }
              });
            }

            // const events = [Events_Types.QUIZ_COMPLETION, Events_Types.POLL_SURVEY_COMPLETED];
            //
            // const query: IEvaluateEventQuery= {
            //     itemIds: [quizId],
            //     itemTypeId: ITEM_TYPES.QUIZ,
            //     resources: true,
            //     metadatas: true,
            //     languageIds: TAConstants.Settings.LANGUAGES};
            //
            // const promises = events.map(event=>{
            //     const input: IEventInput = {
            //         userId: TAValues.UserId,
            //         eventTypeId: event,
            //         gameTypeId: Settings.GAME_TYPE,
            //         clientTypeId: TAValues.CLIENT_TYPE_ID,
            //         withNoRewards: false,
            //         applicationId: TAValues.APPLICATION_ID
            //     }
            //     return this.eventSrv.evaluateEvent(query, input);
            // })
            // await Promise.all(promises).then(arrayOfResults => {
            //     if(arrayOfResults){
            //         arrayOfResults.forEach(r=>{
            //             r.forEach(e => {
            //                 if (e.rewards && e.rewards.length > 0) {
            //                     quiz.reward = e.rewards[0];
            //                 }
            //             })
            //         })
            //     }
            // }, (err) => {
            //
            // });

            resolve(quiz);
            return;
          }

          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  answerQuizNew(
    quizId: string,
    questions: Array<QuestionDTO>,
    additionalEventProperties?: string
  ): Promise<AnswerQuestionResponse> {
    return new Promise((resolve, reject) => {
      if (!quizId || quizId == "" || !questions) {
        reject("No Quiz");
        return;
      }
      const startQuizInput: StartQuizInput = {
        userId: TAValues.UserId,
        quizId: quizId,
        quizGameType: 1,
      };
      this.quizzesSrv
        .startQuiz(startQuizInput)
        .then((userQuizId: string) => {
          if (!userQuizId) {
            resolve(null);
            return;
          }
          const answers = new Array<AnswerQuestionInput>();
          if (questions) {
            questions.forEach((question: QuestionDTO) => {
              if (question.answered) {
                question.selectedAnswers.forEach((selected: AnswerDTO) => {
                  const a: AnswerQuestionInput = this.createAnswer(
                    question.id,
                    selected.id,
                    selected.order || "",
                    userQuizId,
                    "10"
                  );
                  answers.push(a);
                });
              }
            });
          }
          const answerQuestionsInput: AnswerQuestionsInput = {
            answers: answers,
            additionalEventProperties: additionalEventProperties,
          };
          this.quizzesSrv
            .answerQuiz(answerQuestionsInput)
            .then((finalResult: AnswerQuestionResponse) => {
              resolve(finalResult);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  answerQuiz(quizDTO: QuizDTO): Promise<AnswerQuestionResponse> {
    return new Promise((resolve, reject) => {
      if (!quizDTO) {
        reject("No Quiz");
        return;
      }
      const startQuizInput: StartQuizInput = {
        userId: TAValues.UserId,
        quizId: quizDTO.id,
        quizGameType: 1,
      };
      this.quizzesSrv
        .startQuiz(startQuizInput)
        .then((userQuizId: string) => {
          if (!userQuizId) {
            resolve(null);
            return;
          }
          const answers = new Array<AnswerQuestionInput>();
          if (quizDTO && quizDTO.questions) {
            quizDTO.questions.forEach((question: QuestionDTO) => {
              if (question.answered) {
                question.selectedAnswers.forEach((selected: AnswerDTO) => {
                  const a: AnswerQuestionInput = this.createAnswer(
                    question.id,
                    selected.id,
                    "",
                    userQuizId,
                    "10"
                  );
                  answers.push(a);
                });
              }
            });
          }
          const answerQuestionsInput: AnswerQuestionsInput = {
            answers: answers,
          };
          this.quizzesSrv
            .answerQuiz(answerQuestionsInput)
            .then((finalResult: AnswerQuestionResponse) => {
              resolve(finalResult);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createAnswer(
    questionId: string,
    answerId: string,
    answerText: string,
    userQuizId: string,
    timePassed: string
  ): AnswerQuestionInput {
    return {
      questionId: questionId,
      answerId: answerId,
      answerText: answerText,
      userQuizId: userQuizId,
      timePassed: timePassed,
    };
  }

  getEventRewardsRules(): Promise<Map<number, string>> {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .getEventRewardRules(
          Settings.GAME_TYPE,
          [Events_Types.QUIZ_COMPLETION, Events_Types.POLL_SURVEY_COMPLETED],
          true,
          LANGUAGES
        )
        .then((result: Array<EventRewardRulesDTO>) => {
          const eventRewards = new Map<number, string>();
          if (result) {
            result.forEach((e: EventRewardRulesDTO) => {
              eventRewards[e.eventTypeId] =
                e.rewards && e.rewards.length > 0
                  ? this.resourcesSrv.getResourcesBasic(
                      e.rewards[0],
                      Resource_Types.NAME
                    )
                  : "";
            });
          }
          resolve(eventRewards);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getReferences(valuePoolIds: Array<string>): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!valuePoolIds || valuePoolIds.length == 0) {
        resolve([]);
        return;
      }

      const promises = valuePoolIds.map((id) => {
        return this.referenceSrv.getReferencesMetadata(id, {
          rangeFrom: 0,
          rangeTo: -1,
        });
      });

      const result = await Promise.all(promises).catch((err) => {});
      if (!result) {
        reject(null);
        return;
      }

      resolve(result);
    });
  }

  public async getQuizResults(categoryId: number): Promise<Array<any>> {
    return new Promise<Array<any>>(async (resolve, reject) => {
      const setupResultContent = (item: FinalResultContent) => {
        item.title = this.resourcesSrv.getResourcesBasic(
          item,
          Resource_Types.NAME
        );
        item.description = this.resourcesSrv.getResourcesBasic(
          item,
          Resource_Types.DESCRIPTION
        );
        item.text = this.resourcesSrv.getResourcesBasic(
          item,
          Resource_Types.SHORT_DESCRIPTION
        );
        item.content = this.resourcesSrv.getResourcesBasic(
          item,
          Resource_Types.TEXT
        );
        // item.image = this.resourcesSrv.getResourcesBasic(
        //   item,
        //   Resource_Types.IMAGE
        // );
        item.image = this.resourcesSrv.getResourcesBasic(
          item,
          this.globals.isMobile
            ? Resource_Types.IMAGE_FEATURED_WEB
            : Resource_Types.IMAGE
        );
        item.action = this.resourcesSrv.getResourcesBasic(
          item,
          Resource_Types.ACTION_TYPE
        );
        item.actionLabel = this.resourcesSrv.getResourcesBasic(
          item,
          Resource_Types.ACTION_TYPE_DESCRIPTION
        );

        item.isMain =
          this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.UPLOAD_CONTENT_DISPLAY_TYPE,
            item.metadata
          ) == "true";
      };
      const input: FilterESContentDataInput = {
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT],
        categoryIds: [String(categoryId)],
        rangeFrom: 0,
        rangeTo: 1000,
        sortOrderTypes: ["BY_RECENT"],
        currentlyRewarding: null,
        countMode: null,
        userRewarded: null,
        currentlyActive: true,
        matchingLevel: null,
        matchingRules: null,
      };
      const items = await this.recommendationsSrv
        .getRecommendationContent(TAValues.UserId, input, {
          resources: true,
          languageIds: TAConstants.Settings.LANGUAGES,
          metadatas: true,
        })
        .catch((err) => {});
      if (items && items.length > 0) {
        items.forEach((i: FinalResultContent) => {
          setupResultContent(i);
          console.log(items);
        });

        resolve(items);
        return;
      }

      resolve(null);
    });
  }
}

export interface FinalResultContents {
  mainContent?: FinalResultContent;
  contents?: Array<FinalResultContent>;
}

export interface FinalResultContent extends IndexedDataContent {
  title?: string;
  description?: string;
  content?: string;
  text?: string;
  image?: string;
  actionLabel?: string;
  action?: string;
  isMain?: boolean;
}
