import { Injectable } from "@angular/core";
import { Http2Talos } from "../http2Talos";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class UserV2Api {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Request pin
   * @param gameTypeId
   * @param userId
   * @param input
   * @param requireSession
   * @param headers
   * @returns
   */
  public requestPin(
    gameTypeId: string,
    userId: string,
    input: ItemItemTypeInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<any> {
    return this.http2Talos.post({
      path: `/users/v2/pin/${gameTypeId}/${userId}`,
      input: input,
      requireSession: requireSession,
      headers: headers,
    });
  }

  /**
   * Validate Pin
   * @param gameTypeId
   * @param userId
   * @param input
   * @param requireSession
   * @param headers
   * @returns
   */
  public validatePin(
    gameTypeId: string,
    userId: string,
    input: ItemItemTypeInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<any> {
    return this.http2Talos.post({
      path: `/users/v2/pin/${gameTypeId}/${userId}/validate`,
      input: input,
      requireSession: requireSession,
      headers: headers,
    });
  }
}

export interface ItemItemTypeInput {
  itemId?: string;
  itemTypeId?: number;
  refItemId?: string;
  reference?: string;
}
