import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { UtilsService } from "../services/utils.service";
import { AppGlobalsService } from "../services/appGlobals.service";
import { Location } from "@angular/common";
import { InitService } from "../services/talos/init.service";
import { CachingService } from "../services/caching.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    public appGlobals: AppGlobalsService,
    private myRoute: Router,
    private utilsSrv: UtilsService,
    public activatedRoute: ActivatedRoute,
    @Inject(Location) private readonly location: Location,
    public route: ActivatedRoute,
    private initSrv: InitService,
    private cachingSrv: CachingService
  ) {
    const pathAfterDomainName = this.location.path();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.appGlobals.hasDonate = false;
    let tmp = `${this.location.path().split("?")[0]}`;
    if (this.auth.isLoggednIn()) {
      const href = next.url.join("/");
      // var myCustomData = { foo: 'bar' }
      // var event = new CustomEvent('iframeChanged', { detail: myCustomData })
      // window.parent.document.dispatchEvent(event)
      window.scroll(0, 0);

      //Just in case
      var views = document.getElementsByTagName("html");
      if (views) {
        for (var i = 0; i < views.length; i++) {
          views[i].setAttribute("class", "");
        }
      }

      // window.parent.postMessage({'id':'iframeChanged'}, '*');
      this.initSrv.removeMenu = false;
      this.cachingSrv.checkIfMustRemoveCache(href);
      return true;
    } else {
      this.appGlobals.redirectUrl = `${tmp}${window.location.hash}`;
      let token_path = `${this.location.path().split("?")[1]}`;
      const vars = token_path.split("&");
      let token = "";
      let iv = "";
      let lp_code = "";
      let utm_medium = "";
      let queryParams;
      for (var i = 0; i < vars.length; i++) {
        if (!queryParams) queryParams = {};
        var pair = vars[i].split("=");
        queryParams[pair[0]] = decodeURIComponent(decodeURIComponent(pair[1]));
        // if (decodeURIComponent(pair[0]) == 'token') {
        //     token = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
        // if (decodeURIComponent(pair[0]) == 'iv') {
        //     iv = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
        // if (decodeURIComponent(pair[0]) == 'lp_code') {
        //     lp_code = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
        // if (decodeURIComponent(pair[0]) == 'utm_medium') {
        //     utm_medium = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
      }
      this.appGlobals.queryParams = queryParams;
      this.myRoute.navigate(["/"], {
        replaceUrl: false,
        queryParamsHandling: "merge",
        queryParams: queryParams,
      });
      return false;
    }
  }
}
