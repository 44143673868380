import {ExternalSystemAuthenticationResponse, PmiApi} from "../../talosApi/api/PmiApi";
import {PmiOptinDTO} from "../../talosApi/models/PmiOptinDTO";
import {User} from "../../talosApi/models";
import {TAConstants, TAValues} from "../../talosApi/settings";
import GAME_TYPE_ID = TAConstants.Settings.GAME_TYPE_ID;
import {IPmiOptinUniq} from "../../talosApi/models/User";
import * as _ from "lodash";
import {Injectable} from "@angular/core";
import {ScannedDataInput} from "../../talosApi/models/ScannedDataInput";

@Injectable()
export class PmiServices {

    private _uniqueCode: Map<string, PmiOptinDTO> = new Map<string, PmiOptinDTO>();


    constructor(private pmiApi: PmiApi) {
    }

    getUniqueCode(username: string): Promise<PmiOptinDTO> {
        return new Promise<PmiOptinDTO>((resolve, reject) => {

            const uniqueCode = this._uniqueCode.get(username);
            if (!_.isNil(uniqueCode)) {
                resolve(uniqueCode);
                return;
            }


            const inputGetUnique: User = {
                username: username
            };

            this.pmiApi.pmiOptin(GAME_TYPE_ID, inputGetUnique)
                .then((userUniqueResult) => {
                    if (!_.isNil(userUniqueResult.user.userCodes)) {
                        const userCodes_: IPmiOptinUniq[] = userUniqueResult.user.userCodes;
                        this._uniqueCode.set(username, userCodes_[0]);
                        resolve(userCodes_[0]);
                        return;
                    }
                    resolve(null);

                })
                .catch((err) => {
                    reject(err);
                });

        });
    }

    pmiExternalAuthentication(userId: string, extAppSourceId: number): Promise<ExternalSystemAuthenticationResponse> {
        return new Promise<ExternalSystemAuthenticationResponse>((resolve, reject) => {
            this.pmiApi.pmiExternalAuthentication(userId, extAppSourceId)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**
     * Data Scanned
     * @param {string} gameTypeId
     * @param {string} userId
     * @param {ScannedDataInput} input
     * @param {boolean} requireSession
     * @return {Promise<any>}
     */
    dataScanned(gameTypeId: string,userId: string, input: ScannedDataInput, requireSession: boolean = true): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.pmiApi.dataScanned(gameTypeId, userId, input, requireSession)
                .then((result) => {
                    resolve("DONE");
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }


}// END CLASSS