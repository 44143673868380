import {Injectable} from "@angular/core";
import {AchievementApi, GetAchievementsParams} from "../../talosApi/api/AchievementApi";
import {AchievementDTO} from "../../talosApi/models/AchievementDTO";
import {TAConstants, TAValues} from "../../talosApi/settings";
import {ResourcesServices} from "./resources.services";
import Resource_Types = TAConstants.Resource_Types;

@Injectable()
export class AchievementService {

    constructor(private api: AchievementApi, private resourcesSrv: ResourcesServices) {

    }

    getAchievements(params: GetAchievementsParams): Promise<Array<AchievementDTO>> {
        return new Promise((resolve, reject) => {
            this.api.getAchievements(TAValues.UserId, params).then((result) => {
                if (!result) {
                    resolve([])
                    return;
                }

                resolve(result);
                // this.resourcesSrv.getResources(result, 'itemIdForResourceLookup', [Resource_Types.NAME], 'itemTypeIdForResourceLookup')
                //     .then(next => {
                //         resolve(result);
                //     }, error => {
                //         reject(error);
                //     }).catch((error) => {
                //     reject(error);
                // });
            }).catch(err => {
                reject(err);
            })
        });
    }
}