import {
  FilterESContentDataInput,
  RecommendationsApi,
} from "../../talosApi/api/RecommendationsApi";
import { Injectable } from "@angular/core";
import { IndexedDataContent } from "../../talosApi/models/IndexedDataContent";
import { GetIndexedContentQuery } from "../../talosApi/api/IndexedDataApi";
import { HttpHeaders } from "@angular/common/http";
import { TAConstants, TAValues } from "../../talosApi/settings";
import { ItemActionStatusDTO } from "../../talosApi/models/ItemActionStatusDTO";
import { GetUserActionStatusInput } from "../../talosApi/models/GetUserActionStatusInput";
import {
  MaterialService,
  MaterialType,
} from "../../app/pages/material/material.service";
import { ItemService } from "./item.service";
import { MaterialObject } from "../../models/MaterialObject";
import { UtilsService } from "../utils.service";
import Unit_Types = TAConstants.Unit_Types;
import Events_Types = TAConstants.Events_Types;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import TAHeaders = TAConstants.TAHeaders;
import QUIZ_TYPES = TAConstants.QUIZ_TYPES;
import ITEM_TYPES = TAConstants.ITEM_TYPES;

@Injectable()
export class RecommendationsService {
  constructor(
    private api: RecommendationsApi,
    private itemSrv: ItemService,
    private materialSrv: MaterialService,
    private utilsSrv: UtilsService
  ) {}

  getRecommendationMaterial(
    input: FilterESContentDataInput
  ): Promise<Array<MaterialObject>> {
    return new Promise<Array<MaterialObject>>((resolve, reject) => {
      const articleCat = this.materialSrv.getCategoryIdByType(
        MaterialType.ARTICLE,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const videoCat = this.materialSrv.getCategoryIdByType(
        MaterialType.VIDEO,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quizCat = this.materialSrv.getCategoryIdByType(
        MaterialType.QUIZ,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quickCat = this.materialSrv.getCategoryIdByType(
        MaterialType.QUICK_REPLY,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );

      const getMaterialType = (item: IndexedDataContent): MaterialType => {
        let result: MaterialType;
        if (item.categoryIds) {
          if (item.categoryIds.find((c) => c == articleCat)) {
            result = MaterialType.ARTICLE;
          } else if (item.categoryIds.find((c) => c == videoCat)) {
            result = MaterialType.VIDEO;
          } else if (item.categoryIds.find((c) => c == quickCat)) {
            result = MaterialType.QUICK_REPLY;
          } else if (item.categoryIds.find((c) => c == quizCat)) {
            // result = MaterialType.QUIZ;
            if (item.contentReferencedType) {
              if (item.contentReferencedType.typeId == String(QUIZ_TYPES.QUIZ))
                result = MaterialType.QUIZ;
              else if (
                item.contentReferencedType.typeId == String(QUIZ_TYPES.SURVEY)
              )
                result = MaterialType.SURVEY;
            }
          }
        }
        return result;
      };

      Promise.all([
        this.materialSrv.getEventRewardsRules(),
        this.getRecommendationContent(TAValues.UserId, input, {
          resources: true,
          languageIds: TAConstants.Settings.LANGUAGES,
          metadatas: true,
        }),
        this.materialSrv.getMaterialFiltersGuadagna(),
      ])
        .then(async (arrayOfResults) => {
          if (arrayOfResults && arrayOfResults.length == 3) {
            const objects: Array<IndexedDataContent> = arrayOfResults[1] || [];
            if (objects.length > 0) {
              const aIds = [];
              const vIds = [];
              const qIds = [];
              const sIds = [];

              objects.forEach((i) => {
                const type = getMaterialType(i);
                if (type) {
                  let eventTypeId: number;
                  switch (type) {
                    case MaterialType.ARTICLE:
                    case MaterialType.QUICK_REPLY:
                      aIds.push(i.itemId);
                      break;
                    case MaterialType.VIDEO:
                      vIds.push(i.itemId);
                      break;
                    case MaterialType.QUIZ:
                      qIds.push(i.itemId);
                      break;
                    case MaterialType.SURVEY:
                      sIds.push(i.itemId);
                      break;
                  }
                }
              });

              const promise = (
                itemIds: Array<string>,
                itemTypeId: number,
                eventTypeId: number
              ): Promise<Array<ItemActionStatusDTO>> => {
                const input: GetUserActionStatusInput = {
                  itemIds: itemIds,
                  itemTypeId: itemTypeId,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };
                return this.itemSrv.getUserActions(eventTypeId, null, input);
              };

              const promises = [];

              if (aIds.length > 0) {
                promises.push(
                  promise(
                    aIds,
                    ITEM_TYPES.UPLOAD_CONTENT,
                    Events_Types.ITEM_VIEWED
                  )
                );
              }
              if (vIds.length > 0) {
                promises.push(
                  promise(
                    vIds,
                    ITEM_TYPES.UPLOAD_CONTENT,
                    Events_Types.VIDEO_WATCHED
                  )
                );
              }
              if (qIds.length > 0) {
                promises.push(
                  promise(qIds, ITEM_TYPES.QUIZ, Events_Types.QUIZ_COMPLETION)
                );
              }
              if (sIds.length > 0) {
                promises.push(
                  promise(
                    sIds,
                    ITEM_TYPES.QUIZ,
                    Events_Types.POLL_SURVEY_COMPLETED
                  )
                );
              }

              if (promises.length > 0) {
                const results = await Promise.all(promises).catch((err) => {});
                if (results) {
                  results.forEach((r) => {
                    if (r) {
                      r.forEach((a) => {
                        objects.forEach((i: IndexedDataContent) => {
                          if (
                            a.itemId == i.itemId &&
                            a.actionsStatus &&
                            a.actionsStatus.length > 0
                          ) {
                            i.actionsStatus = a.actionsStatus[0];
                          }
                        });
                      });
                    }
                  });
                }
              }
            }
            const items = [];
            objects.forEach((i) => {
              const type = getMaterialType(i);
              if (type) {
                const o = this.utilsSrv.materialObjectByOverviewContent(
                  i,
                  type,
                  arrayOfResults[2]
                );
                if (o) items.push(o);
              }
            });
            if (objects[TAHeaders.ITEM_COUNT]) {
              items[TAHeaders.ITEM_COUNT] = objects[TAHeaders.ITEM_COUNT];
            }
            resolve(items);
            return;
          }
          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * GEt Recommendation Content
   * @param {string} userId
   * @param {FilterESContentDataInput} input
   * @param {GetIndexedContentQuery} queryParams
   * @param {boolean} requireSession
   * @param {HttpHeaders} headers
   * @return {Promise<Array<IndexedDataContent>>}
   */
  public getRecommendationContent(
    userId: string,
    input?: FilterESContentDataInput,
    queryParams?: GetIndexedContentQuery,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<Array<IndexedDataContent>> {
    return new Promise<Array<IndexedDataContent>>((resolve, reject) => {
      this.api
        .getRecommendationContent(
          userId,
          input,
          queryParams,
          requireSession,
          headers
        )
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
