import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class MiddleApi {
    constructor(private http2Talos: Http2Talos,
                private  http: HttpClient,
    ) {
    }

    public getQrCode(code:string, size:number) {
       return this.http.post(`https://friendsplus.mystartapp.org/user/qrcode?text=${code}&size=${size}`,{}, {responseType: 'text' }).toPromise();
    }

} //END CLASS