import { Injectable } from "@angular/core";
import {
  GetIndirectUserDataQuery,
  IndirectDataApi,
} from "src/talosApi/api/IndirectDataApi";
import { IndirectUserDataDTO } from "src/talosApi/models/IndirectUserDataDTO";

@Injectable()
export class IndirectDataService {
  constructor(private api: IndirectDataApi) {}

  /**
   * Get Indexed User Data
   * @param userId
   * @param mode
   * @param queryParams
   * @param requireSession_
   * @returns {Promise<Array<IndirectUserDataDTO>>}
   */
  public getIndirectUserData(
    userId: string,
    mode: number,
    queryParams?: GetIndirectUserDataQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndirectUserDataDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .getIndirectUserData(userId, mode, queryParams, requireSession_)
        .then((response: Array<IndirectUserDataDTO>) => {
          resolve(response);
        })
        .catch(reject);
    });
  }
}
