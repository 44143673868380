import {Injectable} from "@angular/core";
import {ItemApi} from "../../talosApi/api/ItemApi";
import {ITalosApiModel, ResourceDTO, ItemsList} from "../../talosApi/models";
import  _ from 'lodash';
import {TAConstants} from "../../talosApi/settings";
import Resource_Status = TAConstants.Resource_Status;
import {isArray, isNullOrUndefined} from "util";
import {TranslateService} from '@ngx-translate/core';
import Language = TAConstants.Language;
import { ResourceRequestDTO } from "src/talosApi/models/ResourceRequestDTO";
import { KpDictionary } from "../../../../src/utils/kp_dictionary/kp.dictionary";
import { resource } from "selenium-webdriver/http";
import { to } from "../../../../src/utils/utils";



@Injectable()
export class ResourcesServices {

    constructor(private api:ItemApi,public translate:TranslateService){
    }

    /**
     * Searchs etag
     * @param items 
     * @param resourcesIds 
     * @param languageIds 
     * @param [includeBinaryResources] 
     * @returns etag 
     */
    public async searchEtag(
        items:Array<ITalosApiModel>, 
        languageIds:Array<string>,
        resourcesTypeIds:Array<number>,
        includeBinaryResources:boolean = true
    ): Promise<ItemsList<ResourceDTO>> {
        return new Promise<ItemsList<ResourceDTO>>(async (resolve, reject) => {
            if (isNullOrUndefined(items) || isNullOrUndefined(resourcesTypeIds))
                reject(null);
            else if (!items.length || !resourcesTypeIds.length)
                reject(null);
            else {
                if (isNullOrUndefined(languageIds) || !languageIds.length)
                    languageIds = TAConstants.Settings.LANGUAGES;
                let payload: Array<ResourceRequestDTO> = [];
                items.forEach((item) => {
                    resourcesTypeIds.forEach(resourceTypeId => {
                        languageIds.forEach(languageId => {
                            payload.push({
                                itemId: item.itemIdForResourceLookup,
                                resourceTypeId: resourceTypeId,
                                itemTypeId: item.itemTypeIdForResourceLookup,
                                languageIsoCode: languageId,
                            });
                        });
                    });
                });
                const _searchEtag = await to(this.api.searchEtag(payload));
                if (isNullOrUndefined(_searchEtag.data)) reject(null); 
                else {
                    resolve(_searchEtag.data);
                    // _searchEtag.data.map((resp: ResourceRequestDTO) => {
                    //     if (!isNullOrUndefined(resp.textResource)) {
                    //         if (resp.textResource.length) {
                    //             if (!_resp.ContainsKey(resp.itemId))
                    //                 _resp.Add(resp.itemId, new KpDictionary<ResourceDTO>());
                    //             if (!_resp.Item(resp.itemId).ContainsKey(resp.languageIsoCode)) 
                    //                 _resp.Item(resp.itemId).Add(resp.languageIsoCode, resp);
                    //         }
                    //     }
                    // });
                    // if (_resp.Count()) resolve(_resp);
                }
            }
        });
    }

    loadItemResources(items:Array<ITalosApiModel>, itemId:string, resourcesIds:Array<number>, itemTypeId:string|number, forced:boolean = false): Promise<Array<ResourceDTO> | boolean> {
        return new Promise<Array<ResourceDTO>|boolean>((resolve, reject) => {
            let storedResources, useItemResourcesIds, languages, langLength, itemCode, etagCode, resource,
                pending: Array<any>;
            let reffernceObjs = {};
            let resources: Array<any> = [];

            if (_.isNil(items.length) || _.isNil(items)===true || items.length === 0 ) {
                resolve(items);
                return;
            }

            useItemResourcesIds = _.isNil(resourcesIds);
            languages = TAConstants.Settings.LANGUAGES; //FIXME: need a global common file to insert lang.

            const searchResourcesFromTalos =  (resourceItems) => {

                if (_.isNil(storedResources) === true)
                    storedResources = {};

                resourceItems.forEach((resourceItem) => {
                    resourcesIds = useItemResourcesIds === true ? resourceItem.resourcesIds : resourcesIds;
                    resourceItem.resources = _.isNil(resourceItem.resources) === true || (forced === true && isArray(resourceItem.resources) === true) ? {} : resourceItem.resources;

                    resourcesIds.forEach((resourceId) => {
                        for (let language of languages) {
                            resource = {};
                            resource.itemId = resourceItem[itemId];
                            resource.itemTypeId = typeof itemTypeId === 'string' ? resourceItem[itemTypeId] : itemTypeId;
                            resource.resourceTypeId = resourceId;
                            resource.languageIsoCode = language;
                            itemCode = resourceId + "_" + language;

                            if (_.isNil(resourceItem.resources[itemCode]) === false)
                                continue;

                            etagCode = resource.itemId + "_" + itemCode;
                            resource.cachedHash = _.isNil(storedResources[etagCode]) === true ? "" : storedResources[etagCode].cachedHash;
                            resources.push(resource);
                        }
                    });
                    reffernceObjs[resourceItem[itemId]] = reffernceObjs[resourceItem[itemId]] === undefined ? [] : reffernceObjs[resourceItem[itemId]];

                    reffernceObjs[resourceItem[itemId]].push(resourceItem);

                });

                if (resources.length === 0) {
                    resolve(items);
                    return;
                }

                this.api.searchEtag(resources).then((data) => {

                    data.forEach((resource) => {
                        itemCode = resource.resourceTypeId + "_" + resource.languageIsoCode;
                        etagCode = resource.itemId + "_" + itemCode;
                        switch (resource.cacheControl) {
                            case Resource_Status.NOT_CHANGED: {
                                resource = storedResources[etagCode];
                            }
                                break;
                            case Resource_Status.NOT_FOUND:
                            case Resource_Status.CHANGED: {
                                storedResources[etagCode] = resource;
                            }
                                break;
                        }

                        if (_.isNil(resource) === false) {
                            reffernceObjs[resource.itemId].forEach((item) => {
                                item.resources[itemCode] = _.isNil(resource.binaryResource) === false ? resource.binaryResource : resource.textResource;
                            });
                        }
                    });

                    reffernceObjs = null;
                    resourcesIds.length = 0;
                    resourcesIds = null;
                    resolve(items);
                }).catch((error) => {
                    logger.log(error);
                    reject(error);
                });
            };

            // here call for the resources.
            searchResourcesFromTalos(items);
        });
    }

    getResources =(items:Array<any>, itemId:string, resourcesIds:Array<number>, itemTypeId:string|number, forced:boolean = false) =>{
        return this.loadItemResources(items, itemId, resourcesIds, itemTypeId,  forced);
    };

    getResourceUrl(itemId:string, itemTypeId:number, resourceTypeId:number,lang_code?:number) :string {
        return TAConstants.Settings.RESOURCE_SERVER + "?itemId=" + itemId + "&itemTypeId=" + itemTypeId + "&resourceTypeId=" + resourceTypeId + "&languageId=" + (lang_code ? lang_code:Language.IDs[this.translate.currentLang.toUpperCase()]);
    }

    getResourceUrlWithLang(itemId:string, itemTypeId:number, resourceTypeId:number,lang_code:string): string {
      return TAConstants.Settings.RESOURCE_SERVER + "?itemId=" + itemId + "&itemTypeId=" + itemTypeId + "&resourceTypeId=" + resourceTypeId + "&languageId=" + Language.IDs[lang_code];
    }

    getResource(item:any, resourceType:number, langCode?:string): string {
        return (_.isNil(item) === false && _.isNil(item.resources) === false) ? item.resources[resourceType+ "_" + (langCode || this.translate.currentLang)]:undefined;
    }

    getResourceBinary(item:any, resourceType:number, type:string): string {
        let itemResource = item.resources[resourceType+ "_" + this.translate.currentLang];
        return (_.isNil(item) === false && _.isNil(itemResource) === false) ? "data:image/"+ type +";base64,"+ itemResource :undefined;
    }

    getResourcesBasic(item:any, resourceType:number):string{
        let result = "";
        if(item && item.resources){
            const r = item.resources.find(res=>{
                return (res.resourceTypeId ==resourceType && res.languageIsoCode == this.translate.currentLang);
            })

            if(r){
                result = r.textResource;
            }
        }
        return result;
    }

} //END CLASS
