import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {LoaderComponent} from "./components/loader/loader.component";
import {IconItemDisplayModeAngComponent} from './components/icon-item-display-mode-ang/icon-item-display-mode-ang.component';
import {IconItemFilterTagAngComponent} from './components/icon-item-filter-tag-ang/icon-item-filter-tag-ang.component';
import {IconItemShorterAngComponent} from './components/icon-item-shorter-ang/icon-item-shorter-ang.component';
import {IconItemFilterTagAngModule} from "./components/icon-item-filter-tag-ang/icon-item-filter-tag-ang.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {IconPageTabAngComponent} from './components/icon-page-tab-ang/icon-page-tab-ang.component';
import {SafeHtmlPipe} from "../pipes/safeHtml.pipe";
import {CountdownTimerModule} from "ngx-countdown-timer";
import {InviteSingleMenuComponent} from "../pages/invite/invite-single-menu/invite-single-menu.component";
import {FeedbackContainerModule} from "../components/feedback-container/feedback-container.module";
import { RangeFromToPaginator } from "../components/range-from-to-paginator/range-from-to-paginator.component";
import { GoBackComponent } from "../components/go-back-header/go-back-header.component";
import {SafeUrlPipe} from "../pipes/SafeUrlPipe";
import { ProfileProgressComponent } from "../components/profile-progress/profile-progress.component";
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // FeedbackContainerModule
    ],
    declarations: [
        LoaderComponent,
        IconItemFilterTagAngComponent,
        IconItemDisplayModeAngComponent,
        IconItemShorterAngComponent,
        IconPageTabAngComponent,
        RangeFromToPaginator,
        GoBackComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
    ],
    exports: [
        LoaderComponent,
        IconItemFilterTagAngComponent,
        IconItemDisplayModeAngComponent,
        IconItemShorterAngComponent,
        RangeFromToPaginator,
        GoBackComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
}