/*
 * @Author: Peter Fousteris (petfoust@gmail.com)
 * @Date: 2019-06-28 16:11:20
 * @Last Modified by:   Peter Fousteris (petfoust@gmail.com)
 * @Last Modified time: 2019-06-28 16:11:20
 */

import { Injectable } from "@angular/core";
import { ReferralService } from "../../../services/talos/referral.service";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import { GetGroupByReferrals } from "../../../talosApi/api/ReferralApi";
import { TransactionHistoryInput } from "../../../talosApi/models/TransactionHistoryInput";
import { UserService } from "../../../services/talos/user.service";
import { PendingPointsQ } from "../../../talosApi/api/UserApi";
import { UtilsService } from "../../../services/utils.service";
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { AppGlobalsService } from "../../../services/appGlobals.service";
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Order_Stores = TAConstants.Order_Stores;
import Resource_Types = TAConstants.Resource_Types;

/**
 * Injectable
 */
@Injectable()
export class InvitePageService {
  constructor(
    private referralSrv: ReferralService,
    private userSrv: UserService,
    private utilsSrv: UtilsService,
    private recommendationsSrv: RecommendationsService,
    private resourcesSrv: ResourcesServices,
    private appGlobals: AppGlobalsService
  ) {}

  getCounters(isList: boolean): Promise<InviteCounters> {
    return new Promise((resolve, reject) => {
      const now = new Date();
      const start =
        now.getFullYear() == 2022
          ? new Date(2021, 10, 10, 1)
          : new Date(now.getFullYear(), 0, 1, 0);
      // const start = new Date(2021, 10, 10, 1);

      const prCountInput: GetGroupByReferrals = {
        groupByType: "BY_USER",
        referralTypeIds: [
          REFERRAL_TYPES.MGM_UNICODE,
          REFERRAL_TYPES.LENDING_REFERRAL,
          REFERRAL_TYPES.LIL,
        ],
        referralStatusIds: [2, 3, 4],
        dateFrom: start.getTime(),
        dateTo: now.getTime(),
      };
      const prCount = this.referralSrv.groupByReferrals(
        TAValues.UserId,
        prCountInput
      );

      const prPointsInput: TransactionHistoryInput = isList
        ? {
            eventTypeIds: [19023, 19060, 19098],
            unitTypeId: [126],
            groupByGroupByType: "BY_UNIT_TYPE_TRANSACTION_TYPE",
          }
        : {
            eventTypeIds: [19023, 19060, 19098],
            unitTypeId: [126],
            groupByGroupByType: "BY_UNIT_TYPE_TRANSACTION_TYPE",
            dateFrom: start.getTime(),
            dateTo: now.getTime(),
          };
      const prPoints = this.userSrv.transactionsHistoryGroupBy(
        TAValues.UserId,
        prPointsInput
      );

      const prArrivoInput: PendingPointsQ = {
        eventTypeIds: [19023, 19060, 19098],
        excludeZeroAmounts: true,
      };
      const prArrivo = this.userSrv.getPendingPoints(
        TAValues.UserId,
        prArrivoInput,
        false
      );

      const prCountTotalInput: GetGroupByReferrals = {
        groupByType: "BY_USER",
        referralTypeIds: [
          REFERRAL_TYPES.MGM_UNICODE,
          REFERRAL_TYPES.LENDING_REFERRAL,
          REFERRAL_TYPES.LIL,
        ],
        referralStatusIds: [2, 3, 4, 6],
      };
      const prCountTotal = this.referralSrv.groupByReferrals(
        TAValues.UserId,
        prCountTotalInput
      );

      // const prCountMasterInput: GetGroupByReferrals = {
      //     groupByType: 'BY_USER',
      //     referralTypeIds: [REFERRAL_TYPES.MGM_UNICODE, REFERRAL_TYPES.LENDING_REFERRAL, REFERRAL_TYPES.LIL],
      //     referralStatusIds: [3, 4]
      // };
      // const prCountMaster = this.referralSrv.groupByReferrals(TAValues.UserId, prCountMasterInput);

      const prCountInputDisable: GetGroupByReferrals = {
        groupByType: "BY_USER",
        referralTypeIds: [REFERRAL_TYPES.MGM_UNICODE, REFERRAL_TYPES.LIL],
        referralStatusIds: [3, 4],
        dateFrom: start.getTime(),
        dateTo: now.getTime(),
      };
      const prCountDisable = this.referralSrv.groupByReferrals(
        TAValues.UserId,
        prCountInputDisable
      );

      const prCountMasterInput: GetGroupByReferrals = {
        groupByType: "BY_USER",
        referralTypeIds: [
          REFERRAL_TYPES.MGM_UNICODE,
          REFERRAL_TYPES.LENDING_REFERRAL,
          REFERRAL_TYPES.LIL,
        ],
        referralStatusIds: [2, 3, 4],
        // dateFrom: new Date(2021, 10, 10, 1).getTime(),
        dateTo: now.getTime(),
      };
      const prCountMaster = this.referralSrv.groupByReferrals(
        TAValues.UserId,
        prCountMasterInput
      );

      Promise.all([
        prCount,
        prPoints,
        prArrivo,
        prCountTotal,
        prCountDisable,
        prCountMaster,
      ])
        .then((res) => {
          let referrals = 0;
          let points = "0";
          let pointsArrivo = "0";
          let referralsTotal = 0;
          let referralsMaster = 0;
          let referralsRewarded = 0;
          if (res) {
            if (res[0] && res[0].length > 0) {
              referrals = res[0][0].noOfRecords;
              // referralsMaster = res[0][0].noOfRecords;
            }
            if (
              res[1] &&
              res[1].length > 0 &&
              res[1][0].vmTransactionTypeStats &&
              res[1][0].vmTransactionTypeStats.length > 0
            ) {
              let p = 0;
              res[1][0].vmTransactionTypeStats.forEach((t) => {
                p += t.amount;
              });
              points = this.utilsSrv.integerToString(p);
            }
            if (res[2])
              pointsArrivo = this.utilsSrv.integerToString(
                Number(
                  res[2].balance && res[2].balance != "" ? res[2].balance : "0"
                )
              );
            if (res[3] && res[3].length > 0)
              referralsTotal = res[3][0].noOfRecords;
            // if (res[4] && res[4].length > 0)
            //     referralsMaster = res[4][0].noOfRecords;
            if (res[4] && res[4].length > 0)
              referralsRewarded = res[4][0].noOfRecords;
            if (res[5] && res[5].length > 0) {
              referralsMaster = res[5][0].noOfRecords;
            }
          }
          const result: InviteCounters = {
            referrals: referrals,
            points: points,
            pointsArrivo: pointsArrivo,
            referralsTotal: referralsTotal,
            referralsMaster: referralsMaster,
            referralsRewarded: referralsRewarded,
          };
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBenefits(): Promise<Array<ReferBenefit>> {
    return new Promise(async (resolve, reject) => {
      const categoryId =
        this.appGlobals.config[
          APPLICATION_SETTING_KEYS.REFER_BENEFIT_CATEGORY_ID
        ] || null;
      let benefits: Array<ReferBenefit>;
      if (categoryId) {
        const input: FilterESContentDataInput = {
          itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT],
          categoryIds: [String(categoryId)],
          rangeFrom: 0,
          rangeTo: -1,
          sortOrderTypes: [Order_Stores.BY_ORDERING_ASC],
          currentlyActive: true,
          matchingRules: true,
        };
        const promoUploadContents = await this.recommendationsSrv
          .getRecommendationContent(TAValues.UserId, input, {
            resources: true,
            languageIds: TAConstants.Settings.LANGUAGES,
            metadatas: true,
          })
          .catch((err) => {});

        if (promoUploadContents && promoUploadContents.length > 0) {
          benefits = promoUploadContents.map((p) => {
            return {
              id: p.itemId,
              title: this.resourcesSrv.getResourcesBasic(
                p,
                Resource_Types.NAME
              ),
              description: this.resourcesSrv.getResourcesBasic(
                p,
                Resource_Types.DESCRIPTION
              ),
              image: this.resourcesSrv.getResourcesBasic(
                p,
                Resource_Types.IMAGE
              ),
            };
          });
        }
      }

      resolve(benefits);

      // resolve([
      //     {id: "1", title: "Title 1", description: " Desc 1", image: "https://www.ets.org/s/toeic/svg/test_taker.svg"},
      //     {id: "2", title: "Title 2", description: " Desc 2", image: "https://www.ets.org/s/toeic/svg/test_taker.svg"},
      //     {id: "3", title: "Title 3", description: " Desc 3", image: "https://www.ets.org/s/toeic/svg/test_taker.svg"}
      // ])
    });
  }
}

export interface InviteCounters {
  referrals?: number;
  referralsTotal?: number;
  referralsMaster?: number;
  referralsRewarded?: number;
  points?: string;
  pointsArrivo?: string;
}

export interface ReferBenefit {
  id?: string;
  title?: string;
  description?: string;
  image?: string;
}
