import {Injectable} from "@angular/core";
import {EventService} from "../../../services/talos/event.service";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import Settings = TAConstants.Settings;
import Events_Types = TAConstants.Events_Types;
import {UserEventCounterDTO} from "../../../talosApi/models/UserEventCounterDTO";
import {ReferralService} from "../../../services/talos/referral.service";
import {GetGroupByReferrals} from "../../../talosApi/api/ReferralApi";
import {GroupByDTO} from "../../../talosApi/models/GroupByDTO";
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;

@Injectable()
export class UniqueCodeService {

    constructor(private eventService: EventService, private referralSrv: ReferralService) {

    }

    getUniqueCodeAndCounters(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.eventService.getEventStats(TAValues.UserId, [Events_Types.IQOS_CLUB_IT_MGM_REFERRAL_COMPLETED], null, 0, -1)
                .then((result: Array<UserEventCounterDTO>) => {
                    resolve(result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getUniqueCounters(): Promise<Array<GroupByDTO>> {
        return new Promise((resolve, reject) => {
            const params: GetGroupByReferrals = {referralTypeIds: [REFERRAL_TYPES.MGM_UNICODE], groupByType: "BY_TYPE",
                referralStatusIds: [2, 3, 4, 6]};
            this.referralSrv.groupByReferrals(TAValues.UserId, params)
                .then((result: Array<GroupByDTO>) => {
                    resolve(result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }
}