import {Injectable} from "@angular/core";
import {Http2Service} from "../http2Service";
import {IShortify} from "../models/IShortify";

@Injectable()
export class ShortifyApi {
    constructor(private http2Service: Http2Service) {
    }

    public getShortifyUrl(user_id: string, link: string): Promise<IShortify> {
        return this.http2Service.get({path: `/taloscms/shortify_url.php`, queryParams: {user_id: user_id, link: link}});
    }
}
