import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class Logger {
  /**
   * this should be a function and it will display debug messages at the console
   */

  log: any;
  /**
   * this should be a function and it will display debug Info messages at the console
   */
  warn: any;
  /**
   * this should be a function and it will display debug error messages at the console
   */
  error: any;
}

/**
 *
 * this service is the default implementation of the logging Service
 *
 */
@Injectable()
export class LoggerService  implements Logger {

  warn: any;
  error: any;
  log: any;

  constructor() { }


}
