/**
 * Talos REST API
 * Talos REST API
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {Http2Talos} from "../http2Talos";
import {TournamentDTOS} from "../models/TournamentDTOS";
import {Injectable} from "@angular/core";
import {UserRankingDTOS} from "../models/UserRankingDTOS";
import {TournamentUserStatisticsDTO} from "../models/TournamentUserStatisticsDTO";
import {TournamentWinnersDTO} from "../models/TournamentWinnersDTO";
import {query} from '@angular/animations';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class TournamentsApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * Get tournaments
     * @param {GetTournamentsQuery} query
     * @return {Promise<Array<TournamentDTOS>>}
     */
    getTournaments(query: GetTournamentsQuery): Promise<Array<TournamentDTOS>> {
        return this.http2Talos.get({
            path: `/tournaments`,
            queryParams: query
        });
    }

    // /**
    //  * Get Tournament Ranking
    //  *
    //  * @param {string} tournamentId
    //  * @param {GetTournamentRankingQuery} query
    //  * @return {Promise<Array<UserRankingDTOS>>}
    //  */
    // getTournamentRanking(tournamentId: string, query?: GetTournamentRankingQuery): Promise<Array<RankingDTO>> {
    //     return this.http2Talos.get({
    //         path: `/tournaments/${tournamentId}/ranking`,
    //         queryParams: query
    //     });
    // }
    //
    // /**
    //  * Get User Ranking
    //  * @param {string} userId
    //  * @param {string} tournamentId
    //  * @return {Promise<UserRankingDTOS>}
    //  */
    // getUserTournamentRanking(userId: string, tournamentId: string, query?:IGetTournamentUserRankingQuery ): Promise<UserRankingDTOS> {
    //     return this.http2Talos.get({
    //         path: `/tournaments/${tournamentId}/ranking/${userId}`,
    //         queryParams: query
    //     });
    // }
    //
    /**
     * Get User tournament statistics
     * @param {string} userId
     * @param {GetUserTournamentStatisticsQuery} query
     * @return {Promise<Array<TournamentUserStatisticsDTO>>}
     */
    getUserTournamentStatistics(userId: string, query: GetUserTournamentStatisticsQuery): Promise<Array<TournamentUserStatisticsDTO>> {
        return this.http2Talos.get({
            path: `/tournaments/statistics/${userId}`,
            queryParams: query
        });
    }

    /**
     * Convert Tournament Points
     * @param {string} userId
     * @param {string} tournamentId
     * @param {ConvertToTournamentParticipationsInput} input
     * @return {Promise<any>}
     */
    convertTournamentPoints(userId: string, tournamentId: string, input: ConvertToTournamentParticipationsInput): Promise<any> {
        return this.http2Talos.post({
            path: `/tournaments/${userId}/convert/${tournamentId}`,
            input: input
        });
    }

    //
    // /**
    //  * Get Tournament Winners
    //  * @param {GetTournamentWinnersQuery} query
    //  * @return {Promise<Array<TournamentWinnersDTO>>}
    //  */
    // getTournamentWinners(query: GetTournamentWinnersQuery): Promise<Array<TournamentWinnersDTO>> {
    //     return this.http2Talos.get({
    //         path: `/tournaments/winners`,
    //         queryParams: query,
    //         requireSession: true
    //     });
    // }
}

export interface GetTournamentRankingQuery {
    rangeFrom?: number,
    rangeTo?: number,
    onlyFriends?: boolean,
    tournamentUserProperties?: Array<string>,
    currentlyActive?: boolean;
    resources?: boolean;
    metadatas?: boolean;
    languageIds?: Array<string>;
    statistics?: boolean;
    onlyFriendsAndCaller?: boolean;

}

export interface GetTournamentsQuery {
    tournamentTypes?: Array<number>;
    tournamentIds?: Array<string>
    unitTypeIds?: Array<number>;
    matchingLevel?: boolean;
    currentlyActive?: boolean;
    orderType?: string;
    includeDetails?: boolean;
    gameTypeId?: string;
    rangeFrom?: number;
    rangeTo?: number;
    resources?: boolean;
    metadatas?: boolean;
    languageIds?: Array<string>;
}

export interface GetUserTournamentStatisticsQuery {
    gameTypeId?: string;
    tournamentTypeIds?: Array<number>;
    tournamentIds?: Array<string>
    tournamentUserProperties?: Array<string>
    includeGuests?: boolean;
    includeRank?: boolean;
    active?: boolean;
    groupByTxType?: boolean;
    groupByType?: string;
}

export interface GetTournamentWinnersQuery {
    tournamentIds?: Array<string>
    userIds?: Array<string>
    statistics?: Array<string>
    rangeFrom?: number;
    rangeTo?: number;
}

export interface RankingDTO {
    userId?: string;
    nickname?: string;
    userGroupId?: string;
    score?: string;
    ranking?: number;
    gameTypeId?: any;
    userPicUrl?: string;
    nationality?: string;
    profilePicUrl?: string;
    status?: any;
    balance?: any;
}

export interface IGetTournamentUserRankingQuery {
    resources?: boolean;
    languageIds?: string[];
    metadatas?: boolean;
    statistics?: boolean;
    tournamentUserProperties?: string[];
    onlyFriendsAndCaller?: boolean;
    includeGuests?: boolean;
    includeRank?: boolean;

}

export interface ConvertToTournamentParticipationsInput {
    points?: number;
    fromUnitTypeId?: number;
    targetUserId?: string;
}