import { Http2Talos } from "../http2Talos";
import { DetailedBrandProductDTO, ItemsList } from "../models";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DetailedBrandProductCouponDTO } from "../models/DetailedBrandProductCouponDTO";
import { ICancelCoupon } from "./UserApi";
import { GroupByDTO } from "../models/GroupByDTO";

@Injectable()
export class BrandApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Asyncs get brand products
   * @param userId
   * @param input
   * @param [requireSession]
   * @returns get brand products
   */
  asyncGetBrandProducts(
    userId: string,
    input: any,
    requireSession: boolean = true
  ): Promise<ItemsList<DetailedBrandProductDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/brands/${userId}/products`,
      queryParams: input,
    });
  }

  getBrandProducts(
    userId: string,
    input: any,
    requireSession: boolean = true
  ): Promise<ItemsList<DetailedBrandProductDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/brands/${userId}/products`,
      queryParams: input,
    });
  }

  getBrandProductsGeneric(
    userId: string,
    input: any,
    requireSession: boolean = true
  ): Promise<Array<DetailedBrandProductDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/brands/${userId}/products`,
      queryParams: input,
    });
  }

  getBrandProductsGroupBy(
    userId: string,
    input: any,
    requireSession: boolean = true
  ): Promise<Array<ProductGroupByDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/brands/${userId}/groupByProducts`,
      queryParams: input,
    });
  }

  getTheStoresUser(
    input: IInputStore,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<ItemsList<IStoreUser[]>> {
    return this.http2Talos.get({
      headers: headers,
      queryParams: input,
      path: `/brands/stores`,
    });
  }

  /**
   *
   * @param {string} userId
   * @param {string} couponId
   * @param {string} friendId
   * @param {boolean} requireSession
   * @return {Promise<any>}
   */
  public giftCoupon(
    userId: string,
    couponId: string,
    friendId: string,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/brands/${userId}/${couponId}/gift/${friendId}`,
    });
  }

  /**
   *
   * @param input
   * @param userId
   * @param requireSession
   * @returns {Observable<any>}
   */
  public cancelCoupon(
    input: ICancelCoupon,
    userId: string,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      requireSession: requireSession,
      path: `/brands/${userId}/coupon/cancel`,
      input: input,
    });
  }

  async PurchaseCoupon(
    userId: string,
    requireSession: boolean = true,
    headers: HttpHeaders = null,
    payload: IPurchaseInput
  ): Promise<PurchaseResponse> {
    return this.http2Talos.post({
      requireSession: requireSession,
      headers: headers,
      path: `/brands/purchase/${userId}`,
      input: payload,
    });
  }

  async getUserBrandProductCoupons(
    userId: string,
    requireSession: boolean = true,
    headers: HttpHeaders = null,
    payload: IListUserBrandProductCoupons
  ): Promise<Array<DetailedBrandProductCouponDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      headers: headers,
      path: `/brands/${userId}/coupons`,
      queryParams: payload,
    });
  }

  async getUserBrandProductGroupByCoupons(
    userId: string,
    requireSession: boolean = true,
    headers: HttpHeaders = null,
    payload: IListUserBrandProductCoupons
  ): Promise<Array<GroupByDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      headers: headers,
      path: `/brands/${userId}/groupByCoupons`,
      queryParams: payload,
    });
  }

  getCouponQrCode(
    userId: string,
    couponCode: string,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<string> {
    return this.http2Talos.get({
      requireSession: requireSession,
      headers: headers,
      path: `/brands/${userId}/coupon/${couponCode}`,
      queryParams: {},
    });
  }
} // END CLASS

export interface ProductGroupByDTO {
  itemId?: string;
  noOfRecords?: number;
}

export interface IListUserBrandProductCoupons {
  brandProductCouponIds?: Array<string>;
  brandProductCouponCodes?: Array<string>;
  brandProductIds?: Array<string>;
  categoryIds?: Array<string>;
  assignedUserId?: string;
  brandProductCouponTypeIds?: Array<number>;
  brandProductCouponStatusIds?: Array<number>;
  brandStoreIds?: Array<string>;
  brandStoreUserIds?: Array<string>;
  purchasedFrom?: number | string;
  purchasedTo?: number | string;
  rangeFrom?: number;
  rangeTo?: number;
  includeDetails?: boolean;
  includeBom?: boolean;
  includeQRCode?: boolean;
  matchingLevel?: boolean;
  gameTypeId?: string;
  metadatas?: boolean;
  resources?: boolean;
  friendsOnly?: boolean;
  languageIds?: Array<string>;
  responseItemTypeIds?: Array<number>;
  groupByType?: string;
}

export interface IPurchaseInput {
  brandProductId: string;
  quantity: number;
  amountsToPay: Array<IAmountInput>;
  initiatorUserId?: string;
  metadatas?: Array<{ key: string; value: string }>;
}

export interface IAmountInput {
  amount: number;
  unitTypeId: number;
  brandServiceId?: string;
}

export interface IStoreUser {
  brandStoreId?: string;
  description: string;
  virtual: boolean;
  brandId: string;
  addressBook: IAddressBook;
  active: boolean;
}

export interface IAddressBook {
  addressbookId?: string;
  addressTitle?: string;
  addressStreet?: string;
  addressNo?: string;
  cityName?: string;
  cityId?: string;
  stateName?: string;
  stateId?: string;
  postcodeName?: string;
  postcode?: IPassCode;
  floor?: string;
  label?: string;
  comments?: string;
  latitude?: number;
  longitude?: number;
  countryId?: string;
  phonebookCollection?: IPhoneBookCollection[];
  active?: boolean;
}

export interface IPassCode {
  postcodeId?: number;
  postcodeName?: string;
}

export interface IPhoneBookCollection {
  phoneBookId?: string;
  phoneNumber?: string;
  phoneType?: IPhoneType;
  active?: boolean;
}

export interface IPhoneType {
  id?: number;
  name?: string;
}

export interface IInputStore {
  brandId?: string;
  brandStoreId?: string;
  gameTypeIds?: string[];
  description?: string;
  active?: boolean;
  virtual?: boolean;
  rangeFrom?: number;
  rangeTo?: number;
  includeStatistics?: boolean;
  floor?: number;
  brandTagIds?: string[];
  lastUpdatedSince?: any;
}

export interface PurchaseResponse {
  couponCodes?: Array<string>;
  couponIds?: Array<string>;
  brandProductId?: string;
  coupons?: Array<DetailedBrandProductCouponDTO>;
}
