import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {EventRewardRulesDTO} from "../models/EventRewardRulesDTO";
import {UserEventCounterDTO} from "../models/UserEventCounterDTO";
import {EventItemRewardsDTO} from "../models/EventItemRewardsDTO";

@Injectable()
export class EventApi {
    constructor(private http2Talos: Http2Talos) {
    }


    public addEvent(input: IEventInput, requireSession: boolean = true, isV1: boolean = false): Promise<any> {
        return this.http2Talos.post({requireSession: requireSession, path: `/event/add`, input: input, isV1: isV1})
    }

    public getEventStats(userId: string, eventTypeIds: Array<number>, counterType: number, rangeFrom: number, rangeTo: number, requireSession: boolean = true): Promise<Array<UserEventCounterDTO>> {
        return this.http2Talos.get({
            path: `/event/${userId}/stats`, queryParams: {
                eventTypeIds: eventTypeIds, counterType: counterType, rangeFrom: rangeFrom, rangeTo: rangeTo
            }
        })
    }


    public getEventRewardRules(gameTypeId: string, eventTypeIds: Array<number>, resources?: Array<string>, languageIds?: Array<string>, metadatas?: Array<string>,
                               statistics?: Array<string>): Promise<Array<EventRewardRulesDTO>> {
        return this.http2Talos.get({
            path: `/event/eventRewardRules`, queryParams: {
                gameTypeId: gameTypeId, eventTypeIds: eventTypeIds, resources: resources,
                languageIds: languageIds, metadatas: metadatas, statistics: statistics
            }
        })
    }

    /**
     * Evaluate Event
     * @param {IEvaluateEventQuery} query
     * @param {IEventInput} input
     * @param {boolean} requireSession
     * @return {Promise<any>}
     */
    public evaluateEvent(query?: IEvaluateEventQuery, input?: IEventInput, requireSession: boolean = true): Promise<Array<EventItemRewardsDTO>> {
        return this.http2Talos.post({requireSession: requireSession, path: `/event/evaluate`, input: input, queryParams: query})
    }


} // END CLASS

export interface IEventInput {
    userId?: string,
    applicationId?: string,
    gameTypeId?: string,
    eventTypeId?: number,
    eventProperties?: string,
    clientTypeId?: number,
    withNoRewards?: boolean
}

export interface IEvaluateEventQuery {
    resources?: boolean | Array<string>;
    languageIds?: Array<string>;
    metadatas?: boolean | Array<string>;
    statistics?: Array<string>;
    itemIds?: Array<string>;
    itemTypeId?: number;
}