/*
 * @Author: Peter Fousteris (petfoust@gmail.com)
 * @Date: 2019-02-15 13:07:21
 * @Last Modified by: Chris Papadopoulos
 * @Last Modified time: 2023-03-31 17:30:50
 */

import { Injectable } from "@angular/core";
import { UserService } from "../../../services/talos/user.service";
import { DetailedBrandProductCouponDTO } from "../../../talosApi/models/DetailedBrandProductCouponDTO";
import { CategoryDTO } from "../../../talosApi/models/CategoryDTO";
import { AppGlobalsService } from "../../../services/appGlobals.service";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import { isArray, isNull, isNullOrUndefined, isNumber } from "util";
import { KpDictionary } from "src/services/kp_dictionary/kp.dictionary";
import { ItemApi } from "src/talosApi/api";
import { AsyncCategoryService } from "src/services/asyncTalos/async.category.service";
import { BrandsService } from "src/services/talos/brands.service";
import {
  DetailedBrandProductDTO,
  ItemsList,
  ItemStatisticsDTO,
  ResourceDTO,
} from "src/talosApi";
import { to } from "../../../../../src/utils/utils";
import { get, isNil, isObject, isString } from "lodash";
import { BrandProductItemDTO } from "../../../talosApi/models/BrandProductItemDTO";
import { UtilsService } from "src/services/utils.service";
import { BrandProductTransactionLimitsDTO } from "../../../talosApi/models/BrandProductTransactionLimitsDTO";
import { BrandProductTransactionGameTypeLimitsDTO } from "../../../talosApi/models/BrandProductTransactionGameTypeLimitsDTO";
import { AchievementService } from "../../../services/talos/achievement.service";
import { GetAchievementsParams } from "../../../talosApi/api/AchievementApi";
import { AchievementDTO } from "../../../talosApi/models/AchievementDTO";
import { CachingService } from "../../../services/caching.service";
import { ExternalSystemAuthenticationResponse } from "../../../talosApi/api/PmiApi";
import { PmiServices } from "../../../services/talos/pmi.services";
import { TranslateService } from "@ngx-translate/core";
import {
  IListUserBrandProductCoupons,
  ProductGroupByDTO,
} from "../../../talosApi/api/BrandApi";
import { ImageItem } from "../../../../../src/shared/image/image.item.dto";
import { AlertDef } from "../../../../../src/shared/interfaces/Alert";
import { ReferralService } from "../../../services/talos/referral.service";
import { ListReferralInput } from "../../../talosApi/models/ListReferralInput";
import { IndexedDataContent } from "../../../talosApi/models/IndexedDataContent";
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Resource_Types = TAConstants.Resource_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import GAME_TYPE_ID = TAConstants.Settings.GAME_TYPE_ID;
import Settings = TAConstants.Settings;
import COUPON_STATUS = TAConstants.COUPON_STATUSES;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Order_Stores = TAConstants.Order_Stores;
import moment from "moment";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/internal/operators";
import { CouponCountersPerBrandService } from "src/talosApi/models/DetailedBrandProductDTO";
import { ItemItemTypeDTO } from "src/talosApi/models/ItemItemTypeDTO";

@Injectable()
export class RewardsService {
  private boughtByCategoryId: Map<number, boolean> = new Map<number, boolean>();
  private hasReferral: boolean;
  private hasBoughtByDate: boolean;
  private hasBoughtVoucher: boolean;
  private hasBoughtSegregated: boolean;

  private data: CatalogPageData = null;

  /**
   * Reward config of rewards service
   */
  private rewardConfig = {};

  /**
   * Category required resource types of rewards service
   */
  private CategoryRequiredResourceTypes: Array<number> = [];

  /**
   * Game type required resource types of rewards service
   */
  private GameTypeRequiredResourceTypes: Array<number> = [];

  private pricePointsOfSale: string[] = ["offline", "online"];

  /**
   * Creates an instance of rewards service.
   * @param appGlobalsSrv
   * @param asyncCategoryService
   * @param brandsService
   * @param itemApi
   * @param userSrv
   */
  constructor(
    private appGlobalsSrv: AppGlobalsService,
    private asyncCategoryService: AsyncCategoryService,
    private brandsService: BrandsService,
    private itemApi: ItemApi,
    private userSrv: UserService,
    private utilSrv: UtilsService,
    private achievementSrv: AchievementService,
    private cachingSrv: CachingService,
    private pmiSrv: PmiServices,
    private translateSrv: TranslateService,
    private referralSrv: ReferralService,
    private resourcesSrv: ResourcesServices,
    private recommendationsSrv: RecommendationsService
  ) {
    if (this.appGlobalsSrv.config) {
      this.rewardConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
        {};
    }
    this.CategoryRequiredResourceTypes = [Resource_Types.NAME];
    this.GameTypeRequiredResourceTypes = [
      Resource_Types.FEATURE_IMAGE,
      Resource_Types.GAME_TYPE_LOGO,
      Resource_Types.IMAGE_LARGE_SELECTED,
    ];
  }

  /**
   * Counts Brand Products by Category
   * @param categoryIds
   * @param [bundle]
   * @returns brand products by categories
   */
  private async CountBrandProductsByCategories(
    categoryIds: Array<number>,
    bundle: boolean = true
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      let _respcountBrandProductsByCategories: KpDictionary<number> =
        new KpDictionary<number>();
      for await (const categoryId of categoryIds) {
        await this.brandsService
          .GetBrandProductsByInput(
            this.userSrv.user.user.userId,
            [],
            [categoryId],
            true,
            [],
            [],
            null,
            0,
            1
          )
          .then((_resp) => {
            if (_resp.hasOwnProperty("X-Talos-Item-Count")) {
              _respcountBrandProductsByCategories.Add(
                categoryId.toString(),
                parseInt(_resp["X-Talos-Item-Count"])
              );
            } else reject(null);
          })
          .catch((err) => {
            reject(null);
          });
        /* Any Error lead to rejection. */
      }
      resolve(_respcountBrandProductsByCategories);
    });
  }

  /**
   * Counts Brand Product Coupons (of active user per category)
   * @param categoryIds (Array of Category IDs)
   * @returns Dictionary with key pair of (Category ID - Counter)
   */
  // private async CountBrandProductCouponsByCategory(
  //   categoryIds: Array<string>,
  //   brandProductCouponStatusIds: Array<number> = []
  // ): Promise<KpDictionary<number>> {
  //   return new Promise<KpDictionary<number>>(async (resolve, reject) => {
  //     if (!categoryIds.length) reject(null);
  //     let errors = 0;
  //     let _allResolved = false;
  //     let _respcountBrandProductCouponsByCategories: KpDictionary<number> =
  //       new KpDictionary<number>();
  //     for (const categoryId of categoryIds) {
  //       const _getUserBrandProductCoupons = await to(
  //         this.brandsService.getUserBrandProductCoupons(
  //           this.userSrv.user.user.userId,
  //           {
  //             brandProductCouponStatusIds: brandProductCouponStatusIds,
  //             assignedUserId: this.userSrv.user.user.userId,
  //             includeDetails: false,
  //             includeBom: false,
  //             categoryIds: [categoryId.toString()],
  //             rangeFrom: 0,
  //             rangeTo: 1,
  //             matchingLevel: true,
  //             gameTypeId: "A7B385DC-C5A7-4312-D7A3-F1AB91B4B785",
  //           }
  //         )
  //       );
  //       if (isNullOrUndefined(_getUserBrandProductCoupons.data)) {
  //         /* Skip Single errors */
  //         errors++;
  //         _respcountBrandProductCouponsByCategories.Add(
  //           /* Consider Empty. */
  //           categoryId.toString(),
  //           0
  //         );
  //       } else {
  //         let _cnt = 0;
  //         /* Check Value. (Handle Errors) */
  //         if (
  //           _getUserBrandProductCoupons.data.hasOwnProperty(
  //             "X-Talos-Item-Count"
  //           )
  //         ) {
  //           if (
  //             !isNaN(
  //               parseInt(_getUserBrandProductCoupons.data["X-Talos-Item-Count"])
  //             )
  //           ) {
  //             _cnt = parseInt(
  //               _getUserBrandProductCoupons.data["X-Talos-Item-Count"]
  //             );
  //           } else errors++;
  //         } else errors++;
  //         _respcountBrandProductCouponsByCategories.Add(
  //           categoryId.toString(),
  //           _cnt
  //         );
  //       }
  //     }
  //     if (errors == categoryIds.length) reject(null);
  //     /* All Failed. */
  //     resolve(_respcountBrandProductCouponsByCategories);
  //   });
  // }

  public preparePresentResponse(items: KpDictionary<LoyaltyBrandProductDTO>) {}

  private formatProduct(item: ExtendedBrandProductDTO): LoyaltyBrandProductDTO {
    let respItem: LoyaltyBrandProductDTO = {
      ...item,
      categoryName: null,
      isBundle: false,
      isBundled: false,
      isListed: true,
      image: null,
      imageLarge: null,
      imageCarousel: null,
      displayDate: false,
      displayTicketsLeft: false,
      externalUrl: null,
      couponCountersPerBrandService: item.couponCountersPerBrandService,
      numOfTotalAvailableCoupons: item.numOfAvailableCoupons,
      maxAllowedPurchaseQuantity: null,
      availableCouponsThreshold: null,
      unlimitedCoupons: false,
      notRedeemable: false,
      brandProductColor: null,
      brandProductColorName: null,
      tag: null,
      purchaseType: null,
      achievementRequired: false,
      statisticsMapped: this.utilSrv.formatItemStatistics(item),
      resourcesMapped: this.utilSrv.formatItemResources(item),
      metadataMapped: this.utilSrv.formatItemMetadata(item),
    };

    respItem.totalBundleCouponsByServiceId = new Map<string, number>();
    if (respItem.prices) {
      respItem.prices.forEach((p) => {
        respItem.totalBundleCouponsByServiceId.set(p.brandServiceId, 0);
      });
    }
    if (respItem.resourcesMapped) {
      if (
        this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.IMAGE,
          this.translateSrv.currentLang
        )
      ) {
        respItem.image = {
          path: this.utilSrv.getItemMappedResource(
            respItem,
            Resource_Types.IMAGE,
            this.translateSrv.currentLang
          ),
        };
      }
      if (
        this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.COLOR,
          this.translateSrv.currentLang
        )
      ) {
        const color = this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.COLOR,
          this.translateSrv.currentLang
        );
        respItem.brandProductColor = color.startsWith("#")
          ? color
          : "#" + color;
      }
      if (
        this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.COLOR_NAME,
          this.translateSrv.currentLang
        )
      ) {
        respItem.brandProductColorName = this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.COLOR_NAME,
          this.translateSrv.currentLang
        );
      }
      if (
        this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.JSON_MULTI_CONTENT,
          this.translateSrv.currentLang
        )
      ) {
        let content = this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.JSON_MULTI_CONTENT,
          this.translateSrv.currentLang
        );
        if (!isNil(content) && isString(content)) {
          try {
            let parsed = JSON.parse(content);
            if (
              !isNil(parsed) &&
              isObject(parsed) &&
              isArray(get(parsed, "content")) &&
              get(parsed, "enabled") === true
            ) {
              let mapped: Array<ImageItem> = [];
              parsed["content"]
                .filter((p) => p.enabled === true && !isNull(p.file))
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((p) => {
                  mapped.push({
                    path: p.file,
                  });
                });
              logger.log(parsed);
              if (mapped.length) respItem.imageCarousel = mapped;
            }
          } catch (error) {
            logger.log(error);
          }
        }
        respItem.image = {
          path: this.utilSrv.getItemMappedResource(
            respItem,
            Resource_Types.IMAGE,
            this.translateSrv.currentLang
          ),
        };
      }
      if (
        this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.IMAGE_LARGE,
          this.translateSrv.currentLang
        )
      ) {
        respItem.imageLarge = {
          path: this.utilSrv.getItemMappedResource(
            respItem,
            Resource_Types.IMAGE,
            this.translateSrv.currentLang
          ),
        };
      }
      if (
        this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.NAME,
          this.translateSrv.currentLang
        )
      ) {
        respItem.name = this.utilSrv.getItemMappedResource(
          respItem,
          Resource_Types.NAME,
          this.translateSrv.currentLang
        );
      }
    }
    if (!isNull(respItem.metadataMapped)) {
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_STORE_TYPE
        )
      ) {
        respItem.storeType = respItem.metadataMapped.Item(
          METADATA_KEY.BRAND_PRODUCT_STORE_TYPE
        );
      }
      if (respItem.metadataMapped.ContainsKey(METADATA_KEY.GIFT_CARD_DEAL)) {
        respItem.isGiftCard =
          respItem.metadataMapped.Item(METADATA_KEY.GIFT_CARD_DEAL) == "true";
      }
      if (
        respItem.metadataMapped.ContainsKey(METADATA_KEY.BRAND_PRODUCT_CATEGORY)
      ) {
        respItem.isVoucher =
          respItem.metadataMapped
            .Item(METADATA_KEY.BRAND_PRODUCT_CATEGORY)
            .toUpperCase() == "ILUMA";

        respItem.isWelcome =
          respItem.metadataMapped
            .Item(METADATA_KEY.BRAND_PRODUCT_CATEGORY)
            .toUpperCase() == "WELCOME";

        respItem.isDonate =
          respItem.metadataMapped
            .Item(METADATA_KEY.BRAND_PRODUCT_CATEGORY)
            .toUpperCase() == "DONATE";

        respItem.isSegregated =
          respItem.metadataMapped
            .Item(METADATA_KEY.BRAND_PRODUCT_CATEGORY)
            .toUpperCase() == "SEGREGATED";
      }
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_EXTERNAL_SYSTEM
        )
      ) {
        respItem.isDCS =
          respItem.metadataMapped.Item(
            METADATA_KEY.BRAND_PRODUCT_EXTERNAL_SYSTEM
          ) == "7";
      }
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_EXTERNAL_URL
        )
      ) {
        respItem.externalUrl = respItem.metadataMapped.Item(
          METADATA_KEY.BRAND_PRODUCT_EXTERNAL_URL
        );
      }
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_COUNT_DOWN
        )
      ) {
        respItem.countdown =
          respItem.metadataMapped.Item(METADATA_KEY.BRAND_PRODUCT_COUNT_DOWN) ==
          "true";
      }
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_AVAILABLE_COUPONS_THRESHOLD
        )
      ) {
        respItem.availableCouponsThreshold = Number(
          respItem.metadataMapped.Item(
            METADATA_KEY.BRAND_PRODUCT_AVAILABLE_COUPONS_THRESHOLD
          )
        );
      }
      if (
        respItem.metadataMapped.ContainsKey(METADATA_KEY.BRAND_PRODUCT_THEME)
      ) {
        respItem.theme = respItem.metadataMapped.Item(
          METADATA_KEY.BRAND_PRODUCT_THEME
        );
      }
      if (
        respItem.metadataMapped.ContainsKey(METADATA_KEY.BUNDLE_BRAND_PRODUCT)
      ) {
        respItem.parentBrandPorductId = respItem.metadataMapped.Item(
          METADATA_KEY.BUNDLE_BRAND_PRODUCT
        );
      }
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
        )
      ) {
        try {
          const tmp_val = JSON.parse(
            respItem.metadataMapped.Item(
              METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
            )
          );
          if (!isNullOrUndefined(tmp_val.type)) {
            if (isArray(tmp_val.type)) {
              tmp_val.type.forEach((val) => {
                if (val in BRAND_PRODUCT_PURHCASE_TYPES) {
                  if (isNull(respItem.purchaseType)) respItem.purchaseType = [];
                  respItem.purchaseType.push(val);
                } else {
                  logger.warn(
                    respItem.metadataMapped.Item(
                      METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
                    )
                  );
                }
              });
            }
          }
        } catch (e) {
          logger.error(
            respItem.metadataMapped.Item(
              METADATA_KEY.BRAND_PRODUCT_REDEEM_OPTIONS
            )
          );
        }
      }
      if (respItem.metadataMapped.ContainsKey("BrandProduct.externalUrl")) {
        respItem.externalUrl = respItem.metadataMapped.Item(
          "BrandProduct.externalUrl"
        );
      }
      if (
        respItem.metadataMapped.ContainsKey(
          METADATA_KEY.BRAND_PRODUCT_IS_NOT_REDEEMABLE
        )
      ) {
        respItem.notRedeemable = Boolean(
          respItem.metadataMapped.Item(
            METADATA_KEY.BRAND_PRODUCT_IS_NOT_REDEEMABLE
          )
        );
      }
      if (respItem.resourcesMapped.ContainsKey(String(Resource_Types.ACTION))) {
        if (
          respItem.resourcesMapped
            .Item(String(Resource_Types.ACTION))
            .ContainsKey(this.translateSrv.currentLang)
        ) {
          respItem.action = respItem.resourcesMapped
            .Item(String(Resource_Types.ACTION))
            .Item(this.translateSrv.currentLang).textResource;
        }
      }
      if (
        respItem.resourcesMapped.ContainsKey(
          String(Resource_Types.ACTION_TYPE_DESCRIPTION)
        )
      ) {
        if (
          respItem.resourcesMapped
            .Item(String(Resource_Types.ACTION_TYPE_DESCRIPTION))
            .ContainsKey(this.translateSrv.currentLang)
        ) {
          respItem.actionLabel = respItem.resourcesMapped
            .Item(String(Resource_Types.ACTION_TYPE_DESCRIPTION))
            .Item(this.translateSrv.currentLang).textResource;
        }
      }
    }
    if (respItem.transactionLimitations) {
      const limitation = respItem.transactionLimitations.find(
        (t: BrandProductTransactionLimitsDTO) => {
          return t.limitTypeId == 2;
        }
      );
      if (limitation && limitation.limits) {
        const limit = limitation.limits.find(
          (l: BrandProductTransactionGameTypeLimitsDTO) => {
            return l.gameTypeId == Settings.GAME_TYPE;
          }
        );
        if (limit) {
          respItem.maxAllowedPurchaseQuantity =
            !isNaN(limit.limit) && limit.limit > 0 ? limit.limit : null;
        }
      }
      const limitationAchievement = respItem.transactionLimitations.find(
        (t: BrandProductTransactionLimitsDTO) => {
          return t.limitTypeId == 5;
        }
      );
      if (limitationAchievement && limitationAchievement.limits) {
        const limit = limitationAchievement.limits.find(
          (l: BrandProductTransactionGameTypeLimitsDTO) => {
            return l.gameTypeId == Settings.GAME_TYPE;
          }
        );
        if (
          limit &&
          respItem.achievement &&
          respItem.achievement.userAchievement &&
          limit.conditionStatus === 1
        ) {
          respItem.achievementRequired = true;
        }
      }
      const limitationOTP = respItem.transactionLimitations.find(
        (t: BrandProductTransactionLimitsDTO) => {
          return t.limitTypeId == 6;
        }
      );
      if (limitationOTP) {
        if (
          limitationOTP.limits &&
          limitationOTP.limits.length > 0 &&
          limitationOTP.limits[0].prerequisiteItem
        )
          respItem.oTPConfig = {
            oTPRequired: true,
            prerequisiteItem: limitationOTP.limits[0].prerequisiteItem,
          };
      }
    }

    if (
      !isNullOrUndefined(respItem.bundledProducts) &&
      isArray(respItem.bundledProducts) &&
      respItem.bundledProducts.length
    ) {
      if (respItem.prices) {
        respItem.prices.sort((a, b) =>
          a.brandServiceId < b.brandServiceId ? 1 : -1
        );

        respItem.prices.forEach((price) => {
          for (const bundledProduct of respItem.bundledProducts) {
            const coupons = this.getAvailableCoupons(
              bundledProduct,
              price.brandServiceId
            );

            let c = respItem.totalBundleCouponsByServiceId.get(
              price.brandServiceId
            );
            c += coupons;
            respItem.totalBundleCouponsByServiceId.set(price.brandServiceId, c);
          }
        });
      }
      respItem.isBundled = true;
    } else {
      if (respItem.prices) {
        respItem.prices.sort((a, b) =>
          a.brandServiceId < b.brandServiceId ? 1 : -1
        );
        respItem.prices.forEach((price) => {
          const coupons = this.getAvailableCoupons(
            respItem,
            price.brandServiceId
          );

          let c = respItem.totalBundleCouponsByServiceId.get(
            price.brandServiceId
          );
          c += coupons;
          respItem.totalBundleCouponsByServiceId.set(price.brandServiceId, c);
        });
      }
    }
    return respItem;
  }

  /**
   * Gets brand products by categories
   * @param categoryIds
   * @returns brand products by categories
   */
  public async getBrandProductsByCategories(
    categoryIds: Array<number>,
    brandProductIds: Array<string> = [],
    bundle: boolean = true,
    withDateValidation: boolean = true,
    includeDetails: boolean = true,
    statistics: number | boolean = true,
    resources: boolean = true,
    metadatas: boolean = true,
    orderType: string = null,
    includeStatistics: boolean = false,
    matchingLevel: boolean = false,
    rangeFrom?: number,
    rangeTo?: number,
    includeAchievements?: boolean,
    transactionLimitTypeIds?: number,
    brandProductTypeId?: string
  ): Promise<KpDictionary<LoyaltyBrandProductDTO>> {
    return new Promise<KpDictionary<LoyaltyBrandProductDTO>>(
      async (resolve, reject) => {
        let _retExtendedBrandProducts =
          new KpDictionary<LoyaltyBrandProductDTO>();
        const _asyncGetBrandProductsByCategoryId = await to(
          this.brandsService.asyncGetBrandProductsByCategoryId(
            this.userSrv.user.user.userId,
            brandProductIds,
            categoryIds,
            bundle,
            withDateValidation,
            includeDetails,
            statistics,
            resources,
            metadatas,
            includeStatistics,
            matchingLevel,
            TAConstants.Settings.LANGUAGES,
            orderType,
            rangeFrom,
            rangeTo,
            transactionLimitTypeIds,
            brandProductTypeId
          )
        );
        if (isNullOrUndefined(_asyncGetBrandProductsByCategoryId.data))
          reject(null);
        const _brandProducts =
          _asyncGetBrandProductsByCategoryId.data as ItemsList<DetailedBrandProductDTO>;

        if (includeAchievements) {
          const achievementIds = [];
          const achievementsIdByContentId: Map<string, string> = new Map<
            string,
            string
          >();
          if (_brandProducts && _brandProducts.length > 0) {
            _brandProducts.forEach((respItem: LoyaltyBrandProductDTO) => {
              if (respItem.transactionLimitations) {
                const limitationAchievement =
                  respItem.transactionLimitations.find(
                    (t: BrandProductTransactionLimitsDTO) => {
                      return t.limitTypeId == 5;
                    }
                  );
                if (limitationAchievement && limitationAchievement.limits) {
                  const limit = limitationAchievement.limits.find(
                    (l: BrandProductTransactionGameTypeLimitsDTO) => {
                      return l.gameTypeId == Settings.GAME_TYPE;
                    }
                  );
                  if (
                    limit &&
                    limit.prerequisiteItem &&
                    limit.prerequisiteItem.itemTypeId ==
                      ITEM_TYPE.ACHIEVEMENT_TYPE
                  ) {
                    achievementsIdByContentId.set(
                      respItem.brandProductId,
                      limit.prerequisiteItem.itemId
                    );
                    if (!achievementIds.includes(limit.prerequisiteItem.itemId))
                      achievementIds.push(limit.prerequisiteItem.itemId);
                  }
                }
              }
            });
          }

          if (achievementIds.length > 0) {
            const input: GetAchievementsParams = {
              achievementIds: achievementIds,
              active: true,
              currentlyActive: true,
              matchingLevel: true,
              resources: true,
              languageIds: TAConstants.Settings.LANGUAGES,
            };
            const results = await this.achievementSrv
              .getAchievements(input)
              .catch((err) => {});
            if (results && results.length > 0) {
              achievementsIdByContentId.forEach(
                (value: string, key: string) => {
                  const b: LoyaltyBrandProductDTO = _brandProducts.find(
                    (v: LoyaltyBrandProductDTO) => {
                      return key == v.brandProductId;
                    }
                  );
                  const a: AchievementDTO = results.find(
                    (v: AchievementDTO) => {
                      return value == v.achievementId;
                    }
                  );
                  if (b && a) {
                    b.achievement = a;
                  }
                }
              );
            }
          }
        }

        if (_brandProducts) {
          await _brandProducts.map((respItem: LoyaltyBrandProductDTO) => {
            respItem = this.formatProduct(respItem);
            if (respItem.bundledProducts && isArray(respItem.bundledProducts)) {
              let formattedBundles: Array<LoyaltyBrandProductDTO> = [];
              respItem.bundledProducts.forEach((pb) => {
                formattedBundles.push({
                  ...this.formatProduct(pb as LoyaltyBrandProductDTO),
                  isBundle: true,
                  isListed: false,
                });
              });
              respItem.bundledProducts = formattedBundles;
            }
            _retExtendedBrandProducts.Add(respItem.brandProductId, respItem);
          });
        }
        if (
          _asyncGetBrandProductsByCategoryId.data &&
          _asyncGetBrandProductsByCategoryId.data[
            TAConstants.TAHeaders.ITEM_COUNT
          ]
        )
          _retExtendedBrandProducts[TAConstants.TAHeaders.ITEM_COUNT] =
            _asyncGetBrandProductsByCategoryId.data[
              TAConstants.TAHeaders.ITEM_COUNT
            ];
        resolve(_retExtendedBrandProducts);
      }
    );
  }

  /**
   * Gets user brand product coupons by categories
   * @param categoryIds
   * @returns user brand product coupons by categories
   */
  private async getUserBrandProductCouponsByCategories(
    categoryIds: Array<string>,
    brandProductCouponStatusIds: Array<number> = [],
    brandProductCouponCodes: Array<string> = [],
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number
  ): Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>> {
    return new Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>>(
      async (resolve, reject) => {
        /* Fetch All user Coupons. */
        let _ret: KpDictionary<ExtendeDetailedBrandProductCouponDTO> =
          new KpDictionary<ExtendeDetailedBrandProductCouponDTO>();
        const _brandProductCoupons = await to(
          this.brandsService.getUserBrandProductCoupons(
            this.userSrv.user.user.userId,
            {
              assignedUserId: this.userSrv.user.user.userId,
              includeDetails: false,
              includeBom: true,
              categoryIds: categoryIds,
              includeQRCode: true,
              brandProductCouponCodes: brandProductCouponCodes,
              brandProductCouponStatusIds: brandProductCouponStatusIds,
              rangeFrom: !isNullOrUndefined(rangeFrom) ? rangeFrom : 0,
              rangeTo: !isNullOrUndefined(rangeTo) ? rangeTo : -1,
              matchingLevel: true,
              gameTypeId: GAME_TYPE_ID,
              resources: true,
              languageIds: TAConstants.Settings.LANGUAGES,
            }
          )
        );
        /* Check And Format Data. */
        if (isNullOrUndefined(_brandProductCoupons.data)) reject(null);
        if (!_brandProductCoupons.data.length) reject(null);
        let _tmpResp: Array<ExtendeDetailedBrandProductCouponDTO> = [];
        _brandProductCoupons.data.map(
          (respItem: ExtendeDetailedBrandProductCouponDTO) => {
            respItem.productItemsMapped =
              new KpDictionary<ExtendeDetailedBrandProductCouponDTO>();
            if (isArray(respItem.productItems)) {
              for (const productItem of respItem.productItems) {
                respItem.productItemsMapped.Add(
                  productItem.brandProductItemId,
                  productItem
                );
              }
            }
            _tmpResp.push(respItem);
          }
        );
        if (!isNullOrUndefined(orderType)) {
          if (orderType === "BY_OLDER") {
            _tmpResp = _tmpResp.reverse();
          }
        }
        _tmpResp.map((respItem) => {
          _ret.Add(respItem.code, respItem);
          // ELEGXOS
          // _ret.Add(respItem.externalReferenceId, respItem);
        });
        resolve(_ret);
      }
    );
  }

  /**
   * Gets user brand product coupons by categories
   * @param categoryIds
   * @returns user brand product coupons by categories
   */
  private async getUserBrandProductCouponsByCategoriesAlt(
    categoryIds: Array<number>,
    brandProductCouponStatusIds: Array<number> = [],
    matchingLevel: boolean = true,
    includeDetails: boolean = true,
    includeBom: boolean = true,
    includeQRCode: boolean = true,
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number,
    responseItemTypeIds?: Array<number>,
    purchasedTo?: number | string
  ): Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>> {
    return new Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>>(
      async (resolve, reject) => {
        /* Fetch All user Coupons. */
        let _ret: KpDictionary<ExtendeDetailedBrandProductCouponDTO> =
          new KpDictionary<ExtendeDetailedBrandProductCouponDTO>();
        const _brandProductCoupons = await to(
          this.brandsService.getUserBrandProductCoupons(
            this.userSrv.user.user.userId,
            {
              assignedUserId: this.userSrv.user.user.userId,
              includeDetails: includeDetails,
              includeBom: includeBom,
              categoryIds: categoryIds.map(String),
              includeQRCode: includeQRCode,
              brandProductCouponStatusIds: brandProductCouponStatusIds,
              rangeFrom: !isNullOrUndefined(rangeFrom) ? rangeFrom : 0,
              rangeTo: !isNullOrUndefined(rangeTo) ? rangeTo : -1,
              matchingLevel: matchingLevel,
              responseItemTypeIds: responseItemTypeIds,
              gameTypeId: GAME_TYPE_ID,
              resources: true,
              metadatas: true,
              languageIds: TAConstants.Settings.LANGUAGES,
              purchasedTo: purchasedTo,
            }
          )
        );
        /* Check And Format Data. */
        if (isNullOrUndefined(_brandProductCoupons.data)) reject(null);
        if (!_brandProductCoupons.data.length) reject(null);
        let _tmpResp: Array<ExtendeDetailedBrandProductCouponDTO> = [];
        _brandProductCoupons.data.map(
          (respItem: ExtendeDetailedBrandProductCouponDTO) => {
            respItem.productItemsMapped =
              new KpDictionary<ExtendeDetailedBrandProductCouponDTO>();
            if (isArray(respItem.productItems)) {
              for (const productItem of respItem.productItems) {
                respItem.productItemsMapped.Add(
                  productItem.brandProductItemId,
                  productItem
                );
              }
            }
            respItem.metadataMapped = this.utilSrv.formatItemMetadata(respItem);
            respItem.resourcesMapped =
              this.utilSrv.formatItemResources(respItem);
            respItem.purchaseType = null;
            if (
              respItem.metadataMapped.Count() &&
              respItem.metadataMapped.ContainsKey(
                METADATA_KEY.BRAND_PRODUCT_COUPON_PURCHASE_TYPE
              )
            )
              respItem.purchaseType = respItem.metadataMapped.Item(
                METADATA_KEY.BRAND_PRODUCT_COUPON_PURCHASE_TYPE
              );
            _tmpResp.push(respItem);
          }
        );
        if (!isNullOrUndefined(orderType)) {
          if (orderType === "BY_OLDER") {
            _tmpResp = _tmpResp.reverse();
          }
        }
        _tmpResp.map((respItem) => {
          _ret.Add(respItem.code, respItem);
        });
        _ret["X-Talos-Item-Count"] =
          _brandProductCoupons.data["X-Talos-Item-Count"];
        resolve(_ret);
      }
    );
  }

  /**
   * Formats bundle products
   * @param input
   * @returns
   */
  public appendBundleProductsFormatted(
    input: KpDictionary<LoyaltyBrandProductDTO>
  ) {
    let _ret: KpDictionary<LoyaltyBrandProductDTO> =
      new KpDictionary<LoyaltyBrandProductDTO>();
    for (const respItem of input.Values()) {
      _ret.Add(respItem.brandProductId, respItem);
      if (respItem.isBundled && isArray(respItem.bundledProducts)) {
        for (const _innerRespItem of respItem.bundledProducts as Array<LoyaltyBrandProductDTO>) {
          _ret.Add(_innerRespItem.brandProductId, _innerRespItem);
        }
      }
    }
    return _ret;
  }

  /**
   * Gets product selection
   * @param categoryIds
   * @param [orderType]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @returns product selection
   */
  public async getProductSelection(
    categoryIds: KpDictionary<ExtendedCategoryDTO>,
    selectedCategoryId: number,
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number,
    statistics?: number | boolean,
    includeAchievements?: boolean,
    transactionLimitTypeIds?: number
  ): Promise<KpDictionary<LoyaltyBrandProductDTO>> {
    return new Promise<KpDictionary<LoyaltyBrandProductDTO>>(
      async (resolve, reject) => {
        if (isNullOrUndefined(categoryIds) || !categoryIds.Count())
          reject(null);
        else {
          let _reqCategories: Array<number> = categoryIds.Keys().map(Number);
          if (!_reqCategories.length) reject(null);
          else {
            if (
              !isNullOrUndefined(selectedCategoryId) &&
              isNumber(selectedCategoryId) &&
              selectedCategoryId > 0
            ) {
              _reqCategories = [selectedCategoryId];
            }
            const _getBrandProductsByCategories = await to(
              this.getBrandProductsByCategories(
                _reqCategories,
                [],
                true,
                true,
                true,
                statistics,
                true,
                true,
                orderType,
                false,
                true,
                rangeFrom,
                rangeTo,
                includeAchievements,
                transactionLimitTypeIds
              )
            );
            if (isNullOrUndefined(_getBrandProductsByCategories.data))
              reject(null);
            else {
              logger.log(
                "getBrandProductsByCategories",
                _getBrandProductsByCategories.data
              );
              let _BrandProducts =
                _getBrandProductsByCategories.data as KpDictionary<LoyaltyBrandProductDTO>;
              /* Format Data. */
              _BrandProducts =
                this.appendBundleProductsFormatted(_BrandProducts);
              if (!_BrandProducts.Count())
                reject(null); /* Should not Happen. */
              else {
                logger.log("_BrandProducts", _BrandProducts);
                _BrandProducts[TAConstants.TAHeaders.ITEM_COUNT] =
                  _getBrandProductsByCategories.data[
                    TAConstants.TAHeaders.ITEM_COUNT
                  ];
                resolve(_BrandProducts);
              }
            }
          }
        }
      }
    );
  }

  /**
   * Products sub category
   * @param product
   * @param categories
   * @returns sub category
   */
  public productSubCategory(
    product: ExtendedBrandProductDTO,
    categories: KpDictionary<ExtendedCategoryDTO>
  ): string {
    let ret = null;
    try {
      if (isNullOrUndefined(product) || isNullOrUndefined(categories))
        return null;
      if (!categories.Count()) return null;
      if (isNullOrUndefined(product.subcategoryIds)) return null;
      if (!isArray(product.subcategoryIds)) return null;
      if (isArray(product.subcategoryIds) && !product.subcategoryIds.length)
        return null;
      product.subcategoryIds.forEach((categoryId) => {
        if (categories.ContainsKey(categoryId)) ret = categoryId;
      });
    } catch (e) {
      logger.error(e);
    }
    return ret;
  }

  /**
   * Gets product price
   * @param product
   */
  public getProductPrice(product: ExtendedBrandProductDTO): number | number[] {
    let price = 0;
    try {
      if (isNullOrUndefined(product)) return price;
      if (isNullOrUndefined(product.prices) || !isArray(product.prices))
        return price;
      if (isNullOrUndefined(product.prices)) return price;
      if (product.prices.length > 1) {
        return product.prices.map((price) => price.prices[0].amount.value);
      } else {
        return [product.prices[0].prices[0].amount.value];
      }
    } catch (e) {
      logger.error(e);
    }
    return price;
  }

  // public getProductPriceScalled(product: ExtendedBrandProductDTO) {
  //   let price = null;
  //   if (isNullOrUndefined(product)) return price;
  //   if (isNullOrUndefined(product.prices)) return price;
  //   let _points = !product.prices
  //     ? 0
  //     : product.prices[0].prices[0].amount.value;
  // }

  public getProductPointTags(product, parent, achievementProduct) {
    const getBrandServiceId = (index) => {
      const price = achievementProduct.prices
        ? achievementProduct.prices[index]
        : null;
      return price ? price.brandServiceId : null;
    };

    const getPointsLabel = (salePoints) => {
      const amount =
        salePoints > 0 ? salePoints.toLocaleString().replace(/,/g, ".") : null;
      return !!amount
        ? this.translateSrv
            .instant(product.isDonate ? "DONATE_POINTS_LABEL" : "POINTS_LABEL")
            .replace("$points", amount)
        : null;
    };

    let pointTags = [];
    const numberPoints = this.getProductNumberOfPoints(product, parent);
    numberPoints.forEach((salePoints, index) => {
      const brandServiceId = getBrandServiceId(index);
      const pointsLabel = getPointsLabel(salePoints);
      let variant = "info";
      let disabled = false;
      if (product.isSegregated && this.hasBoughtSegregated) {
        disabled = true;
      }
      if (product.isVoucher && this.hasBoughtVoucher) {
        disabled = true;
      }
      if (
        this.productHasLimitation(product) &&
        this.isProductLimitReached(product)
      ) {
        disabled = true;
      }
      if (
        product.isBundle &&
        !isNull(parent) &&
        this.productHasLimitation(parent) &&
        this.isProductLimitReached(parent)
      ) {
        disabled = true;
      }
      if (product.isBundle && parent && brandServiceId) {
        if (this.getAvailableCoupons(product, brandServiceId) == 0) {
          // if (this.productIsTerminatedBundle(parent, product)) {
          disabled = true;
        }
      } else if (brandServiceId) {
        if (this.getAvailableCoupons(product, brandServiceId) == 0) {
          // if (this.productIsTerminatedNewByService(product, brandServiceId)) {
          disabled = true;
        }
      }
      if (this.productHasRequiredAchievement(achievementProduct)) {
        if (!this.productAchievementCompleted(achievementProduct)) {
          disabled = true;
        }
      }

      if (
        !this.canUserPurchaseProduct(
          product,
          parent,
          brandServiceId
            ? this.getPricePOSById(brandServiceId)
            : index === 0
            ? "offline"
            : "online"
        )
      ) {
        variant = "error";
      }

      pointTags.push({
        label: pointsLabel,
        disabled,
        variant: variant as any,
        pointOfSale: brandServiceId
          ? this.getPricePOSById(brandServiceId)
          : index === 0
          ? "offline"
          : "online",
      });
    });
    return pointTags;
  }

  public productNoteConditionalOverlap(
    product: LoyaltyBrandProductDTO,
    parent: LoyaltyBrandProductDTO,
    achievementProduct: LoyaltyBrandProductDTO,
    numberPoints: number[],
    countDownValue: number,
    brandServiceId?: string
  ): ProductNote | null {
    /* -- If Product is Terminated. */

    if (product.isSegregated && this.hasBoughtSegregated) {
      return {
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      };
    }

    if (product.isVoucher && this.hasBoughtVoucher) {
      return {
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      };
    }

    if (
      this.productHasLimitation(product) &&
      this.isProductLimitReached(product)
    ) {
      /* User has Used the product. */
      return {
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      };
    } else if (
      product.isBundle &&
      !isNull(parent) &&
      this.productHasLimitation(parent) &&
      this.isProductLimitReached(parent)
    ) {
      /* User has Used the product. */
      return {
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      };
    }

    let terminated = false;
    if (product.isBundle && parent) {
      if (this.productIsTerminatedBundle(parent, product)) {
        return {
          variant: "error",
          message: this.translateSrv.instant("NO_AVAILABLE_COUPONS_COLOR"),
        };
      }
    } else {
      if (this.productIsTerminatedFull(product)) {
        return {
          variant: "error",
          message: this.translateSrv.instant("TERMINATO"),
        };
      }
    }
    /* -- If Product is Under an Achievement. */

    if (this.productHasRequiredAchievement(achievementProduct)) {
      let achievementFormattedResources: KpDictionary<
        KpDictionary<ResourceDTO>
      > = null;
      if (
        achievementProduct.achievement &&
        achievementProduct.achievement.resources
      ) {
        achievementFormattedResources = this.utilSrv.formatItemResources(
          achievementProduct.achievement
        );
      }
      if (!this.productAchievementCompleted(achievementProduct)) {
        let text = this.translateSrv.instant("ACHIEVEMENT_REQUIRED")
          ? this.translateSrv.instant("ACHIEVEMENT_REQUIRED")
          : null;
        if (
          !isNull(achievementFormattedResources) &&
          achievementFormattedResources.Count()
        ) {
          text = this.utilSrv.getItemResource(
            { resourcesMapped: achievementFormattedResources },
            Resource_Types.CHALLENGE_LOST_TEXT,
            this.translateSrv.currentLang
          );
        }
        if (!isNull(text) && isString(text) && text.length) {
          return {
            variant: "warning",
            message: text,
          };
        }
      } else {
        /* User Can Purchase and Product has Coupons. */
        let text = this.translateSrv.instant("ACHIEVEMENT_COMPLETED")
          ? this.translateSrv.instant("ACHIEVEMENT_COMPLETED")
          : null;
        if (
          !isNull(achievementFormattedResources) &&
          achievementFormattedResources.Count()
        ) {
          text = this.utilSrv.getItemResource(
            { resourcesMapped: achievementFormattedResources },
            Resource_Types.CHALLENGE_WON_TEXT,
            this.translateSrv.currentLang
          );
        }
        if (
          this.canUserPurchaseProduct(product, parent)
          // && this.productIsTerminated(product)
        ) {
          if (!isNull(text) && isString(text) && text.length) {
            return {
              variant: "success",
              message: text,
            };
          }
        }
      }
    }

    if (
      this.productHasLimitation(product) &&
      !this.isProductLimitReached(product)
    ) {
      /* User Can Purchase and Product has Coupons. */
      return {
        variant: "warning",
        message: this.translateSrv.instant(
          this.appGlobalsSrv.isMobile
            ? "PRODUCT_LIMITATION_WARNING_MOBILE"
            : "PRODUCT_LIMITATION_WARNING"
        ),
      };
    } else if (
      product.isBundle &&
      !isNull(parent) &&
      this.productHasLimitation(parent) &&
      !this.isProductLimitReached(parent)
    ) {
      /* User Can Purchase and Product has Coupons. */
      return {
        variant: "warning",
        message: this.translateSrv.instant(
          this.appGlobalsSrv.isMobile
            ? "PRODUCT_LIMITATION_WARNING_MOBILE"
            : "PRODUCT_LIMITATION_WARNING"
        ),
      };
    }
    /* -- If Product has Coupon Countdown. */
    if (
      !isNull(countDownValue) &&
      !this.isProductLimitReached(parent) &&
      !this.isProductLimitReached(product)
    ) {
      // if (
      //   product.brandProductId === "BD1B3827-837E-4F81-837A-621432371264" ||
      //   product.brandProductId === "D6E16797-EEDA-4DCC-83CC-A80318DC9F06"
      // )
      //   debugger;
      if (brandServiceId) {
        const coupons = this.getAvailableCoupons(product, brandServiceId);
        if (coupons < countDownValue && coupons > 0) {
          let variant = "threshold-light";
          if (product.theme === "66267") {
            variant = "threshold";
          }
          return {
            variant: "warning",
            backgroundColor: product.brandProductColor,
            message: this.translateSrv
              .instant(`LAST_N_ITEMS_ON_PRODUCT_SHORT_${brandServiceId}`)
              .replace("$count", coupons),
          };
        }
      } else if (
        !parent &&
        !product.isBundle &&
        !product.isBundled &&
        !brandServiceId
      ) {
        let note: ProductNote = null;
        product.prices.forEach((price) => {
          const coupons = this.getAvailableCoupons(
            product,
            price.brandServiceId
          );
          if (coupons < countDownValue && coupons > 0) {
            let variant = "threshold-light";
            if (product.theme === "66267") {
              variant = "threshold";
            }
            note = {
              variant: "warning",
              backgroundColor: product.brandProductColor,
              message: this.translateSrv
                .instant(
                  `LAST_N_ITEMS_ON_PRODUCT_SHORT_${price.brandServiceId}`
                )
                .replace("$count", coupons),
            };
          }
        });
        if (note) return note;
      } else if (parent && parent.prices) {
        let note: ProductNote = null;
        parent.prices.forEach((p) => {
          if (!note) {
            const coupons = this.getAvailableCoupons(product, p.brandServiceId);
            if (coupons < countDownValue && coupons > 0) {
              let variant = "threshold-light";
              if (product.theme === "66267") {
                variant = "threshold";
              }

              note = {
                variant: "warning",
                backgroundColor: product.brandProductColor,
                message: this.translateSrv
                  .instant(`LAST_N_ITEMS_ON_PRODUCT_SHORT_${p.brandServiceId}`)
                  .replace("$count", coupons),
              };
            }
          }
        });
        if (note) return note;
      }

      // if (
      //   !isNull(countDownValue) &&
      //   product.numOfTotalAvailableCoupons < countDownValue &&
      //   !this.isProductLimitReached(parent) &&
      //   !this.isProductLimitReached(product)
      // ) {
      //   let variant = "threshold-light";
      //   if (product.theme === "66267") {
      //     variant = "threshold";
      //   }
      //   return {
      //     variant: variant as any,
      //     backgroundColor: product.brandProductColor,
      //     message: this.translateSrv
      //       .instant("LAST_N_ITEMS_ON_PRODUCT_SHORT")
      //       .replace("$count", product.numOfTotalAvailableCoupons),
      //   };
      // }
    }

    /* -- If User Cannot Purchase. */
    if (numberPoints) {
      let results = [];
      numberPoints.forEach((_, index) => {
        // get pos of points
        results.push(
          this.canUserPurchaseProduct(
            product,
            parent,
            this.getPricePOSById(
              achievementProduct.prices[index].brandServiceId
            )
          )
        );
      });

      if (results.every((r) => !r)) {
        return {
          variant: "error",
          message: this.translateSrv
            .instant(
              this.appGlobalsSrv.isMobile
                ? "PRODUCT_MISSING_POINTS_TO_PURCHASE_MOBILE"
                : "PRODUCT_MISSING_POINTS_TO_PURCHASE"
            )
            .replace(
              "$points",
              // Get the minimum amount of prices
              Math.ceil(
                this.getMinimumPrice(numberPoints) - this.userSrv.user.balance
              )
            ),
        };
        // Not all prices are affordable
      } else if (results.some((r) => !r)) {
        /*
         * Determine which one is false
         * If there are many, catch the minimum amount
         */
        // Get the index of false elements
        const affordableIndexes: number[] = results.reduce(
          (acc, value: boolean, i) => (!value && acc.push(i), acc),
          []
        );
        logger.log("reduced", affordableIndexes);
        return {
          variant: "error",
          message: this.translateSrv
            .instant(
              this.appGlobalsSrv.isMobile
                ? `PRODUCT_MISSING_POINTS_TO_PURCHASE_${
                    achievementProduct.prices[affordableIndexes[0]]
                      .brandServiceId
                  }_MOBILE`
                : `PRODUCT_MISSING_POINTS_TO_PURCHASE_${
                    achievementProduct.prices[affordableIndexes[0]]
                      .brandServiceId
                  }`
            )
            .replace(
              "$points",
              Math.ceil(
                (affordableIndexes.length > 1
                  ? this.getMinimumPrice(numberPoints)
                  : numberPoints[affordableIndexes[0]]) -
                  this.userSrv.user.balance
              )
            ),
        };
      }
    }
    /*-- If product is parent and has no coupons */
    // if (!product.isBundle && parent && !parent.numOfTotalAvailableCoupons) {
    //   return {
    //     variant: "warning",
    //     backgroundColor: product.brandProductColor,
    //     message: this.translateSrv
    //       .instant("PARENT_PRODUCT_NO_COUPONS")
    //   };
    // } else if (product.isBundle && product.numOfTotalAvailableCoupons) {
    // /* Product is Bundled and has no coupons */
    //   return {
    //     variant: "warning",
    //     backgroundColor: product.brandProductColor,
    //     message: this.translateSrv
    //       .instant("BUNDLE_PRODUCT_NO_COUPONS")
    //   };
    // }
    /*-- If Product is Limited Set Related Content. */

    return null;
  }

  public productNoteConditionalOverlapNew(
    product: LoyaltyBrandProductDTO,
    parent: LoyaltyBrandProductDTO,
    achievementProduct: LoyaltyBrandProductDTO,
    numberPoints: number[],
    countDownValue: number,
    brandServiceId?: string
  ): Array<ProductNote> {
    /* -- If Product is Terminated. */

    let result = [];
    let terminated = false;

    if (product.isSegregated && this.hasBoughtSegregated) {
      result.push({
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      });
      return result;
    }

    if (product.isVoucher && this.hasBoughtVoucher) {
      result.push({
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      });
      return result;
    }

    if (
      this.productHasLimitation(product) &&
      this.isProductLimitReached(product)
    ) {
      /* User has Used the product. */
      result.push({
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      });
      return result;
    } else if (
      product.isBundle &&
      !isNull(parent) &&
      this.productHasLimitation(parent) &&
      this.isProductLimitReached(parent)
    ) {
      /* User has Used the product. */
      result.push({
        variant: "success",
        message: this.translateSrv.instant("USED_NEW"),
      });
      return result;
    }

    if (product.isBundle) {
      if (this.productIsTerminatedBundle(parent, product)) {
        result.push({
          message: this.translateSrv.instant("TERMINATO_BADGE_LABEL"),
          backgroundColor: `${this.translateSrv.instant(
            "TERMINATO_BADGE_BACKGROUND_COLOR"
          )}`,
          variant: "warning",
        });
      } else {
        let note: ProductNote;
        parent.prices.forEach((p) => {
          if (this.getAvailableCoupons(product, p.brandServiceId) == 0) {
            result.push({
              message: this.translateSrv.instant(
                p.brandServiceId == "5A28B062-5043-47AF-D8A4-729BB0D73A7C"
                  ? "TERMINATO_ONLINE"
                  : "TERMINATO_OFFLINE"
              ),
              backgroundColor: `${this.translateSrv.instant(
                "TERMINATO_BADGE_BACKGROUND_COLOR"
              )}`,
              variant: "warning",
            });
          }
        });
      }
    } else {
      if (this.productIsTerminatedFull(product)) {
        result.push({
          message: this.translateSrv.instant("TERMINATO"),
          backgroundColor: `${this.translateSrv.instant(
            "TERMINATO_BADGE_BACKGROUND_COLOR"
          )}`,
          variant: "warning",
        });
      } else {
        let note: ProductNote;
        product.prices.forEach((p) => {
          if (this.getAvailableCoupons(product, p.brandServiceId) == 0) {
            result.push({
              message: this.translateSrv.instant(
                p.brandServiceId == "5A28B062-5043-47AF-D8A4-729BB0D73A7C"
                  ? "TERMINATO_ONLINE"
                  : "TERMINATO_OFFLINE"
              ),
              backgroundColor: `${this.translateSrv.instant(
                "TERMINATO_BADGE_BACKGROUND_COLOR"
              )}`,
              variant: "warning",
            });
          }
        });
      }
    }

    // if (product.isBundle && parent) {
    //   if (this.productIsTerminatedBundle(parent, product)) {
    //     result.push({
    //       variant: "error",
    //       message: this.translateSrv.instant("NO_AVAILABLE_COUPONS_COLOR"),
    //     });
    //   }
    // } else {
    //   if (this.productIsTerminatedFull(product)) {
    //     result.push({
    //       variant: "error",
    //       message: this.translateSrv.instant("TERMINATO"),
    //     });
    //   }
    // }
    /* -- If Product is Under an Achievement. */
    if (this.productHasRequiredAchievement(achievementProduct)) {
      let achievementFormattedResources: KpDictionary<
        KpDictionary<ResourceDTO>
      > = null;
      if (
        achievementProduct.achievement &&
        achievementProduct.achievement.resources
      ) {
        achievementFormattedResources = this.utilSrv.formatItemResources(
          achievementProduct.achievement
        );
      }
      if (!this.productAchievementCompleted(achievementProduct)) {
        let text = this.translateSrv.instant("ACHIEVEMENT_REQUIRED")
          ? this.translateSrv.instant("ACHIEVEMENT_REQUIRED")
          : null;
        if (
          !isNull(achievementFormattedResources) &&
          achievementFormattedResources.Count()
        ) {
          text = this.utilSrv.getItemResource(
            { resourcesMapped: achievementFormattedResources },
            Resource_Types.CHALLENGE_LOST_TEXT,
            this.translateSrv.currentLang
          );
        }
        if (!isNull(text) && isString(text) && text.length) {
          result.push({
            variant: "warning",
            message: text,
          });
        }
      } else {
        /* User Can Purchase and Product has Coupons. */
        let text = this.translateSrv.instant("ACHIEVEMENT_COMPLETED")
          ? this.translateSrv.instant("ACHIEVEMENT_COMPLETED")
          : null;
        if (
          !isNull(achievementFormattedResources) &&
          achievementFormattedResources.Count()
        ) {
          text = this.utilSrv.getItemResource(
            { resourcesMapped: achievementFormattedResources },
            Resource_Types.CHALLENGE_WON_TEXT,
            this.translateSrv.currentLang
          );
        }
        if (
          this.canUserPurchaseProduct(product, parent)
          // && this.productIsTerminated(product)
        ) {
          if (!isNull(text) && isString(text) && text.length) {
            result.push({
              variant: "success",
              message: text,
            });
          }
        }
      }
    }

    /* -- If Product has Coupon Countdown. */
    if (
      !isNull(countDownValue) &&
      !this.isProductLimitReached(parent) &&
      !this.isProductLimitReached(product)
    ) {
      if (brandServiceId) {
        const coupons = this.getAvailableCoupons(product, brandServiceId);
        if (coupons < countDownValue && coupons > 0) {
          let variant = "threshold-light";
          if (product.theme === "66267") {
            variant = "threshold";
          }
          result.push({
            variant: variant as any,
            backgroundColor: product.brandProductColor,
            message: this.translateSrv
              .instant(`LAST_N_ITEMS_ON_PRODUCT_SHORT_${brandServiceId}`)
              .replace("$count", coupons),
          });
        }
      } else if (!parent.isBundled && parent.prices) {
        parent.prices.forEach((p) => {
          const coupons = this.getAvailableCoupons(product, p.brandServiceId);
          if (coupons < countDownValue && coupons > 0) {
            let variant = "threshold-light";
            if (product.theme === "66267") {
              variant = "threshold";
            }
            result.push({
              variant: variant as any,
              backgroundColor: product.brandProductColor,
              brandServiceId: p.brandServiceId,
              message: this.translateSrv
                .instant(`LAST_N_ITEMS_ON_PRODUCT_SHORT_${p.brandServiceId}`)
                .replace("$count", coupons),
            });
          }
        });
      }
    }

    // if (
    //   !isNull(countDownValue) &&
    //   product.numOfTotalAvailableCoupons < countDownValue &&
    //   !this.isProductLimitReached(parent) &&
    //   !this.isProductLimitReached(product)
    // ) {
    //   let variant = "threshold-light";
    //   if (product.theme === "66267") {
    //     variant = "threshold";
    //   }
    //   result.push({
    //     variant: variant as any,
    //     backgroundColor: product.brandProductColor,
    //     message: this.translateSrv
    //       .instant("LAST_N_ITEMS_ON_PRODUCT_SHORT")
    //       .replace("$count", product.numOfTotalAvailableCoupons),
    //   });
    // }

    if (
      this.productHasLimitation(product) &&
      !this.isProductLimitReached(product)
    ) {
      /* User Can Purchase and Product has Coupons. */
      result.push({
        variant: "warning",
        message: this.translateSrv.instant(
          this.appGlobalsSrv.isMobile
            ? "PRODUCT_LIMITATION_WARNING_MOBILE"
            : "PRODUCT_LIMITATION_WARNING"
        ),
      });
      return result;
    } else if (
      product.isBundle &&
      !isNull(parent) &&
      this.productHasLimitation(parent) &&
      !this.isProductLimitReached(parent)
    ) {
      /* User Can Purchase and Product has Coupons. */
      result.push({
        variant: "warning",
        message: this.translateSrv.instant(
          this.appGlobalsSrv.isMobile
            ? "PRODUCT_LIMITATION_WARNING_MOBILE"
            : "PRODUCT_LIMITATION_WARNING"
        ),
      });
      return result;
    }
    /* -- If User Cannot Purchase. */
    if (numberPoints) {
      // let results = [];
      // numberPoints.forEach((_, index) => {
      //   // get pos of points
      //   results.push(
      //     this.canUserPurchaseProduct(
      //       product,
      //       parent,
      //       this.getPricePOSById(
      //         achievementProduct.prices[index].brandServiceId
      //       )
      //     )
      //   );
      // });
      // if (results.every((r) => !r)) {
      //   result.push({
      //     variant: "error",
      //     message: this.translateSrv
      //       .instant(
      //         this.appGlobalsSrv.isMobile
      //           ? "PRODUCT_MISSING_POINTS_TO_PURCHASE_MOBILE"
      //           : "PRODUCT_MISSING_POINTS_TO_PURCHASE"
      //       )
      //       .replace(
      //         "$points",
      //         // Get the minimum amount of prices
      //         Math.ceil(
      //           this.getMinimumPrice(numberPoints) - this.userSrv.user.balance
      //         )
      //       ),
      //   });
      //   // Not all prices are affordable
      // } else if (results.some((r) => !r)) {
      //   /*
      //    * Determine which one is false
      //    * If there are many, catch the minimum amount
      //    */
      //   // Get the index of false elements
      //   const affordableIndexes: number[] = results.reduce(
      //     (acc, value: boolean, i) => (!value && acc.push(i), acc),
      //     []
      //   );
      //   logger.log("reduced", affordableIndexes);
      //   result.push({
      //     variant: "error",
      //     message: this.translateSrv
      //       .instant(
      //         this.appGlobalsSrv.isMobile
      //           ? "PRODUCT_MISSING_POINTS_TO_PURCHASE_MOBILE"
      //           : "PRODUCT_MISSING_POINTS_TO_PURCHASE"
      //       )
      //       .replace(
      //         "$points",
      //         Math.ceil(
      //           (affordableIndexes.length > 1
      //             ? this.getMinimumPrice(numberPoints)
      //             : numberPoints[affordableIndexes[0]]) -
      //             this.userSrv.user.balance
      //         )
      //       ),
      //   });
      // }
    }
    /*-- If product is parent and has no coupons */
    // if (!product.isBundle && parent && !parent.numOfTotalAvailableCoupons) {
    //   return {
    //     variant: "warning",
    //     backgroundColor: product.brandProductColor,
    //     message: this.translateSrv
    //       .instant("PARENT_PRODUCT_NO_COUPONS")
    //   };
    // } else if (product.isBundle && product.numOfTotalAvailableCoupons) {
    // /* Product is Bundled and has no coupons */
    //   return {
    //     variant: "warning",
    //     backgroundColor: product.brandProductColor,
    //     message: this.translateSrv
    //       .instant("BUNDLE_PRODUCT_NO_COUPONS")
    //   };
    // }
    /*-- If Product is Limited Set Related Content. */

    return result;
  }

  public getPricePOSById(brandServiceId) {
    const posMap = {
      "5A28B062-5043-47AF-D8A4-729BB0D73A7C": "online",
      "C7A01EF9-1066-43CA-CEA7-8ACEDEAD0590": "offline",
    };
    return posMap[brandServiceId];
  }

  public getMinimumPrice(points: number[]) {
    return Math.min.apply(Math, points);
  }

  /**
   * Gets product selection
   * @param categoryIds
   * @param [orderType]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @returns product selection
   */
  public async getProductCouponSelection(
    categoryIds: KpDictionary<ExtendedCategoryDTO> | ExtendedCategoryDTO,
    selectedCategoryId: number,
    matchingLevel: boolean = true,
    includeDetails: boolean = true,
    includeBom: boolean = true,
    includeQRCode: boolean = true,
    couponStatus?: Array<number>,
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number,
    responseItemTypeIds?: Array<number>,
    purchaseTo?: number | string
  ): Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>> {
    return new Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>>(
      async (resolve, reject) => {
        let _reqCategories: Array<number> = [];
        let _callOk = true;
        if (!isNullOrUndefined(categoryIds)) {
          if (categoryIds instanceof KpDictionary) {
            if (isNullOrUndefined(categoryIds) || !categoryIds.Count())
              _callOk = false;
            else _reqCategories = categoryIds.Keys().map(Number);
          } else {
            if (categoryIds.id) {
              _reqCategories.push(categoryIds.id);
            } else _callOk = false;
          }
        }
        if (
          !isNullOrUndefined(selectedCategoryId) &&
          isNumber(selectedCategoryId) &&
          selectedCategoryId > 0
        ) {
          _reqCategories = [selectedCategoryId];
        }
        if (!_callOk) reject(null);
        else {
          if (!_reqCategories.length) reject(null);
          else {
            const _getBrandProductCouponsByCategories = await to(
              this.getUserBrandProductCouponsByCategoriesAlt(
                _reqCategories,
                couponStatus,
                matchingLevel,
                includeDetails,
                includeBom,
                includeQRCode,
                orderType,
                rangeFrom,
                rangeTo,
                responseItemTypeIds,
                purchaseTo
              )
            );
            if (isNullOrUndefined(_getBrandProductCouponsByCategories.data))
              reject(null);
            else {
              let _BrandProductCoupons =
                _getBrandProductCouponsByCategories.data as KpDictionary<ExtendeDetailedBrandProductCouponDTO>;
              if (!_BrandProductCoupons.Count())
                reject(null); /* Should not Happen. */
              else {
                logger.log("_BrandProductCoupons", _BrandProductCoupons);
                resolve(_BrandProductCoupons);
              }
            }
          }
        }
      }
    );
  }

  /**
   * Gets signle product selection
   * @param brandProductId
   * @returns signle product selection
   */
  public async getSignleProductSelection(
    brandProductId: string,
    selectedCategoryId?: Array<number>,
    statistics?: number | boolean,
    includeAchievements?: boolean
  ): Promise<LoyaltyBrandProductDTO> {
    return new Promise<LoyaltyBrandProductDTO>(async (resolve, reject) => {
      if (isNullOrUndefined(brandProductId)) reject(null);
      else {
        let _newId = null;
        const _product = await to(
          this.brandsService.asyncGetBrandProductsByCategoryId(
            this.userSrv.user.user.userId,
            [brandProductId],
            null,
            null,
            true,
            false,
            false,
            false,
            true,
            false,
            true,
            null,
            null,
            0,
            1
          )
        );
        if (isNullOrUndefined(_product.data)) reject(null);
        else {
          logger.log(_product.data);
          if (_product.data.length) {
            const _tmpMetadata = this.utilSrv.formatItemMetadata(
              _product.data[0]
            );
            if (_tmpMetadata.ContainsKey(METADATA_KEY.BUNDLE_BRAND_PRODUCT))
              _newId = _tmpMetadata.Item(METADATA_KEY.BUNDLE_BRAND_PRODUCT);
            if (!_newId) _newId = brandProductId;
            if (!_newId) reject(null);
            else {
              const _fproduct = await to(
                this.brandsService.asyncGetBrandProductsByCategoryId(
                  this.userSrv.user.user.userId,
                  [_newId],
                  selectedCategoryId ? selectedCategoryId : null,
                  true,
                  true,
                  true,
                  statistics,
                  true,
                  true,
                  true,
                  true,
                  TAConstants.Settings.LANGUAGES,
                  null,
                  0,
                  1
                )
              );
              if (isNullOrUndefined(_fproduct.data)) reject(null);
              else {
                const product = _fproduct.data[0];
                let assocAchievement: AchievementDTO = null;
                if (includeAchievements) {
                  const achievementIds = [];
                  const achievementsIdByContentId: Map<string, string> =
                    new Map<string, string>();
                  if (product.transactionLimitations) {
                    const limitationAchievement =
                      product.transactionLimitations.find(
                        (t: BrandProductTransactionLimitsDTO) => {
                          return t.limitTypeId == 5;
                        }
                      );
                    if (limitationAchievement && limitationAchievement.limits) {
                      const limit = limitationAchievement.limits.find(
                        (l: BrandProductTransactionGameTypeLimitsDTO) => {
                          return l.gameTypeId == Settings.GAME_TYPE;
                        }
                      );
                      if (
                        limit &&
                        limit.prerequisiteItem &&
                        limit.prerequisiteItem.itemTypeId ==
                          ITEM_TYPE.ACHIEVEMENT_TYPE
                      ) {
                        achievementsIdByContentId.set(
                          product.brandProductId,
                          limit.prerequisiteItem.itemId
                        );
                        if (
                          !achievementIds.includes(
                            limit.prerequisiteItem.itemId
                          )
                        )
                          achievementIds.push(limit.prerequisiteItem.itemId);
                      }
                    }
                  }

                  if (achievementIds.length > 0) {
                    const input: GetAchievementsParams = {
                      achievementIds: achievementIds,
                      active: true,
                      currentlyActive: true,
                      matchingLevel: true,
                      resources: true,
                      languageIds: TAConstants.Settings.LANGUAGES,
                    };
                    const results = await this.achievementSrv
                      .getAchievements(input)
                      .catch((err) => {});
                    if (results && results.length > 0) {
                      achievementsIdByContentId.forEach(
                        (value: string, key: string) => {
                          if (key === product.brandProductId) {
                            const a: AchievementDTO = results.find(
                              (v: AchievementDTO) => {
                                return value == v.achievementId;
                              }
                            );
                            if (a) {
                              assocAchievement = a;
                            }
                          }
                        }
                      );
                    }
                  }
                }
                if (assocAchievement) {
                  _fproduct.data[0].achievement = assocAchievement;
                }
                let _tmp = this.formatProduct(_fproduct.data[0]);
                if (_tmp.bundledProducts && _tmp.bundledProducts.length) {
                  let formattedBundles: Array<LoyaltyBrandProductDTO> = [];
                  _tmp.bundledProducts.forEach((pb) => {
                    if (assocAchievement) {
                      pb.achievement = assocAchievement;
                    }
                    formattedBundles.push({
                      ...this.formatProduct(pb as LoyaltyBrandProductDTO),
                      achievement: assocAchievement,
                      isBundle: true,
                      isListed: false,
                    });
                  });
                  _tmp.bundledProducts = formattedBundles;
                }
                resolve(_tmp);
              }
            }
          } else reject(null);
        }
      }
    });
  }

  /**
   * Gets single coupon selection
   * @param brandProductId
   * @returns single coupon selection
   */
  public async getSingleCouponSelection(
    rootCategoryId: Array<number>,
    code: string
  ): Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>> {
    return new Promise<KpDictionary<ExtendeDetailedBrandProductCouponDTO>>(
      async (resolve, reject) => {
        if (isNullOrUndefined(code) || isNullOrUndefined(rootCategoryId))
          reject(null);
        else {
          let _resp: KpDictionary<ExtendeDetailedBrandProductCouponDTO> =
            new KpDictionary<ExtendeDetailedBrandProductCouponDTO>();
          let categories = [];
          rootCategoryId.map((c) => categories.push(c.toString()));
          const _tmp = await to(
            this.getUserBrandProductCouponsByCategories(
              categories,
              [],
              [code],
              null,
              0,
              1
            )
          );
          if (isNullOrUndefined(_tmp.data)) reject(null);
          else {
            logger.log(_tmp.data);
            resolve(_tmp.data);
          }
        }
      }
    );
  }

  /**
   * Gets category resource
   * @param category
   * @param resourceTypeId
   * @param languageId
   * @returns
   */
  public getCategoryResource(
    category: ExtendedCategoryDTO,
    resourceTypeId: number,
    languageId: string
  ) {
    if (isNullOrUndefined(category)) return null;
    const resId = resourceTypeId.toString();
    if (!category.resourcesMapped.Count()) return null;
    if (!category.resourcesMapped.ContainsKey(resId)) return null;
    if (!category.resourcesMapped.Item(resId).ContainsKey(languageId))
      return null;
    if (
      isNullOrUndefined(
        category.resourcesMapped.Item(resId).Item(languageId).textResource
      )
    )
      return null;
    return category.resourcesMapped.Item(resId).Item(languageId).textResource;
  }

  /**
   * Gets category name
   * @param cat
   * @returns category name
   */
  public getCategoryName(
    category: ExtendedCategoryDTO,
    languageId: string
  ): string {
    let ret = this.getCategoryResource(
      category,
      Resource_Types.TITLE,
      languageId
    );
    if (isNull(ret)) {
      ret = category.name;
    }
    return ret;
  }

  /**
   * Gets feaured imagte
   */
  public async getGameTypeResources(): Promise<KpDictionary<ResourceDTO>> {
    return new Promise<KpDictionary<ResourceDTO>>(async (resolve, reject) => {
      let payload = [];
      this.GameTypeRequiredResourceTypes.forEach((resource) => {
        TAConstants.Settings.LANGUAGES.forEach((lang) => {
          payload.push({
            itemId: TAConstants.Settings.GAME_TYPE_ID,
            resourceTypeId: resource,
            itemTypeId: ITEM_TYPE.GAME_TYPE_ID,
            languageIsoCode: lang,
          });
        });
      });
      const searchEtag = await to(
        this.itemApi.searchEtag(payload, null, false)
      );
      if (!isNullOrUndefined(searchEtag.data)) {
        let convertTasks$: Array<Observable<any>> = searchEtag.data.map(
          (resource) => {
            return this.utilSrv.imageUrlToBase64(resource.textResource);
          }
        );

        combineLatest(
          [...convertTasks$].map((task, index) =>
            task.pipe(
              tap((base64) => {
                searchEtag.data[index].binaryResource = base64;
              })
            )
          )
        ).subscribe(
          () => {
            logger.log("Converted successfully", searchEtag.data);
            resolve(
              this.utilSrv.formatItemResources({
                resources: searchEtag.data,
              })
            );
          },
          (err) => reject(err)
        );
      }
    });
  }

  /**
   * Gets categories tree
   * @param rootCategoryId
   * @param [resources]
   * @returns categories tree
   */
  public async getProductCategoriesTree(
    rootCategoryId: number,
    resources: boolean = true
  ): Promise<KpDictionary<ExtendedCategoryDTO>> {
    return new Promise<KpDictionary<ExtendedCategoryDTO>>(
      async (resolve, reject) => {
        if (!rootCategoryId) {
          reject(null);
        } else {
          const _getRootCategoryTree = await to(
            this.utilSrv.getRootCategoryTree(
              rootCategoryId,
              true,
              resources,
              false
            )
          );
          if (isNullOrUndefined(_getRootCategoryTree.data)) reject(null);
          else {
            logger.log("_getRootCategoryTree.data", _getRootCategoryTree.data);
            let _rootTree: KpDictionary<ExtendedCategoryDTO> =
              new KpDictionary<ExtendedCategoryDTO>();
            if (!_getRootCategoryTree.data.children.length) reject(null);
            else {
              let _ChildCategoryIds: Array<number> =
                _getRootCategoryTree.data.children.map((child) => child.id);
              for (const _ChildCategoryId of _ChildCategoryIds) {
                /* Format Output. */
                _rootTree.Add(
                  _ChildCategoryId.toString(),
                  _getRootCategoryTree.data.childrenMapped.Item(
                    _ChildCategoryId.toString()
                  )
                );
              }
              logger.log("_rootTree", _rootTree);
              resolve(_rootTree);
            }
          }
        }
      }
    );
  }

  /**
   * Gets categories counters
   * @param input
   * @returns categories counters
   */
  public async getCategoriesCounters(
    input: KpDictionary<ExtendedCategoryDTO>
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      if (isNullOrUndefined(input) || !input.Count()) reject(null);
      else {
        let _ChildCategoryIds: Array<number> = input.Keys().map(Number);
        if (!_ChildCategoryIds.length) reject(null);
        let promises: Array<Promise<any>> = [];
        _ChildCategoryIds.forEach((element) => {
          promises.push(
            to(this.CountBrandProductsByCategory(element, true, true))
          );
        });
        /* Run In Parallel. */
        await Promise.all(promises.map((p) => p.catch((e) => e)))
          .then((results) => {
            if (!results) reject(null);
            let _resp: KpDictionary<number> = new KpDictionary<number>();
            results.forEach((result) => {
              if (isNullOrUndefined(result.data)) {
                /* If Any Failed. */
                reject(null);
                return;
              } else {
                _resp.Add(result.data.categoryId.toString(), result.data.count);
              }
            });
            logger.log("_counters", _resp);
            resolve(_resp);
          })
          .catch((e) => logger.log(e));
      }
    });
  }

  public async getCategoriesCountersNew(): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      const _rewardConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
        {};
      const categoryId =
        _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID];
      const query = {
        bundled: true,
        groupByType: "BY_CATEGORY",
        categoryIds: [categoryId],
      };
      let _resp: KpDictionary<number> = new KpDictionary<number>();
      const result = await this.brandsService
        .getBrandProductsGroupBy(TAValues.UserId, query)
        .catch((err) => {});
      if (result && result.length > 0) {
        result.forEach((r: ProductGroupByDTO) => {
          _resp.Add(r.itemId, r.noOfRecords);
        });
      }
      resolve(_resp);
    });
  }

  public async getCategoriesCouponCountersDummy(
    input: KpDictionary<ExtendedCategoryDTO> | ExtendedCategoryDTO | number,
    brandProductCouponStatusIds: Array<number> = [],
    matchingLevel: boolean = true
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>((resolve, reject) => {
      resolve(new KpDictionary<number>());
    });
  }

  /**
   * Gets categories counters
   * @param input
   * @returns categories counters
   */
  public async getCategoriesCouponCounters(
    input: KpDictionary<ExtendedCategoryDTO> | ExtendedCategoryDTO | number,
    brandProductCouponStatusIds: Array<number> = [],
    matchingLevel: boolean = true
  ): Promise<KpDictionary<number>> {
    return new Promise<KpDictionary<number>>(async (resolve, reject) => {
      let _ChildCategoryIds: Array<number> = [];
      let _callOk = true;
      if (input instanceof KpDictionary) {
        if (isNullOrUndefined(input) || !input.Count()) _callOk = false;
        else _ChildCategoryIds = input.Keys().map(Number);
      } else if (typeof input === "number") {
        _ChildCategoryIds.push(input);
      } else {
        if (input.id) {
          _ChildCategoryIds.push(input.id);
        } else _callOk = false;
      }
      if (!_callOk) reject(null);
      else {
        if (!_ChildCategoryIds.length) reject(null);
        else {
          let promises: Array<Promise<any>> = [];
          _ChildCategoryIds.forEach((element) => {
            promises.push(
              to(
                this.CountBrandProductCouponsByCategoryAlt(
                  element,
                  brandProductCouponStatusIds,
                  matchingLevel
                )
              )
            );
          });
          /* Run In Parallel. */
          await Promise.all(promises.map((p) => p.catch((e) => e)))
            .then((results) => {
              if (!results) reject(null);
              let _resp: KpDictionary<number> = new KpDictionary<number>();
              results.forEach((result) => {
                if (isNullOrUndefined(result.data)) {
                  /* If Any Failed. */
                  reject(null);
                  return;
                } else {
                  _resp.Add(
                    result.data.categoryId.toString(),
                    result.data.count
                  );
                }
              });
              logger.log("_counters", _resp);
              resolve(_resp);
            })
            .catch((e) => logger.log(e));
        }
      }
    });
  }

  /**
   * Counts Brand Products by Category
   * @param categoryIds
   * @param [bundle]
   * @returns brand products by categories
   */
  private async CountBrandProductsByCategory(
    categoryId: number,
    bundle: boolean = true,
    matchingLevel: boolean = null
  ): Promise<{ categoryId: number; count: number }> {
    return new Promise<{ categoryId: number; count: number }>(
      async (resolve, reject) => {
        if (!categoryId) reject(null);
        const cachedCategory =
          this.cachingSrv.filterProductById.get(categoryId);
        if (cachedCategory) {
          resolve(cachedCategory);
          return;
        }
        await this.brandsService
          .GetBrandProductsByInput(
            this.userSrv.user.user.userId,
            [],
            [categoryId],
            true,
            [],
            [],
            null,
            0,
            1,
            matchingLevel,
            false,
            false,
            false,
            false
          )
          .then((_resp) => {
            if (_resp.hasOwnProperty("X-Talos-Item-Count")) {
              this.cachingSrv.filterProductById.set(categoryId, {
                categoryId: categoryId,
                count: parseInt(_resp["X-Talos-Item-Count"]),
              });
              resolve({
                categoryId: categoryId,
                count: parseInt(_resp["X-Talos-Item-Count"]),
              });
            } else reject(null);
          })
          .catch((err) => {
            reject(null);
          });
        /* Any Error lead to rejection. */
      }
    );
  }

  /**
   * Counts Brand Products by Category
   * @param categoryIds
   * @param [bundle]
   * @returns brand products by categories
   */
  public async CountBrandProductCouponsByCategoryAlt(
    /* getUserBrandProductCoupons */
    categoryId: number,
    brandProductCouponStatusIds: Array<number> = [],
    matchingLevel: boolean = true
  ): Promise<{ categoryId: number; count: number }> {
    return new Promise<{ categoryId: number; count: number }>(
      async (resolve, reject) => {
        if (!categoryId) reject(null);
        const cachedCategory = this.cachingSrv.filterCouponById.get(categoryId);
        if (cachedCategory) {
          resolve(cachedCategory);
          return;
        }
        await this.brandsService
          .getUserBrandProductCoupons(this.userSrv.user.user.userId, {
            brandProductCouponStatusIds: brandProductCouponStatusIds,
            assignedUserId: this.userSrv.user.user.userId,
            includeDetails: false,
            includeBom: false,
            categoryIds: [categoryId.toString()],
            rangeFrom: 0,
            rangeTo: 1,
            matchingLevel: matchingLevel,
            gameTypeId: GAME_TYPE_ID,
            responseItemTypeIds: [107],
          })
          .then((_resp) => {
            if (_resp.hasOwnProperty("X-Talos-Item-Count")) {
              this.cachingSrv.filterCouponById.set(categoryId, {
                categoryId: categoryId,
                count: parseInt(_resp["X-Talos-Item-Count"]),
              });
              resolve({
                categoryId: categoryId,
                count: parseInt(_resp["X-Talos-Item-Count"]),
              });
            } else reject(null);
          })
          .catch((err) => {
            reject(null);
          });
        /* Any Error lead to rejection. */
      }
    );
  }

  public async getProductCoupon(
    categoryId: number,
    brandProductCouponCode: string,
    currentDataCouponBlock: KpDictionary<ExtendeDetailedBrandProductCouponDTO>,
    currentDataProductBlock: KpDictionary<ExtendedBrandProductDTO>
  ): Promise<getProductCouponDAO> {
    return new Promise<getProductCouponDAO>(async (resolve, reject) => {
      /* Try to get from Page Block. */
      let couponFound: ExtendeDetailedBrandProductCouponDTO = null;
      let productFound: ExtendedBrandProductDTO = null;
      if (!brandProductCouponCode || !categoryId) reject(null);
      else {
        /* Get Coupon. */
        if (!isNullOrUndefined(currentDataCouponBlock)) {
          /* Try From Current Block. */
          if (currentDataCouponBlock.ContainsKey(brandProductCouponCode)) {
            couponFound = currentDataCouponBlock.Item(brandProductCouponCode);
          }
        }
        if (!couponFound) {
          /* Try to Get. */
          const _tmp = await to(
            this.getSingleCouponSelection([categoryId], brandProductCouponCode)
          );
          if (!isNullOrUndefined(_tmp.data)) {
            const _ret =
              _tmp.data as KpDictionary<ExtendeDetailedBrandProductCouponDTO>;
            if (_ret.ContainsKey(brandProductCouponCode)) {
              couponFound = _ret.Item(brandProductCouponCode);
            }
          }
        }
        if (couponFound) {
          /* Get Product. */
          const brandProductId = couponFound.brandProductId;
          if (currentDataProductBlock.ContainsKey(brandProductId)) {
            productFound = currentDataProductBlock.Item(brandProductId);
          }
          const _assocProduct = await to(
            this.getProduct(
              brandProductId,
              currentDataProductBlock as KpDictionary<LoyaltyBrandProductDTO>,
              categoryId
            )
          );
          if (!isNullOrUndefined(_assocProduct.data)) {
            productFound = _assocProduct.data as ExtendedBrandProductDTO;
          }
        }
        if (couponFound && productFound) {
          resolve({
            assocCoupon: couponFound,
            assocProduct: productFound,
          });
        } else {
          reject(null);
        }
      }
    });
  }

  /**
   * Gets product
   * @returns product
   */
  public async getProduct(
    brandProductId: string,
    currentDataBlock: KpDictionary<LoyaltyBrandProductDTO>,
    categoryId: number
  ): Promise<LoyaltyBrandProductDTO> {
    return new Promise<LoyaltyBrandProductDTO>(async (resolve, reject) => {
      /* Try to get from Page Block. */
      if (!brandProductId) reject(null);
      else {
        /* Check Current Data Block. */
        let itemFound: LoyaltyBrandProductDTO = null;
        if (!isNullOrUndefined(currentDataBlock)) {
          if (currentDataBlock.ContainsKey(brandProductId)) {
            const _tmp = currentDataBlock.Item(brandProductId);
            if (
              _tmp.isBundle &&
              !isNullOrUndefined(_tmp.parentBrandPorductId)
            ) {
              itemFound = currentDataBlock.Item(_tmp.parentBrandPorductId);
            } else itemFound = currentDataBlock.Item(brandProductId);
          }
        }
        if (!itemFound) {
          /* Request Item. */
          const _tmp = await to(
            this.getSignleProductSelection(brandProductId, [categoryId])
          );
          if (!isNullOrUndefined(_tmp.data)) {
            if (
              _tmp.data.isBundled &&
              !isNullOrUndefined(_tmp.data.parentBrandPorductId)
            ) {
              itemFound = _tmp.data;
            } else {
              itemFound = _tmp.data;
            }
          }
        }
        if (itemFound) {
          resolve(itemFound);
        } else reject(null);
      }
    });
  }

  public async checkIfReferral(query: ListReferralInput): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!isNullOrUndefined(this.hasReferral)) {
        resolve(this.hasReferral);
        return;
      }

      let result = false;
      const referrals = await this.referralSrv
        .getReferrals(query)
        .catch((err) => {});
      if (!referrals) {
        this.hasReferral = result;
        resolve(result);
        return;
      }

      if (referrals.length > 0) {
        result = true;
        const ref =
          this.userSrv.user.user.customUserProfileFields[
            METADATA_KEY.SALESFORCE_ACCOUNT_SOURCE
          ];
        if (ref && ref != "") {
          const referralTypes =
            "Advertisement,Customer Event,Google AdWords,Other,Partner,Purchased List,Trade Show,Webinar,Website,VEEV.COM HAV,Gift";
          if (referralTypes.toLowerCase().indexOf(ref.toLowerCase()) >= 0) {
            result = false;
          }
        }

        // const referral = referrals[0];
        // if (referral.referralProperties) {
        //     const ref = this.utilSrv.valueFromKeyValueDTOArray('accountSource', referral.referralProperties);
        //     if (ref && ref != '') {
        //         const referralTypes = 'Advertisement,Customer Event,Google AdWords,Other,Partner,Purchased List,Trade Show,Webinar,Website,VEEV.COM HAV,Gift';
        //         if (referralTypes.toLowerCase().indexOf(ref.toLowerCase()) >= 0) {
        //             result = false;
        //         }
        //     }
        // }
      } else if (referrals["X-Talos-Item-Count"]) {
        const length = parseInt(referrals["X-Talos-Item-Count"]);
        result = length > 0;
      }

      this.hasReferral = result;
      resolve(result);
    });
  }

  public async checkIfHasBoughtByCategoryId(
    categoryId: number
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.boughtByCategoryId.get(categoryId) == true) {
        resolve(true);
        return;
      }

      const query: IListUserBrandProductCoupons = {
        assignedUserId: this.userSrv.user.user.userId,
        categoryIds: [String(categoryId)],
        brandProductCouponStatusIds: [
          COUPON_STATUS.PURCHASED,
          COUPON_STATUS.REDEEMED,
          COUPON_STATUS.EXPIRED,
          COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK,
          COUPON_STATUS.EXPIRED_CLEARED,
          COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
          COUPON_STATUS.EXPIRED_CLEARED_FAILED,
          COUPON_STATUS.EXPIRED_GIFTCARD,
          COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK,
          COUPON_STATUS.REDEEMED_PENDING_DELIVERY,
        ],
        gameTypeId: GAME_TYPE_ID,
        rangeFrom: 0,
        rangeTo: 1,
        matchingLevel: false,
        groupByType: "BY_BRAND_PRODUCT",
      };
      await this.brandsService
        .getUserBrandProductGroupByCoupons(this.userSrv.user.user.userId, query)
        .then((result) => {
          if (!result) {
            this.boughtByCategoryId.set(categoryId, false);
            resolve(false);
            return;
          }

          const bought = result.filter((r) => {
            return r.noOfRecords > 1;
          });
          const hasBought = !isNullOrUndefined(bought);
          this.boughtByCategoryId.set(categoryId, hasBought);
          resolve(hasBought);
          return;
        })
        .catch((err) => {
          this.boughtByCategoryId.set(categoryId, false);
          resolve(true);
          return;
        });
      // const result = await this.brandsService.getUserBrandProductGroupByCoupons(this.userSrv.user.user.userId, query).catch(err => {
      // });
      // if (!result) {
      //     this.boughtByCategoryId.set(categoryId, false);
      //     resolve(true);
      //     return;
      // }
      //
      // const bought = result.filter(r => {
      //     return r.noOfRecords > 1;
      // })
      // const hasBought = !isNullOrUndefined(bought);
      // this.boughtByCategoryId.set(categoryId, hasBought);
      // resolve(hasBought);

      // const _getProductCouponSelection = await to(this.getUserBrandProductCouponsByCategoriesAlt(
      //     [categoryId],
      //     [COUPON_STATUS.PURCHASED, COUPON_STATUS.REDEEMED, COUPON_STATUS.EXPIRED, COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK, COUPON_STATUS.EXPIRED_CLEARED, COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED, COUPON_STATUS.EXPIRED_CLEARED_FAILED, COUPON_STATUS.EXPIRED_GIFTCARD, COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK, COUPON_STATUS.REDEEMED_PENDING_DELIVERY],
      //     false,
      //     false,
      //     false,
      //     true,
      //     null,
      //     0,
      //     1,
      //     [107]
      // ));
      // if (_getProductCouponSelection) {
      //     const bought = !isNullOrUndefined(_getProductCouponSelection.data) && _getProductCouponSelection.data.Count() > 0 ? true : false;
      //     this.boughtByCategoryId.set(categoryId, bought);
      //     resolve(bought);
      //     return;
      // }
      //
      // resolve(true);
      return;
    });
  }

  public async checkIfHasBoughtSegregated(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.hasBoughtSegregated) {
        resolve(true);
        return;
      }
      const _rewardConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
        {};
      if (!isNullOrUndefined(_rewardConfig)) {
        const catIds =
          _rewardConfig[
            APPLICATION_SETTING_KEYS.REWARDS_SEGREGATED_CATEGORY_IDS
          ];
        if (!isNullOrUndefined(catIds)) {
          const query: IListUserBrandProductCoupons = {
            assignedUserId: this.userSrv.user.user.userId,
            categoryIds: catIds,
            brandProductCouponStatusIds: [
              COUPON_STATUS.PURCHASED,
              COUPON_STATUS.REDEEMED,
              COUPON_STATUS.EXPIRED,
              COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK,
              COUPON_STATUS.EXPIRED_CLEARED,
              COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
              COUPON_STATUS.EXPIRED_CLEARED_FAILED,
              COUPON_STATUS.EXPIRED_GIFTCARD,
              COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK,
              COUPON_STATUS.REDEEMED_PENDING_DELIVERY,
            ],
            gameTypeId: GAME_TYPE_ID,
            rangeFrom: 0,
            rangeTo: 1,
            matchingLevel: false,
            groupByType: "BY_BRAND_PRODUCT",
          };
          await this.brandsService
            .getUserBrandProductGroupByCoupons(
              this.userSrv.user.user.userId,
              query
            )
            .then((result) => {
              if (!result) {
                this.hasBoughtSegregated = false;
                resolve(false);
                return;
              }

              // const bought = result.filter(r => {
              //     return r.noOfRecords > 1;
              // })
              const hasBought = true;
              this.hasBoughtSegregated = hasBought;
              resolve(hasBought);
            })
            .catch((err) => {
              this.hasBoughtSegregated = false;
              resolve(true);
              return;
            });
        } else {
          this.hasBoughtSegregated = false;
          resolve(true);
        }
      }
    });
  }

  public async checkIfHasBoughtVoucher(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.hasBoughtVoucher) {
        resolve(true);
        return;
      }
      const _rewardConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
        {};
      if (!isNullOrUndefined(_rewardConfig)) {
        const catIds =
          _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_VOUCHER_CATEGORY_IDS];
        if (!isNullOrUndefined(catIds)) {
          const query: IListUserBrandProductCoupons = {
            assignedUserId: this.userSrv.user.user.userId,
            categoryIds: catIds,
            brandProductCouponStatusIds: [
              COUPON_STATUS.PURCHASED,
              COUPON_STATUS.REDEEMED,
              COUPON_STATUS.EXPIRED,
              COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK,
              COUPON_STATUS.EXPIRED_CLEARED,
              COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
              COUPON_STATUS.EXPIRED_CLEARED_FAILED,
              COUPON_STATUS.EXPIRED_GIFTCARD,
              COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK,
              COUPON_STATUS.REDEEMED_PENDING_DELIVERY,
            ],
            gameTypeId: GAME_TYPE_ID,
            rangeFrom: 0,
            rangeTo: 1,
            matchingLevel: false,
            groupByType: "BY_BRAND_PRODUCT",
          };
          await this.brandsService
            .getUserBrandProductGroupByCoupons(
              this.userSrv.user.user.userId,
              query
            )
            .then((result) => {
              if (!result) {
                this.hasBoughtVoucher = false;
                resolve(false);
                return;
              }

              // const bought = result.filter(r => {
              //     return r.noOfRecords > 1;
              // })
              const hasBought = true;
              this.hasBoughtVoucher = hasBought;
              resolve(hasBought);
            })
            .catch((err) => {
              this.hasBoughtVoucher = false;
              resolve(true);
              return;
            });
        } else {
          this.hasBoughtVoucher = false;
          resolve(true);
        }
      }
    });
  }

  public async checkIfHasBoughtByDate(
    purchaseTo: string | number
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.hasBoughtByDate) {
        resolve(true);
        return;
      }
      let _rootCategoryId;
      let _storeCategoryId;
      let _oldStoreCategoryId;
      let _missionCategoryId;
      let _missionStoreCategoryId;
      const _rewardConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
        {};
      if (!isNullOrUndefined(_rewardConfig)) {
        _rootCategoryId = !isNaN(
          parseInt(
            _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID]
          )
        )
          ? parseInt(
              _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID]
            )
          : null;
        //this._limitedDealsCategoryId = !isNaN(parseInt(_rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_LIMITED_CATEGORY_ID])) ? parseInt(_rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_LIMITED_CATEGORY_ID]) : null;
        _storeCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_STORE_CATEGORY_ID
        ] as number;
        _oldStoreCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_OLD_STORE_CATEGORY_ID
        ] as number;
        _missionCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_MISSION_CATEGORY_ID
        ] as number;
        _missionStoreCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_STORE_MISSION_CATEGORY_ID
        ] as number;
      }

      let _rootCategoryData: ExtendedCategoryDTO = null;
      let _rootStoreCategoryData: ExtendedCategoryDTO = null;
      let _rootOldStoreCategoryData: ExtendedCategoryDTO = null;
      let _rootMissionCategoryData: ExtendedCategoryDTO = null;
      let _rootMissionStoreCategoryData: ExtendedCategoryDTO = null;
      let _childCategoryData: KpDictionary<ExtendedCategoryDTO> = null;

      const _getCategoriesTreeFilter = await to(
        this.utilSrv.getRootCategoryTree(_rootCategoryId, false, true, false)
      );
      let _getStoreCategoriesTreeFilter;
      if (_storeCategoryId) {
        _getStoreCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_storeCategoryId, false, true, false)
        );
      }
      let _getOldStoreCategoriesTreeFilter;
      if (_oldStoreCategoryId) {
        _getOldStoreCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(
            _oldStoreCategoryId,
            false,
            true,
            false
          )
        );
      }
      let _getMissionCategoriesTreeFilter;
      if (_missionCategoryId) {
        _getMissionCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_missionCategoryId, false, true)
        );
      }
      let _getMissionStoreCategoriesTreeFilter;
      if (_missionStoreCategoryId) {
        _getMissionStoreCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_missionStoreCategoryId, false, true)
        );
      }
      if (
        isNullOrUndefined(_getCategoriesTreeFilter) ||
        isNullOrUndefined(_getCategoriesTreeFilter.data)
      ) {
        this.hasBoughtByDate = false;
        resolve(false);
        return;
        // return false;
      } else {
        if (!isNullOrUndefined(_getCategoriesTreeFilter)) {
          _rootCategoryData =
            _getCategoriesTreeFilter.data as ExtendedCategoryDTO;
          if (
            !isNullOrUndefined(_rootCategoryData.childrenMapped) &&
            _rootCategoryData.childrenMapped.Count()
          ) {
            _childCategoryData =
              _rootCategoryData.childrenMapped as KpDictionary<ExtendedCategoryDTO>;
          }
          if (!isNullOrUndefined(_getStoreCategoriesTreeFilter)) {
            _rootStoreCategoryData =
              _getStoreCategoriesTreeFilter.data as ExtendedCategoryDTO;
            if (
              !isNullOrUndefined(_rootStoreCategoryData.childrenMapped) &&
              _rootStoreCategoryData.childrenMapped.Count()
            ) {
              for (const id of _rootStoreCategoryData.childrenMapped.Keys()) {
                if (!_childCategoryData.ContainsKey(id)) {
                  const cat = _rootStoreCategoryData.childrenMapped.Item(id);

                  _childCategoryData.Add(id, cat);
                }
              }
            }
          }
          if (!isNullOrUndefined(_getOldStoreCategoriesTreeFilter)) {
            _rootOldStoreCategoryData =
              _getOldStoreCategoriesTreeFilter.data as ExtendedCategoryDTO;
            if (
              !isNullOrUndefined(_rootOldStoreCategoryData.childrenMapped) &&
              _rootOldStoreCategoryData.childrenMapped.Count()
            ) {
              for (const id of _rootOldStoreCategoryData.childrenMapped.Keys()) {
                if (!_childCategoryData.ContainsKey(id)) {
                  const cat = _rootOldStoreCategoryData.childrenMapped.Item(id);

                  _childCategoryData.Add(id, cat);
                }
              }
            }
          }
          if (!isNullOrUndefined(_getMissionCategoriesTreeFilter)) {
            _rootMissionCategoryData =
              _getMissionCategoriesTreeFilter.data as ExtendedCategoryDTO;
            _childCategoryData.Add(
              String(_rootMissionCategoryData.id),
              _rootMissionCategoryData
            );
          }
          if (!isNullOrUndefined(_getMissionStoreCategoriesTreeFilter)) {
            _rootMissionStoreCategoryData =
              _getMissionStoreCategoriesTreeFilter.data as ExtendedCategoryDTO;
            _childCategoryData.Add(
              String(_rootMissionStoreCategoryData.id),
              _rootMissionStoreCategoryData
            );
          }
        }
      }
      if (!isNull(_childCategoryData) && !isNull(_rootCategoryData)) {
        let _reqCategories: Array<number> = [];
        if (_childCategoryData && _childCategoryData.Count() > 0) {
          _reqCategories = _childCategoryData.Keys().map(Number);
        }

        const query: IListUserBrandProductCoupons = {
          assignedUserId: this.userSrv.user.user.userId,
          categoryIds: _reqCategories.map(String),
          brandProductCouponStatusIds: [
            COUPON_STATUS.PURCHASED,
            COUPON_STATUS.REDEEMED,
            COUPON_STATUS.EXPIRED,
            COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK,
            COUPON_STATUS.EXPIRED_CLEARED,
            COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
            COUPON_STATUS.EXPIRED_CLEARED_FAILED,
            COUPON_STATUS.EXPIRED_GIFTCARD,
            COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK,
            COUPON_STATUS.REDEEMED_PENDING_DELIVERY,
          ],
          gameTypeId: GAME_TYPE_ID,
          rangeFrom: 0,
          rangeTo: 1,
          matchingLevel: false,
          groupByType: "BY_BRAND_PRODUCT",
          purchasedTo: purchaseTo,
        };
        await this.brandsService
          .getUserBrandProductGroupByCoupons(
            this.userSrv.user.user.userId,
            query
          )
          .then((result) => {
            if (!result) {
              this.hasBoughtByDate = false;
              resolve(false);
              return;
            }

            const bought = result.filter((r) => {
              return r.noOfRecords > 1;
            });
            const hasBought = !isNullOrUndefined(bought);
            this.hasBoughtByDate = hasBought;
            resolve(hasBought);
            true;
          })
          .catch((err) => {
            this.hasBoughtByDate = false;
            resolve(true);
            return;
          });

        // const _getProductCouponSelection = await to(this.getProductCouponSelection(
        //     _childCategoryData,
        //     null,
        //     false,
        //     false,
        //     false,
        //     true,
        //     [COUPON_STATUS.PURCHASED, COUPON_STATUS.REDEEMED, COUPON_STATUS.EXPIRED, COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK, COUPON_STATUS.EXPIRED_CLEARED, COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED, COUPON_STATUS.EXPIRED_CLEARED_FAILED, COUPON_STATUS.EXPIRED_GIFTCARD, COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK, COUPON_STATUS.REDEEMED_PENDING_DELIVERY],
        //     null,
        //     0,
        //     1,
        //     [107],
        //     purchaseTo
        // ));
        //
        // if (_getProductCouponSelection) {
        //     const bought = _getProductCouponSelection.data && _getProductCouponSelection.data.Count() > 0 ? true : false;
        //
        //     this.hasBoughtByDate = bought;
        //     resolve(bought);
        //     return;
        // }
      }

      resolve(true);
      return;
    });
  }

  public async checkIfHasBought(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.userSrv.user.hasBought) {
        resolve(true);
        return;
      }
      let _rootCategoryId;
      let _storeCategoryId;
      let _oldStoreCategoryId;
      let _missionCategoryId;
      let _missionStoreCategoryId;
      const _rewardConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
        {};
      if (!isNullOrUndefined(_rewardConfig)) {
        _rootCategoryId = !isNaN(
          parseInt(
            _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID]
          )
        )
          ? parseInt(
              _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID]
            )
          : null;
        //this._limitedDealsCategoryId = !isNaN(parseInt(_rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_LIMITED_CATEGORY_ID])) ? parseInt(_rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_LIMITED_CATEGORY_ID]) : null;
        _storeCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_STORE_CATEGORY_ID
        ] as number;
        _oldStoreCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_OLD_STORE_CATEGORY_ID
        ] as number;
        _missionCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_MISSION_CATEGORY_ID
        ] as number;
        _missionStoreCategoryId = _rewardConfig[
          APPLICATION_SETTING_KEYS.REWARDS_STORE_MISSION_CATEGORY_ID
        ] as number;
      }

      let _rootCategoryData: ExtendedCategoryDTO = null;
      let _rootStoreCategoryData: ExtendedCategoryDTO = null;
      let _rootOldStoreCategoryData: ExtendedCategoryDTO = null;
      let _rootMissionCategoryData: ExtendedCategoryDTO = null;
      let _rootMissionStoreCategoryData: ExtendedCategoryDTO = null;
      let _childCategoryData: KpDictionary<ExtendedCategoryDTO> = null;

      const _getCategoriesTreeFilter = await to(
        this.utilSrv.getRootCategoryTree(_rootCategoryId, false, true, false)
      );
      let _getStoreCategoriesTreeFilter;
      if (_storeCategoryId) {
        _getStoreCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_storeCategoryId, false, true, false)
        );
      }
      let _getOldStoreCategoriesTreeFilter;
      if (_oldStoreCategoryId) {
        _getOldStoreCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(
            _oldStoreCategoryId,
            false,
            true,
            false
          )
        );
      }
      let _getMissionCategoriesTreeFilter;
      if (_missionCategoryId) {
        _getMissionCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_missionCategoryId, false, true)
        );
      }
      let _getMissionStoreCategoriesTreeFilter;
      if (_missionStoreCategoryId) {
        _getMissionStoreCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_missionStoreCategoryId, false, true)
        );
      }
      if (
        isNullOrUndefined(_getCategoriesTreeFilter) ||
        isNullOrUndefined(_getCategoriesTreeFilter.data)
      ) {
        this.cachingSrv.userHasBought = false;
        resolve(false);
        return;
        // return false;
      } else {
        if (!isNullOrUndefined(_getCategoriesTreeFilter)) {
          _rootCategoryData =
            _getCategoriesTreeFilter.data as ExtendedCategoryDTO;
          if (
            !isNullOrUndefined(_rootCategoryData.childrenMapped) &&
            _rootCategoryData.childrenMapped.Count()
          ) {
            _childCategoryData =
              _rootCategoryData.childrenMapped as KpDictionary<ExtendedCategoryDTO>;
          }
          if (!isNullOrUndefined(_getStoreCategoriesTreeFilter)) {
            _rootStoreCategoryData =
              _getStoreCategoriesTreeFilter.data as ExtendedCategoryDTO;
            if (
              !isNullOrUndefined(_rootStoreCategoryData.childrenMapped) &&
              _rootStoreCategoryData.childrenMapped.Count()
            ) {
              for (const id of _rootStoreCategoryData.childrenMapped.Keys()) {
                if (!_childCategoryData.ContainsKey(id)) {
                  const cat = _rootStoreCategoryData.childrenMapped.Item(id);

                  _childCategoryData.Add(id, cat);
                }
              }
            }
          }
          if (!isNullOrUndefined(_getOldStoreCategoriesTreeFilter)) {
            _rootOldStoreCategoryData =
              _getOldStoreCategoriesTreeFilter.data as ExtendedCategoryDTO;
            if (
              !isNullOrUndefined(_rootOldStoreCategoryData.childrenMapped) &&
              _rootOldStoreCategoryData.childrenMapped.Count()
            ) {
              for (const id of _rootOldStoreCategoryData.childrenMapped.Keys()) {
                if (!_childCategoryData.ContainsKey(id)) {
                  const cat = _rootOldStoreCategoryData.childrenMapped.Item(id);

                  _childCategoryData.Add(id, cat);
                }
              }
            }
          }
          if (!isNullOrUndefined(_getMissionCategoriesTreeFilter)) {
            _rootMissionCategoryData =
              _getMissionCategoriesTreeFilter.data as ExtendedCategoryDTO;
            _childCategoryData.Add(
              String(_rootMissionCategoryData.id),
              _rootMissionCategoryData
            );
          }
          if (!isNullOrUndefined(_getMissionStoreCategoriesTreeFilter)) {
            _rootMissionStoreCategoryData =
              _getMissionStoreCategoriesTreeFilter.data as ExtendedCategoryDTO;
            _childCategoryData.Add(
              String(_rootMissionStoreCategoryData.id),
              _rootMissionStoreCategoryData
            );
          }
        }
      }
      if (!isNull(_childCategoryData) && !isNull(_rootCategoryData)) {
        const _getProductCouponSelection = await to(
          this.getProductCouponSelection(
            _childCategoryData,
            null,
            false,
            false,
            false,
            true,
            [
              COUPON_STATUS.PURCHASED,
              COUPON_STATUS.REDEEMED,
              COUPON_STATUS.EXPIRED,
              COUPON_STATUS.EXPIRED_REQUESTED_CHARGE_BACK,
              COUPON_STATUS.EXPIRED_CLEARED,
              COUPON_STATUS.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
              COUPON_STATUS.EXPIRED_CLEARED_FAILED,
              COUPON_STATUS.EXPIRED_GIFTCARD,
              COUPON_STATUS.EXPIRED_YELLOWS_CHARGED_BACK,
              COUPON_STATUS.REDEEMED_PENDING_DELIVERY,
            ],
            null,
            0,
            1,
            [107]
          )
        );

        if (_getProductCouponSelection) {
          const bought =
            _getProductCouponSelection.data &&
            _getProductCouponSelection.data.Count() > 0
              ? true
              : false;

          this.userSrv.user.hasBought = bought;
          this.cachingSrv.userHasBought = bought;
          // localStorage.setItem('hasBought', bought ? 'true' : 'false');
          resolve(bought);
          return;
          // return bought;
        }
      }

      resolve(true);
      return;
      // return  false ;
    });
  }

  public getCouponRedirection(): Promise<ExternalSystemAuthenticationResponse> {
    return new Promise<ExternalSystemAuthenticationResponse>(
      async (resolve, reject) => {
        const res = await this.pmiSrv
          .pmiExternalAuthentication(TAValues.UserId, 12)
          .catch((err) => {});
        if (res) {
          resolve(res);
          return;
        }
        reject("ERROR");
      }
    );
  }

  public getMissionProducts(): Promise<Array<DetailedBrandProductDTO>> {
    return new Promise<Array<DetailedBrandProductDTO>>(
      async (resolve, reject) => {
        const _rewardConfig =
          this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
          {};
        const categoryId =
          _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_MISSION_CATEGORY_ID];
        const query = {
          bundled: true,
          groupByType: "BY_CATEGORY",
          categoryIds: [categoryId],
        };
        const groupRes = await this.brandsService
          .getBrandProductsGroupBy(TAValues.UserId, query)
          .catch((err) => {});

        if (groupRes && groupRes.length > 0) {
          const item = groupRes.find((i) => {
            return i.itemId == categoryId;
          });
          if (item && item.noOfRecords > 0) {
            const input = {
              rangeFrom: 0,
              rangeTo: item.noOfRecords,
              categoryIds: [categoryId],
              statistics: false,
              includeDetails: true,
              bundled: true,
              withDateValidation: true,
              includeStatistics: false,
              matchingLevel: true,
              transactionLimitTypeIds: 5,
              responseItemTypeIds: [111, 121, 225],
            };
            const productRes = await this.brandsService
              .getBrandProductsGeneric(TAValues.UserId, input)
              .catch((err) => {});
            if (productRes && productRes.length > 0) {
              resolve(productRes);
              return;
            }
          }
        }
        resolve([]);
      }
    );
  }

  /**
   *
   * -------------
   * Helper Functions.
   * -------------
   *
   */

  /**
   *
   * @param product
   * @returns
   */
  public getProductNumberOfPoints(
    product: LoyaltyBrandProductDTO,
    parentProduct?: LoyaltyBrandProductDTO | null
  ): number[] {
    const lookupProduct =
      !isNil(parentProduct) && product.isBundle === true
        ? parentProduct
        : product;
    return this.calculatePoints(lookupProduct);
  }

  public calculatePoints(lookupProduct): number[] {
    let pointsScale = 0;
    let numberPoints;
    const points = this.getProductPrice(lookupProduct);
    if (Array.isArray(points) && points.length > 0) {
      let numberPointsResults = [];
      lookupProduct.prices.map((price, index) => {
        const pointsScale = price.prices[0].amount.scale;
        const numberPoints = points[index] / Math.pow(10, pointsScale);
        numberPointsResults.push(numberPoints);
      });
      return numberPointsResults;
    } else {
      if (lookupProduct.prices) {
        pointsScale = lookupProduct.prices[0].prices[0].amount.scale;
      }
      numberPoints = points[0] / Math.pow(10, pointsScale);
      return [numberPoints];
    }
  }

  /**
   *
   * @param product
   * @returns
   */
  // public productIsTerminated(product: LoyaltyBrandProductDTO): boolean {
  //   return product.numOfTotalAvailableCoupons === 0;
  // }

  public productIsTerminatedBundle(
    parent: LoyaltyBrandProductDTO,
    product: LoyaltyBrandProductDTO
  ): boolean {
    let result = true;

    if (parent.prices) {
      parent.prices.forEach((price) => {
        const coupons = this.getAvailableCoupons(product, price.brandServiceId);
        const terminated = coupons === 0;
        if (!terminated) result = false;
      });
    }

    return result;
  }

  public productIsTerminatedFull(product: LoyaltyBrandProductDTO): boolean {
    let result = true;

    if (product.prices && product.totalBundleCouponsByServiceId) {
      product.prices.forEach((price) => {
        const coupons = product.totalBundleCouponsByServiceId.get(
          price.brandServiceId
        );
        const terminated = coupons === 0;
        if (!terminated) result = false;
      });
    }

    return result;
  }

  public getAvailableCoupons(
    product: DetailedBrandProductDTO,
    brandServiceId: string
  ): number {
    if (product.numOfAvailableCoupons != -1)
      return product.numOfAvailableCoupons;

    if (product.couponCountersPerBrandService) {
      const counterBS = product.couponCountersPerBrandService.find(
        (c) => c.brandServiceId == brandServiceId
      );
      if (counterBS) {
        return counterBS.numOfAvailableCoupons;
      }
    }

    return 0;
  }

  public productIsTerminatedNew(product: LoyaltyBrandProductDTO): boolean {
    if (product.numOfAvailableCoupons != -1)
      return product.numOfAvailableCoupons === 0;

    let result = false;

    if (product.couponCountersPerBrandService && product.prices) {
      product.prices.forEach((p) => {
        const counterBS = product.couponCountersPerBrandService.find(
          (c) => c.brandServiceId == p.brandServiceId
        );
        if (counterBS) {
          result = counterBS.numOfAvailableCoupons === 0;
        }
      });
    }

    return result;
  }

  public productIsTerminatedNewByService(
    product: LoyaltyBrandProductDTO,
    brandServiceId: string
  ): boolean {
    if (product.numOfAvailableCoupons != -1)
      return product.numOfAvailableCoupons === 0;

    if (product.couponCountersPerBrandService) {
      const counterBS = product.couponCountersPerBrandService.find(
        (c) => c.brandServiceId == brandServiceId
      );
      if (counterBS) {
        return counterBS.numOfAvailableCoupons === 0;
      }
    }
    return false;
  }

  /**
   * Check if Product has limitation Reached.
   * @param product
   * @returns
   */
  public isProductLimitReached(
    product: LoyaltyBrandProductDTO,
    parentProduct?: LoyaltyBrandProductDTO
  ): boolean {
    if (!product) return false;
    let limitationLookupProduct = product;
    const purchaseCount = this.utilSrv.getItemMappedStatistic(
      limitationLookupProduct,
      4
    );
    if (
      !isNullOrUndefined(limitationLookupProduct.maxAllowedPurchaseQuantity) &&
      !isNullOrUndefined(purchaseCount) &&
      !isNullOrUndefined(purchaseCount.total) &&
      isNumber(purchaseCount.total)
    ) {
      if (
        limitationLookupProduct.maxAllowedPurchaseQuantity <=
        purchaseCount.total
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if Product has Limitation Rule.
   * @param product
   * @returns
   */
  public productHasLimitation(
    product: LoyaltyBrandProductDTO,
    parentProduct?: LoyaltyBrandProductDTO
  ): boolean {
    if (!product) return false;
    let limitationLookupProduct = product;
    const purchaseCount = this.utilSrv.getItemMappedStatistic(
      limitationLookupProduct,
      4
    );
    if (
      !isNullOrUndefined(limitationLookupProduct.maxAllowedPurchaseQuantity) &&
      !isNullOrUndefined(purchaseCount) &&
      !isNullOrUndefined(purchaseCount.total) &&
      isNumber(purchaseCount.total)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Get Product Coupon Countdown value.
   * @param product
   * @returns
   */
  public getProductCouponCountdown(
    product: LoyaltyBrandProductDTO
  ): number | null {
    const hasCountDown = product.countdown;
    if (hasCountDown) {
      if (product.availableCouponsThreshold > 0) {
        return product.availableCouponsThreshold;
      }
    }
    return null;
  }

  /**
   * Check wether user has ammount to make order.
   * @param product
   * @returns
   */
  public canUserPurchaseProduct(
    product: LoyaltyBrandProductDTO,
    parentProduct?: LoyaltyBrandProductDTO | null,
    pointOfSale?: "offline" | "online" | null
  ) {
    let lookupProduct = product;
    if (!isNil(parentProduct) && product.isBundle === true)
      lookupProduct = parentProduct;
    const numberPoints = this.getProductNumberOfPoints(lookupProduct);
    if (!pointOfSale) {
      let results = [];
      for (let points of numberPoints) {
        results.push(!(points > this.userSrv.user.balance));
      }
      // even if one option can get purchased, return true
      return results.some((entry) => entry === true);
    } else {
      const salePoints =
        numberPoints[this.pricePointsOfSale.indexOf(pointOfSale)] ||
        numberPoints[0];
      return salePoints > this.userSrv.user.balance ? false : true;
    }
  }

  /**
   *
   * @param product
   * @returns
   */
  public isSimpleProduct(product: LoyaltyBrandProductDTO): boolean {
    return product.isBundled === false && product.isBundle === false;
  }

  /**
   *
   * @param product
   */
  public productHasRequiredAchievement(
    product: LoyaltyBrandProductDTO
  ): boolean {
    return product.achievementRequired === true;
  }

  public productAchievementCompleted(product: LoyaltyBrandProductDTO): boolean {
    if (!this.productHasRequiredAchievement(product)) return false;
    return (
      product.achievement &&
      product.achievement.userAchievement &&
      product.achievement.userAchievement.completed === true
    );
  }

  /**
   * Pulic  of rewards service
   */
  public getProductRedeemtionStatus(
    product: LoyaltyBrandProductDTO,
    parentProduct?: LoyaltyBrandProductDTO | null,
    brandServiceId?: string
  ): boolean {
    let pointsLookupProduct = product;
    let achievementProduct = product;
    if (product.isBundle && !isNil(parentProduct)) {
      pointsLookupProduct = parentProduct;
      achievementProduct = parentProduct;
    }
    if (product.isSegregated && this.hasBoughtSegregated) {
      return false;
    }
    if (product.isVoucher && this.hasBoughtVoucher) {
      return false;
    }
    if (this.isProductLimitReached(product)) {
      return false;
    }
    if (
      product.isBundle &&
      !isNil(parentProduct) &&
      this.isProductLimitReached(parentProduct)
    ) {
      return false;
    }
    if (
      this.productHasRequiredAchievement(achievementProduct) &&
      !this.productAchievementCompleted(achievementProduct)
    ) {
      return false;
    }
    if (!this.canUserPurchaseProduct(pointsLookupProduct)) {
      return false;
    }

    if (
      product.isBundle &&
      parentProduct &&
      this.productIsTerminatedBundle(parentProduct, product)
    ) {
      return false;
    } else if (this.productIsTerminatedNewByService(product, brandServiceId)) {
      return false;
    }
    //TODO
    // if (this.productIsTerminated(product)) {
    //   return false;
    // }
    return true;
  }

  public async couponRedirectToIqosStore(code: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!isString(code) || !code.length) {
        reject(false);
        return;
      }
      const result = await this.getCouponRedirection().catch((err) => {
        logger.error(err);
      });
      if (result && result.authKey) {
        const encode = btoa(
          TAValues.COUPON_IQOS_REDIR_LINK.replace("$code", code)
        );
        const url = TAValues.COUPON_IQOS_STORE_REDIR_LINK.replace(
          "$authKey",
          result.authKey
        ).replace("$encode", encode);
        window.open(url, "_parent");
        resolve(true);
        return;
      } else {
        reject(false);
        return;
      }
    });
  }

  private setupExtraContent(item: ExtraContent) {
    item.title = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.TITLE
    );
    item.description = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.DESCRIPTION
    );
    item.image = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.IMAGE
    );
    item.videoUrl = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.VIDEO_URL
    );
    item.displayType = this.utilSrv.valueFromKeyValueDTOArray(
      METADATA_KEY.DISPLAY_TYPE,
      item.metadata
    );
  }

  public async getExternalContents(
    BrandProductId
  ): Promise<Array<ExtraContent>> {
    return new Promise<Array<ExtraContent>>(async (resolve, reject) => {
      const input: FilterESContentDataInput = {
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT],
        associatedItemIds: [BrandProductId],
        rangeFrom: 0,
        rangeTo: -1,
        currentlyRewarding: true,
        countMode: false,
        userRewarded: null,
        currentlyActive: true,
        matchingLevel: true,
        matchingRules: true,
        sortOrderTypes: [Order_Stores.BY_ORDERING_ASC],
      };
      const items = await this.recommendationsSrv
        .getRecommendationContent(TAValues.UserId, input, {
          resources: true,
          languageIds: TAConstants.Settings.LANGUAGES,
          metadatas: true,
        })
        .catch((err) => {});
      if (items && items.length > 0) {
        items.forEach((i) => {
          this.setupExtraContent(i);
        });

        resolve(items);
        return;
      }

      resolve(null);
    });
  }

  public async getFilters(): Promise<KpDictionary<ExtendedCategoryDTO>> {
    return new Promise<KpDictionary<ExtendedCategoryDTO>>(
      async (resolve, reject) => {
        let _childCategoryData: KpDictionary<ExtendedCategoryDTO> = null;
        let _rootCategoryData: ExtendedCategoryDTO = null;

        let promoBought = true;
        let referralPromoBought = true;
        let hasReferral = true;
        let hasBought = true;
        let hasBoughtVoucher = true;

        const _rewardConfig =
          this.appGlobalsSrv.config[
            TAConstants.APPLICATION_SETTING_KEYS.REWARDS_CONFIG
          ] || {};

        const _rootCategoryId = !isNaN(
          parseInt(
            _rewardConfig[
              TAConstants.APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
            ]
          )
        )
          ? parseInt(
              _rewardConfig[
                TAConstants.APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
              ]
            )
          : null;

        const promoCategoryId = !isNaN(
          parseInt(
            _rewardConfig[
              TAConstants.APPLICATION_SETTING_KEYS.REWARDS_PROMO_CATEGORY_ID
            ]
          )
        )
          ? parseInt(
              _rewardConfig[
                TAConstants.APPLICATION_SETTING_KEYS.REWARDS_PROMO_CATEGORY_ID
              ]
            )
          : -1;
        const promoStoreCouponCategoryId = !isNaN(
          parseInt(
            _rewardConfig[
              TAConstants.APPLICATION_SETTING_KEYS
                .REWARDS_STORE_PROMO_CATEGORY_ID
            ]
          )
        )
          ? parseInt(
              _rewardConfig[
                TAConstants.APPLICATION_SETTING_KEYS
                  .REWARDS_STORE_PROMO_CATEGORY_ID
              ]
            )
          : -1;
        const referralPromoCategoryId = !isNaN(
          parseInt(
            _rewardConfig[
              TAConstants.APPLICATION_SETTING_KEYS
                .REWARDS_REFERRAL_PROMO_CATEGORY_ID
            ]
          )
        )
          ? parseInt(
              _rewardConfig[
                TAConstants.APPLICATION_SETTING_KEYS
                  .REWARDS_REFERRAL_PROMO_CATEGORY_ID
              ]
            )
          : -1;

        const _missionCategoryId = !isNaN(
          parseInt(
            _rewardConfig[
              TAConstants.APPLICATION_SETTING_KEYS.REWARDS_MISSION_CATEGORY_ID
            ]
          )
        )
          ? parseInt(
              _rewardConfig[
                TAConstants.APPLICATION_SETTING_KEYS.REWARDS_MISSION_CATEGORY_ID
              ]
            )
          : -1;

        const _configuratorCategoryId = !isNaN(
          parseInt(
            _rewardConfig[
              TAConstants.APPLICATION_SETTING_KEYS
                .REWARDS_CONFIGURATOR_CATEGORY_ID
            ]
          )
        )
          ? parseInt(
              _rewardConfig[
                TAConstants.APPLICATION_SETTING_KEYS
                  .REWARDS_CONFIGURATOR_CATEGORY_ID
              ]
            )
          : -1;

        const query: ListReferralInput = {
          referredUserId: TAValues.UserId,
          rangeFrom: 0,
          rangeTo: 1,
          referralTypeIds: [
            TAConstants.REFERRAL_TYPES.MGM_UNICODE,
            TAConstants.REFERRAL_TYPES.LENDING_REFERRAL,
            TAConstants.REFERRAL_TYPES.LIL,
          ],
          referralStatusIds: [2, 3, 4],
          includeDetails: false,
          dateFrom: "2022-02-14T00:00:00.000Z",
        };
        const prResult = await Promise.all([
          this.checkIfHasBoughtByCategoryId(promoCategoryId),
          this.checkIfHasBoughtByCategoryId(referralPromoCategoryId),
          this.checkIfReferral(query),
          this.checkIfHasBoughtByDate(
            moment("14-02-2022", "DD-MM-YYYY").toDate().getTime()
          ),
          this.checkIfHasBoughtByCategoryId(promoStoreCouponCategoryId),
          this.checkIfHasBoughtVoucher(),
        ]).catch((err) => {});
        if (prResult) {
          // this.hasBought = prResult[0];
          promoBought = prResult[0] || prResult[4];
          referralPromoBought = prResult[1];
          hasReferral = prResult[2];
          hasBought = prResult[3];
          hasBoughtVoucher = prResult[5];
        }

        const _getCategoriesTreeFilter = await to(
          this.utilSrv.getRootCategoryTree(_rootCategoryId, false, true, false)
        );
        if (isNil(_getCategoriesTreeFilter.data)) return;
        if (!isNil(_getCategoriesTreeFilter)) {
          _rootCategoryData =
            _getCategoriesTreeFilter.data as ExtendedCategoryDTO;
          if (
            !isNil(_rootCategoryData.childrenMapped) &&
            _rootCategoryData.childrenMapped.Count()
          ) {
            _childCategoryData =
              _rootCategoryData.childrenMapped as KpDictionary<ExtendedCategoryDTO>;
            const callCenterCategoryId = !isNaN(
              parseInt(
                _rewardConfig[
                  TAConstants.APPLICATION_SETTING_KEYS
                    .REWARDS_CALL_CENTER_CATEGORY_ID
                ]
              )
            )
              ? parseInt(
                  _rewardConfig[
                    TAConstants.APPLICATION_SETTING_KEYS
                      .REWARDS_CALL_CENTER_CATEGORY_ID
                  ]
                )
              : null;
            if (callCenterCategoryId) {
              const category = _rootCategoryData.children.find((c) => {
                return c.id == callCenterCategoryId;
              });
              if (category) {
                _childCategoryData.Remove(category.id.toString());
              }
            }

            const removeCategory = (value: string) => {
              const category = _rootCategoryData.children.find((c) => {
                var result = false;
                if (c.metadata && c.metadata.length > 0) {
                  const metadata = this.utilSrv.valueFromKeyValueDTOArray(
                    TAConstants.METADATA_KEY.DISPLAY_BUTTON,
                    c.metadata
                  );
                  result = metadata && metadata == value;
                }
                return result;
              });
              if (category) {
                _childCategoryData.Remove(category.id.toString());
              }
            };

            const checkIfMustShowPromo = (force?: boolean) => {
              if (hasBought || promoBought || force) {
                removeCategory("true");
              }
            };

            const checkIfMustShowReferralPromo = (force?: boolean) => {
              if (promoBought || referralPromoBought || force) {
                removeCategory("false");
              }
            };

            _rootCategoryData.children.forEach((c) => {
              if (c.metadata && c.metadata.length > 0) {
                const levelEnabled = this.utilSrv.valueFromKeyValueDTOArray(
                  TAConstants.METADATA_KEY.CATEGORY_MIN_LEVEL,
                  c.metadata
                );
                if (levelEnabled && levelEnabled != "") {
                  const levels = levelEnabled.split(",");
                  if (levels.length > 0) {
                    if (
                      levelEnabled.indexOf(
                        String(this.userSrv.user.badgeLevel)
                      ) >= 0
                    ) {
                      // if (this.userSrv.user.badgeLevel == 2) {
                      //   if (!this.userSrv.user.isCasting)
                      //     _childCategoryData.Remove(c.id.toString());
                      // }
                    } else {
                      _childCategoryData.Remove(c.id.toString());
                    }
                  }
                }

                // const nonLevelEnabled = this.utilSrv.valueFromKeyValueDTOArray(
                //   METADATA_KEY.CATEGORY_MAX_LEVEL,
                //   c.metadata
                // );
                // if (nonLevelEnabled && nonLevelEnabled != "") {
                //   const levels = nonLevelEnabled.split(",");
                //   if (levels.length > 0) {
                //     if (
                //       nonLevelEnabled.indexOf(
                //         String(this.userSrv.user.badgeLevel)
                //       ) >= 0
                //     ) {
                //       if (this.userSrv.user.badgeLevel == 2) {
                //         if (this.userSrv.user.isCasting)
                //           _childCategoryData.Remove(c.id.toString());
                //       }
                //     } else {
                //       _childCategoryData.Remove(c.id.toString());
                //     }
                //   }
                // }
              }
            });

            if (hasReferral) {
              checkIfMustShowPromo(true);
              checkIfMustShowReferralPromo();
            } else {
              checkIfMustShowReferralPromo(true);
              checkIfMustShowPromo();
            }

            const categorySpecial = _rootCategoryData.children.find((c) => {
              var result = false;
              if (c.metadata && c.metadata.length > 0) {
                const metadata = this.utilSrv.valueFromKeyValueDTOArray(
                  TAConstants.METADATA_KEY.DISPLAY_SPECIAL_DEAL_BUTTON,
                  c.metadata
                );
                result = metadata && metadata == "true";
              }
              return result;
            });
            let mustRemove = true;

            if (categorySpecial) {
              const userMetadata =
                this.userSrv.user.user.customUserProfileFields[
                  TAConstants.METADATA_KEY
                    .PERSONAL_ELIGIBLE_FOR_SPECIAL_CATALOGUE
                ];
              if (userMetadata && userMetadata == "true") {
                const _getProductCouponSelection = await to(
                  this.getProductCouponSelection(
                    categorySpecial,
                    null,
                    false,
                    false,
                    false,
                    true,
                    [
                      TAConstants.COUPON_STATUSES.PURCHASED,
                      TAConstants.COUPON_STATUSES.REDEEMED,
                      TAConstants.COUPON_STATUSES.EXPIRED,
                      TAConstants.COUPON_STATUSES.EXPIRED_REQUESTED_CHARGE_BACK,
                      TAConstants.COUPON_STATUSES.EXPIRED_CLEARED,
                      TAConstants.COUPON_STATUSES
                        .EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
                      TAConstants.COUPON_STATUSES.EXPIRED_CLEARED_FAILED,
                      TAConstants.COUPON_STATUSES.EXPIRED_GIFTCARD,
                      TAConstants.COUPON_STATUSES.EXPIRED_YELLOWS_CHARGED_BACK,
                    ],
                    null,
                    0,
                    1,
                    [107]
                  )
                );

                if (_getProductCouponSelection) {
                  const bought =
                    _getProductCouponSelection.data &&
                    _getProductCouponSelection.data.Count() > 0;
                  mustRemove = bought;
                }
              }

              if (mustRemove) {
                _childCategoryData.Remove(categorySpecial.id.toString());
              }
            }
          }
        }

        resolve(_childCategoryData);
      }
    );
  }

  storeTypeIdsToValues(ids?: string): string {
    const _rewardConfig =
      this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] || {};
    if (ids && ids != "" && _rewardConfig) {
      const idsToValues =
        _rewardConfig[APPLICATION_SETTING_KEYS.STORE_TYPE_ID_TO_VALUE];
      if (idsToValues && idsToValues != "") {
        const array = ids.split(",");
        if (array && array.length > 0) {
          let result = "";
          array.forEach((a) => {
            const value = idsToValues[a.trim()];
            if (value) result += `choices=${value}&`;
          });
          if (result.length > 0)
            result = result.substring(0, result.length - 1);
          return result;
        }
      }
    }
    return null;
  }
}

export interface CategoryCounterDTO {
  categorydId: number;
  items: number;
}

export interface RewardPageData {
  categories: KpDictionary<ExtendedCategoryDTO>;
  brandProducts: KpDictionary<LoyaltyBrandProductDTO>;
}

export interface UserRewardsPageData {
  brandProductCoupons: KpDictionary<DetailedBrandProductCouponDTO>;
  brandProducts: KpDictionary<ExtendedBrandProductDTO>;
  categories: KpDictionary<ExtendedCategoryDTO>;
}

export interface ExtendeDetailedBrandProductCouponDTO
  extends DetailedBrandProductCouponDTO {
  externalReferenceId?: string | null;
  /**
   *
   */
  productItemsMapped?: KpDictionary<BrandProductItemDTO>;

  /**
   * Property Holds Resources in Dictionary Format.
   */
  resourcesMapped?: KpDictionary<KpDictionary<ResourceDTO>>;

  /**
   * Property Holds Metadata in Dictionary Format.
   */
  metadataMapped?: KpDictionary<string>;

  purchaseType: string | null;
}

export interface ExtendedBrandProductDTO extends DetailedBrandProductDTO {
  /**
   * Property Holds Resources in Dictionary Format.
   */
  resourcesMapped?: KpDictionary<KpDictionary<ResourceDTO>>;

  /**
   * Property Holds Metadata in Dictionary Format.
   */
  metadataMapped?: KpDictionary<string>;

  /**
   *  Bundle Flag of a Brand Product.
   */
  isBundle: boolean;

  /**
   *  Bundle Flag of a Brand Product.
   */
  isBundled: boolean;

  /**
   *
   */
  parentBrandPorductId: string;

  /**
   *
   */
  statisticsMapped: KpDictionary<ItemStatisticsDTO>;

  /**
   *
   */
  maxAllowedPurchaseQuantity?: number;

  /**
   *
   */
  purchaseType: Array<BRAND_PRODUCT_PURHCASE_TYPES>;
}

export interface LoyaltyBrandProductDTO extends ExtendedBrandProductDTO {
  /**
   *
   */
  categoryName: string | null;

  /**
   * Display Date
   */
  displayDate: boolean;

  /**
   * Display Tickets left
   */
  displayTicketsLeft: boolean;

  /**
   * Redirection Url.
   */
  externalUrl: string;

  couponCountersPerBrandService: Array<CouponCountersPerBrandService>;

  /* Total Avialable Coupons.*/
  numOfTotalAvailableCoupons: number;

  /**
   *  Bundle Flag of a Brand Product.
   */
  isListed: boolean;

  /**
   * Bundle Coupons Count of a Brand Product.
   */
  totalBundleCoupons?: number | null;

  totalBundleCouponsByServiceId?: Map<string, number>;

  /**
   * Can Redeem Flag.
   */
  notRedeemable: boolean;

  /**
   * Has Unlimited Coupons
   */
  unlimitedCoupons: boolean;

  /**
   * Achievement if requested
   */
  achievement?: AchievementDTO;

  /**
   * Achievement required
   */
  achievementRequired?: boolean;

  oTPConfig?: { oTPRequired?: boolean; prerequisiteItem?: ItemItemTypeDTO };

  numberPoints?: number;

  terminato?: boolean;

  usato?: boolean;

  actionLabel?: string;

  action?: string;

  countdown?: boolean;

  threshold?: string;

  theme?: string;

  availableCouponsThreshold: number | null;

  image: ImageItem | null;

  imageCarousel: Array<ImageItem> | null;

  imageLarge: ImageItem | null;

  tag: AlertDef | null;

  isGiftCard?: boolean;

  isVoucher?: boolean;

  isWelcome?: boolean;

  isDCS?: boolean;

  isSegregated?: boolean;

  isDonate?: boolean;

  storeType?: string;
}

export interface ExtendedCategoryDTO extends CategoryDTO {
  /**
   * Property Holds Resources in Dictionary Format.
   */
  resourcesMapped?: KpDictionary<KpDictionary<ResourceDTO>>;

  /**
   * Property Holds Resources in Dictionary Format.
   */
  brandProducts?: KpDictionary<ExtendedBrandProductDTO>;

  /**
   * Property Holds Resources in Dictionary Format.
   */
  brandProductCoupons?: KpDictionary<DetailedBrandProductCouponDTO>;

  /**
   * Count Items of category
   */
  itemCount?: number;

  /**
   *  Children Formatted.
   */
  childrenMapped?: KpDictionary<ExtendedCategoryDTO>;

  /**
   * Hex Color metadata.
   */
  brandProductColor?: string;

  /**
   * Hex Color metadata.
   */
  brandProductColorName?: string;
  /**
   * Redirection Url.
   */
  externalUrl?: string;
}

export interface resourcePayload {
  itemId: string;
  resourceTypeId: number;
  itemTypeId: number;
  languageIsoCode: string;
}

export enum BRAND_PRODUCT_PURHCASE_TYPES {
  BOUTIQUE = 1,
  ECOMMERCE = 2,
}

export enum BRAND_PRODUCT_COUPON_PURCHASE_TYPES {
  ECOMMERCE = "eCommerce",
  BOUTIQUE = "boutique",
  STORE = "store",
}

export interface getProductCouponDAO {
  assocCoupon: ExtendeDetailedBrandProductCouponDTO;
  assocProduct: ExtendedBrandProductDTO;
}

export interface YourRewardsPageData {
  brandProductCoupons: KpDictionary<DetailedBrandProductCouponDTO>;
  brandProducts: KpDictionary<ExtendedBrandProductDTO>;
  categories: KpDictionary<ExtendedCategoryDTO>;
  counters: KpDictionary<Number>;
}

export interface CatalogPageData {
  categories: KpDictionary<ExtendedCategoryDTO>;
  counters: KpDictionary<Number>;
  brandProducts: KpDictionary<ExtendedBrandProductDTO>;
}

export interface RewardPagging {
  rangeFrom: number;
  rangeTo: number;
}

export type ProductNote = {
  variant:
    | "success"
    | "info"
    | "warning"
    | "error"
    | "threshold"
    | "threshold-light";
  backgroundColor?: string;
  message: string;
};

export interface ExtraContent extends IndexedDataContent {
  title?: string;
  description?: string;
  image?: string;
  videoUrl?: string;
  displayType?: string;
  opened?: boolean;
}
