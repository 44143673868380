import {Http2Talos} from "../http2Talos";
import {AppSettingsDTO} from "../models/AppSettingsDTO";
import {Injectable} from "@angular/core";
import {AppSettingsInitDTO} from "../models/AppSettingsInitDTO";

@Injectable()
export class AppSettingsApi {
    constructor(private http2Talos: Http2Talos) {
    }

    public getAppSettings(applicationID: string, scopeTypes?: number): Promise<Array<AppSettingsDTO>> {
        return this.http2Talos.get({path: `/appsettings/app/${applicationID}`, queryParams: {scopeTypes: scopeTypes}});
    }

    public getAppSettingsInit(applicationID: string): Promise<AppSettingsInitDTO> {
        return this.http2Talos.get({path: `/appsettings/app/init/${applicationID}`});
    }
}
