/*
 * @Author: Peter Fousteris (petfoust@gmail.com)
 * @Date: 2019-09-11 13:22:21
 * @Last Modified by: Peter Fousteris (petfoust@gmail.com)
 * @Last Modified time: 2019-09-26 16:19:43
 */

import { Injectable } from "@angular/core";
import { UtilsService } from "../utils.service";
import { TAValues, TAConstants } from "src/talosApi/settings";
import { IShortify } from "src/sharedApi/models/IShortify";
import { ItemApi } from "src/talosApi/api";
import { to } from "../../../../src/utils/utils";
import { isNullOrUndefined } from "util";
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Resource_Types = TAConstants.Resource_Types;
import { resolve } from "url";
import { TranslateService } from "@ngx-translate/core";

/**
 * Injectable
 */
@Injectable()
export class SlugService {
  protected _slugs: any;

  /**
   * Creates an instance of slug service.
   * @param api
   * @param utilsService
   */
  constructor(
    public itemApi: ItemApi,
    public utilSrv: UtilsService,
    public translateSrv: TranslateService
  ) {
    this.initData();
  }

  /**
   * Inits data
   */
  private async initData() {
    /* Prepare Payload. */
    let payload = [];
    TAConstants.Settings.LANGUAGES.forEach((lang) => {
      payload.push({
        itemId: TAConstants.Settings.GAME_TYPE_ID,
        resourceTypeId: Resource_Types.APPLICATION_SLUGS,
        itemTypeId: ITEM_TYPE.GAME_TYPE_ID,
        languageIsoCode: lang,
      });
    });
    const searchEtag = await to(this.itemApi.searchEtag(payload, null, false));
    if (!isNullOrUndefined(searchEtag.data)) {
      const tmp = this.utilSrv.formatItemResources({
        resources: searchEtag.data,
      });
      if (tmp.ContainsKey(Resource_Types.APPLICATION_SLUGS.toString())) {
        if (
          tmp
            .Item(Resource_Types.APPLICATION_SLUGS.toString())
            .ContainsKey(this.translateSrv.currentLang)
        ) {
          const _tmp = tmp
            .Item(Resource_Types.APPLICATION_SLUGS.toString())
            .Item(this.translateSrv.currentLang);
          try {
            const _parsed = JSON.parse(_tmp.textResource);
            logger.log("_parsed", _parsed);
            this._slugs = _parsed;
          } catch (e) {
            logger.error("JSON.parse", _tmp.textResource);
          }
        }
      }
    }
  }

  private swapKeyValues(obj) {
    var ret = {};
    for (var key in obj) {
      ret[obj[key]] = key;
    }
    return ret;
  }

  /**
   * Gets item slub
   * @param name
   * @param itemType
   * @returns item slub
   */
  async getItemSlug(name: string, itemTypeId: number): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      let _ret = null;
      try {
        if (!this._slugs) {
          await to(this.initData());
        }
        if (this._slugs.hasOwnProperty(itemTypeId)) {
          const _itemTypeSlug = this.swapKeyValues(this._slugs[itemTypeId]);
          if (_itemTypeSlug.hasOwnProperty(name)) {
            _ret = _itemTypeSlug[name];
          }
        }
      } catch (e) {
        logger.log(`No Slug Found for ${itemTypeId}, $name`, e);
      }
      if (_ret) resolve(_ret);
      else reject(null);
    });
  }

  /**
   * Gets slug item
   * @param itemId
   * @param itemTypeId
   * @returns slug item
   */
  async getSlugItem(itemId: string, itemTypeId: number): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      let _ret = null;
      try {
        if (!this._slugs) {
          await to(this.initData());
        }
        if (this._slugs.hasOwnProperty(itemTypeId)) {
          const _itemTypeSlug = this._slugs[itemTypeId];
          if (_itemTypeSlug.hasOwnProperty(itemId)) {
            _ret = _itemTypeSlug[itemId];
          }
        }
      } catch (e) {
        logger.log(`No Slug Found for ${itemTypeId}, $itemId`, e);
      }
      if (_ret) resolve(_ret);
      else reject(null);
    });
  }
}

export interface ISlug {}
