import {ITalosApiModel} from "./TalosApiModel.interface";

export class TransactionHistoryInput{

    unitTypeId?:number[];

    transactionTypeId?:  number[];

    dateFrom?: string | number;

    dateTo?:string | number;

    rangeFrom?:number;

    rangeTo?:number;

    sortByDate?:boolean;

    descendingOrder?:boolean;

    orderType?:string;

    includeDetails?:boolean;

    resources?:boolean|Array<string>;

    metadatas?:boolean|Array<string>;

    languageIds?:Array<string>;

    excludeZeroAmounts?: boolean;

    itemIds?: Array<string>;

    itemTypeId?: number;

    eventTypeIds?: Array<number>;

    groupByGroupByType?: string;

}