/**
 * Injectable
 */
import {
    FinalizeSalesOrderInput, ListSalesOrdersParams, SalesOrderApi, SalesOrderDTO,
    SalesOrderInput
} from "../../talosApi/api/SalesOrderApi";
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class SalesOrderService {

    /**
     * Creates an instance of token services.
     * @param api
     * @param translate
     */
    constructor(private api: SalesOrderApi) {
    }

    /**
     * Creates a SalesOrder
     * @param {string} userId
     * @param {SalesOrderInput} input
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<SalesOrderDTO>>}
     */
    createSalesORder(userId: string, input: SalesOrderInput, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<SalesOrderDTO>> {
        return new Promise<Array<SalesOrderDTO>>((resolve, reject) => {
            this.api.createSalesORder(userId, input, requireSession, headers)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    /**
     * List Sales Orders
     * @param {string} userId
     * @param {ListSalesOrdersParams} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<SalesOrderDTO>>}
     */
    listSalesOrders(userId: string, queryParams: ListSalesOrdersParams, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<SalesOrderDTO>> {
        return new Promise<Array<SalesOrderDTO>>((resolve, reject) => {
            this.api.listSalesOrders(userId, queryParams, requireSession, headers)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    /**
     * Update a SalesOrder
     * @param {string} userId
     * @param {SalesOrderInput} input
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<SalesOrderDTO>>}
     */
    updateSalesOrders(userId: string, input: SalesOrderInput, queryParamMap: Pick<ListSalesOrdersParams, "resources" | "metadatas" | "languageIds">, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<SalesOrderDTO>> {
        return new Promise<Array<SalesOrderDTO>>((resolve, reject) => {
            this.api.updateSalesOrders(userId, input, queryParamMap, requireSession, headers)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    /**
     * Finalize a SalesOrder
     * @param {string} userId
     * @param {FinalizeSalesOrderInput} input
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<SalesOrderDTO>}
     */
    orderSalesOrder(userId: string, input: FinalizeSalesOrderInput, queryParamMap: Pick<ListSalesOrdersParams, "resources" | "metadatas" | "languageIds">, requireSession: boolean = true, headers: HttpHeaders = null): Promise<SalesOrderDTO> {
        return new Promise<SalesOrderDTO>((resolve, reject) => {
            this.api.orderSalesOrder(userId, input, queryParamMap, requireSession, headers)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
}