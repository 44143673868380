import { Http2Talos } from "../http2Talos";
import { Injectable } from "@angular/core";
import { InAppMessageDTO } from "../models/InAppMessageDTO";

@Injectable()
export class InAppMessagesApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Get In App Messages
   * @param {string} userId
   * @param {GetInAppMessagesQuery} queryParams
   * @param {boolean} requireSession
   * @return {Promise<Array<InAppMessageDTO>>}
   */
  public getInAppMessages(
    userId: string,
    queryParams: GetInAppMessagesQuery,
    requireSession: boolean = true
  ): Promise<Array<InAppMessageDTO>> {
    return this.http2Talos.get({
      path: `/inAppMessages/${userId}`,
      queryParams: queryParams,
    });
  }

  /**
   * Set In App Messages status
   * @param {string} userId
   * @param {ChangeMessageStatusInput} input
   * @param {boolean} requireSession
   * @return {Promise<any>}
   */
  public setInAppMessagesStatus(
    userId: string,
    input: ChangeMessageStatusInput,
    requireSession: boolean = true
  ): Promise<any> {
    return this.http2Talos.post({
      path: `/inAppMessages/${userId}/status`,
      input: input,
    });
  }
}

export interface GetInAppMessagesQuery {
  statusId?: number;
  typeId?: number | Array<number>;
  dateFrom?: number | Date;
  dateTo?: number | Date;
  includeDetails?: boolean;
  orderType?: string;
  rangeFrom?: number;
  rangeTo?: number;
}

export interface ChangeMessageStatusInput {
  messageIds?: Array<string>;
  status?: number;
}
