import {Injectable} from "@angular/core";
import {ItemApi} from "../../talosApi/api/ItemApi";
import {AppSettingsService} from "./appSettings.service";
import {CategoryApi} from "../../talosApi/api/CategoryApi";
import {CategoryDTO} from "../../talosApi/models/CategoryDTO";
import {GetMultipleMetadataResponse, ItemsList} from "../../talosApi/models";
import {TAConstants} from "../../talosApi/settings";
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import {ResourcesServices} from "./resources.services";
import _ from "lodash";


@Injectable()
export class CategoryService {

    public categoryById: Map<number, CategoryDTO> = new Map<number, CategoryDTO>();


    constructor(
                private categoryApi:CategoryApi,
                private resourcesSrv: ResourcesServices,
                private appSettingsSrv:AppSettingsService,
                private itemApi:ItemApi
    ) {
    }

    /**
     *
     * @param {number} categoryId
     * @param {boolean} metadata
     * @param {boolean} metadataParent
     * @param {boolean} leaves
     * @param {number[]} resources
     * @param {number[]} resourcesParent
     * @return {Promise<CategoryDTO>}
     */
    public getCategoryById(categoryId: number, metadata: boolean = false, metadataParent: boolean = false, leaves: boolean = true, resources?: number[], resourcesParent?: number[]): Promise<CategoryDTO> {
        return new Promise( (resolve, reject) => {

            //local storage the category
            let category = this.categoryById.get(categoryId);
            if(!_.isNil(category)) {
                resolve(category);
                return;
            }

            this.getCategoriesByParent(
                categoryId,
                2,
                leaves,
                0,
                -1,
                '_category_' + categoryId + '_',
                resources,
                metadata,
                metadataParent

            ).then(
                (category_: CategoryDTO) => {
                    this.categoryById.set(categoryId,category_);
                    resolve(category_);
                }, error => {
                    reject(error);
                });
        });

    }

    
    /**
     *
     * @param categoryId
     * @param depth
     * @param withLeaves
     * @param rangeFrom
     * @param rangeTo
     * @param suffix
     * @param applicationSettingsVersion
     * @param applicationSettingsResourcesVersion
     * @param resources
     * @param includeMetadata
     * @param metadataParent
     * @param force_
     * @returns {any}
     */
    private getCategoriesByParent(categoryId: number,
                                  depth?: number,
                                  withLeaves?: boolean,
                                  rangeFrom?: number,
                                  rangeTo?: number,
                                  suffix: string = '',
                                  resources?: Array<number>,
                                  includeMetadata?: boolean,
                                  metadataParent?: boolean,
                                  force_:boolean =false
    ): Promise<CategoryDTO> {

        return new Promise ((resolve, reject) => {

            let categories: Array<CategoryDTO> = [];
            let parentCategory: CategoryDTO = undefined;


            const getCategoryFromTalos = () => {

                logger.log(categoryId,depth,withLeaves,rangeFrom,rangeTo);
                this.categoryApi.get(categoryId, depth, withLeaves, rangeFrom, rangeTo)
                    .then(
                        (items: ItemsList<CategoryDTO>) => {
                            parentCategory = items[0];
                            if (items.count === 0) {
                                resolve(null);
                                return;
                            }


                            if (_.isNil(parentCategory.children) == false) {
                                categories = categories.concat(parentCategory.children);
                            }

                            if (_.isNil(parentCategory.leaves) === false) {
                                categories = categories.concat(parentCategory.leaves);
                            }
                            const categoryIds: Array<string> = categories.map((category) => {
                                return category.itemIdForResourceLookup;
                            });

                            //TODO add two values for "parent resources and parent metadata"
                            if (includeMetadata) {
                                this.itemApi.getMultipleMetadata(ITEM_TYPE.CATEGORY, metadataParent ? [ parentCategory.id.toString()] : categoryIds)
                                    .then((metadata: GetMultipleMetadataResponse[]) => {
                                        if (_.isNil(metadata) === false) {
                                            metadata.forEach((metadatum) => {
                                                const category = categories.find((category) => ((metadataParent) ? parentCategory.itemIdForResourceLookup : category.itemIdForResourceLookup) == metadatum.itemId);
                                                category && (category.metadata = metadatum.metadata);
                                                parentCategory.metadata = metadatum.metadata;
                                            });
                                        }
                                        categories = withLeaves ? parentCategory.leaves : parentCategory.children;
                                        categories = categories || [];
                                        this.resourcesSrv.getResources([...categories, parentCategory], 'itemIdForResourceLookup', resources, 'itemTypeIdForResourceLookup')
                                            .then(next => {
                                                if (_.isBoolean(next) === false) {
                                                    resolve(parentCategory);
                                                    return;
                                                }
                                            }, error => {
                                                reject(error);
                                            }).catch((error) => {
                                                reject(error);
                                        });
                                    }).catch(() => {});
                            } else {
                                categories = withLeaves ? parentCategory.leaves : parentCategory.children;
                                categories = categories || [];
                                this.resourcesSrv.getResources([...categories, parentCategory], 'itemIdForResourceLookup', resources,  'itemTypeIdForResourceLookup')
                                    .then(next => {
                                        if (_.isBoolean(next) === false) {

                                            resolve(parentCategory);
                                            return;
                                        }
                                    }, error => {
                                        reject(error);
                                    }).catch((error) => {
                                        reject(error);
                                });
                            }
                        },
                        error => {
                            reject(error);
                        })
            };


            //Call the getCategory fun.
            getCategoryFromTalos();



        });
    }



}