import {UserService} from "../../../services/talos/user.service";
import {UtilsService} from "../../../services/utils.service";
import {Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {TAConstants, TAValues} from "../../../talosApi/settings";


export class BasePage implements OnDestroy {

    @ViewChild('spinner') spinner: ElementRef;

    /**
     * Data  of your rewards component
     */
    @ViewChild('errorModal') errorModal: ElementRef;

    // @ViewChild('content') elementView: ElementRef;
    listenerFn: () => void;
    popUpIsShown: boolean;

    constructor(public userSrv: UserService, public utilsSrv: UtilsService) {

    }

    ngAfterViewInit() {
        // const contentHeight = this.elementView.nativeElement.offsetHeight;
        // window.parent.postMessage(contentHeight, '*');

    }

    toggleLoading(show: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.spinner) {
                resolve();
                return;
            }

            if (show) {
                this.spinner.nativeElement.open();
                resolve();
                return;
            }
            if (!show) {
                this.spinner.nativeElement.close();
                resolve()
            }
        });
    }

    public smoothScrollTopTop() {
        window.scroll({top: 0, behavior: "smooth"})
    }


    ngOnDestroy(): void {
        if (this.listenerFn) {
            this.listenerFn();
        }
    }

    setPopupStatus(isShown:boolean){
        // this.popUpIsShown = isShown && TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS;
        // this.utilsSrv.popUpIsShown = isShown && TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS;
    }

    displayErrorModal(data: ErrorModalData) {
        try {
            this.errorModal.nativeElement.open(data);
        } catch (error) {
            logger.log(error)
        }
    }
}

export type ErrorModalData = {
    title: string;
    subTitle: string;
    errorMessage: string;
}