/**
 * Created by georgefloros on 16/05/2017.
 */

import { AppSettingsApi } from "./AppSettingsApi";
import { CategoryApi } from "./CategoryApi";
import { ItemApi } from "./ItemApi";
import { UploadContentsApi } from "./UploadContentsApi";
import { UserApi } from "./UserApi";
import { QuizApi } from "./QuizApi";
import { MessageApi } from "./MessageApi";
import { FavoriteApi } from "./FavoriteApi";
import { BrandApi } from "./BrandApi";
import { PmiApi } from "./PmiApi";
import { AchievementApi } from "./AchievementApi";
import { CheckInApi } from "./CheckInApi";
import { IndexedDataApi } from "./IndexedDataApi";
import { IndirectDataApi } from "./IndirectDataApi";

export {
  AppSettingsApi,
  CategoryApi,
  ItemApi,
  UploadContentsApi,
  UserApi,
  QuizApi,
  MessageApi,
  FavoriteApi,
  BrandApi,
  PmiApi,
  AchievementApi,
  CheckInApi,
  IndexedDataApi,
  IndirectDataApi,
};
