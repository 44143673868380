import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { EVENT_DEFINITIONS } from "../../../../../src/utils/utils";

@Component({
	selector: "range-from-to-paginator",
	templateUrl: "./range-from-to-paginator.component.html",
	styleUrls: ["./range-from-to-paginator.component.scss"],
})
export class RangeFromToPaginator implements OnInit {
	/**
	 * Show prev button of range from to paginator
	 */
	public showPrevButton: boolean = true;

	/**
	 * Show next button of range from to paginator
	 */
	public showNextButton: boolean = true;

	public maxDispalyedPages: number = 5;

	constructor(public pagingElement: ElementRef, public renderer: Renderer2) {
		this.renderer.listen(
			this.pagingElement.nativeElement,
			EVENT_DEFINITIONS.PAGINATION_CHANGE.NAME,
			this.eventFired.bind(this)
		);
	}

	private eventFired(ev: CustomEvent): void {
		logger.log(ev);
		if (!ev || !ev.detail || !ev.detail.page) return;
		const newIndex = !isNaN(ev.detail.page) ? ev.detail.page as number: 0;
		if (newIndex > 0 && newIndex <= this.paginatorTotalPages) {
			window.scrollTo(0, 0);
			this.changed.emit({
				rangeFrom: (newIndex - 1) * this.pagginStep,
				rangeTo: newIndex * this.pagginStep,
				page: Math.ceil(Math.abs(newIndex * this.pagginStep/this.pagginStep)),
			});
		}
	}

	private _rangeFrom: number = 0;

	get rangeFrom(): number {
		return this._rangeFrom;
	}

	@Input()
	set rangeFrom(val: number) {
		if (val && val >= 0) this._rangeFrom = val;
		else this._rangeFrom = 0;
		this.setValues();
	}

	private _pagginStep: number = 0;

	get pagginStep(): number {
		return this._pagginStep;
	}

	@Input()
	set pagginStep(val: number) {
		if (val && val > 0) this._pagginStep = val;
		else this._pagginStep = 0;
		this.setValues();
	}


	private _rangeTo: number = 0;

	get rangeTo(): number {
		return this._rangeTo;
	}

	@Input()
	set rangeTo(val: number) {
		if (val && val > 0) this._rangeTo = val;
		else this._rangeTo = 0;
		this.setValues();
	}

	private _totalItems: number = 0;

	get totalItems(): number {
		return this._totalItems;
	}

	@Input()
	set totalItems(val: number) {
		if (val && val > 0) this._totalItems = val;
		else this._totalItems = 0;
		this.setValues();
	}

	@Output() changed = new EventEmitter<RangeFromToPaginatorPayload>();
	
	public paginatorCurrentPage: number = 0;
	public paginatorTotalPages: number = 0;

	ngOnInit() { }

	private setValues() {
		if (this.pagginStep <= 0) return;
		if (this.rangeFrom < 0) return;
		if (!this.rangeTo) return;
		if (this.rangeFrom > this.rangeTo) return;
		const diff = this.rangeTo - this.rangeFrom;
		const total = Math.ceil(Math.abs(this._totalItems / diff));
		this.paginatorTotalPages = total;
		this.paginatorCurrentPage = Math.ceil(Math.abs(this.rangeTo/this.pagginStep));
	}
}


export type RangeFromToPaginatorPayload = {
	rangeFrom: number;
	rangeTo: number;
	page: number | null;
}