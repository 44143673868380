import { Injectable } from "@angular/core";
import { AppSettingsService } from "./appSettings.service";
import { TAConstants, TAValues } from "../../talosApi/settings";
import { UserService } from "./user.service";
import { ProfileService } from "../../app/pages/profile/profile.service";
import { ResourcesServices } from "./resources.services";
import { PendingPointsQ } from "../../talosApi/api/UserApi";
import { UploadContentsService } from "./uploadContents.service";
import { UtilsService } from "../utils.service";
import { RewardsService } from "../../app/pages/rewards/rewards.service";
import { MaterialService } from "../../app/pages/material/material.service";
import { CartService } from "../../app/pages/cart/cart.service";
import { AppGlobalsService } from "../appGlobals.service";
import { CategoryDTO } from "../../talosApi/models/CategoryDTO";
import { MenuItem } from "../../models/MenuItem";
import { AsyncCategoryService } from "../asyncTalos/async.category.service";
import APPLICATION_SETTING_SCOPE_TYPES = TAConstants.APPLICATION_SETTING_SCOPE_TYPES;
import Unit_Types = TAConstants.Unit_Types;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Resource_Types = TAConstants.Resource_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class InitService {
  initLoaded = false;
  removeMenu = false;

  constructor(
    private appSettingsSrv: AppSettingsService,
    private userService: UserService,
    private profileSrv: ProfileService,
    private resourcesSrv: ResourcesServices,
    private uploadContentsSrv: UploadContentsService,
    private utilsSrv: UtilsService,
    private rewardsSrv: RewardsService,
    private materialSrv: MaterialService,
    private cartSrv: CartService,
    private categorySrv: AsyncCategoryService,
    private globals: AppGlobalsService,
    private translate: TranslateService
  ) {}

  public init(): Promise<any> {
    const setupAppLabels = (appLabels) => {
      logger.log(appLabels);
      if (appLabels && appLabels.length > 0) {
        const gameType = appLabels[0];
        if (gameType) {
          TAConstants.Settings.LANGUAGES.forEach((l) => {
            const resource = this.resourcesSrv.getResource(
              gameType,
              Resource_Types.APP_LABELS,
              l
            );
            try {
              this.translate.setTranslation(
                l,
                resource ? JSON.parse(resource) : {}
              );
            } catch (err) {
              logger.log(err);
            }
          });
        }
      }

      this.translate.setDefaultLang("it");
      this.translate.use("it");
    };

    const menuFromCategory = (category: CategoryDTO): MenuItem => {
      let menu: MenuItem = {};
      if (category) {
        menu = category;
        menu.name = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.NAME
        );
        menu.bannerImage = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.IMAGE
        );
        menu.bannerTitle = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.TITLE
        );
        menu.bannerSubtitle = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.TEXT
        );
        menu.bannerPath = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.REDIRECT_URL
        );
        menu.path = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.URL_PATH
        );
        try {
          const pathParams = this.resourcesSrv.getResourcesBasic(
            category,
            Resource_Types.SOURCE_URL
          );
          if (pathParams && pathParams != "")
            menu.pathParams = JSON.parse(pathParams);
        } catch (ex) {}
        menu.description = this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.DESCRIPTION
        );
        menu.isNew =
          this.resourcesSrv.getResourcesBasic(
            category,
            Resource_Types.CAMPAIGN_TEXT
          ) == "true";
        menu.teaser =
          this.resourcesSrv.getResourcesBasic(
            category,
            Resource_Types.TEASER
          ) == "true";

        const levelEnabled = this.utilsSrv.valueFromKeyValueDTOArray(
          METADATA_KEY.CATEGORY_MIN_LEVEL,
          category.metadata
        );
        if (levelEnabled && levelEnabled != "") {
          const levels = levelEnabled.split(",");
          if (levels.length > 0) {
            menu.levelEnabled = levels;
          }
        }

        const nonLevelEnabled = this.utilsSrv.valueFromKeyValueDTOArray(
          METADATA_KEY.CATEGORY_MAX_LEVEL,
          category.metadata
        );
        if (nonLevelEnabled && nonLevelEnabled != "") {
          const levels = nonLevelEnabled.split(",");
          if (levels.length > 0) {
            menu.nonLevelEnabled = levels;
          }
        }

        // menu.name = 'NAme';
        // menu.bannerImage = 'https://staging.mystartapp.org/pub/resources/24acba24df620b8c1c1143d62eab1a5a/https---www.mystartapp.org-pub-resources-slider-agosto-desk-3.jpg';
        // menu.bannerTitle = 'Title'
        // menu.bannerSubtitle = 'SubTitle'
        // menu.path = 'profile';
        // menu.description = 'Description'

        if (category.children && category.children.length > 0) {
          menu.menuItems = [];
          category.children.forEach((c) => {
            const item = menuFromCategory(c);
            if (item.levelEnabled) {
              if (
                item.levelEnabled.indexOf(
                  String(this.userService.user.badgeLevel)
                ) >= 0
              ) {
                // if (this.userService.user.badgeLevel == 2) {
                //   if (this.userService.user.isCasting)
                //     menu.menuItems.push(item);
                // } else menu.menuItems.push(item);

                menu.menuItems.push(item);
              }
              // } else if (item.nonLevelEnabled) {
              //   if (
              //     item.nonLevelEnabled.indexOf(
              //       String(this.userService.user.badgeLevel)
              //     ) >= 0
              //   ) {
              //     if (this.userService.user.badgeLevel == 2) {
              //       if (!this.userService.user.isCasting)
              //         menu.menuItems.push(item);
              //     } else menu.menuItems.push(item);
              //   }
            } else {
              menu.menuItems.push(item);
            }
          });
        }
      }
      return menu;
    };
    const setupMainMenu = (category: CategoryDTO): MenuItem => {
      let menu: MenuItem;
      if (category) {
        menu = menuFromCategory(category);
      }
      return menu;
    };
    return new Promise((resolve, reject) => {
      //TODO Authenticate and then...
      this.cartSrv
        .getStates()
        .then((res) => {})
        .catch((err) => {});
      // this.userService.user.hasBought = localStorage.getItem('hasBought') == 'true';
      const gameTypeResourcesPrm = this.resourcesSrv.getResources(
        [{ itemIdForResourceLookup: TAConstants.Settings.GAME_TYPE_ID }],
        "itemIdForResourceLookup",
        [Resource_Types.APP_LABELS],
        ITEM_TYPES.GAME_TYPE
      );
      const appSettingsPrm = this.appSettingsSrv.get(
        TAValues.APPLICATION_ID,
        APPLICATION_SETTING_SCOPE_TYPES.APPLICATION_SETTING_SCOPE_TYPE_GAMETYPE
      );
      // const userProfilePrm = this.userService.getUserProfile(TAValues.UserId);
      const userProfilePrm = this.userService.getUserProfile;
      // const userProfileRetryPrm = this.utilsSrv.promiseRetry(
      //   userProfilePrm,
      //   this.userService,
      //   TAValues.UserId,
      //   3
      // );
      // const levelPrm = this.userService.getLevel();
      const userLevelPrm = this.userService.getUserLevel(TAValues.UserId);
      const userUnreadMessagesPrm = this.userService.getUnreadMessages(
        TAValues.UserId
      );
      const referencePrm = this.userService.getLevelRef();
      const pPQ: PendingPointsQ = {
        rangeFrom: 0,
        rangeTo: 1,
        excludeZeroAmounts: true,
      };
      // const pendingPointsPrm = this.userService.getPendingPoints(TAValues.UserId, pPQ);
      const eventRewardsPrm = this.materialSrv.getEventRewards();
      Promise.all([
        gameTypeResourcesPrm,
        appSettingsPrm,
        userLevelPrm,
        userUnreadMessagesPrm,
        referencePrm,
        eventRewardsPrm,
        this.utilsSrv.promiseRetry(
          userProfilePrm,
          this.userService,
          TAValues.UserId,
          3
        ),
      ])
        .then(async (arrayOfResults) => {
          if (arrayOfResults && arrayOfResults[0])
            setupAppLabels(arrayOfResults[0]);
          const mainMenuCategoryId = this.globals.config.mainMenuCategoryId;
          const profileMenuCategoryId =
            this.globals.config.profileMenuCategoryId;

          Promise.all([
            this.userService.getUserBalances(TAValues.UserId, [
              Unit_Types.IQOS_CLUB_IT_POINTS,
              Unit_Types.IQOS_CLUB_IT_LEVEL,
              Unit_Types.IQON_MONEY,
              Unit_Types.IQOS_CLUB_IT_EXPIRING_POINTS,
              Unit_Types.IQOS_CLUB_IT_BADGE,
            ]),
            this.rewardsSrv.checkIfHasBought(),
            this.categorySrv.getCategoriesByRootId(mainMenuCategoryId, true),
            this.categorySrv.getCategoriesByRootId(profileMenuCategoryId, true),
          ])
            .then((results2) => {
              this.userService.user.hasBought = results2[1] || false;
              this.globals.mainMenu = setupMainMenu(results2[2] as CategoryDTO);
              this.globals.profileMenu = setupMainMenu(
                results2[3] as CategoryDTO
              );
            })
            .catch((err) => {})
            .finally(() => {
              this.initLoaded = true;
              resolve(arrayOfResults);
            });
          // resolve(arrayOfResults);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
