import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {IReferenceLevel} from "../../app/pages/profile/profile.component";
import {IReferenceMetadataDTO} from "../models/IReferenceMetadataDTO";
import {StateDTO} from "../models";
import {HttpHeaders} from "@angular/common/http";
import {CityDTO} from "../models/CityDTO";

@Injectable()
export class ReferenceApi {

    readonly reference: string = '/reference';

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * List a state's cities
     * @param {GetStateCitiesQuery} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<CityDTO>>}
     */
    public getStateCities(queryParams?: GetStateCitiesQuery, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<CityDTO>> {
        return this.http2Talos.get({
            path: `${this.reference}/cities`,
            queryParams: queryParams,
            requireSession: requireSession,
            headers: headers
        });
    }

    /**
     * List states
     * @param {GetCountryStatesQuery} queryParams
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<StateDTO>>}
     */
    public getCountryStates(queryParams?: GetCountryStatesQuery, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<StateDTO>> {
        return this.http2Talos.get({
            path: `/reference/states`,
            queryParams: queryParams,
            requireSession: requireSession,
            headers: headers
        });
    }

    public async getReferenceLevels(gameTypeId: string, input: IReferenceLevelInput): Promise<Array<IReferenceLevel>> {
        return await this.http2Talos.get({
            path: `${this.reference}/gameType/${gameTypeId}/levels`,
            queryParams: input
        });
    }


    public getReferenceMetadata(metadataKeyId: string, input: IReferenceMetadataQP): Promise<IReferenceMetadataDTO[]> {
        return this.http2Talos.get({
            path: `${this.reference}/${metadataKeyId}`,
            queryParams: input
        });

    }

    public async getCountryCities(input: ICitiesReference): Promise<Array<StateDTO>> {
        return await this.http2Talos.get({
            path: `/reference/states`,
            queryParams: input
        });
    }


} // END CLASS

export interface GetCountryStatesQuery {
    includeDetails?: boolean;
    countryIds?: string[];
    stateIds?: number[];
    resources?: Array<string> | boolean;
    metadatas?: Array<string> | boolean;
    languageIds?: Array<string>;
    statistics?: Array<string> | boolean;
    rangeFrom?: number;
    rangeTo?: number;
}

export interface GetStateCitiesQuery {
    cityIds?: Array<number>;
    stateIds?: Array<number>;
    includeDetails?: boolean;
    resources?: Array<string> | boolean;
    metadatas?: Array<string> | boolean;
    languageIds?: Array<string>;
    statistics?: Array<string> | boolean;
    rangeFrom?: number;
    rangeTo?: number;
}

export interface IReferenceLevelInput {
    resources?: boolean | string[];
    languageIds?: string[];
    metadatas?: string[];
    statistics?: string[];
}

export interface IReferenceMetadataQP {
    rangeFrom: number;
    rangeTo: number;
}


export interface ICitiesReference {
    resources: boolean,
    languageIds?: string[];
    metadatas: boolean,
    rangeFrom: number;
    rangeTo: number;
    includeDetails: boolean;
    countryIds?: string[];
    stateIds?: number[];
}
