import {Http2Talos} from "../http2Talos";
import {Injectable} from "@angular/core";
import {
    GetMultipleMetadataResponse,
    GetVItemsInput,
    ItemsList,
    ItemStatistics,
    ItemStatisticsDTO,
    ResourceDTO, VirtualItemsDTOS
} from "../models";
import {HttpHeaders} from "@angular/common/http";
import {ResourceRequestDTO} from "../models/ResourceRequestDTO";
import {ItemMetadataDTO} from "../models/ItemMetadataDTO";
import {ItemActionStatusDTO} from "../models/ItemActionStatusDTO";
import {GetUserActionStatusInput} from "../models/GetUserActionStatusInput";


@Injectable()
export class ItemApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     *
     * @param input
     * @param extraHttpRequestParams
     */
    public searchEtag(input?: Array<ResourceRequestDTO>, extraHttpRequestParams?: HttpHeaders, includeBinaryResource: boolean = false): Promise<ItemsList<ResourceDTO>> {
        return this.http2Talos.post({
            path: `/item/resources/searchEtag`,
            input: input,
            headers: extraHttpRequestParams,
            queryParams: {
                includeBinaryResources: includeBinaryResource
            }
        })
    }

    /**
     *
     * @param userId
     * @param input
     * @param extraHttpRequestParams
     */
    public itemStatistics(userId: string, input?: { statistics: Array<ItemStatistics> }, extraHttpRequestParams?: HttpHeaders): Promise<ItemsList<ItemStatisticsDTO>> {
        return this.http2Talos.post({
            path: `/item/${userId}/itemStatistics`,
            input: input,
            headers: extraHttpRequestParams
        })
    }

    /**
     *
     * @param itemType
     * @param input
     */
    public async asyncGetMultipleMetadata(itemType: number, input?: Array<string>, queryParams: IItemMetadata = {announceable: true}): Promise<GetMultipleMetadataResponse[]> {
        return await this.http2Talos.post({path: `/item/metadata/search/${itemType}`, input: input, queryParams: queryParams})
    }

    /**
     *
     * @param itemType
     * @param input
     */
    public getMultipleMetadata(itemType: number, input?: Array<string>, queryParams: IItemMetadata = {announceable: true}): Promise<GetMultipleMetadataResponse[]> {
        return this.http2Talos.post({path: `/item/metadata/search/${itemType}`, input: input, queryParams: queryParams})
    }

    /**
     *
     * @param rootCategoryId
     * @param subCategoryId
     * @param input
     * @param extraHttpRequestParams
     */
    public getVItems(rootCategoryId: string, subCategoryId: string, input: GetVItemsInput, extraHttpRequestParams?: HttpHeaders): Promise<ItemsList<VirtualItemsDTOS>> {
        return this.http2Talos.get({
            path: `/item/${rootCategoryId}/${subCategoryId}`,
            queryParams: input,
            headers: extraHttpRequestParams
        })
    }

    /**
     * @param itemTypeId
     * @param itemId
     * @param statisticType
     * @param ratingValue
     * @param input
     * @param requireSession
     */
    performActionOnVideo(itemTypeId, itemId, statisticType, ratingValue, input: IPerformAction, requireSession: boolean = true): Promise<any> {
        return this.http2Talos.post({
            path: `/item/${itemTypeId}/${itemId}/${statisticType}/${ratingValue}`,
            input: input,
            requireSession: requireSession
        });
    }

    /**
     *
     * @param {string} itemId
     * @param {number} itemTypeId
     * @return {Promise<any>}
     */
    public likeItem(itemId: string, itemTypeId: number): Promise<any> {
        return this.http2Talos.post({path: `/item/${itemTypeId}/${itemId}/like/1`});
    }

    /**
     *
     * @param {string} itemId
     * @param {number} itemTypeId
     * @return {Promise<any>}
     */
    public undoLikeItem(itemId: string, itemTypeId: number): Promise<any> {
        return this.http2Talos.post({path: `/item/${itemTypeId}/${itemId}/undolike`});
    }

    /**
     *
     * @param {string} itemId
     * @param {number} itemTypeId
     * @param {number} statisticType
     * @param {number} ratingValue
     * @param input
     * @return {Promise<any>}
     */
    public submitStatistics(itemId: string, itemTypeId: number, statisticType: number, ratingValue: number, input?): Promise<any> {
        return this.http2Talos.post({
            path: `/item/${itemTypeId}/${itemId}/${statisticType}/${ratingValue}`,
            input: input
        });
    }


    /**
     *
     * @param itemId
     * @param itemTypeId
     * @param queryParams
     */
    public async getItemMetadata(itemId: string, itemTypeId: number, queryParams: IItemMetadata = {announceable: true}): Promise<ItemMetadataDTO[]> {
        return await this.http2Talos.get({
            path: `/item/${itemTypeId}/${itemId}/metadata`,
            queryParams: queryParams
        })
    }

    /**
     * Get User Actions
     * @param {string} userId
     * @param {number} eventTypeId
     * @param {IUserActionsQuery} queryParams
     * @param {GetUserActionStatusInput} input
     * @return {Promise<Array<ItemActionStatusDTO>>}
     */
    public async getUserActions(userId: string, eventTypeId: number, queryParams?: IUserActionsQuery, input?: GetUserActionStatusInput): Promise<Array<ItemActionStatusDTO>> {
        return await this.http2Talos.post({
            path: `/item/${userId}/actionStatus/${eventTypeId}`,
            queryParams: queryParams,
            input: input
        })
    }

    public postRating(itemTypeId: number, itemId: string, ratingValue: number): Promise<any> {
        return this.http2Talos.post({
            path: `/item/${itemTypeId}/${itemId}/rating/${ratingValue}`,
            // input: {}
        })
    }

    public postComment(itemTypeId: number, itemId: string, userId: string, input: SubmitCommentInput): Promise<any> {
        return this.http2Talos.post({
            path: `/item/${itemTypeId}/${itemId}/comments/${userId}`,
            input: input
        })
    }

}// END CLASS

export interface IPerformAction {
    dummy: number;
}

export interface IItemMetadata {
    announceable: boolean;
}

export interface IUserActionsQuery {
    resources?: boolean,
    metadatas?: boolean,
    languageIds?: Array<string>;
    statistics?: Array<string>;
}

export interface UserRateDTO {
    itemId?: string
    itemTypeId?: number
    ratingValue?: number
    refItemId?: string
}

export interface SubmitCommentInput {
    comment?: string;
    externalSystemIds?: Array<number>;
    checkIn?: boolean;
    postId?: string;
    refItemId?: string;
    onlyExternalPost?: boolean;
}
