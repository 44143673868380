/*
 https://angular.io/docs/ts/latest/cookbook/ngmodule-faq.html#!#q-for-root
 CoreModule
 Create a CoreModule with providers for the singleton services you load when the application starts.
 Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
 Consider making CoreModule a pure services module with no declarations.
 */
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {Http2Service} from "./http2Service";
import {ShortifyApi} from "./api/ShortifyApi";

@NgModule({
    imports: [HttpClientModule],
    providers: [
        Http2Service,
        ShortifyApi
    ]
})
export class SharedApiModule {

    constructor(@Optional() @SkipSelf() parentModule: SharedApiModule) {
        if (parentModule) {
            throw new Error(
                'Http2Service Module is already loaded. Import it in the AppModule only');
        }
    }

}
