import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TAConstants} from "../../../../talosApi/settings";
import {QuizService} from "../../../pages/quizzes/quiz/quiz.service";
import {QuizDTO} from "../../../../talosApi/models/QuizDTO";
import {QuestionDTO} from "../../../../talosApi/models/QuestionDTO";
import {AnswerDTO} from "../../../../talosApi/models/AnswerDTO";
import {ResourcesServices} from "../../../../services/talos/resources.services";
import {AnswerQuestionInput} from "../../../../talosApi/models/AnswerQuestionInput";

@Component({
    selector: 'app-quiz-view',
    templateUrl: './quiz-view.component.html',
    styleUrls: ['./quiz-view.component.scss']
})
export class QuizViewComponent { // implements OnInit, OnChanges {

    @Input()
    quiz: QuizDTO;

    @Input()
    eventRewards: Map<number, string>;

    @Input()
    synchronizeAnswers: boolean;

    @Output()
    synchronizeAnswersFired = new EventEmitter();

    @Output()
    quizAnswersFired = new EventEmitter();

    @Input()
    quizSubmitted: boolean;

    @Input()
    answeredQuestions: Array<AnswerQuestionInput>;

    private _answeredQuestions: Array<AnswerQuestionInput>;

    loading: boolean = true;
    totalQuestions: number = 0;
    currentChoice: AnswerDTO;
    selectedQuestion: QuestionDTO;
    questionIndex: number = 0;

    buttonText;
    translate;
    quizTime = 2000;
    checkingAnswer = false;
    isAnswerCorrect: boolean;

    constructor(public quizSrv: QuizService, public translateSrv: TranslateService, public resourcesSrv: ResourcesServices) {


    }



    // ngOnInit() {
    //     this._answeredQuestions = this.answeredQuestions;
    //     const sub = this.translateSrv.get(['QUIZ_COMPLETED_SUCCESS_MESSAGE', 'QUIZ_COMPLETED_FAIL_MESSAGE', 'SURVEY_COMPLETED_MESSAGE',
    //         'SURVEY_COMPLETED_MESSAGE_TITLE', 'SURVEY_COMPLETED_MESSAGE_DESCRIPTION', 'SURVEY_COMPLETED_TITLE', 'QUIZ_COMPLETED_SUCCESS_TITLE',
    //         'QUIZ_COMPLETED_FAIL_TITLE',
    //         'DUMMY_' + this.quiz.id + '_1_IMAGE', 'DUMMY_' + this.quiz.id + '_1_TITLE',
    //         'DUMMY_' + this.quiz.id + '_2_IMAGE', 'DUMMY_' + this.quiz.id + '_2_TITLE',
    //         'DUMMY_' + this.quiz.id + '_3_IMAGE', 'DUMMY_' + this.quiz.id + '_3_TITLE',
    //         'DUMMY_' + this.quiz.id + '_4_IMAGE', 'DUMMY_' + this.quiz.id + '_4_TITLE']).subscribe((result) => {
    //         this.translate = result;
    //         sub && sub.unsubscribe();
    //     }, error => {
    //         sub && sub.unsubscribe();
    //     },);
    //
    //     // if (this.quiz) {
    //     //
    //     //     const rewardsPrm = this.quizSrv.getEventRewardsRules();
    //     //     Promise.all([this.quizSrv.getQuizById(this.quiz.id), rewardsPrm]).then(arrayOfResults => {
    //     //         logger.log(arrayOfResults);
    //     //         if (!arrayOfResults)
    //     //             return;
    //     //
    //     //         if (arrayOfResults[0]) {
    //     //             this.quiz = arrayOfResults[0];
    //     //             this.buttonText = this.quiz.quizType == QUIZ_TYPES.QUIZ ? 'START_QUIZ' : (this.quiz.quizType == QUIZ_TYPES.SURVEY ? 'START_SURVEY' : '');
    //     //
    //     //             this.totalQuestions = (this.quiz && this.quiz.questions) ? this.quiz.questions.length : 0;
    //     //             if (this.quiz.questions && this.quiz.questions[0])
    //     //                 this.selectedQuestion = this.quiz.questions[0];
    //     //         }
    //     //
    //     //         this.loading = false;
    //     //
    //     //         this.eventRewards = arrayOfResults[1];
    //     //
    //     //         this.loading = false;
    //     //     }, (err) => {
    //     //         logger.log(err);
    //     //         this.loading = false;
    //     //     });
    //     // }
    //
    //     this.buttonText = this.quiz.quizType == QUIZ_TYPES.QUIZ ? 'START_QUIZ' : (this.quiz.quizType == QUIZ_TYPES.SURVEY ? 'START_SURVEY' : '');
    //     this.totalQuestions = (this.quiz && this.quiz.questions) ? this.quiz.questions.length : 0;
    //
    //     if (this.quiz.questions && this.quiz.questions.length > 0) {
    //         this.quiz.questions[0].current = true;
    //         this.selectedQuestion = this.quiz.questions[0];
    //         this.showDivs(0);
    //     }
    //
    //     if (this._answeredQuestions && this._answeredQuestions.length > 0) {
    //         this.showDivs(this._answeredQuestions.length);
    //     }
    // }
    //
    // ngOnChanges(changes: SimpleChanges) {
    //     const answeredQuestions: SimpleChange = changes.answeredQuestions;
    //     // console.log('prev value: ', answeredQuestions.previousValue);
    //     // console.log('got name: ', answeredQuestions.currentValue);
    //     if (answeredQuestions)
    //         this._answeredQuestions = answeredQuestions.currentValue;
    // }
    //
    // answerChanged(answer, position: number) {
    //     if (this.checkingAnswer)
    //         return;
    //
    //     logger.log(answer);
    //     logger.log(position);
    //     if (this.selectedQuestion) {
    //         this.selectedQuestion.answers.forEach((a: AnswerDTO, index: number) => {
    //             // if (position != index) {
    //             a.selected = false;
    //             // } else {
    //             //     answer.selected = true;
    //             //     this.currentChoice = answer;
    //             // }
    //         });
    //         answer.selected = true;
    //         this.currentChoice = answer;
    //         this.selectedQuestion.answered = true;
    //         this.selectedQuestion.selectedAnswer = answer;
    //     }
    //
    //     if (this.questionIndex < this._answeredQuestions.length) {
    //         this._answeredQuestions[this.questionIndex] = answer;
    //     }
    // }
    //
    // previousButtonClicked() {
    //     this.showDivs(this.questionIndex - 1);
    // }
    //
    // setupCurrentQuestions(index: number) {
    //     if (this.quiz.questions) {
    //         for (let i = 0; i < this.quiz.questions.length; i++) {
    //             this.quiz.questions[i].current = i == index;
    //         }
    //     }
    // }
    //
    // nextButtonClicked() {
    //     if (this.questionIndex + 1 == this.totalQuestions) {
    //         // this.answerQuiz();
    //         if (this.quiz.quizType == QUIZ_TYPES.SURVEY)
    //             this.answerQuiz();
    //         else {
    //             this.answerQuizAnswer();
    //         }
    //
    //     } else {
    //         // this.showDivs(this.questionIndex + 1);
    //         if (this.quiz.quizType == QUIZ_TYPES.SURVEY) {
    //
    //             if (this.synchronizeAnswers) {
    //                 this.synchronizeSurveyAnswers();
    //             }
    //             this.showDivs(this.questionIndex + 1);
    //         }
    //         else {
    //             this.showRightAnswer(this.questionIndex + 1);
    //         }
    //     }
    //
    // }
    //
    // synchronizeSurveyAnswers() {
    //     if (this.synchronizeAnswersFired) {
    //         const quiz = JSON.parse(JSON.stringify(this.quiz));
    //         this.synchronizeAnswersFired.emit(quiz)
    //     }
    // }
    //
    // showRightAnswer(n: number) {
    //     this.checkingAnswer = true;
    //     this.setupUpdatedValues();
    //
    //     this.isAnswerCorrect = Number(this.currentChoice.value) > 0;
    //
    //     setTimeout(function () {
    //         this.isAnswerCorrect = undefined;
    //         this.showDivs(n);
    //         this.checkingAnswer = false;
    //     }.bind(this), this.quizTime);
    //
    // }
    //
    // answerQuizAnswer() {
    //     this.checkingAnswer = true;
    //     this.setupUpdatedValues();
    //
    //     this.isAnswerCorrect = Number(this.currentChoice.value) > 0;
    //
    //     setTimeout(function () {
    //         this.answerQuiz();
    //         this.checkingAnswer = false;
    //     }.bind(this), this.quizTime);
    // }
    //
    // answerQuiz() {
    //     this.quizSubmitted = true;
    //     const setupSurveyResult = (successIndex: number) => {
    //         // this.popupAnswerImage = null;
    //         // this.popupAnswerTitle = "";
    //         // this.popupAnswerMessage = "";
    //         // this.popupAnswerDesc = "";
    //         // this.popupTitle = this.translate['SURVEY_COMPLETED_TITLE'];
    //         // this.popupMessage = this.translate['SURVEY_COMPLETED_MESSAGE'].replace('$points', this.eventRewards[Events_Types.POLL_SURVEY_COMPLETED]);
    //         // this.popupButtonLabel = 'SURVEY_COMPLETED_BUTTON';
    //         // // this.popupAnswerTitle = this.translate['SURVEY_COMPLETED_MESSAGE_TITLE'];
    //         // const imagesResource = this.resourcesSrv.getResource(this.quiz, Resource_Types.WEB_IMAGE_ARRAY);
    //         // if (imagesResource && imagesResource != "") {
    //         //     const images = imagesResource.split(',');
    //         //     this.popupAnswerImage = this.resourcesSrv.getResourceUrl(this.quiz.id, ITEM_TYPES.QUIZ, Number(images[successIndex]));
    //         // }
    //         // const configResource = this.resourcesSrv.getResource(this.quiz, Resource_Types.USER_QUIZ_RESULTS_TEXTS);
    //         // if (configResource) {
    //         //     const config = JSON.parse(configResource)[String(successIndex)];
    //         //     if (config) {
    //         //         this.popupAnswerTitle = config['title'] || "";
    //         //         this.popupAnswerMessage = config['message'] || "";
    //         //         this.popupAnswerDesc = config['description'] || "";
    //         //         // this.popupAnswerMessage = this.translate['DUMMY_' + this.quizId + '_' + successIndex + '_TITLE'];
    //         //         // this.popupAnswerImage = this.translate['DUMMY_' + this.quizId + '_' + successIndex + '_IMAGE'];
    //         //         // this.popupAnswerDesc = this.translate['SURVEY_COMPLETED_MESSAGE_DESCRIPTION'];
    //         //     }
    //         // }
    //         // this.popup.nativeElement.open();
    //     };
    //
    //     const setupQuizResult = (success: boolean, score: number) => {
    //         // // this.popupTitle = this.translate[(success ? 'QUIZ_COMPLETED_SUCCESS_TITLE' : 'QUIZ_COMPLETED_FAIL_TITLE')];
    //         // const configResource = this.resourcesSrv.getResource(this.quiz, Resource_Types.USER_QUIZ_RESULTS_TEXTS);
    //         // let titleText = "";
    //         // let messageText = "";
    //         // let quizResult;
    //         // if (configResource) {
    //         //     try {
    //         //         const config = JSON.parse(configResource);
    //         //         if (config) {
    //         //             const keys = Object.keys(config);
    //         //             keys.forEach((k) => {
    //         //                 try {
    //         //                     const value = Number(k);
    //         //                     if (score <= (value * 100) && quizResult === undefined) {
    //         //                         quizResult = config[k]
    //         //                         titleText = config[k].title;
    //         //                         messageText = config[k].message;
    //         //                     }
    //         //
    //         //                 } catch (ex) {
    //         //
    //         //                 }
    //         //             })
    //         //         }
    //         //     } catch (e){
    //         //
    //         //     }
    //         // }
    //         //
    //         // // this.popupMessage = (success ? this.translate['QUIZ_COMPLETED_SUCCESS_MESSAGE'] : this.translate['QUIZ_COMPLETED_FAIL_MESSAGE']).replace('$points', this.eventRewards[Events_Types.QUIZ_COMPLETION]);
    //         // this.popupTitle = titleText;
    //         // this.popupMessage = messageText.replace('$points', this.eventRewards[Events_Types.QUIZ_COMPLETION]);
    //         // this.popupButtonLabel = (success ? 'QUIZ_COMPLETED_SUCCESS_BUTTON' : 'QUIZ_COMPLETED_FAIL_BUTTON');
    //         // this.popup.nativeElement.open();
    //     };
    //
    //     const checkQuizResult = (result: Array<UserAnswerDTO>) => {
    //         const lastAnswer: UserAnswerDTO = result[result.length - 1];
    //
    //         if (this.quiz.quizType == QUIZ_TYPES.SURVEY) {
    //
    //             const indexOfMax = (arr: Array<number>) => {
    //                 if (arr.length === 0) {
    //                     return -1;
    //                 }
    //
    //                 var max = arr[0];
    //                 var maxIndex = 0;
    //
    //                 for (var i = 1; i < arr.length; i++) {
    //                     if (arr[i] > max) {
    //                         maxIndex = i;
    //                         max = arr[i];
    //                     }
    //                 }
    //
    //                 return maxIndex;
    //             }
    //
    //
    //             const counters = this.quiz.questions[0].answers.map(a => {
    //                 return 0;
    //             });
    //             this.quiz.questions.forEach((question: QuestionDTO) => {
    //                 if (question.answered) {
    //                     for (let i = 0; i < question.answers.length; i++) {
    //                         const a = question.answers[i];
    //                         if (a.selected) {
    //                             counters[i]++;
    //                         }
    //                     }
    //                 }
    //             });
    //
    //             const index = indexOfMax(counters);
    //
    //             setupSurveyResult(index);
    //         } else {
    //             const success = lastAnswer.quizStatus === QUIZ_STATUS.WIN;
    //             const score = Number(lastAnswer.score);
    //             setupQuizResult(success, score);
    //         }
    //     }
    //
    //     if (this.quiz && this.quizAnswersFired) {
    //         this.quizAnswersFired.emit(this.quiz);
    //         this.quizAnswersFired.subscribe((res) => {
    //
    //         }, (err) => {
    //
    //         })
    //         // this.quizSrv.answerQuiz(this.quiz).then((result: Array<UserAnswerDTO>) => {
    //         //     logger.log(result);
    //         //     this.userSrv.updateUserStatus().then(result => logger.log(result)).catch(err => logger.log(err));
    //         //     checkQuizResult(result);
    //         //     this.quizSubmitted = false;
    //         //     this.quizDetailService.quizAnswered = true;
    //         // }).then(err => {
    //         //     logger.log(err);
    //         //     this.quizSubmitted = false;
    //         // })
    //     }
    // }
    //
    // showDivs(n: number) {
    //
    //     // var x = document.getElementsByClassName("mySlides");
    //     this.questionIndex = n;
    //     if (n + 1 > this.totalQuestions) {
    //         this.questionIndex = this.totalQuestions - 1;
    //     }
    //     if (n < 0) {
    //         this.questionIndex = 0;
    //     }
    //     if (this.quiz.questions && this.quiz.questions[this.questionIndex])
    //         this.selectedQuestion = this.quiz.questions[this.questionIndex];
    //
    //     this.setupCurrentQuestions(n);
    //     this.setupUpdatedValues();
    // }
    //
    // setupUpdatedValues() {
    //
    //     if (this.selectedQuestion) {
    //         this.selectedQuestion.answers.forEach((a: AnswerDTO) => {
    //             if (this.questionIndex < this._answeredQuestions.length) {
    //                 this.selectedQuestion.answered = true;
    //                 if (a.id == this._answeredQuestions[this.questionIndex].answerId) {
    //                     a.selected = true;
    //                     this.currentChoice = a;
    //                     this.selectedQuestion.selectedAnswer = a;
    //
    //                 }
    //             }
    //             if (a.selected == true)
    //                 this.currentChoice = a;
    //         });
    //     }
    // }
    //
    // getItemName(item, resourceType: string): string {
    //     return this.resourcesSrv.getResourcesBasic(item, Resource_Types[resourceType]);
    // }

}
