import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EVENT_DEFINITIONS } from "../../../../../src/utils/utils";

@Component({
	selector: "go-back-header",
	templateUrl: "./go-back-header.component.html",
	styleUrls: ["./go-back-header.component.scss"],
})
export class GoBackComponent implements OnInit {
	/**
	 * Label  of go back component
	 */
	@Input() public label: string = '';

	/**
	 * Route  of go back component
	 */
	@Output() clicked = new EventEmitter<void>();
	
	constructor(public translate: TranslateService) {
		
	}
	ngOnInit(): void {
		
	}

	public gotTo() {
		this.clicked.emit();
	}
	
}
