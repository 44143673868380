import {Injectable} from "@angular/core";
import {ItemApi, IUserActionsQuery, SubmitCommentInput, UserRateDTO} from "../../talosApi/api/ItemApi";
import {MetadataDTO} from "../../talosApi/models";
import {TAConstants, TAValues} from "../../talosApi/settings";
import Settings = TAConstants.Settings;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import {ItemMetadataDTO} from "../../talosApi/models/ItemMetadataDTO";
import {GetMultipleMetadataResponse} from "../../talosApi/models/GetMultipleMetadataResponse";
import {GetUserActionStatusInput} from "../../talosApi/models/GetUserActionStatusInput";
import {ItemActionStatusDTO} from "../../talosApi/models/ItemActionStatusDTO";

@Injectable()
export class ItemService {

    constructor(private api: ItemApi) {

    }

    /**
     *
     * @param {string} itemId
     * @param {number} itemTypeId
     * @return {Promise<any>}
     */
    likeItem(itemId: string, itemTypeId: number): Promise<any> {
        return new Promise((resolve, reject) => {

            this.api.likeItem(itemId, itemTypeId).then((result) => {

                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     *
     * @param {string} itemId
     * @param {number} itemTypeId
     * @return {Promise<any>}
     */
    undoLikeItem(itemId: string, itemTypeId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.undoLikeItem(itemId, itemTypeId).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     *
     * @param {string} itemId
     * @param {number} itemTypeId
     * @param {number} statisticType
     * @param {number} ratingValue
     * @return {Promise<any>}
     */
    submitStatistics(itemId: string, itemTypeId: number, statisticType: number, ratingValue: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.submitStatistics(itemId, itemTypeId, statisticType, ratingValue).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     * Get Multiple metadata
     * @param {number} itemTypeId
     * @param {Array<string>} input
     * @return {Promise<GetMultipleMetadataResponse[]>}
     */
    getMultipleMetadata(itemTypeId: number, input?: Array<string>): Promise<GetMultipleMetadataResponse[]> {
        return new Promise((resolve, reject) => {
            this.api.getMultipleMetadata(itemTypeId, input).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    postRating(itemTypeId: number, itemId:string, ratingValue: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.postRating(itemTypeId, itemId, ratingValue).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    postComment(itemTypeId: number, itemId: string, userId: string, input: SubmitCommentInput): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.postComment(itemTypeId, itemId, userId, input).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     * Get Item Metadata
     * @return {Promise<ItemMetadataDTO[]>}
     */
    public async getItemMetadata(): Promise<ItemMetadataDTO[]> {
        return new Promise<any>(async (resolve, reject) => {

            await this.api.getItemMetadata(Settings.GAME_TYPE_ID, ITEM_TYPES.GAME_TYPE)
                .then(async (result: ItemMetadataDTO[]) => {
                    resolve(result);
                    logger.log(result);
                })
                .catch((error) => {
                    logger.log(error);
                    reject(error);
                })
        });
    }

    /**
     * Get User Actions
     * @param {number} eventTypeId
     * @param {IUserActionsQuery} queryParams
     * @param {GetUserActionStatusInput} input
     * @return {Promise<Array<ItemActionStatusDTO>>}
     */
    getUserActions(eventTypeId: number, queryParams?: IUserActionsQuery, input?: GetUserActionStatusInput): Promise<Array<ItemActionStatusDTO>> {
        return new Promise((resolve, reject) => {
            this.api.getUserActions(TAValues.UserId, eventTypeId, queryParams, input).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }
}