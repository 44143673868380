import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {Ng5SliderModule} from "ng5-slider";
import {IqosFooterComponent} from "./iqos-footer.component";

@NgModule({
    declarations: [IqosFooterComponent],
    imports: [
        CommonModule,
        SharedModule,
        Ng5SliderModule,
        TranslateModule.forChild()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        IqosFooterComponent
    ]

})
export class IqosFooterModule {

}