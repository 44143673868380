import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {ItemsList} from "../models";
import {HttpHeaders} from "@angular/common/http";
import {TAConstants} from "../settings";
import Favorites_Status = TAConstants.Favorites_Status;
import {FavoriteDTO} from "../models/FavoriteDTO";

@Injectable()
export class FavoriteApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     *
     * @param {string} userId
     * @param {string} itemId
     * @param {number} itemTypeId
     * @param {boolean} requireSession
     * @param {HttpHeaders} headers
     * @return {Promise<Array<FavoriteDTO>>}
     */
    public addFavorite(userId: string, itemId: string, itemTypeId: number, requireSession: boolean = true, headers: HttpHeaders = null,): Promise<Array<FavoriteDTO>> {
        return this.http2Talos.post({
            headers: headers,
            requireSession: requireSession,
            path: `/favorites/${userId}/add/${itemTypeId}/${itemId}`
        })
    }


    /**
     *
     * @param userId
     * @param input
     * @param requireSession
     * @param headers
     */
    public getAllFavorite(userId: string, input: InputGetFavorite, requireSession: boolean = true, headers: HttpHeaders = null): Promise<Array<FavoriteDTO>> {
        return this.http2Talos.get({
            headers: headers,
            requireSession: requireSession,
            path: `/favorites/${userId}`,
            queryParams: input
        });
    }

    /**
     *
     * @param userId
     * @param favoriteId
     * @param requireSession
     */
    public removeFavorite(userId: string, favoriteId: string, requireSession: boolean = true): Promise<null> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/favorites/${userId}/remove/${favoriteId}`
        });
    }


}// END CLASS

export interface IAddFavoriteInput {
    userId: string;
    itemTypeId: number;
    itemId: string;
    uploadContentId?: string;
    uploadContentTypeId?: number;
}

export interface InputGetFavorite {
    itemId?: string | number;
    itemTypeIds: number[];
    rangeFrom: number;
    rangeTo: number;
    statusIds?: Favorites_Status[]
}
