import {Injectable} from "@angular/core";
import {ItemApi} from "../../talosApi/api/ItemApi";
import {ITalosApiModel, ResourceDTO} from "../../talosApi/models";
import _ from 'lodash';
import {TAConstants} from "../../talosApi/settings";
import Resource_Status = TAConstants.Resource_Status;
import {isArray} from "util";
import {TranslateService} from '@ngx-translate/core';
import Language = TAConstants.Language;
import {ResourceRequestDTO} from "src/talosApi/models/ResourceRequestDTO";
import {TokenApi} from '../../talosApi/api/TokenApi';

/**
 * Injectable
 */
@Injectable()
export class TokenService {

    /**
     * Creates an instance of token services.
     * @param api
     * @param translate
     */
    constructor(private api: TokenApi,
                public translate: TranslateService) {
    }


    /**
     * Generates share token
     * @param userId
     * @param itemId
     * @param itemTypeId
     * @param medium
     * @param brandStoreId
     * @returns share token
     */
    public generateShareToken(userId: string,
                              itemId: string,
                              itemTypeId: string,
                              medium: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.api.generateToten({
                itemId: itemId,
                itemTypeId: itemTypeId,
                userId: userId,
                medium: medium,
                auto: "true",
                actionType: "share"
            }).then((result) => {
                resolve(result);
            })
                .catch((error) => {
                    reject(error)
                });
        });
    }
}