import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { AppGlobalsService } from "../services/appGlobals.service";
import { TAConstants, TAValues } from "../talosApi/settings";
import { ActivatedRoute, Router } from "@angular/router";
import { InitService } from "../services/talos/init.service";
import { MetadataDTO, User } from "../talosApi/models";
import { UserService } from "../services/talos/user.service";
import { UtilsService } from "../services/utils.service";
import { checkElement, EVENT_DEFINITIONS, to } from "../../../src/utils/utils";
import { NotificationsService } from "./pages/notifications/notifications.service";
import { ReferenceService } from "../services/talos/reference.service";
import { isNull, isNullOrUndefined, isObject } from "util";
import { Message } from "../talosApi/models/Message";
import { SaveUserMetadataInput } from "../talosApi/models/SaveUserMetadataInput";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { filter, switchMap, takeUntil, tap } from "rxjs/internal/operators";
import { Subject, from, timer } from "rxjs/index";
import { Cart, CartItem, CartPresenter } from "./pages/cart/cart.presenter";
import { CartError } from "./pages/cart/cart.service";
import { SalesOrderErrorCodes } from "../talosApi/api/SalesOrderApi";
import { MenuItem } from "../models/MenuItem";
import { GoBackService } from "../services/shared/goBack.service";
import Value_Pools = TAConstants.Value_Pools;
import Resource_Types = TAConstants.Resource_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import moment from "moment";
import { ResourcesServices } from "src/services/talos/resources.services";
import { OverviewContent } from "./pages/overview/overview.component";
import { ReferralService } from "src/services/talos/referral.service";
import { AlertService } from "src/services/talos/alert.service";
import { ProfileService } from "./pages/profile/profile.service";
import { ProfileProgressComponent } from "./components/profile-progress/profile-progress.component";
import { BannerConfiguration } from "src/talosApi/models/IconBannerConfigDTO";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(-100%, 0, 0)",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
  ],
})
export class AppComponent implements OnInit {
  /**
   * View child of app component
   */
  @ViewChild("notificationModal") modal: ElementRef;

  /**
   * View child of app component
   */
  @ViewChild("cartItemPanel") cartItemPanel: ElementRef;

  /**
   * View child of app component
   */
  @ViewChild("toolTipModal") toolTipModal: ElementRef;

  /**
   * View child of app component
   */
  @ViewChild("navTabs") navTabs: ElementRef;

  /**
   * View child of app component
   */
  @ViewChild("promoPopUp") promoPopUp: ElementRef;

  /**
   * View child of app component
   */
  @ViewChild("countDownPromoPopUp") countDownPromoPopUp: ElementRef;

  /**
   * View child of overview component
   */
  @ViewChild("welcomeModal") welcomeModal: ElementRef;

  @ViewChild("unicodeModal") unicodeModal: ElementRef;
  referral: any;
  unicode: string;
  copyButton: any;

  /**
   * View child of app component
   */
  @ViewChild("logoModal") logoModal: ElementRef;

  /**
   * View child of app component
   */
  @ViewChild("errorPopUp") errorPopUp: ElementRef;

  @ViewChild("welcomePromoCloseBtn") welcomeCloseBtn: ElementRef;

  @ViewChild("profileProgressPopup") profileProgressPopup: ElementRef;

  profileProgressCounts: number = 0;
  profileProgressCountsTotal: number = 125;
  profileProgressHasOpened: boolean = false;
  public showProfileProgress: boolean = false;
  /**
   * Determines whether on board open is
   */
  public isOnBoardOpen = false;

  public arrowBtnIcon = {
    path: "assets/img/arrow_icon.png",
    altText: "",
    default: "assets/img/arrow_icon.png",
  };

  public notifications: Array<NotificationTemplate> = [];
  private currentOnBoardTab: number = -1;

  title = "IQOSLoyaltyWeb";
  innerWidth: number;
  isMobile = false;
  protected unreadMessage: boolean = false;

  protected onBoardTabs: Array<HTMLElement> = [];

  expanded = false;

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.checkMenuDimension(this.innerWidth);
  }

  menuState: string = "out";
  logoModalIsShown: boolean = false;
  cartCounter: number = 0;
  destroy: Subject<void> = new Subject();
  menuOpened: boolean = false;
  selectedMenu: MenuItem;

  // WELCOME PROMO
  panelOpenState: boolean = false;
  welcomeModalData: any;
  welcomeBannerData: any;
  showWelcomeBanner: boolean = false;

  isProfileMenu = false;

  // Cart processing
  itemAdded: CartItem = null;
  cartItems: Array<CartItem> = [];

  bannerConfig = undefined;

  /**
   * Creates an instance of app component.
   * @param translate
   * @param globalsSrv
   * @param route
   * @param router
   * @param userSrv
   * @param renderer
   * @param notificationModal
   * @param referenceSrv
   * @param translateSrv
   * @param notificationsSrv
   * @param initService
   * @param utilsSrv
   */
  constructor(
    public translate: TranslateService,
    public globalsSrv: AppGlobalsService,
    public route: ActivatedRoute,
    public router: Router,
    public userSrv: UserService,
    public renderer: Renderer2,
    public notificationModal: ElementRef,
    public iconBanner: ElementRef,
    public referenceSrv: ReferenceService,
    public translateSrv: TranslateService,
    public notificationsSrv: NotificationsService,
    public initService: InitService,
    public utilsSrv: UtilsService,
    private cartPresenter: CartPresenter,
    private goBackSrv: GoBackService,
    private location: Location,
    public resourcesSrv: ResourcesServices,
    private referralSrv: ReferralService,
    private alertSrv: AlertService,
    public profileSrv: ProfileService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef
  ) {
    this.translate.setDefaultLang("it");
    this.translate.use("it");
    logger.log(this.title);
    this.onResize();
    this.renderer.listen(
      this.notificationModal.nativeElement,
      EVENT_DEFINITIONS.ACTION_BUTTON_DEFAULT.NAME,
      this.eventFired.bind(this)
    );
    this.renderer.listen(
      this.notificationModal.nativeElement,
      EVENT_DEFINITIONS.NOTIFICATION_CLICKED.NAME,
      this.eventFired.bind(this)
    );
    this.renderer.listen(
      this.notificationModal.nativeElement,
      EVENT_DEFINITIONS.MENU_TO_APPLICATION_PAGE.NAME,
      this.eventFired.bind(this)
    );
    this.renderer.listen(
      this.iconBanner.nativeElement,
      EVENT_DEFINITIONS.BANNER_REDIRECT.NAME,
      this.eventFired.bind(this)
    );
    this.renderer.listen(
      this.iconBanner.nativeElement,
      EVENT_DEFINITIONS.BANNER_CLOSE.NAME,
      this.eventFired.bind(this)
    );
    this.renderer.listen(
      this.iconBanner.nativeElement,
      EVENT_DEFINITIONS.BANNER_SLIDE_CHANGED.NAME,
      this.eventFired.bind(this)
    );
    //  Render content on init (welcome promo | unicode popup)
    this.globalsSrv.appLoaded$
      .pipe(
        takeUntil(this.destroy),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.bannerInit();
        res && !!this.globalsSrv.hasUnicodePopup
          ? this.renderUnicodeModal()
          : this.setupWelcomeBannerAndModal();

        if (res) {
          this.initProfileProgress();
        }
      });

    this.route.fragment.pipe(takeUntil(this.destroy)).subscribe((f) => {
      if (f)
        setTimeout(() => {
          logger.log("FRAGMENT FOUND", f);
          const element = document.getElementById(f);
          logger.log(element);
          if (element)
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1000);
    });
  }

  navigateToTerms() {
    window.open(this.translate.instant("TERMS_URL"), "_blank");
  }

  navigateTo(value: string, type: number) {
    if (type === 1) {
      this.unicodeModal.nativeElement.close();
    } else if (type === 2) {
      this.profileProgressPopup.nativeElement.close();
      if (this.modal.nativeElement) {
        this.modal.nativeElement.close();
      }
    }
    this.router.navigate([value]);
  }

  shareButtonTapped(event) {
    logger.log(event);
    const id = event.id ? event.id : event.detail.action_id;

    // if (id == EVENT_DEFINITIONS.WHATS_APP_SHARE.ID) {
    //   this.shareWhatsapp();
    // } else if (id == EVENT_DEFINITIONS.SMS_SHARE.ID) {
    //   this.shareSMS();
    // } else if (id == EVENT_DEFINITIONS.EMAIL_SHARE.ID) {
    //   this.shareEmail();
    // } else
    // if (id == EVENT_DEFINITIONS.COPY_TO_CLIPBOARD.ID) {
    //   const text: string = this.translateSrv.instant(
    //     "SHARING_UNIQUE_TEXT_SIMPLE"
    //   );
    //   this.utilsSrv.copyTextToClipboard(
    //     this.utilsSrv.replaceAll(text, "UNIQUE_CODE", this.uniqueCode)
    //   );
    // } else
    if (id == EVENT_DEFINITIONS.COPY_TO_CLIPBOARD_UNICODE.ID) {
      const text: string = this.translateSrv.instant(
        "SHARING_UNIQUE_TEXT_SIMPLE"
      );
      this.utilsSrv.copyTextToClipboard(
        this.utilsSrv.replaceAll(text, "UNIQUE_CODE", this.unicode)
      );
    }

    setTimeout(() => {
      if (event.detail.element) {
        event.detail.element.ResetActionLimit();
      }
    }, 2000);
  }

  /**
   * Events fired
   * @param event
   */
  private eventFired(event) {
    logger.log(event.detail.action_id);
    if (
      event.detail.action_id === EVENT_DEFINITIONS.MENU_TO_APPLICATION_PAGE.ID
    ) {
      this.router.navigateByUrl("notifications");
      this.modal.nativeElement.close();
    } else if (
      event.detail.action_id === EVENT_DEFINITIONS.NOTIFICATION_CLICKED.ID
    ) {
      const categoryId =
        this.globalsSrv.config[
          APPLICATION_SETTING_KEYS.NOTIFICATION_CATEGORY_ID
        ];
      const message = event.detail.data
        ? event.detail.data.selectedValue
        : null;
      if (
        message &&
        String(message.categoryId) == categoryId &&
        message.message.indexOf(`<a`) >= 0
      ) {
        this.notificationsSrv.sendClickEvent(message);
      }
    } else if (
      !!event.id &&
      event.id === EVENT_DEFINITIONS.COPY_TO_CLIPBOARD_UNICODE.ID
    ) {
      this.copyCodeToClipboard();
    } else if (event.detail.action_id === EVENT_DEFINITIONS.BANNER_CLOSE.ID) {
      this.closeBanner(event);
    } else if (
      event.detail.action_id === EVENT_DEFINITIONS.BANNER_REDIRECT.ID
    ) {
      this.router.navigateByUrl(event.detail.item_id);
    } else if (
      event.detail.action_id === EVENT_DEFINITIONS.BANNER_SLIDE_CHANGED.ID
    ) {
      this.saveBannerHeight();
    }
  }

  public areWelcomeActionsCompleted(data): boolean {
    if (!data) return;
    return data.actions.every((c) => c.state === "completed");
  }

  public welcomeModalActionClicked(isActive) {
    if (!isActive) return;
    this.welcomeModal.nativeElement.close();
  }

  public welcomeModalOKButtonClicked() {
    this.router.navigateByUrl("guadagna-punti");
    this.welcomeModal.nativeElement.close();
  }

  private setupWelcomeBannerAndModal() {
    let conditionsMet = false;
    const welcomePromoFinished = localStorage.getItem("welcome_promo_finished");
    if (!welcomePromoFinished) {
      let actionDate: string = "";
      const user = this.userSrv.user;
      const today = moment(new Date()).startOf("day");

      if (user && !!user.firstActionCompleted) {
        actionDate = user.firstActionCompleted;
      } else if (user && !!user.loginActionCompleted) {
        actionDate = user.loginActionCompleted;
      }
      if (!!actionDate) {
        const priorDate = moment(actionDate).startOf("day").add(30, "days");
        const sameOrAfter = today.isSameOrAfter(priorDate);
        if (!sameOrAfter) {
          conditionsMet = true;
          const daysRemaining = priorDate.diff(today, "days");
          this.renderWelcomePromo(
            daysRemaining,
            Boolean(user.firstActionCompleted)
          );
        }
      }
    }
    if (!conditionsMet) this.renderUnicodeModal();
  }

  public disableWelcomePromo() {
    logger.log("close pressed, disabling welcome promo");
    this.showWelcomeBanner = false;
    localStorage.setItem("welcome_promo_finished", "true");
    // this.saveUserMetadata(welcome promo finished metadata)
  }

  private renderWelcomePromo(
    daysRemaining: number,
    isFirstActionCompleted: boolean
  ) {
    this.globalsSrv.welcomePromoContent$
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        logger.log("welcome promo subscribe", res);
        if (res) {
          res.modal.subtitle =
            res.modal.subtitle.replace("{{COUNT}}", "<b>" + daysRemaining) +
            "</b>";
          res.modal.subtitleMobile = res.modal.subtitleMobile.replace(
            "{{COUNT}}",
            "<b>" + daysRemaining + "</b>"
          );
          res.banner.content.note = res.banner.content.note.replace(
            "{{count}}",
            daysRemaining.toString()
          );
          this.welcomeModalData = res.modal;
          this.welcomeBannerData = res.banner;
        }
      });
    if (this.welcomeModalData && this.welcomeBannerData) {
      // this.globalsSrv.hasWelcome = true;
      this.showWelcomeBanner = true;
      if (!isFirstActionCompleted) this.welcomeModal.nativeElement.open();
    }
  }

  private renderUnicodeModal() {
    // prevent visibility of unicode modal
    // when other popups are enabled
    if (
      this.globalsSrv.config &&
      !this.globalsSrv.promoContent &&
      !this.globalsSrv.countDownPromoContent
    ) {
      from(this.referralSrv.getOverviewReferral()).subscribe(
        (res) => {
          this.referral = res;
          const version = this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.VERSION,
            this.referral.metadata
          );
          const displayOnce = this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.DISPLAY_ONCE,
            this.referral.metadata
          );
          const local = localStorage.getItem(
            "PROMO_VERSION_" + this.referral.itemIdForResourceLookup
          );
          let popupDisplayed = this.userSrv.user.popupDisplayed;
          let popupShown = false;
          if (popupDisplayed) {
            const config =
              popupDisplayed[this.referral.itemIdForResourceLookup];
            popupShown = config && config.version == version;
          } else {
            popupDisplayed = {};
          }
          if (displayOnce != "true" || !popupShown) {
            localStorage.setItem(
              "PROMO_VERSION_" + this.referral.itemIdForResourceLookup,
              version
            );
            this.unicode = this.userSrv.user.unicode;
            this.copyButton = {
              id: EVENT_DEFINITIONS.COPY_TO_CLIPBOARD_UNICODE.ID,
              enabled: true,
              image: "assets/img/copy-paste.svg",
            };

            this.unicodeModal.nativeElement.open();

            const metadataToSave: MetadataDTO[] = [];
            popupDisplayed[this.referral.itemIdForResourceLookup] = {
              version: version,
            };
            metadataToSave.push({
              key: METADATA_KEY.POPUP_DISPLAYED,
              value: JSON.stringify(popupDisplayed),
            });

            const inputMetadata: SaveUserMetadataInput = {
              userId: TAValues.UserId,
              userMetadataToSave: metadataToSave,
            };

            this.userSrv
              .saveMetadata(inputMetadata, true)
              .then((resultSaveProfile) => {
                logger.log(resultSaveProfile);
              })
              .catch((error) => {
                logger.log(error);
              });
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  copyCodeToClipboard() {
    const text: string = this.translateSrv.instant(
      "SHARING_UNIQUE_TEXT_SIMPLE"
    );
    this.utilsSrv.copyTextToClipboard(
      this.utilsSrv.replaceAll(text, "UNIQUE_CODE", this.unicode)
    );
  }

  /**
   * Sets references
   * @param valuePoolId
   * @returns references
   */
  private setReferences(valuePoolId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.referenceSrv
        .getReferencesMetadata(valuePoolId, { rangeFrom: 0, rangeTo: -1 })
        .then((result) => {
          logger.log(result);
          resolve(result);
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   * Show notification modal of app component
   */
  public async showNotificationModal(event: MouseEvent, tag: string) {
    const _notificationsSrv = await to(this.notificationsSrv.getMessages(0, 3));
    if (!isNullOrUndefined(_notificationsSrv.data)) {
      /* Parse Response. */
      try {
        const _notificationData = _notificationsSrv.data as Array<Message>;
        logger.log("_notificationData", _notificationData);
        const _getReferences = await to(
          this.setReferences(Value_Pools.NOTIFICATIONS_CATEGORIES)
        );
        logger.log("_getReferences", _getReferences);
        let _references = null;
        if (!isNullOrUndefined(_getReferences.data)) {
          _references = _getReferences.data;
        }
        this.notifications = [];
        _notificationData.map((notification) => {
          let _category = notification.category as string;
          if (_category.length && !isNull(_references)) {
            _references
              .filter(
                (reference) => reference.itemIdForResourceLookup === _category
              )
              .map((reference) => {
                if (!isNull(reference)) {
                  if (!isNullOrUndefined(reference.resources)) {
                    if (isObject(reference.resources)) {
                      if (
                        reference.resources.hasOwnProperty(
                          `${Resource_Types.NAME}_${this.translateSrv.currentLang}`
                        )
                      )
                        _category =
                          reference.resources[
                            `${Resource_Types.NAME}_${this.translateSrv.currentLang}`
                          ];
                    }
                  }
                }
              });
          }
          let _notification: NotificationTemplate = {
            message: notification.message.length ? notification.message : "-",
            categoryId: notification.category,
            category: _category,
            date: notification.formatedDate,
            readFlag: notification.readFlag,
            itemId: notification.itemId,
          };

          this.notifications.push(_notification);
        });
      } catch (e) {
        // logger.log(e);
        // this.noData = true;
        // this.loading = false;
        // this.showMoreVisible = false;
        // reject();
      }
    }

    let _srcElement = document.getElementById(tag) as HTMLElement;
    // let offsetHeight = (_srcElement.parentNode as HTMLElement).clientHeight;
    // let offsetWidth = (_srcElement.parentNode as HTMLElement).offsetLeft + _srcElement.clientWidth;
    // let offsetWidth = _srcElement.offsetLeft + _srcElement.clientWidth;
    this.modal.nativeElement.open(_srcElement);
  }

  /**
   * Gets tool tip content
   * @returns
   */
  public getToolTipContent() {
    if (this.currentOnBoardTab < 0) return "-";
    if (this.currentOnBoardTab >= this.onBoardTabs.length) return "-";
    const selectedElement = this.onBoardTabs[this.currentOnBoardTab];
    if (selectedElement.hasAttribute("name")) {
      if (selectedElement.getAttribute("name").length)
        return selectedElement.getAttribute("name");
    }
    return "-";
  }

  /**
   * Gets tool tip index
   * @returns
   */
  public getToolTipIndex() {
    if (this.currentOnBoardTab < 0) return "-";
    if (this.currentOnBoardTab >= this.onBoardTabs.length) return "-";
    return `${this.currentOnBoardTab + 1} ${this.translateSrv.instant("OF")} ${
      this.onBoardTabs.length
    }`;
  }

  /**
   * Gets tool tip header
   * @returns
   */
  public getToolTipHeader() {
    if (
      this.currentOnBoardTab >= 0 &&
      this.currentOnBoardTab < this.onBoardTabs.length
    ) {
      const selectedElement = this.onBoardTabs[this.currentOnBoardTab];
      if (selectedElement.hasAttribute("title")) {
        if (selectedElement.getAttribute("title").length)
          return selectedElement.getAttribute("title").toLowerCase();
      }
    }
    return "-";
  }

  /**
   * Sets on board tab
   * @param event
   * @returns
   */
  public async setOnBoardTab(event: MouseEvent) {
    const el = this.navTabs.nativeElement as HTMLElement;
    if (el.childNodes.length && !this.onBoardTabs.length) {
      el.childNodes.forEach((node: HTMLElement) => {
        const node1 = node as HTMLElement;
        try {
          if (node1.hasAttribute("onBoardingLinked")) {
            this.onBoardTabs.push(node1);
          }
        } catch (e) {}
      });
    }
    this.currentOnBoardTab++;
    try {
      if (
        this.currentOnBoardTab >= 0 &&
        this.currentOnBoardTab < this.onBoardTabs.length
      ) {
        const selectedElement = this.onBoardTabs[this.currentOnBoardTab];
        const check = await checkElement("nav-bar-out").then(async (data) => {
          // const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
          // if (supportsNativeSmoothScroll)
          //     document.querySelector('#nav-bar-out').scrollTo(selectedElement.offsetLeft - selectedElement.clientWidth / 2, 0);
          // else document.querySelector('#nav-bar-out').scrollLeft = selectedElement.offsetLeft - selectedElement.clientWidth / 2;
          // const leftPos = selectedElement.getBoundingClientRect().left + window.scrollX;// - (this.currentOnBoardTab == 0 ? (selectedElement.getBoundingClientRect().width) : 0);
          // if (true || this.userSrv.user == undefined || this.userSrv.user.promo != 'true') {
          //     this.toolTipModal.nativeElement.open(selectedElement, selectedElement.parentElement.clientHeight, leftPos, 35);
          //     this.isOnBoardOpen = true;
          // }

          // const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
          // if (supportsNativeSmoothScroll)
          //     document.querySelector('#nav-bar-out').scrollTo(selectedElement.offsetLeft - selectedElement.clientWidth / 2, 0);
          // else document.querySelector('#nav-bar-out').scrollLeft = selectedElement.offsetLeft - selectedElement.clientWidth / 2;
          // const leftPos = selectedElement.getBoundingClientRect().left + window.scrollX - (this.currentOnBoardTab == 0 ? (selectedElement.getBoundingClientRect().width) : 0);
          // if (true || this.userSrv.user == undefined || this.userSrv.user.promo != 'true') {
          //     this.toolTipModal.nativeElement.open(selectedElement, selectedElement.parentElement.clientHeight, leftPos, 35);
          //     this.isOnBoardOpen = true;
          // }

          setTimeout(
            function () {
              const supportsNativeSmoothScroll =
                "scrollBehavior" in document.documentElement.style;
              if (supportsNativeSmoothScroll)
                document
                  .querySelector("#nav-bar-out")
                  .scrollTo(
                    selectedElement.offsetLeft -
                      selectedElement.clientWidth / 2,
                    0
                  );
              else
                document.querySelector("#nav-bar-out").scrollLeft =
                  selectedElement.offsetLeft - selectedElement.clientWidth / 2;
              const leftPos =
                selectedElement.getBoundingClientRect().left +
                window.scrollX -
                (this.currentOnBoardTab == 0
                  ? selectedElement.getBoundingClientRect().width
                  : 0);
              if (
                this.userSrv.user == undefined ||
                this.userSrv.user.promo != "true"
              ) {
                this.toolTipModal.nativeElement.open(
                  selectedElement,
                  selectedElement.parentElement.clientHeight,
                  leftPos,
                  35
                );
                this.isOnBoardOpen = true;
              } else {
                this.openPopup();
              }
            }.bind(this),
            100
          );
        }, 2000);

        return;
      } else {
        localStorage.setItem("completedOnBoard", "1");
        this.isOnBoardOpen = false;
        this.currentOnBoardTab = -1;
        this.onBoardTabs = [];
        this.saveUserMetadata();

        this.openPopup();
      }
      this.toolTipModal.nativeElement.close();
    } catch (e) {
      logger.log(e);
      this.isOnBoardOpen = false;
      this.isOnBoardOpen = false;
      this.currentOnBoardTab = -1;
      this.onBoardTabs = [];

      this.openPopup();
    }
  }

  openPopup() {
    try {
      if (this.globalsSrv.countDownPromoContent) {
        this.countDownPromoPopUp.nativeElement.open("count-down");
        return;
      }
    } catch (error) {
      logger.error(error);
    }

    try {
      if (this.globalsSrv.promoContent) {
        const promoPopUp = document.getElementById("promoPopUp");
        if (promoPopUp) {
          promoPopUp["open"]();
        }
      }
    } catch (error) {
      logger.error(error);
    }
  }

  countDownClose(event?: any) {
    if (event) event.stopPropagation();
    this.countDownPromoPopUp.nativeElement.close();
  }

  saveUserMetadata(metadatas?: MetadataDTO[]) {
    let metadataToSave = metadatas ? metadatas : [];

    if (!metadatas) {
      metadataToSave.push({
        key: METADATA_KEY.PERSONAL_PROMO,
        value: "true",
      });
    }

    const inputMetadata: SaveUserMetadataInput = {
      userId: TAValues.UserId,
      userMetadataToSave: metadataToSave,
    };

    this.userSrv
      .saveMetadata(inputMetadata, true)
      .then((resultSaveProfile) => {
        logger.log(resultSaveProfile);
      })
      .catch((error) => {
        logger.log(error);
      });
  }

  public panelHeaderClicked() {
    this.welcomeCloseBtn.nativeElement.style.display = this.panelOpenState
      ? "none"
      : "flex";
  }

  /**
   * on init
   */
  async ngOnInit() {
    this.initProfileProgressInNotification();
    this.initCartPresenters();
    this.initAlertObserver();
    if (!this.globalsSrv.isMobile) {
      const check = await checkElement("test1").then(async (data) => {
        // this.setOnBoardTab(null);

        // if (true || !(localStorage.getItem('completedOnBoard') === '1')) {
        //     this.setOnBoardTab(null);
        // } else if (isNullOrUndefined(this.userSrv.user.promo) || this.userSrv.user.promo === '') {
        //     this.saveUserMetadata();
        //     this.openPopup();
        // } else {
        //     this.openPopup();
        // }
        this.openPopup();
      }, 2000);
    } else {
      const check2 = await checkElement("test2").then(async (data) => {
        this.openPopup();
      }, 2000);
    }

    // this.route
    //     .queryParams
    //     .subscribe(params => {
    //         console.log('params: ' + JSON.stringify(params));
    //         let at = params['#access_token'];
    //         console.log('access_token: ' + at);
    //     });
    //
    // this.route.fragment.subscribe((fragment: string) => {
    //
    //     console.log("My hash fragment is here => ", fragment);
    //     const test = new URLSearchParams(fragment).get('access_token')
    //     logger.log(test);
    // })
    //
    // const route = this.router.url;
    // const instaIndex = route.indexOf('#access_token');
    // if (instaIndex > 0) {
    //     const token = route.slice(instaIndex + '#access_token'.length + 1, route.length);
    //     console.log(token);
    // }
  }

  private initProfileProgress(): void {
    this.userSrv.getUserProfile(TAValues.UserId);
    this.userSrv.profileProgressCounts$
      .pipe(takeUntil(this.destroy))
      .subscribe((value: number) => {
        if (value < 5 && !this.profileProgressHasOpened) {
          // this.profileProgressPopup.nativeElement.open();
          // this.profileProgressHasOpened = true;
        }
      });
  }

  private initProfileProgressInNotification(): void {
    this.userSrv.getUserProfile(TAValues.UserId);
    this.userSrv.profileProgressCounts$
      .pipe(takeUntil(this.destroy))
      .subscribe((value: number) => {
        if (value < 5) {
          this.showProfileProgress = true;
        }
      });
  }

  private initAlertObserver(): void {
    this.alertSrv.alert$
      .pipe(
        takeUntil(this.destroy),
        filter((res) => !!res),
        tap((res) => {
          this.errorPopUp.nativeElement.open({
            image: res.image,
            title: res.title,
            message: res.message,
          });
        }),
        switchMap((value) => {
          if (value.autoClose === true)
            return timer(6000).pipe(
              tap(() => {
                // this.alertSrv.setAlertData(undefined);
                if (this.errorPopUp.nativeElement) {
                  this.errorPopUp.nativeElement.close();
                }
              })
            );
        })
      )
      .subscribe();
  }

  private initCartPresenters(): void {
    this.cartPresenter.cartObserver$
      .pipe(takeUntil(this.destroy))
      .subscribe((cart) => {
        this.cartItems =
          !!cart && !!cart.items && cart.items.length > 0 ? cart.items : [];
      });
    this.cartPresenter.cartCountObserver$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.cartCounter = value;
      });
    this.cartPresenter.cartItemAdded$
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.cartItemPanel.nativeElement.open({
          title: this.translateSrv.instant("PRODUCT_ADDED_TO_CART"),
          items: !(this.cartCounter > 0) ? [value] : this.cartItems,
          //  items: !(this.cartCounter > 0) ? [value] : this.sortCartItems(value),
          button: {
            label: this.translateSrv.instant("GO_TO_CART"),
            action: {
              action_id: EVENT_DEFINITIONS.REDIR_TO_APPLICATION_PAGE.ID,
              action_name: EVENT_DEFINITIONS.REDIR_TO_APPLICATION_PAGE.NAME,
              action_limit: Infinity,
            },
          },
        });
      });
    this.cartPresenter.cartError$
      .pipe(takeUntil(this.destroy))
      .subscribe((error: CartError) => {
        if (error.errorCode == SalesOrderErrorCodes.ITEM_NOT_ACTIVE) return;
        let header = this.translateSrv.instant(
          `CART_ERROR_${error.errorCode}_HEADER`
        );
        if (header === `CART_ERROR_${error.errorCode}_HEADER`) {
          header = this.translateSrv.instant(`CART_ERROR_DEFAULT_HEADER`);
        }
        let message = this.translateSrv.instant(
          `CART_ERROR_${error.errorCode}_MESSAGE`
        );
        if (message === `CART_ERROR_${error.errorCode}_MESSAGE`) {
          message = this.translateSrv.instant(`CART_ERROR_DEFAULT_MESSAGE`);
        }
        try {
          this.cartItemPanel.nativeElement.close();
        } catch (error) {
          logger.log(error);
        }
        this.errorPopUp.nativeElement.open({
          title: header,
          message: message,
          // image: '-alert'
        });
      });
  }

  /**
   * Checks menu dimension
   * @param width
   */
  checkMenuDimension(width) {
    if (width < 600) {
      this.globalsSrv.isMobile = true;
      this.globalsSrv.smallScreenView = false;
    } else if (width > 600 && width < 992) {
      this.globalsSrv.isMobile = false;
      this.globalsSrv.smallScreenView = true;
    } else {
      this.globalsSrv.isMobile = false;
      this.globalsSrv.smallScreenView = false;
    }
  }

  /**
   * Determines whether activate on
   * @param event
   */
  onActivate(event) {}

  /**
   * Check  of app component
   */
  check = (event) => {
    if (this.userSrv.user.unlinked) {
      event.preventDefault();
    }
  };

  toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === "out" ? "in" : "out";
  }

  private bannerInit() {
    let dummyData: BannerConfiguration = {
      autoSlide: true,
      slideInterval: 10000,
      contents: [
        {
          id: "534543534534",
          button: {
            label: "Invita Ora",
            href: "/invite",
          },
          text: {
            label: "Condividi con un amico il tuo codice invito",
            mobileLabel:
              "Condividi con un amico il tuo codice invito,</br> per te <b>fino a 20€ di voucher sconto</b> sulla</br> linea IQOS ILUMA.",
          },
        },
        // {
        //   id: "34324324324",
        //   backgroundColor: "#F6F4F0", // gray
        //   theme: "light",
        //   button: {
        //     label: "Invita Ora",
        //     href: "/invite",
        //   },
        //   text: {
        //     label: "This is a single line test",
        //     mobileLabel: "This is a double,</br> line <b>Test</b>",
        //   },
        // },
        {
          id: "9543854985",
          backgroundColor: "#F6F4F0", // gray
          theme: "dark",
          button: {
            label: "Invita Ora",
            href: "/invite",
          },
          text: {
            label: "Condividi con un amico il tuo codice invito",
            mobileLabel:
              "Condividi con un amico il tuo codice invito,</br> per te <b>fino a 20€ di voucher sconto</b> sulla</br> linea IQOS ILUMA.",
          },
        },
        {
          id: "123123131231",
          backgroundColor: "#000000",
          theme: "light",
          button: {
            label: "Invita Ora",
            href: "/invite",
            classNames: "light to-dark",
          },
          text: {
            label: "This is a single line test",
            mobileLabel: "This is a double,</br> line <b>Test</b>",
          },
        },
      ],
    };
    // let dummyData = undefined;
    if (dummyData) {
      const dispatchedItems = localStorage.getItem(
        "banner-content-dispatched-ids"
      );
      let currentIds: string[] = [];

      if (dispatchedItems)
        try {
          currentIds = JSON.parse(dispatchedItems);
        } catch (error) {
          logger.error("Error parsing stored IDs:", error);
        }

      const existingIdsSet = new Set(currentIds);
      const filtered = dummyData.contents.filter(
        (content) => !existingIdsSet.has(content.id)
      );

      if (filtered.length > 0) {
        logger.log("New IDs not in localStorage:", filtered);
        dummyData.contents = filtered;
        this.bannerConfig = dummyData;
        this.globalsSrv.saveSlideBanner(this.bannerConfig);
      } else {
        logger.log("All new IDs are already in localStorage.");
      }
    }
  }

  private closeBanner(event) {
    logger.log("banner_close action");

    this.bannerConfig = {
      // remove banner
      ...this.bannerConfig,
      contents: this.bannerConfig.contents.filter(
        (c) => c.id !== event.detail.content_id
      ),
    };

    const dispatchedItems = localStorage.getItem(
      "banner-content-dispatched-ids"
    );
    let currentIds: string[] = [];

    if (dispatchedItems)
      try {
        currentIds = JSON.parse(dispatchedItems);
      } catch (error) {
        logger.error("Error parsing stored IDs:", error);
      }

    const updatedIdsSet = new Set([...currentIds, ...event.detail.content_id]);
    const updatedIdsArray = Array.from(updatedIdsSet);

    try {
      localStorage.setItem(
        "banner-content-dispatched-ids",
        JSON.stringify(updatedIdsArray)
      );
      logger.log("Content IDs saved to localStorage:", updatedIdsArray);
    } catch (error) {
      logger.error("Error saving content IDs to localStorage:", error);
    }
  }

  goToIqosClub() {
    window.open("http://iqos.com/", "_blank");
  }

  public goToCartPreview() {
    try {
      this.router.navigate(["/cart-preview/"]);
      this.cartItemPanel.nativeElement.close();
    } catch (error) {
      logger.log(error);
    }
  }

  closeMessagePopup() {
    const messageWelcomePopup = document.getElementById("messageWelcomePopup");
    if (messageWelcomePopup && messageWelcomePopup["close"]) {
      messageWelcomePopup["close"]();
    }
  }

  navigate(value: string) {
    if (
      false &&
      (value.indexOf("community") >= 0 || value.indexOf("casting") >= 0)
    ) {
      window.open(`${this.location.path()}/${value}/it`, "_parent");
      return;
    }
    this.router.navigateByUrl(value);
  }

  promoClicked(promo) {
    if (promo && promo.action && promo.action != "")
      this.navigate(promo.action);

    const promoPopUp = document.getElementById("promoPopUp");
    if (promoPopUp) {
      promoPopUp["close"]();
    }
  }

  countdownClicked(promo) {
    if (promo && promo.action && promo.action != "")
      this.navigate(promo.action);

    this.countDownClose();
  }

  bannerTapped(value) {
    if (value && value.action && value.action != "")
      this.navigate(value.action);
  }

  closeLogoModal(event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.logoModalIsShown) {
      logger.log("closePendingModal");
      this.logoModal.nativeElement.close();
      this.logoModalIsShown = false;
    }
  }

  async showLogoModal(event: MouseEvent, tag: string) {
    if (event) {
      event.stopPropagation();
    }
    if (this.logoModalIsShown) return;
    logger.log("showPendingModal");

    this.logoModalIsShown = true;
    let _srcElement = document.getElementById(tag) as HTMLElement;
    this.logoModal.nativeElement.open(_srcElement);
  }

  public getLevelResource(
    level: number,
    levelReferences: any,
    resourceType: number
  ): string {
    let ret = "";
    if (levelReferences && levelReferences.levels) {
      levelReferences.levels.map((level_) => {
        if (level_.index === level) {
          const _tmp = this.utilsSrv.formatItemResources(level_);
          if (!isNullOrUndefined(_tmp) && _tmp.Count()) {
            ret = this.utilsSrv.getItemMappedResource(
              { resourcesMapped: _tmp },
              resourceType,
              this.translateSrv.currentLang
            );
          }
        }
      });
    }
    if (!isNull(ret)) return ret.toUpperCase();
    return ret;
  }

  public getLevelTitle(level: number, levelReferences: any): string {
    let ret = "";
    if (levelReferences && levelReferences.levels) {
      levelReferences.levels.map((level_) => {
        if (level_.index === level) {
          const _tmp = this.utilsSrv.formatItemResources(level_);
          if (!isNullOrUndefined(_tmp) && _tmp.Count()) {
            ret = this.utilsSrv.getItemMappedResource(
              { resourcesMapped: _tmp },
              Resource_Types.NAME,
              this.translateSrv.currentLang
            );
          }
        }
      });
    }
    if (!isNull(ret)) return ret.toUpperCase();
    return ret;
  }

  initiateMenu() {
    this.globalsSrv.mainMenu.menuItems.forEach((m) => {
      m.open = false;
    });
    this.menuOpened = false;
    setTimeout(() => {
      this.selectedMenu = null;
    }, 100);
  }

  mobileBannerClicked(value: MenuItem) {
    if (value && value.bannerPath && value.bannerPath != "") {
      this.menuClicked(value, true);
      this.toggleMenu();
      return;
    }
  }

  profileClicked() {
    this.isProfileMenu = true;
    // this.toggleMenu();
    // this.navigate("profile");

    const value = this.globalsSrv.profileMenu;

    value.open = true;
    value.active = true;
    // this.selectedMenu = value;

    setTimeout(() => {
      this.menuOpened = true;
    }, 100);
    setTimeout(() => {
      this.selectedMenu = value;
    }, 500);
  }

  mobileMenuClicked(value: MenuItem) {
    if (value && value.path && value.path != "") {
      this.menuClicked(value);
      this.toggleMenu();
      this.isProfileMenu = false;
      return;
    }
    this.isProfileMenu = false;
    this.menuSelected(value);
  }

  menuClicked(value: MenuItem, isBanner?: boolean) {
    const base = "https://club.it.iqos.com/pub/iqosclub/it";

    const removeLastDirectoryPartOf = (url: string): string => {
      var the_arr = url.split("/");
      if (the_arr[the_arr.length - 1] == "it") {
        the_arr.pop();
      }
      the_arr.pop();
      return the_arr.join("/");
    };

    if (isBanner) {
      if (value && value.bannerPath && value.bannerPath != "") {
        if (
          false &&
          (value.bannerPath.indexOf("community") >= 0 ||
            value.bannerPath.indexOf("casting") >= 0) &&
          this.globalsSrv.wyng
        ) {
          // const base = removeLastDirectoryPartOf(window.location.href);
          window.open(`${base}/${value.bannerPath}`, "_parent");
        } else {
          this.goBackSrv.setPreviewPageData(value.pathParams);
          this.router.navigateByUrl(value.bannerPath);
        }
        value.active = true;
      }
    } else {
      if (value && value.path && value.path != "") {
        if (
          false &&
          (value.path.indexOf("community") >= 0 ||
            value.path.indexOf("casting") >= 0) &&
          this.globalsSrv.wyng
        ) {
          // const base = removeLastDirectoryPartOf(window.location.href);
          window.open(`${base}/${value.path}`, "_parent");
        } else {
          this.goBackSrv.setPreviewPageData(value.pathParams);
          this.router.navigateByUrl(
            this.isProfileMenu ? `profile/${value.path}` : value.path
          );
          // this.router.navigateByUrl("profile/info#enrichment")
        }
        value.active = true;
      }
    }

    if (this.globalsSrv.mainMenu.menuItems) {
      this.globalsSrv.mainMenu.menuItems.forEach((m) => {
        m.open = false;
        m.active = false;
        if (m.menuItems) {
          const child = m.menuItems.find((i) => i.id == m.id);
          if (child) m.active = true;
        }
      });
    }
    this.menuOpened = false;
    setTimeout(() => {
      this.selectedMenu = null;
    }, 250);
  }

  menuSelected(value: MenuItem) {
    if (!value) {
      return;
    }

    this.globalsSrv.mainMenu.menuItems.forEach((m) => {
      m.open = false;
    });
    if (this.menuOpened) {
      if (this.selectedMenu) {
        if (this.selectedMenu.id == value.id) {
          this.menuOpened = false;
          setTimeout(() => {
            this.selectedMenu = null;
          }, 250);
          return;
        }
      }
    }

    if (!(value.menuItems && value.menuItems.length > 0)) {
      value.active = true;
      this.selectedMenu = value;
      this.menuClicked(value);
      return;
    }

    value.open = true;
    value.active = true;
    // this.selectedMenu = value;

    setTimeout(() => {
      this.menuOpened = true;
    }, 100);
    setTimeout(() => {
      this.selectedMenu = value;
    }, 500);
  }

  filterMenuItemsOfType(
    items: Array<MenuItem>,
    teaser: boolean
  ): Array<MenuItem> {
    return items.filter((x) => x.teaser == teaser);
  }

  actionClicked(value: string) {
    if (!value || value == "") return;

    this.router.navigate([value]);
    this.expanded = false;
  }

  // private sortCartItems(itemAdded: CartItem): Array<CartItem> {
  //   // Put itemAdded first on the list
  //   const sortedItems = this.cartItems.reduce((acc, elem) => {
  //     if (elem.itemId === itemAdded.itemId) return [elem, ...acc];
  //     return [...acc, elem];
  //   }, []);
  //   return sortedItems;
  // }

  private saveBannerHeight() {
    let slideBanner = this.globalsSrv.loadSlideBanner();
    if (slideBanner) {
      this.globalsSrv.saveSlideBanner({
        ...slideBanner,
        height: document.querySelector("icon-banner").getBoundingClientRect()
          .height,
      });
    }
  }
}

export interface NotificationTemplate {
  message?: string;
  category?: string;
  date?: string;
  readFlag?: boolean;
  categoryId?: string | number;
  itemId?: string;
  profileProgressComponent?: ProfileProgressComponent;
  type?: "notification" | "profileProgress";
  data?: NotificationTemplate | ProfileProgressComponent;
}
