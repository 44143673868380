import {
  GetIndexedContentQuery,
  IndexedDataApi,
} from "../../talosApi/api/IndexedDataApi";
import { Injectable } from "@angular/core";
import { FilterUsersIndexedDataInput } from "../../talosApi/models/FilterUsersIndexedDataInput";
import { ESUserDTO } from "../../talosApi/models/ESUserDTO";
import { FilterContentIndexedDataInput } from "../../talosApi/models/FilterContentIndexedDataInput";
import { IndexedDataContent } from "../../talosApi/models/IndexedDataContent";
import { IndirectUserDataDTO } from "src/talosApi/models/IndirectUserDataDTO";

@Injectable()
export class IndexedDataService {
  constructor(private api: IndexedDataApi) {}

  /**
   * Get Indexed Users
   * @param {FilterUsersIndexedDataInput} input
   * @param {boolean} requireSession_
   * @return {Promise<Array<ESUserDTO>>}
   */
  public getIndexedUsers(
    input?: FilterUsersIndexedDataInput,
    requireSession_: boolean = true
  ): Promise<Array<ESUserDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .getIndexedUsers(input, requireSession_)
        .then((response: Array<ESUserDTO>) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  /**
   * Get Indexed Content
   * @param {FilterContentIndexedDataInput} input
   * @param {GetIndexedContentQuery} queryParams
   * @param {boolean} requireSession_
   * @return {Promise<Array<IndexedDataContent>>}
   */
  public getIndexedContent(
    input?: FilterContentIndexedDataInput,
    queryParams?: GetIndexedContentQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndexedDataContent>> {
    return new Promise((resolve, reject) => {
      this.api
        .getIndexedContent(input, queryParams, requireSession_)
        .then((response: Array<IndexedDataContent>) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  /**
   * Get Indexed Content
   * @param {FilterContentIndexedDataInput} input
   * @param {GetIndexedContentQuery} queryParams
   * @param {boolean} requireSession_
   * @return {Promise<Array<IndexedDataContent>>}
   */
  public getRecomendationContent(
    input?: FilterContentIndexedDataInput,
    queryParams?: GetIndexedContentQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndexedDataContent>> {
    return new Promise((resolve, reject) => {
      this.api
        .getRecomendationContent(input, queryParams, requireSession_)
        .then((response: Array<IndexedDataContent>) => {
          resolve(response);
        })
        .catch(reject);
    });
  }
}
