import { Injectable } from "@angular/core";
import { map, takeWhile } from "rxjs/operators";
import { Subscription, Observable, timer, BehaviorSubject } from "rxjs";
import { ItemItemTypeInput } from "src/talosApi/api/UserV2Api";
import { TAConstants, TAValues } from "src/talosApi/settings";
import { UserV2Service } from "./talos/userV2.service";
import GAME_TYPE_ID = TAConstants.Settings.GAME_TYPE_ID;
import { TranslateService } from "@ngx-translate/core";
import { AppGlobalsService } from "./appGlobals.service";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;

@Injectable()
export class OtpService {
  public firstVisit: boolean = true;
  public otpTimerSub: Subscription;
  private otpTimer$(time): Observable<number> {
    return timer(0, 1000).pipe(
      map((n: number) => time - n),
      takeWhile((n: number) => n >= 0) // end subscription
    );
  }

  private countDownSubject = new BehaviorSubject<number>(0);
  public countDown$ = this.countDownSubject.asObservable();

  constructor(
    private userV2Srv: UserV2Service,
    private translateSrv: TranslateService,
    private appGlobalsSrv: AppGlobalsService
  ) {}

  requestPin(input: ItemItemTypeInput): Promise<any> {
    return this.userV2Srv.requestPin(GAME_TYPE_ID, TAValues.UserId, input);
  }

  validatePin(input: ItemItemTypeInput): Promise<any> {
    return this.userV2Srv.validatePin(GAME_TYPE_ID, TAValues.UserId, input);
  }

  public startOTPCountdown() {
    this.firstVisit = false;
    let otpResendSMSTime: number = this.appGlobalsSrv.config[
      APPLICATION_SETTING_KEYS.OTP_RESEND_SMS_TIME
    ]
      ? parseInt(
          this.appGlobalsSrv.config[
            APPLICATION_SETTING_KEYS.OTP_RESEND_SMS_TIME
          ],
          10
        )
      : 179;

    this.otpTimerSub = this.otpTimer$(otpResendSMSTime).subscribe((value) => {
      this.countDownSubject.next(value);
    });
  }
}
