import {ChangeMessageStatusInput, GetInAppMessagesQuery, InAppMessagesApi} from "../../talosApi/api/InAppMessagesApi";
import {Injectable} from "@angular/core";
import {InAppMessageDTO} from "../../talosApi/models/InAppMessageDTO";

@Injectable()
export class InAppMessagesService {

    constructor(private api: InAppMessagesApi) {
    }

    /**
     * Get In App Messages
     * @param {string} userId
     * @param {GetInAppMessagesQuery} queryParams
     * @return {Promise<Array<InAppMessageDTO>>}
     */
    getInAppMessages(userId: string, queryParams: GetInAppMessagesQuery): Promise<Array<InAppMessageDTO>> {
        return new Promise((resolve, reject) => {
            this.api.getInAppMessages(userId, queryParams).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }

    /**
     * Set In App Messages status
     * @param {string} userId
     * @param {ChangeMessageStatusInput} input
     * @return {Promise<any>}
     */
    setInAppMessagesStatus(userId: string, input: ChangeMessageStatusInput): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.setInAppMessagesStatus(userId, input).then((result) => {
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        })
    }
}