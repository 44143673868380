import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ItemItemTypeInput, UserV2Api } from "src/talosApi/api/UserV2Api";

@Injectable()
export class UserV2Service {
  constructor(private userV2Api: UserV2Api) {}

  /**
   * Request pin
   * @param gameTypeId
   * @param userId
   * @param input
   * @param requireSession
   * @param headers
   * @returns
   */
  public requestPin(
    gameTypeId: string,
    userId: string,
    input: ItemItemTypeInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userV2Api
        .requestPin(gameTypeId, userId, input, requireSession, headers)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Validate Pin
   * @param gameTypeId
   * @param userId
   * @param input
   * @param requireSession
   * @param headers
   * @returns
   */
  public validatePin(
    gameTypeId: string,
    userId: string,
    input: ItemItemTypeInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userV2Api
        .validatePin(gameTypeId, userId, input, requireSession, headers)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
