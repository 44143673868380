import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ProfileProgressComponent } from "./profile-progress.component";
import { NgCircleProgressModule } from "ng-circle-progress";

@NgModule({
    declarations: [ProfileProgressComponent],
    entryComponents: [ProfileProgressComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgCircleProgressModule.forRoot(),
        TranslateModule.forChild(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [ProfileProgressComponent],
})
export class ProfileProgressModule {}
