import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {FeedbackContainerComponent} from "./feedback-container.component";
import {Ng5SliderModule} from "ng5-slider";
import {FeedbackContainerService} from "./feedback-container.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [FeedbackContainerComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        FeedbackContainerComponent
    ]

})
export class FeedbackContainerModule {

}