import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-icon-item-display-mode-ang',
    templateUrl: './icon-item-display-mode-ang.component.html',
    styleUrls: ['./icon-item-display-mode-ang.component.scss']
})
export class IconItemDisplayModeAngComponent implements OnInit {

    @Input()
    displayItems: Array<DisplayModeItem> = [];

    @Output()
    selectedItemChanged = new EventEmitter();

    selectedItem: DisplayModeItem = {};
    activeGrid:IColsGrid;
    __grid__:IColsGrid;
    __flat_grid__:IColsGrid;

    constructor() {
        this.__grid__ = {
            sm: 12,
            xs: 12,
            md: 6,
            lg: 6,
            xl: 4
        }
        this.__flat_grid__ = {
            sm: 12,
            xs: 12,
            md: 12,
            lg: 12,
            xl: 12
        }
        this.activeGrid = this.__grid__;
    }

    ngOnInit() {
        if (this.displayItems) {
            const selected = this.displayItems.find((item: DisplayModeItem) => {
                return item.selected == true;
            })

            if (selected) {
                this.displayModeChanged(selected);
            }
        }
    }

    displayModeChanged(item: DisplayModeItem) {
        if (this.selectedItem != undefined && item.tag != this.selectedItem.tag)
            this.selectedItem = item;

        this.setDisplayMode(item);
        if (this.selectedItemChanged) {
            this.selectedItemChanged.emit({id: item.tag, grid:this.activeGrid});
        }
    }

    setDisplayMode(item: DisplayModeItem) {
        switch (item.tag) {
            case DisplayModes.GRID:
                this.activeGrid = this.__grid__;
                break;
            default:
                this.activeGrid = this.__flat_grid__;
                break;
        }
    }
}

export interface DisplayModeItem {
    name?: string;
    tag?: DisplayModes;
    image?: string;
    selected?: boolean;
}

export enum DisplayModes {
    GRID = 0,
    LIST = 1,
}

export interface IColsGrid {
    readonly xs?: number;

    readonly sm?: number;

    readonly md?: number;

    readonly lg?: number;

    readonly xl?: number;
}
