import {Http2Talos} from "../http2Talos";
import {User} from "../models";
import {PmiOptinDTO} from "../models/PmiOptinDTO";
import {Injectable} from "@angular/core";
import {ScannedDataInput} from "../models/ScannedDataInput";

@Injectable()
export class PmiApi {

    constructor(private http2Talos: Http2Talos) {
    }

    /**
     * @param gameTypeId
     * @param input
     * @param requireSession
     */
    pmiOptin(gameTypeId: string, input: User, requireSession: boolean = true): Promise<PmiOptinDTO> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/pmi/${gameTypeId}/optin`,
            input: input
        });
    }

    pmiExternalAuthentication(userId: string, extAppSourceId: number, requireSession: boolean = true): Promise<ExternalSystemAuthenticationResponse> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/pmi/${userId}/${extAppSourceId}/external/authenticate`,
            input: {}
        });
    }

    /**
     * Data Scanned
     * @param {string} gameTypeId
     * @param {string} userId
     * @param {ScannedDataInput} input
     * @param {boolean} requireSession
     * @return {Promise<any>}
     */
    dataScanned(gameTypeId: string,userId: string, input: ScannedDataInput, requireSession: boolean = true): Promise<any> {
        return this.http2Talos.post({
            requireSession: requireSession,
            path: `/pmi/${gameTypeId}/dataScanned/${userId}`,
            input: input
        });
    }


}// END CLASS

export interface ExternalSystemAuthenticationResponse {
    authKey?: string;
    redirectionLink?: string;
}