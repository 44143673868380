import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";
import {ItemsList} from "../models";
import {CategoryDTO} from "../models/CategoryDTO";
import { isNullOrUndefined, isArray } from 'util';

@Injectable()
export class CategoryApi {
    constructor(private http2Talos:Http2Talos) {
    }

    async getCategories(
        input: any,
        categoryId: number|string
    ) {
        return this.http2Talos.get({
            path:`/category/${categoryId}`,
            queryParams:input
        });
    }

    get(categoryId: number, depth?: number, withLeaves?: boolean, rangeFrom?:number, rangeTo?: number):Promise<ItemsList<CategoryDTO>> {
        return this.http2Talos.get({path:`/category/${categoryId}`, queryParams:{'depth': depth, 'withleaves': withLeaves, 'rangeFrom': rangeFrom, 'rangeTo': rangeTo}});
    }


} // END CLASS

