import { Injectable } from "@angular/core";
import { DetailedBrandProductCouponDTO } from "../../talosApi/models/DetailedBrandProductCouponDTO";
import {
  AchievementQ,
  BalanceTypeQ,
  ConvertInput,
  IActionConstraint,
  IBalance,
  PendingPointsQ,
  StatsReturn,
  UserApi,
} from "../../talosApi/api/UserApi";
import { TAConstants, TAValues } from "../../talosApi/settings";
import {
  AuthenticateUserResponse,
  AuthorizationKeyResponse,
  DeleteMessageInput,
  GetStatisticsInput,
  ItemsList,
  MetadataDTO,
  TransactionHistoryInput,
  User,
  UserProfileUpdateInput,
} from "../../talosApi/models";
import { SsoAuthedicationInput } from "../../talosApi/models/SsoAuthedicationinput";
import { HttpHeaders } from "@angular/common/http";
import * as _ from "lodash";
import { ActionConstrainDTO } from "../../talosApi/models/ActionConstrainDTO";
import { SaveUserMetadataInput } from "../../talosApi/models/SaveUserMetadataInput";
import { LoyaltyUser } from "../../models/LoyaltyUser";
import { UtilsService } from "../utils.service";
import { UserLevelsDTO } from "../../talosApi/models/UserLevelsDTO";
import { AuthenticateUserInput } from "../../talosApi/models/AuthenticateUserInput";
import { AuthorizationKeyInput } from "../../talosApi/models/AuthorizationKeyInput";
import { AuthenticateKeyInput } from "../../talosApi/models/AuthenticateKeyInput";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { BalanceDTO } from "../../talosApi/models/BalanceDTO";
import { SSOTokenInput } from "../../talosApi/models/SSOTokenInput";
import { IReferenceLevel } from "../../app/pages/profile/profile.component";
import {
  IReferenceLevelInput,
  ReferenceApi,
} from "../../talosApi/api/referenceApi";
import { environment } from "../../environments/environment";
import {
  ILevelImage,
  IMessageCategory,
} from "../../app/pages/profile/profile.service";
import { MessageApi } from "../../talosApi/api";
import { EWalletDTO } from "../../talosApi/models/EWalletDTO";
import { ExtSystemInfoDTO } from "../../talosApi/models/ExtSystemInfoDTO";
import { GuestInfoInput } from "../../talosApi/models/GuestInfoInput";
import { ProfileFilter } from "../../talosApi/models/ProfileFilter";
import { PurchaseCouponInput } from "../../talosApi/models/PurchaseCouponInput";
import { NewUserInput } from "../../talosApi/models/NewUserInput";
import { isNullOrUndefined } from "util";
import { BadgeDTO } from "src/talosApi/models/BadgesDTO";
import { to } from "../../../../src/utils/utils";
import {
  UserMaxObtainablePointsDTO,
  UserPendingPointsDTO,
} from "../../talosApi/models/UserPendingPointsDTO";
import { SendVirtualAmountInput } from "../../talosApi/models/SendVirtualAmountInput";
import { FilterUsersIndexedDataInput } from "../../talosApi/models/FilterUsersIndexedDataInput";
import { ESUserDTO } from "../../talosApi/models/ESUserDTO";
import { AppGlobalsService } from "../appGlobals.service";
import { UploadContentsService } from "./uploadContents.service";
import { ResourcesServices } from "./resources.services";
import { TransactionGroupByGroupByDTO } from "../../talosApi/models/TransactionGroupByGroupByDTO";
import { LoginWithAuthKeyInput } from "../../talosApi/models/LoginWithAuthKeyInput";
import { AuthKeyUserResponse } from "../../talosApi/models/AuthKeyUserResponse";
import { PromoConfig } from "../../app/pages/loading/loading.component";
import External_Application_SourceId = TAConstants.External_Application_SourceId;
import Settings = TAConstants.Settings;
import UNREAD_USER_MESSAGES = TAConstants.User_Stats.UNREAD_USER_MESSAGES;
import Unit_Types = TAConstants.Unit_Types;
import GAME_TYPE_ID = TAConstants.Settings.GAME_TYPE_ID;
import METADATA_KEY = TAConstants.METADATA_KEY;
import LEVELS_RESOURCES_TYPE_ID = TAConstants.LEVELS_RESOURCES_TYPE_ID;
import GET_STATS = TAConstants.GET_STATS;
import COUPON_STATS_PER_GAME_TYPE = TAConstants.User_Stats.COUPON_STATS_PER_GAME_TYPE;
import User_Status = TAConstants.User_Status;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import Resource_Types = TAConstants.Resource_Types;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Order_Stores = TAConstants.Order_Stores;
import { EWalletSummaryPerVmTransactionTypeTO } from "src/talosApi/models/EWalletSummaryPerVmTransactionTypeTO";
import { FilterESContentDataInput } from "src/talosApi/api/RecommendationsApi";
import { RecommendationsService } from "./recommendations.service";
import { Observable, Subject } from "rxjs";

@Injectable()
export class UserService {
  public user: LoyaltyUser = new LoyaltyUser();
  public levelReferences: IReferenceLevel;
  public unreadMessage: boolean = false;
  public unreadMessageCount: number = 0;
  public promoConfig: PromoConfig;
  public profileProgressCounts: number = 0;

  private profileProgressCountsSubject: Subject<number> = new Subject();
  profileProgressCounts$: Observable<number> =
    this.profileProgressCountsSubject.pipe();

  constructor(
    private userApi: UserApi,
    private utilsSrv: UtilsService,
    private referenceApi: ReferenceApi,
    private appGlobalsSrv: AppGlobalsService,
    private uploadContentsSrv: UploadContentsService,
    private resourcesSrv: ResourcesServices,
    private messageApi: MessageApi,
    private recommendationsSrv: RecommendationsService
  ) {}

  public authenticateUser(
    input: AuthenticateUserInput
  ): Promise<AuthenticateUserResponse> {
    return new Promise((resolve, reject) => {
      this.userApi
        .authenticate(input)
        .then((result) => {
          TAValues.UserSessionId = result.userSessionId;
          TAValues.UserId = result.userId;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getAuthKey(
    input: AuthorizationKeyInput
  ): Promise<AuthorizationKeyResponse> {
    return new Promise((resolve, reject) => {
      this.userApi
        .getAuthKey(input)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public authenticateKey(
    input: AuthenticateKeyInput
  ): Promise<AuthenticateUserResponse> {
    return new Promise((resolve, reject) => {
      this.userApi
        .authenticateKey(input)
        .then((result) => {
          TAValues.UserSessionId = result.userSessionId;
          TAValues.UserId = result.userId;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public authenticateKeyNew(
    input: LoginWithAuthKeyInput
  ): Promise<AuthKeyUserResponse> {
    return new Promise((resolve, reject) => {
      this.userApi
        .authenticateKeyNew(input)
        .then((result) => {
          if (result && result.userSession && result.user) {
            TAValues.UserSessionId = result.userSession.userSessionId;
            TAValues.UserId = result.user.userId;
            resolve(result);
            return;
          }
          reject("ERROR");
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get User's coupons
   * @param {Array<number>} brandProductCouponStatusIds
   * @param {Array<string>} brandProductIds
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @param {boolean} friendsOnly
   */
  public getUserCouponRewards(
    brandProductCouponStatusIds?: Array<number>,
    brandProductIds?: Array<string>,
    rangeFrom?: number,
    rangeTo?: number,
    friendsOnly?: boolean
  ): Promise<Array<DetailedBrandProductCouponDTO>> {
    return new Promise((resolve, reject) => {
      this.userApi
        .getUserCouponRewards(
          brandProductCouponStatusIds,
          brandProductIds,
          rangeFrom,
          rangeTo,
          friendsOnly
        )
        .then((response: Array<DetailedBrandProductCouponDTO>) => {
          if (!response) {
            resolve([]);
            return;
          }

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateProfileProgress() {
    if (!this.user || !this.user.user) return;

    this.profileProgressCounts = 0;

    const nickName =
      this.user.user.customUserProfileFields[
        METADATA_KEY.PERSONAL_USER_DEFINED_NICK_NAME
      ];
    if (nickName && nickName != "") this.profileProgressCounts++;

    const jobSector =
      this.user.user.customUserProfileFields[METADATA_KEY.PERSONAL_JOB_SECTOR];
    if (jobSector && jobSector != "") this.profileProgressCounts++;

    const jobProfile =
      this.user.user.customUserProfileFields[METADATA_KEY.PERSONAL_JOB_PROFILE];
    if (jobProfile && jobProfile != "") this.profileProgressCounts++;

    const interests =
      this.user.user.customUserProfileFields[METADATA_KEY.PERSONAL_INTERESTS];
    if (interests && interests != "") this.profileProgressCounts++;

    const favoriteColor =
      this.user.user.customUserProfileFields[
        METADATA_KEY.PERSONAL_FAVORITE_COLOR
      ];
    if (favoriteColor && favoriteColor != "") this.profileProgressCounts++;
    this.profileProgressCountsSubject.next(this.profileProgressCounts);
  }

  /**
   *
   * @param userId
   * @param requireSession
   * @param headers
   */
  public getUserProfile(
    userId,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.userApi
        .userProfile(userId, requireSession, headers)
        .then((profileResponses) => {
          if (
            _.isNil(profileResponses) ||
            _.isNil(profileResponses.customUserProfileFields)
          ) {
            reject(null);
            return;
          }
          this.user.user = profileResponses;
          this.updateProfileProgress();
          window["dataLayer"] = window["dataLayer"] || [];
          window["dataLayer"].push({
            "sf-id": profileResponses.externalSystemId,
          });
          this.user.unlinked = profileResponses.status == User_Status.INACTIVE;
          this.user.fullName =
            (this.user.user.firstName || "") +
            " " +
            (this.user.user.lastName || "");

          if (
            this.user.user.preferedLanguageCode &&
            this.user.user.preferedLanguageCode != ""
          )
            TAValues.INACTIVE_REDIRECTION_LINK =
              TAValues.INACTIVE_REDIRECTION_BASE.replace(
                "{langCode}",
                this.user.user.preferedLanguageCode
              );
          // if (profileResponses.customUserProfileFieldList)
          // this.user.fullName = this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_NAME, profileResponses.customUserProfileFieldList) + " " + this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.PERSONAL_SURNAME, profileResponses.customUserProfileFieldList)
          if (profileResponses.customUserProfileFields) {
            this.user.uniqueCode =
              profileResponses.customUserProfileFields[
                METADATA_KEY.PERSONAL_CODE
              ];
            this.user.unicode =
              profileResponses.customUserProfileFields[
                METADATA_KEY.METADATA_KEY_CODE
              ];
            this.user.promo =
              profileResponses.customUserProfileFields[
                METADATA_KEY.PERSONAL_PROMO
              ];
            const settings =
              profileResponses.customUserProfileFields[
                METADATA_KEY.PERSONAL_DYNAMIC_SETTINGS
              ];
            // WELCOME PROMO: Get user owner Type (iluma | duo)
            const userOwnedProdVersions =
              profileResponses.customUserProfileFields[
                METADATA_KEY.NUM_OF_KITS_PER_DEVICE_TYPE
              ];
            if (userOwnedProdVersions && userOwnedProdVersions != "") {
              try {
                let userType: string = "";
                const setJson = JSON.parse(userOwnedProdVersions);
                logger.log("User's devices: ", setJson);
                Object.keys(setJson).forEach(
                  (key) => (setJson[key] = parseInt(setJson[key], 10))
                );
                // iluma devices
                const iluma: number = setJson["ILUMA"] || 0;
                const ilumaPrime: number = setJson["ILUMA PRIME"] || 0;
                const ilumaOne: number = setJson["ILUMA ONE"] || 0;
                // duo & other devices
                const duo: number = setJson["DUO"] || 0;
                const duoPoint4: number = setJson["2.4"] || 0;
                const duoPoint4P: number = setJson["2.4P"] || 0;
                const iqosMulti: number = setJson["IQOS MULTI"] || 0;
                const iqosThree: number = setJson["IQOS 3"] || 0;
                const iqos: number = setJson["IQOS"] || 0;

                const lil: number = setJson["LIL"] || 0;
                const lilSolid: number = setJson["LIL SOLID 2.0"] || 0;

                switch (true) {
                  case iqos > 0:
                  case iqos === 0 && lil > 0 && iluma > 0:
                    userType = "DUO";
                    break;
                  case iqos === 0 && lil === 0 && iluma > 0:
                    userType = "ILUMA";
                    break;
                  case iqos === 0 && iluma === 0 && lil > 0:
                    userType = "DUO";
                    break;
                }

                // // conditions
                // const hasIluma: boolean =
                //   iluma > 0 || ilumaPrime > 0 || ilumaOne > 0;
                // const hasOtherDevice: boolean =
                //   duoPoint4 > 0 ||
                //   duoPoint4P > 0 ||
                //   iqosMulti > 0 ||
                //   iqosThree > 0;

                // switch (true) {
                //   case LIL > 0:
                //   case duo > 0:
                //   case duo == 0 && hasIluma && hasOtherDevice:
                //     userType = "DUO";
                //     break;
                //   case duo === 0 && hasIluma && !hasOtherDevice:
                //     userType = "ILUMA";
                //     break;
                // }
                this.user.ownerType = userType;
              } catch (ex) {
                logger.error(ex);
              }
            }
            if (settings && settings != "") {
              try {
                logger.log("SETTINGS", settings);
                const setJson = JSON.parse(settings);
                this.user.isCasting = setJson["castingCall"] == "true";
                this.user.loginActionCompleted =
                  setJson["loginActionCompleted"];
                this.user.firstActionCompleted =
                  setJson["firstActionCompleted"];
                this.user.firstVoucherPurchased =
                  setJson["firstVoucherPurchased"];
              } catch (ex) {
                logger.error(ex);
              }
            }
            const popup =
              profileResponses.customUserProfileFields[
                METADATA_KEY.POPUP_DISPLAYED
              ];
            if (popup) {
              try {
                this.user.popupDisplayed = JSON.parse(popup);
              } catch (ex) {}
            }
            localStorage.setItem("completedOnBoard", "1");
            const quiz =
              profileResponses.customUserProfileFields[
                METADATA_KEY.QUIZ_COMPLETED
              ];
            if (quiz) {
              try {
                this.user.quizCompleted = JSON.parse(quiz);
              } catch (ex) {}
            }
          }
          this.user.completedOnBoard =
            localStorage.getItem("completedOnBoard") === "1";
          // this.user.unlinked = true;
          resolve(profileResponses);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param {SsoAuthedicationInput} input_
   * @return {Promise<AuthenticateUserResponse>}
   */
  public ssoAuthentication(
    input_: SsoAuthedicationInput
  ): Promise<AuthenticateUserResponse> {
    return new Promise((resolve, reject) => {
      this.userApi
        .ssoAuthentication(
          Settings.GAME_TYPE,
          External_Application_SourceId.EXTERNAL_SYSTEM_USER,
          input_
        )
        .then((ssoResponses: AuthenticateUserResponse) => {
          if (_.isNil(ssoResponses)) {
            reject(null);
            return;
          }
          TAValues.UserId = ssoResponses.userId;
          TAValues.UserSessionId = ssoResponses.userSessionId;
          resolve(<AuthenticateUserResponse>{
            userId: ssoResponses.userId,
            userSessionId: ssoResponses.userSessionId,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param userId_
   */
  public getUnreadMessages(userId_: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const stats: GetStatisticsInput = {
        statKeys: [UNREAD_USER_MESSAGES],
      };

      await this.userApi
        .userStats(userId_, stats)
        .then((unReadStats) => {
          if (unReadStats && unReadStats.statistics) {
            const unreadMessage: MetadataDTO = unReadStats.statistics.find(
              (key) => key.key === GET_STATS.UNREAD_MESSAGE
            );
            this.unreadMessage = _.isNil(unreadMessage.value)
              ? false
              : +unreadMessage.value > 0;
            this.unreadMessageCount = _.isNil(unreadMessage.value)
              ? 0
              : +unreadMessage.value;
          } else {
            this.unreadMessage = false;
            this.unreadMessageCount = 0;
          }
          resolve(unReadStats);
        })
        .catch((err) => {
          this.unreadMessage = false;
          reject(err);
        });
    });
  }

  public getUserStats(
    userId_: string,
    input_?: GetStatisticsInput
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.userApi
        .userStats(userId_, input_)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param userId_
   */
  public getCouponCount(userId_: string): Promise<StatsReturn> {
    return new Promise<any>(async (resolve, reject) => {
      const stats: GetStatisticsInput = {
        statKeys: [COUPON_STATS_PER_GAME_TYPE],
      };

      this.userApi
        .userStats(userId_, stats)
        .then((unReadStats) => {
          resolve(unReadStats);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * @description get the uniq code.
   * @param input_
   */
  public getActionConstrain(
    input_: IActionConstraint
  ): Promise<ItemsList<ActionConstrainDTO[]>> {
    return new Promise((resolve, reject) => {
      this.userApi
        .actionConstraint(input_)
        .then((actionConstrain_: ItemsList<ActionConstrainDTO[]>) => {
          if (_.isNil(actionConstrain_) || actionConstrain_.count === 0) {
            reject(null);
            return;
          }
          resolve(actionConstrain_);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * @description we can choose what to get from the balances.
   * we can pass in the unitTypeId: [Unit_Types.IQOS_LEVEL_POINTS,Unit_Types.IQOS_POINTS]
   * and receive an array fro level and balances.
   * @param userId_
   * @param unitTypeId_
   */
  public getUserBalances(
    userId_: string,
    unitTypeId_: Array<Unit_Types>
  ): Promise<BalanceDTO[] | BalanceDTO> {
    return new Promise((resolve, reject) => {
      //HINT: here you can change the unitTypeId.
      const input_: IBalance = {
        gameTypeId: GAME_TYPE_ID,
        unitTypeId: unitTypeId_,
      };
      this.userApi
        .userBalance(userId_, input_)
        .then((userBalances: BalanceDTO[]) => {
          if (_.isNil(userBalances)) {
            reject(null);
            return;
          }

          const getLevelThreshold = (
            level: number,
            levelReferences: any
          ): number => {
            let res = 0;
            if (levelReferences && levelReferences.levels) {
              const l = levelReferences.levels.find((level_) => {
                return level_.index === level;
              });
              if (l) res = l.threshold;
            }
            return res;
          };

          if (userId_ == TAValues.UserId) {
            if (userBalances instanceof Array) {
              this.user.userBalances = userBalances;
            } else {
              this.user.userBalances.push(userBalances);
            }

            this.user.balance =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_POINTS,
                    "balance"
                  )
                : 0) || 0;
            this.user.balanceToShow = this.utilsSrv.integerToString(
              this.user.balance
            );
            this.user.balanceTotal =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_LEVEL,
                    "balance"
                  )
                : 0) || 0;
            this.user.balanceTotalToShow = this.utilsSrv.integerToString(
              this.user.balanceTotal
            );
            const balanceForNextLevel =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_LEVEL,
                    "pointsForNextLevel"
                  )
                : 0) || 0;
            this.user.balanceForNextLevel =
              this.utilsSrv.integerToString(balanceForNextLevel);
            this.user.statusLevel =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_LEVEL,
                    "level"
                  )
                : 0) || 0;
            this.user.badgeLevel =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_BADGE,
                    "level"
                  )
                : 0) || 0;

            const prevLevel =
              this.user.statusLevel > 2
                ? getLevelThreshold(this.user.statusLevel, this.levelReferences)
                : 0; // this.user.percentageForNextLevel = ((this.user.balanceTotal * 100) / (balanceForNextLevel + this.user.balanceTotal));

            const percentage =
              ((this.user.balanceTotal - prevLevel) * 100) /
              (this.user.balanceTotal + balanceForNextLevel - prevLevel);
            this.user.percentageForNextLevel = percentage;
            // this.user.percentageForNextLevel = this.user.userBalances ? this.utilsSrv.numberFromBalancesArray(this.user.userBalances, Unit_Types.IQOS_CLUB_IT_LEVEL, "percentageForNextLevel") : 0;
            this.user.iqosMoney =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQON_MONEY,
                    "balance"
                  )
                : 0) || 0;
            this.user.balanceExpiring =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_EXPIRING_POINTS,
                    "balance"
                  )
                : 0) || 0;
            this.user.balanceExpiringToShow = this.utilsSrv.integerToString(
              this.user.balanceExpiring
            );
            this.user.statusBalance =
              (this.user.userBalances
                ? this.utilsSrv.numberFromBalancesArray(
                    this.user.userBalances,
                    Unit_Types.IQOS_CLUB_IT_LEVEL,
                    "balance"
                  )
                : 0) || 0;
          }
          resolve(userBalances);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get User Balance Type
   * @param userId
   * @param queryParams
   * @param requireSession
   * @returns {Observable<Array<EWalletSummaryPerVmTransactionTypeTO>>}
   */
  public getUserBalanceType(
    userId: string,
    queryParams: BalanceTypeQ,
    requireSession: boolean = true
  ): Promise<Array<EWalletSummaryPerVmTransactionTypeTO>> {
    return new Promise<Array<EWalletSummaryPerVmTransactionTypeTO>>(
      (resolve, reject) => {
        this.userApi
          .userBalanceType(userId, queryParams, requireSession)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  }

  public getUserAchievements(
    userId: string,
    achievementIds: Array<string>,
    active: boolean = true,
    matchingLevel: boolean,
    includeDetails: boolean,
    resources: boolean,
    languageIds: Array<string>,
    metadatas: boolean,
    rangeFrom: number = 0,
    rangeTo: number = 10,
    filterOverlappingAchievements?: boolean
  ): Promise<ItemsList<BadgeDTO>> {
    return new Promise(async (resolve, reject) => {
      if (isNullOrUndefined(filterOverlappingAchievements))
        filterOverlappingAchievements = false;
      if (isNullOrUndefined(active)) active = true;
      if (!languageIds.length) resources = false;
      let payload: AchievementQ = {
        achievementIds: achievementIds,
        active: active,
        matchingLevel: matchingLevel,
        includeDetails: includeDetails,
        resources: resources,
        metadatas: metadatas,
        languageIds: languageIds,
        filterOverlappingAchievements: filterOverlappingAchievements,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
      };
      const _resp = await to(
        this.userApi.userAchievements(userId, payload, true)
      );
      if (isNullOrUndefined(_resp.data)) {
        reject(null);
      } else {
        resolve(_resp.data as ItemsList<BadgeDTO>);
      }
    });
  }

  /**
   *
   * @param userId_
   * @param input
   */
  public uploadImgProfile(userId_: string, input: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.userApi
        .uploadImgUser(userId_, input)
        .then((userImgProfile) => {
          if (_.isNil(userImgProfile)) {
            reject(null);
            return;
          }
          resolve(userImgProfile);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param input
   * @param requiredSession
   * @param header
   */
  public saveMetadata(
    input: SaveUserMetadataInput,
    requiredSession?: boolean,
    header?: HttpHeaders
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .saveMetadata(input)
        .then((saveMetadaResult) => {
          resolve(saveMetadaResult);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param {string} userId
   * @return {Promise<UserLevelsDTO>}
   */
  public getUserLevel(userId: string): Promise<UserLevelsDTO> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .getUserLevel(userId)
        .then((level) => {
          if (_.isNil(level)) {
            resolve({});
            return;
          }
          this.user.level = level;
          resolve(level);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @return {Promise<UserLevelsDTO>}
   */
  public getLevel(): Promise<UserLevelsDTO> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .getLevel()
        .then((level) => {
          if (_.isNil(level)) {
            resolve({});
            return;
          }
          this.user.level = level;
          resolve(level);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param userId
   * @param input_
   */
  public getHistoryTransaction(
    userId: string,
    input_: TransactionHistoryInput
  ): Promise<Array<EWalletDTO>> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .transactionsHistory(userId, input_)
        .then((historyTran) => {
          if (_.isNil(historyTran)) {
            resolve({});
            return;
          }
          resolve(historyTran);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public transactionsHistoryGroupBy(
    userId: string,
    input_: TransactionHistoryInput
  ): Promise<Array<TransactionGroupByGroupByDTO>> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .transactionsHistoryGroupBy(userId, input_)
        .then((historyTran) => {
          if (_.isNil(historyTran)) {
            resolve({});
            return;
          }
          resolve(historyTran);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @description unregister the user.
   */
  public unRegisterUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .unregisterUser(TAValues.UserId)
        .then((unRegister_) => {
          logger.log({ unRegister_: unRegister_ });
          resolve(unRegister_);
        })
        .catch((error) => {
          logger(error);
        });
    });
  }

  /**
   *
   * @param {User} user
   * @param {string} email
   * @param {string} newPassword
   * @param {boolean} requireSession
   * @param {string} sessionId
   * @param {string} firstName
   * @param {string} lastName
   * @param {string | NgbDateStruct } birthday
   * @param {boolean} allowCommunication
   * @returns {Observable<User>}
   */
  public updateMyProfile(
    user: User,
    email: string,
    birthday: string | NgbDateStruct,
    newPassword: string,
    allowCommunication: boolean,
    requireSession: boolean,
    sessionId: string = null,
    firstName?: string,
    lastName?: string
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      const userObj: UserProfileUpdateInput = {
        user: {
          userId: user.userId,
          username: user.username,
          nickname: user.nickname,
          birthday: user.birthday,
          email: user.email,
          allowCommunication: allowCommunication,
        },
        newPassword: newPassword,
      };
      userObj.user.customUserProfileFields = {};
      if (_.isNil(user.mobileNo) === false && user.mobileNo != "") {
        userObj.user.customUserProfileFields = {
          key: METADATA_KEY.USER_PHONE,
          value: user.mobileNo,
        };
      }
      if (_.isNil(firstName) === false && firstName != "") {
        userObj.user.customUserProfileFields = {
          key: METADATA_KEY.PERSONAL_NAME,
          value: firstName,
        };
      }
      if (_.isNil(email) === false && email != "") {
        userObj.user.customUserProfileFields = {
          key: METADATA_KEY.PERSONAL_EMAIL,
          value: email,
        };
      }
      if (_.isNil(lastName) === false && lastName != "") {
        userObj.user.customUserProfileFields = {
          key: METADATA_KEY.PERSONAL_SURNAME,
          value: lastName,
        };
      }

      this.userApi
        .updateMyProfile_(user.userId, userObj, requireSession)
        .then((userUpdate) => {
          logger.log(userUpdate);
          resolve(userUpdate);
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  public unsubscribeUser(
    user: User,
    allowCommunication: boolean,
    requireSession: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const userObj: UserProfileUpdateInput = {
        user: {
          userId: user.userId,
          allowCommunication: allowCommunication,
        },
        newPassword: null,
      };

      this.userApi
        .updateMyProfile_(user.userId, userObj, requireSession)
        .then((userUnsubscribe) => {
          logger.log(userUnsubscribe);
          resolve(userUnsubscribe);
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  public updatePicUrl(userId: string, picUrl: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const userObj: UserProfileUpdateInput = {
        user: {
          userId: userId,
          picUrl: picUrl,
        },
        newPassword: null,
      };

      this.userApi
        .updateMyProfile_(userId, userObj, true)
        .then((userUnsubscribe) => {
          logger.log(userUnsubscribe);
          resolve(userUnsubscribe);
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   * @description get the ref for the resources images and
   * we need to get from this the percentages.(with the balances.)
   */
  public getLevelRef(): Promise<IReferenceLevel> {
    return new Promise((resolve, reject) => {
      const input: IReferenceLevelInput = {
        resources: true,
        languageIds: TAConstants.Settings.LANGUAGES,
      };
      this.referenceApi
        .getReferenceLevels(environment.GAME_TYPE_ID, input)
        .then((result: Array<IReferenceLevel>) => {
          try {
            const referencesLevel: IReferenceLevel = result.find(
              (unitType) =>
                unitType.unitType.unitTypeId === Unit_Types.IQOS_CLUB_IT_LEVEL
            );

            //TODO: i need to get the level
            const levelOne: any = referencesLevel.levels.find(
              (index) => index.index === 1
            );
            const levelTwo: any = referencesLevel.levels.find(
              (index) => index.index === 2
            );
            const levelThree: any = referencesLevel.levels.find(
              (index) => index.index === 3
            );
            const levelFour: any = referencesLevel.levels.find(
              (index) => index.index === 4
            );
            const levelOneResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelOne, 1);
            const levelTwoResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelTwo, 2);
            const levelThreeResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelThree, 3);
            const levelFourResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelFour, 4);
            result.forEach((items) => {
              items.levelResources = [
                levelOneResources,
                levelTwoResources,
                levelThreeResources,
                levelFourResources,
              ];
            });
            const refItemsLevel = result.find(
              (unitType) =>
                unitType.unitType.unitTypeId === Unit_Types.IQOS_CLUB_IT_LEVEL
            );
            this.levelReferences = refItemsLevel;
            resolve(refItemsLevel);
          } catch (err) {
            logger.log(err);
            reject(err);
          }
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   *
   * @param item
   * @param level_
   */
  private setImagesLevelsActiveInactive(
    item: any,
    level_: number
  ): ILevelImage {
    const levelOneActive = item.resources.find(
      (resourcesTypeId) =>
        resourcesTypeId.resourceTypeId === LEVELS_RESOURCES_TYPE_ID.ACTIVE
    );
    const levelOneInactive = item.resources.find(
      (resourcesTypeId) =>
        resourcesTypeId.resourceTypeId === LEVELS_RESOURCES_TYPE_ID.INACTIVE
    );

    return <ILevelImage>{
      levelImageActive: levelOneActive.textResource,
      levelImageInactive: levelOneInactive.textResource,
      level: level_,
    };
  }

  /**
   *
   * @param message
   */
  public deleteMessage(message: IMessageCategory): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const input_: DeleteMessageInput = {
        messageIds: [message.id],
      };

      this.messageApi
        .deleteMessages(TAValues.UserId, input_)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateUserStatus(hasPending?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const userBalancesPrm = this.getUserBalances(TAValues.UserId, [
        Unit_Types.IQOS_CLUB_IT_POINTS,
        Unit_Types.IQOS_CLUB_IT_LEVEL,
        Unit_Types.IQON_MONEY,
        Unit_Types.IQOS_CLUB_IT_EXPIRING_POINTS,
        Unit_Types.IQOS_CLUB_IT_BADGE,
      ]);
      // const levelPrm = this.userService.getLevel();
      const userLevelPrm = this.getUserLevel(TAValues.UserId);
      if (!hasPending) {
        Promise.all([userBalancesPrm, userLevelPrm]).then((arrayOfResults) => {
          this.setupTopBannerGeneric()
            .then((res) => {})
            .catch((err) => {});
          resolve(arrayOfResults);
        }, reject);
      } else {
        const pPQ: PendingPointsQ = {
          rangeFrom: 0,
          rangeTo: 1,
          excludeZeroAmounts: true,
        };
        const userPendingPointsPrm = this.getPendingPoints(
          TAValues.UserId,
          pPQ
        );
        Promise.all([userBalancesPrm, userLevelPrm, userPendingPointsPrm]).then(
          (arrayOfResults) => {
            this.setupTopBannerGeneric()
              .then((res) => {})
              .catch((err) => {});
            resolve(arrayOfResults);
          },
          reject
        );
      }
    });
  }

  /**
   *
   * @param {string} userId
   * @param {ExtSystemInfoDTO} external
   * @return {Promise<any>}
   */
  public saveExternalUser(
    userId: string,
    external: ExtSystemInfoDTO
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .saveExternalUser(userId, external)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {GuestInfoInput} input
   * @return {Promise<any>}
   */
  public createGuest(
    input: GuestInfoInput,
    headers: HttpHeaders = null
  ): Promise<AuthenticateUserResponse> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .createGuest(input, false, headers)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Upgrade Guest User
   * @param {NewUserInput} input
   * @return {Promise<any>}
   */
  public upgradeUser(input: NewUserInput, sessionId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append(TAConstants.TAHeaders.SESSION_ID, sessionId);
      this.userApi
        .upgradeUser(input, headers)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {string} username
   * @return {Promise<any>}
   */
  public checkUsername(username: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .checkUsername(username)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get Indexed Users
   * @param {Array<ProfileFilter>} profileFilters
   * @param {boolean} guest
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<any>}
   */
  public getIndexedUsers(
    profileFilters: Array<ProfileFilter>,
    guest: boolean,
    rangeFrom: number,
    rangeTo: number
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .getIndexedUsers(profileFilters, guest, rangeFrom, rangeTo)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get Indexed Data New
   * @param {FilterUsersIndexedDataInput} input
   * @return {Promise<any>}
   */
  public getIndexedUsersNew(
    input?: FilterUsersIndexedDataInput
  ): Promise<Array<ESUserDTO>> {
    return new Promise<Array<ESUserDTO>>((resolve, reject) => {
      this.userApi
        .getIndexedUsersnew(input)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {string} userId
   * @param {PurchaseCouponInput} input
   * @return {Promise<any>}
   */
  public purchaseCoupon(
    userId: string,
    input: PurchaseCouponInput
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .purchaseCoupon(userId, input)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Conver Balance
   * @param {string} userId
   * @param {ConvertInput} input
   * @return {Promise<any>}
   */
  public convertBalance(userId: string, input: ConvertInput): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .convertBalance(userId, input)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get Pending Points
   * @param {string} userId
   * @param {PendingPointsQ} queryParams
   * @return {Promise<UserPendingPointsDTO>}
   */
  public getPendingPoints(
    userId: string,
    queryParams: PendingPointsQ,
    updateFields: boolean = true
  ): Promise<UserPendingPointsDTO> {
    return new Promise<any>((resolve, reject) => {
      // this.user.pendingBalanceToShow = "0";
      // resolve({pendingPoints:[], balance:"0"});
      this.userApi
        .getPendingPoints(userId, queryParams)
        .then((result) => {
          if (updateFields) {
            this.user.pendingBalance =
              result && result.balance && result.balance != ""
                ? Number(result.balance)
                : 0;
            this.user.pendingBalanceToShow = this.utilsSrv.integerToString(
              Number(
                result && result.balance && result.balance != ""
                  ? result.balance
                  : "0"
              )
            );
          }
          resolve(result);
        })
        .catch((error) => {
          resolve({});
        });
    });
  }

  public getMaxObtainablePoints(): Promise<number> {
    return new Promise((resolve, reject) => {
      const actionConstraintId =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.ACTION_CONSTRAIN_ID];

      this.userApi
        .getMaxObtainablePoints(TAValues.UserId, actionConstraintId)
        .then((res: UserMaxObtainablePointsDTO) => {
          // calculate max pending points to show:
          // for rsp the actionConstraint responded, rsp.count - rsp.userCounter = maxPendingPoints client can show
          !!res ? resolve(res[0].count - res[0].userCounter) : resolve(null);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Tranfer Currency
   * @param {string} userId
   * @param {SendVirtualAmountInput} input
   * @return {Promise<any>}
   */
  public tranferCurrency(
    userId: string,
    input: SendVirtualAmountInput
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userApi
        .tranferCurrency(userId, input)
        .then((result) => {
          this.updateUserStatus()
            .then((result) => logger.log(result))
            .catch((err) => logger.log(err));
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public setupTopBannerGeneric(): Promise<boolean> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.user.unlinked || !this.promoConfig) {
        resolve(false);
        return;
      }

      const topBannerCategoryId = this.user.topBannerCategoryId;
      const topBannerConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.TOP_BANNER_CONFIG] ||
        {};

      if (topBannerConfig) {
        let categoryId = topBannerConfig[String(this.user.level.level)];
        // categoryId = !this.user.hasBought ? topBannerConfig['1000'] : topBannerConfig[String(this.user.level.level)];
        if (this.promoConfig.hasReferral) {
          if (
            !this.promoConfig.promoBought &&
            !this.promoConfig.referralPromoBought
          ) {
            categoryId = topBannerConfig["1001"];
          }
        } else {
          if (!this.promoConfig.promoBought && !this.promoConfig.hasBought) {
            categoryId = topBannerConfig["1000"];
          }
        }
        if (
          categoryId &&
          categoryId != "" &&
          this.user.level.level == 2 &&
          this.user.balance == 0
        ) {
          if (categoryId == topBannerCategoryId) return;
          const promoUploadContents =
            await this.uploadContentsSrv.getUploadContentByCategoryUploadContentType(
              [categoryId],
              UPLOAD_CONTENT_TYPE.GENERAL,
              true,
              TAConstants.Settings.LANGUAGES,
              true,
              null,
              null,
              0,
              1
            );

          if (promoUploadContents && promoUploadContents.length > 0) {
            const topBanner = promoUploadContents[0];
            this.appGlobalsSrv.topBanner = {
              title: this.resourcesSrv.getResourcesBasic(
                topBanner,
                Resource_Types.NAME
              ),
              action: this.resourcesSrv.getResourcesBasic(
                topBanner,
                Resource_Types.ACTION
              ),
              color: this.utilsSrv.valueFromKeyValueDTOArray(
                METADATA_KEY.COLOR,
                topBanner.metadata
              ),
            };
          }
          this.user.topBannerCategoryId = categoryId;
          resolve(true);
          return;
        }
      }

      const mainBannerCategoryId =
        this.appGlobalsSrv.config[
          APPLICATION_SETTING_KEYS.MAIN_BANNER_CATEGORY_ID
        ] || null;
      if (mainBannerCategoryId) {
        if (mainBannerCategoryId == topBannerCategoryId) return;
        const input: FilterESContentDataInput = {
          itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT],
          categoryIds: [String(mainBannerCategoryId)],
          rangeFrom: 0,
          rangeTo: -1,
          sortOrderTypes: [Order_Stores.BY_ORDERING_ASC],
          currentlyActive: true,
          matchingRules: true,
        };
        const promoUploadContents = await this.recommendationsSrv
          .getRecommendationContent(TAValues.UserId, input, {
            resources: true,
            languageIds: TAConstants.Settings.LANGUAGES,
            metadatas: true,
          })
          .catch((err) => {});

        if (promoUploadContents && promoUploadContents.length > 0) {
          const topBanner = promoUploadContents[0];
          this.appGlobalsSrv.topBanner = {
            title: this.resourcesSrv.getResourcesBasic(
              topBanner,
              Resource_Types.NAME
            ),
            action: this.resourcesSrv.getResourcesBasic(
              topBanner,
              Resource_Types.ACTION
            ),
            color: this.utilsSrv.valueFromKeyValueDTOArray(
              METADATA_KEY.COLOR,
              topBanner.metadata
            ),
          };
          this.user.topBannerCategoryId = mainBannerCategoryId;
          resolve(true);
          return;
        }
      }

      resolve(false);
    });
  }
} //END CLASS
