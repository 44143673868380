import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { UtilsService } from "../services/utils.service";
import { AppGlobalsService } from "../services/appGlobals.service";
import { InitService } from "../services/talos/init.service";
import { QrSurveyService } from "./pages/qr-survey/qr-survey.service";
import { UserService } from "../services/talos/user.service";
import { Location } from "@angular/common";
import { CachingService } from "../services/caching.service";

const BASE_URL = "https://club.it.iqos.com/pub/iqosclub/it";
// const BASE_URL = 'https://staging.mystartapp.org/pub/loyalty/iqosclubv3';

@Injectable({
  providedIn: "root",
})
export class AuthCastingGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    public appGlobals: AppGlobalsService,
    public initSrv: InitService,
    private myRoute: Router,
    private utilsSrv: UtilsService,
    public activatedRoute: ActivatedRoute,
    @Inject(Location) private readonly location: Location,
    public qrSurveySrv: QrSurveyService,
    public userSrv: UserService,
    private cachingSrv: CachingService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const value = next.url.join("/");
    let tmp = `${this.location.path().split("?")[0]}`;
    const mustCheck =
      value.indexOf("community") >= 0 || value.indexOf("casting") >= 0;
    if (this.auth.isLoggednIn()) {
      if (mustCheck) {
        if (this.appGlobals.wyng) {
          window.open(`${BASE_URL}/${value}`, "_parent");
          return;
        }
        this.appGlobals.wyng = true;
      }

      const href = next.url.join("/");
      // var myCustomData = { foo: 'bar' }
      // var event = new CustomEvent('iframeChanged', { detail: myCustomData })
      // window.parent.document.dispatchEvent(event)
      window.scroll(0, 0);

      //Just in case
      var views = document.getElementsByTagName("html");
      if (views) {
        for (var i = 0; i < views.length; i++) {
          views[i].setAttribute("class", "");
        }
      }

      // window.parent.postMessage({'id':'iframeChanged'}, '*');
      this.initSrv.removeMenu = false;
      this.cachingSrv.checkIfMustRemoveCache(href);
      return true;
    } else {
      this.appGlobals.redirectUrl = `${tmp}${window.location.hash}`;
      let token_path = `${this.location.path().split("?")[1]}`;
      const vars = token_path.split("&");
      let token = "";
      let iv = "";
      let lp_code = "";
      let utm_medium = "";
      let queryParams = {};
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        queryParams[pair[0]] = decodeURIComponent(decodeURIComponent(pair[1]));
        // if (decodeURIComponent(pair[0]) == 'token') {
        //     token = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
        // if (decodeURIComponent(pair[0]) == 'iv') {
        //     iv = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
        // if (decodeURIComponent(pair[0]) == 'lp_code') {
        //     lp_code = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
        // if (decodeURIComponent(pair[0]) == 'utm_medium') {
        //     utm_medium = decodeURIComponent(decodeURIComponent(pair[1]));
        // }
      }
      this.myRoute.navigate(["/"], {
        replaceUrl: false,
        queryParamsHandling: "merge",
        queryParams: queryParams,
      });
      return false;
    }
  }
}
