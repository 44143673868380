/*
 * @Author: Peter Fousteris (petfoust@gmail.com) 
 * @Date: 2019-01-17 11:25:15 
 * @Last Modified by: Peter Fousteris (petfoust@gmail.com)
 * @Last Modified time: 2019-01-17 12:49:45
 */

import { IkpDictionary } from './interface/kp.dictionary.interface';

/**
 * Kp dictionary
 * @template T 
 */
export class KpDictionary<T> implements IkpDictionary<T> {
    /** 
     * 
     * Properties. 
     * 
    */
   
    /**
     * Object items
     * Items of the Dictionary.
     */
    private items: { [index: string]: T } = {};

    /**
     * number nItems
     * Counter of the Items on the Dictionary.
     */
    private nItems: number = 0;

    /**
     * number Count
     * Count items of the Dictionary
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.count?view=netcore-2.2
     */
    readonly Count = (): number => {
        return this.nItems;
    };

    /**
     * Array<string> Keys
     * List all Keys of the Dictionary
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.keys?view=netcore-2.2
     */
    readonly Keys = (): Array<string> => {
        if (!this.Count) return [];
        let __set: Array<string> = Array();
        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                __set.push(prop);
            }
        }
        return __set;
    }
     
    /**
     * Array<T> Values
     * List all Values of the Dictionary
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.values?view=netcore-2.2
     * @returns values 
     */
    readonly Values = (): Array<T> => {
        if (!this.Count) return [];
        let __set: Array<T> = [];
        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                __set.push(this.items[prop]);
            }
        }
        return __set;
    }

    readonly Item = (key: string): T => {
        if (!this.ContainsKey(key)) return null;
        return this.items[key];
    }

    /** 
     * 
     * Methods. 
     * 
    */

    /**
     * void Add(key: string, value: T) 
     * Adds new Item to the Dictionary
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.add?view=netframework-4.7.2
     * @param key 
     * @param value 
     */
    public Add(key: string, value: T): void {
        if (this.isNullOrUndefined(key)) throw TypeError;
        if(this.items.hasOwnProperty(key)) return;
        this.nItems += 1;
        this.items[key] = value;
    }
 
    /**
     * void Clear()
     * Clears the Dictionary
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.clear?view=netframework-4.7.2
     */
    public Clear(): void {
        if (!this.Count()) return;
        this.items = {};
        this.nItems = 0;
    }

    /**
     * boolean ContainsKey(key: string)
     * Determines whether the Dictionary contains certain a Key
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.containskey?view=netframework-4.7.2
     * @param key 
     */
    public ContainsKey(key: string): boolean {
        if (this.isNullOrUndefined(key)) throw TypeError;
        return this.items.hasOwnProperty(key);
    }

    /**
     * boolean ContainsValue(value: T)
     * Determines whether the Dictionary contains certain a Value
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.containsvalue?view=netframework-4.7.2
     * @param value 
     * @returns true if value 
     */
    public ContainsValue(value: T): boolean {
        if (!this.Count()) return false;
        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                if (this.items[prop] === value) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Removes kp dictionary
     * https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2.remove?view=netframework-4.7.2
     * @param key 
     * @returns remove 
     */
    public Remove(key: string): boolean {
        if (!this.ContainsKey(key)) return false;
        try {
            delete this.items[key];
            this.nItems -= 1;
            return true;
        } catch {
            return false;
        }
    }

    private isNullOrUndefined<T>(obj?: T | null): boolean {
        // null == undefined so this is true if obj = null or obj = undefined
        return obj == null;
      }

}