import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { environment as E} from '../environments/environment';
import {TAValues} from "../talosApi/settings";


@Injectable({
  providedIn: 'root'
})
export class AuthService  {
    constructor(private myRoute: Router, private http: HttpClient) { }

    // async login(body) {
    //     const headers = { headers: {
    //         'X-Talos-Application-Id': E.APPLICATION_ID,
    //         'Content-Type': 'application/json',
    //         'Accept-Language': 'en'
    //      }};
    //     const url = E.URL + 'users/authenticate';
    //     const resp =  await this.http.post(url, body, headers).toPromise();
    //     localStorage.setItem( 'LoggedInUser' , JSON.stringify(resp));
    // }
    getToken() {
        return localStorage.getItem('LoggedInUser');
    }
    isLoggednIn() {
        return (TAValues.UserId != undefined && TAValues.UserSessionId != undefined)
        //return this.getToken() !== null;
    }
    // logout() {
    //     localStorage.removeItem('LoggedInUser');
    //     this.myRoute.navigate(['']);
    // }
    //
    // // Fetch Application Settings Run First
    // // And if Applications Setting procced to login
    // async prefetch() {
    //     const url = E.URL + 'appsettings/app/' + E.APPLICATION_ID;
    //     const resp = await this.http.get(url).toPromise();
    //     this.initApplicationSettings(resp);
    //     return resp;
    // }
    // initApplicationSettings(settings): void {
    //     settings.forEach(setting => {
    //     });
    // }
}
