import {Injectable} from "@angular/core";
import {Http2Talos} from "../http2Talos";

@Injectable()
export class TokenApi {

    /**
     * Creates an instance of token api.
     * @param http2Talos 
     */
    constructor(
        private http2Talos: Http2Talos
    ) {}

    public generateToten(payload: TokenGenerateInput): Promise<TokenGenerateOutput> {
        return this.http2Talos.post({
            path: '/tokens', 
            input: payload, 
            requireSession: true
        });
    }
   
    public getToken(tokenId: string): Promise<any> {
        return this.http2Talos.get({
            path: `/tokens/${tokenId}`, 
            requireSession: true
        });
    }

    public useToken(tokenId: string): Promise<any> {
        return this.http2Talos.post({
            path: `/tokens/${tokenId}`, 
            requireSession: true
        });
    }
}

export interface TokenGenerateInput {
    itemId: string;
    itemTypeId: string;
    medium: string;
    userId: string;
    auto: string;
    actionType: string;
}

export interface TokenGenerateOutput {
    token: string;
}