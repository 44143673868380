import { Injectable } from "@angular/core";

/**
 * Injectable
 */
@Injectable()
export class GoBackService {

    private data: PreviewsPageData | null = null;

    constructor() { }

    public setPreviewPageData(input: PreviewsPageData | null): void {
        this.data = input;
    }

    public getPreviewPageData(): PreviewsPageData | null {
        return this.data;
    }
}

export type PreviewsPageData = {
    route: string;
    label?: string;
    page?: number | null;
    /* Additional Properties Per View. */
    [key: string]: any;
}