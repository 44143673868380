import {Injectable} from "@angular/core";
import {UserService} from "../../../services/talos/user.service";
import {EventService} from "../../../services/talos/event.service";
import {KeyValueDTO} from "../../../talosApi/models/KeyValueDTO";
import {SaveUserMetadataInput} from "../../../talosApi/models/SaveUserMetadataInput";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import {IEventInput} from "../../../talosApi/api/EventApi";
import Settings = TAConstants.Settings;
import Events_Types = TAConstants.Events_Types;
import {StatsReturn} from "../../../talosApi/api/UserApi";
import COUPON_STATUSES = TAConstants.COUPON_STATUSES;
import {IListUserBrandProductCoupons} from "../../../talosApi/api/BrandApi";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import {AppGlobalsService} from "../../../services/appGlobals.service";
import TAHeaders = TAConstants.TAHeaders;
import {BrandsService} from "../../../services/talos/brands.service";
import * as _ from "lodash";
import {GroupByDTO} from "../../../talosApi/models/GroupByDTO";
import {ReferralService} from "../../../services/talos/referral.service";
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;
import {GetGroupByReferrals} from "../../../talosApi/api/ReferralApi";

@Injectable()
export class IqosMasterService {
    inviteConfig;

    constructor(private userSrv: UserService, private eventSrv: EventService, public appGlobalsSrv: AppGlobalsService, public brandsSrv: BrandsService,
                private referralSrv: ReferralService) {
        if (appGlobalsSrv.config) {
            this.inviteConfig = appGlobalsSrv.config[APPLICATION_SETTING_KEYS.INVITE_CONFIG] || null;
        }
    }

    getInviteConfig() {
        if (this.inviteConfig)
            return this.inviteConfig;

        if (this.appGlobalsSrv.config)
            this.inviteConfig = this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.INVITE_CONFIG] || null;

        return this.inviteConfig
    }

    getCouponsCount(): Promise<number> {
        return new Promise((resolve, reject) => {

            this.userSrv.getCouponCount(TAValues.UserId).then((result) => {
                logger.log(result);

                if (_.isNil(result)) {
                    resolve(0);
                    return;
                }

                let count = 0;

                if (result.statistics) {
                    result.statistics.forEach(s => {
                        if (s.key == String(COUPON_STATUSES.REDEEMED)) {
                            count = Number(s.value);
                        }
                    })
                }

                resolve(count);
            }).catch(err => {
                reject(err);
            })
        })
    }

    getCouponsCountNew(): Promise<Array<GroupByDTO>> {
        return new Promise((resolve, reject) => {
            const params: GetGroupByReferrals = {
                referralTypeIds: [
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_IQOS_CLUB_ONE_TO_MANY,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ON_BEHALF_LENDING_ONE_TO_MANY,
                    REFERRAL_TYPES.IQOS_CLUB_ITALY_COUPON_LEAD_ONE_TO_MANY,
                    REFERRAL_TYPES.LENDING_REFERRAL
                ], groupByType: "BY_TYPE",
                referralStatusIds: [2, 3, 4, 6]
            };
            this.referralSrv.groupByReferrals(TAValues.UserId, params)
                .then((result: Array<GroupByDTO>) => {
                    resolve(result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    saveIqosMaster(metadata: KeyValueDTO[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const input: SaveUserMetadataInput = {userMetadataToSave: metadata, userId: TAValues.UserId}
            this.userSrv.saveMetadata(input, true).then((result) => {
                const eventInput: IEventInput = {
                    userId: TAValues.UserId,
                    applicationId: TAValues.APPLICATION_ID,
                    gameTypeId: Settings.GAME_TYPE_ID,
                    eventTypeId: Events_Types.CHALLENGE_ACCEPTED,
                    eventProperties: "",
                    withNoRewards: false,
                    clientTypeId: TAValues.CLIENT_TYPE_ID
                };
                this.eventSrv.addEvent(eventInput, true).then((res) => {
                    resolve();
                })
            }).catch(err => {
                reject(err);
            })
        });
    }
}