import { Http2Integration } from "../http2Integration";
import { Injectable } from "@angular/core";
import { ReferResponse } from "../models/ReferResponse";

@Injectable()
export class ReferralApi {
  constructor(private http2Integration: Http2Integration) {}

  /**
   * Create Referral
   * @param {CreateReferralInput} input
   * @return {Promise<ReferResponse>}
   */
  public createReferral(input: CreateReferralInput): Promise<ReferResponse> {
    return this.http2Integration.post({
      path: `/process/referral/it/create`,
      input: input,
    });
  }
}

export interface CreateReferralInput {
  customerEmail?: string;
  customerMobileNumber?: string;
  customerName?: string;
  customerSurname?: string;
  channel?: string;
  leadKeyword?: string;
  gfUserId?: string;
}
