import { Injectable } from "@angular/core";
import { UserService } from "../../../services/talos/user.service";
import { FilterUsersIndexedDataInput } from "../../../talosApi/models/FilterUsersIndexedDataInput";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import Settings = TAConstants.Settings;
import { SendVirtualAmountInput } from "../../../talosApi/models/SendVirtualAmountInput";
import User_Status = TAConstants.User_Status;
import { ESUserDTO } from "../../../talosApi/models/ESUserDTO";
import {
  ConvertToTournamentParticipationsInput,
  GetTournamentsQuery,
  GetUserTournamentStatisticsQuery,
} from "../../../talosApi/api/TournamentsApi";
import { TournamentDTOS } from "../../../talosApi/models/TournamentDTOS";
import { TournamentsService } from "../../../services/talos/tournaments.service";
import Resource_Types = TAConstants.Resource_Types;
import { ResourcesServices } from "../../../services/talos/resources.services";
import { UtilsService } from "../../../services/utils.service";
import METADATA_KEY = TAConstants.METADATA_KEY;
import { TournamentUserStatisticsPerTxTypeDTO } from "../../../talosApi/models/TournamentUserStatisticsPerTxTypeDTO";

@Injectable()
export class DonateService {
  constructor(
    private userSrv: UserService,
    private tournamentsSrv: TournamentsService,
    private resourcesSrv: ResourcesServices,
    private utilsSrv: UtilsService
  ) {}

  getTournament(
    query: GetTournamentsQuery,
    statistics: boolean
  ): Promise<DonateSection> {
    return new Promise<DonateSection>(async (resolve, reject) => {
      const result = await this.tournamentsSrv
        .getTournaments(query)
        .catch((err) => {});
      if (result && result.length > 0) {
        const tournament: DonateSection = result[0];
        tournament.donerCount = 0;
        tournament.doneeAmount = 0;
        tournament.donerCurrentCount = 0;
        tournament.donerCurrentAmount = 0;
        tournament.title = this.resourcesSrv.getResourcesBasic(
          tournament,
          Resource_Types.TITLE
        );
        tournament.description = this.resourcesSrv.getResourcesBasic(
          tournament,
          Resource_Types.DESCRIPTION
        );
        tournament.category = this.resourcesSrv.getResourcesBasic(
          tournament,
          Resource_Types.REWARD_DESCRIPTION
        );
        tournament.totalLabel = this.resourcesSrv.getResourcesBasic(
          tournament,
          Resource_Types.TYPE_TEXT
        );
        tournament.image = {
          path: this.resourcesSrv.getResourcesBasic(
            tournament,
            Resource_Types.IMAGE
          ),
          altText: "",
          default: "",
        };
        tournament.imageMobile = {
          path: this.resourcesSrv.getResourcesBasic(
            tournament,
            Resource_Types.MESSAGE_IMAGE
          ),
          altText: "",
          default: "",
        };
        tournament.action = this.resourcesSrv.getResourcesBasic(
          tournament,
          Resource_Types.ACTION_TYPE
        );
        tournament.actionLabel = this.resourcesSrv.getResourcesBasic(
          tournament,
          Resource_Types.ACTION_TYPE_DESCRIPTION
        );
        if (tournament.metadata && tournament.metadata.length > 0) {
          tournament.removeDonate =
            this.utilsSrv.valueFromKeyValueDTOArray(
              METADATA_KEY.IS_FEARUTE,
              tournament.metadata
            ) == "false";
          tournament.systemUser = this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.TOURNAMENT_TARGET_USER_ID,
            tournament.metadata
          );
          const doner = this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.TOURNAMENT_USER_VM_TRANSACTION_LIMITS,
            tournament.metadata
          );
          try {
            tournament.donerLimitations =
              doner && doner != "" ? JSON.parse(doner) : null;
            if (
              tournament.donerLimitations &&
              tournament.donerLimitations.length > 0
            ) {
              tournament.donerCount =
                tournament.donerLimitations[0].maxTournamentTransactionsCount;
              // tournament.donerAmount = tournament.donerLimitations[0].totalAmountLimit;
            }
          } catch (err) {}
          const donee = this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.TOURNAMENT_TARGET_USER_VM_TRANSACTION_LIMITS,
            tournament.metadata
          );
          try {
            tournament.doneeLimitations =
              donee && donee != "" ? JSON.parse(donee) : null;
            if (
              tournament.doneeLimitations &&
              tournament.doneeLimitations.length > 0
            ) {
              tournament.doneeAmount =
                tournament.doneeLimitations[0].totalAmountLimit;
            }
          } catch (err) {}
        }

        const statQuery: GetUserTournamentStatisticsQuery = {
          tournamentIds: [tournament.tournamentId],
          groupByType: "BY_TRANSACTION_TYPE",
        };
        const statResult = await this.tournamentsSrv
          .getUserTournamentStatistics(
            statistics ? TAValues.UserId : tournament.systemUser,
            statQuery
          )
          .catch((err) => {});
        if (statResult) {
          statResult.forEach((s) => {
            if (s.statisticsByTxType && s.statisticsByTxType.length > 0) {
              const stat: TournamentUserStatisticsPerTxTypeDTO =
                s.statisticsByTxType.find(
                  (t: TournamentUserStatisticsPerTxTypeDTO) => {
                    return t.transactionTypeId == 72;
                  }
                );
              if (stat) {
                tournament.donerCurrentCount = stat.count;
              }
              const total: TournamentUserStatisticsPerTxTypeDTO =
                s.statisticsByTxType.find(
                  (t: TournamentUserStatisticsPerTxTypeDTO) => {
                    return t.transactionTypeId == 75;
                  }
                );
              if (!statistics) {
                tournament.systemTotal = 0;

                if (total && total.amounts) {
                  tournament.systemTotal = total.amounts.amount;
                }
              } else {
                tournament.donerCurrentAmount = 0;

                if (total && total.amounts) {
                  tournament.donerCurrentAmount = total.amounts.amount;
                }
              }
            }
          });
        }

        resolve(tournament);
        return;
      }
      resolve(null);
    });
  }

  async updateCount(tournament: DonateSection, system?: boolean): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const statQuery: GetUserTournamentStatisticsQuery = {
        tournamentIds: [tournament.tournamentId],
        groupByType: "BY_TRANSACTION_TYPE",
      };
      const statResult = await this.tournamentsSrv
        .getUserTournamentStatistics(
          system ? tournament.systemUser : TAValues.UserId,
          statQuery
        )
        .catch((err) => {});
      if (statResult) {
        statResult.forEach((s) => {
          if (s.statisticsByTxType && s.statisticsByTxType.length > 0) {
            const stat: TournamentUserStatisticsPerTxTypeDTO =
              s.statisticsByTxType.find(
                (t: TournamentUserStatisticsPerTxTypeDTO) => {
                  return t.transactionTypeId == 72;
                }
              );
            if (stat) {
              tournament.donerCurrentCount = stat.count;
            }
            const total: TournamentUserStatisticsPerTxTypeDTO =
              s.statisticsByTxType.find(
                (t: TournamentUserStatisticsPerTxTypeDTO) => {
                  return t.transactionTypeId == 75;
                }
              );
            if (system) {
              tournament.systemTotal = 0;

              if (total && total.amounts) {
                tournament.systemTotal = total.amounts.amount;
              }
            } else {
              tournament.donerCurrentAmount = 0;

              if (total && total.amounts) {
                tournament.donerCurrentAmount = total.amounts.amount;
              }
            }
          }
        });
      }

      resolve(statResult);
    });
  }

  async getUserStatistics(
    userId: string,
    query: GetUserTournamentStatisticsQuery
  ): Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      let result = 0;
      const statResult = await this.tournamentsSrv
        .getUserTournamentStatistics(userId, query)
        .catch((err) => {});
      if (statResult) {
        statResult.forEach((s) => {
          if (s.statisticsByTxType && s.statisticsByTxType.length > 0) {
            const stat: TournamentUserStatisticsPerTxTypeDTO =
              s.statisticsByTxType.find(
                (t: TournamentUserStatisticsPerTxTypeDTO) => {
                  return t.transactionTypeId == 75;
                }
              );
            if (stat && stat.amounts) {
              result = stat.amounts.amount;
            }
          }
        });
      }
      resolve(result);
    });
  }

  async getUserByEmail(email: string): Promise<Array<ESUserDTO>> {
    const input: FilterUsersIndexedDataInput = {
      gameTypeId: Settings.GAME_TYPE_ID.toUpperCase(),
      email: email,
      userStatusIds: [User_Status.ACTIVE],
      rangeFrom: 0,
      rangeTo: 2,
    };
    // const result = await this.userSrv.getIndexedUsersNew(input).catch(err => {
    // });
    // if (!result || result.length != 1) {
    //     return null;
    // }
    //
    //
    return this.userSrv.getIndexedUsersNew(input);
  }

  async getUserByMobile(mobile: string): Promise<Array<ESUserDTO>> {
    return new Promise<Array<ESUserDTO>>(async (resolve, reject) => {
      if (mobile.startsWith("+")) {
        mobile = mobile.substr(1, mobile.length);
      }
      if (mobile.startsWith("00")) {
        mobile = mobile.substr(2, mobile.length);
      }
      if (mobile.startsWith("39")) {
        mobile = mobile.substr(2, mobile.length);
      }
      const indexPromise = (
        input: FilterUsersIndexedDataInput
      ): Promise<Array<ESUserDTO>> => {
        return this.userSrv.getIndexedUsersNew(input);
      };
      const phones = [mobile, "39" + mobile, "0039" + mobile, "+39" + mobile];
      const promises = phones.map((p) => {
        const input: FilterUsersIndexedDataInput = {
          gameTypeId: Settings.GAME_TYPE_ID.toUpperCase(),
          mobileNumber: p,
          userStatusIds: [User_Status.ACTIVE],
          rangeFrom: 0,
          rangeTo: 2,
        };
        return indexPromise(input);
      });

      const result = await Promise.all(promises).catch((err) => {});
      if (!result) {
        reject(null);
        return;
      }
      const users = [];
      result.forEach((r) => {
        if (r) users.push(...r);
      });
      resolve(users);

      // this.userSrv.tranferCurrency(TAValues.UserId, input).then(res => {
      //     resolve(true);
      // }).catch(err => {
      //     reject(err);
      // });
    });
  }

  public async tranferCurrency(
    input: SendVirtualAmountInput
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.userSrv
        .tranferCurrency(TAValues.UserId, input)
        .then((res) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async transferConvert(
    tournamentId: string,
    input: ConvertToTournamentParticipationsInput
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.tournamentsSrv
        .convertTournamentPoints(
          TAValues.UserId.toUpperCase(),
          tournamentId,
          input
        )
        .then(async (res) => {
          await this.userSrv
            .updateUserStatus()
            .then((result) => logger.log(result))
            .catch((err) => logger.log(err));
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export interface DonateSection extends TournamentDTOS {
  title?: string;
  description?: string;
  category?: string;
  imageMobile?: {
    path?: string;
    altText?: string;
    default?: string;
  };
  image?: {
    path?: string;
    altText?: string;
    default?: string;
  };
  totalLabel?: string;
  donerLimitations?: Array<{
    totalAmountLimit?: number;
    maxTournamentTransactionsCount?: number;
  }>;
  doneeLimitations?: Array<{
    totalAmountLimit?: number;
    maxTournamentTransactionsCount?: number;
  }>;
  donerCount?: number;
  donerCurrentCount?: number;
  donerCurrentAmount?: number;
  doneeAmount?: number;
  doneeCurrentAmount?: number;
  systemUser?: string;
  systemTotal?: number;
  removeDonate?: boolean;
  action?: string;
  actionLabel?: string;
}
