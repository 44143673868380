import {Injectable} from '@angular/core';
import {Logger} from './logger.service';
import { LOGGER as l } from '../../environments/environment';
// import {environment} from '../../../environments/environment';


export const isDebugMode = l.loggerLevel > 0;

const noop = (): any => undefined;

/**
 * This service is the final implementation of Logger Service.
 */

@Injectable()
export class ConsoleLoggerService implements Logger {


  /**
   * console.log ()
   * @returns   Function || undefined
   */
  get log() {
    if (isDebugMode) {
      return console.log.bind(console);
    } else {
      return noop;
    }
  }
  /**
   * console.warn ()
   * @returns   Function || undefined
   */
  get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }
  /**
   * console.error ()
   * @returns   Function || undefined
   */
  get error() {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }



  constructor() {
    /**
     * we need to use logger with out injection , since its a very common service ;
     * See {@link AppModule} where the Logger Service is instantiates.
     */
     ( <any> window )['logger'] = this;
  }

}
