import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-icon-item-shorter-ang',
    templateUrl: './icon-item-shorter-ang.component.html',
    styleUrls: ['./icon-item-shorter-ang.component.scss']
})
export class IconItemShorterAngComponent implements OnInit {

    @Input()
    sortItems: Array<SortItem> = [];

    @Input()
    sorterLabel: string = '';

    @Output()
    selectedItemChanged = new EventEmitter();

    selectedItem: SortItem = {};

    constructor() {
    }

    ngOnInit() {
        if (this.sortItems) {
            const selected = this.sortItems.find((item: SortItem) => {
                return item.selected == true;
            })

            if (selected) {
                this.selectedItem = selected;
            }
        }
    }

    onChangeObj(newItem: SortItem) {
        this.selectedItem = newItem;
        if (this.selectedItemChanged) {
            this.selectedItemChanged.emit({id: this.selectedItem.tag});
        }
    }

}

export interface SortItem {
    name?: string;
    tag?: SortTag;
    value?: string;
    selected?: boolean;
}

export enum SortTag {
    RECENT = 11,
    OLDER = 12,
    NOT_VIEWED = 13,
    NOT_DONE = 14,
    COST_LOW_TO_HIGH = 15,
    COST_HIGH_TO_LOW = 16,
    PREDEFINITO = 17,
    BY_ORDERING_DESC = 18,
    BY_ORDERING_ASC = 19
}