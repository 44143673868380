/*
 * @Author: Peter Fousteris (petfoust@gmail.com)
 * @Date: 2019-09-30 10:20:43
 * @Last Modified by: Peter Fousteris (petfoust@gmail.com)
 * @Last Modified time: 2021-06-29 12:33:39
 */

import { BrandsService } from "src/services/talos/brands.service";
import { EVENT_DEFINITIONS, to } from "./../../../../../../src/utils/utils";
import {
  ExtendedCategoryDTO,
  LoyaltyBrandProductDTO,
  ProductNote,
  RewardPagging,
  RewardsService,
} from "./../rewards.service";
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { KpDictionary } from "src/services/kp_dictionary/kp.dictionary";
import { UserService } from "src/services/talos/user.service";
import { TranslateService } from "@ngx-translate/core";
import { APP_CONSTANTS, TAConstants, TAValues } from "src/talosApi/settings";
import { ProfileService } from "../../profile/profile.service";
import { ExtendedBrandProductDTO } from "../rewards.service";
import { BasePage } from "../../base-page/base-page";
import { UtilsService } from "../../../../services/utils.service";
import { AppGlobalsService } from "src/services/appGlobals.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SlugService } from "src/services/shared/slug.service";
import { RangeFromToPaginatorPayload } from "src/app/components/range-from-to-paginator/range-from-to-paginator.component";
import { isNil, isNull, isNumber, isString } from "lodash";
import { IACtionButton } from "../../../../../../src/shared/interfaces/IActionButton";
import { ProductContext, ProductPointsTag } from "../product/product.component";
import { ResourceDTO } from "src/talosApi";
import { GoBackService } from "src/services/shared/goBack.service";
import { CachingService } from "../../../../services/caching.service";
import { ListReferralInput } from "../../../../talosApi/models/ListReferralInput";
import moment from "moment";
import { IPurchaseInput } from "../../../../talosApi/api/BrandApi";
// import {Subscription} from "rxjs/Rx";
import Resource_Types = TAConstants.Resource_Types;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Item_Types = TAConstants.ITEM_TYPES;
import COUPON_STATUS = TAConstants.COUPON_STATUSES;
import METADATA_KEY = TAConstants.METADATA_KEY;
import Coupon_Types = TAConstants.COUPON_STATUSES;
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;
import { CategoryDTO } from "src/talosApi/models/CategoryDTO";
import { map, takeUntil, takeWhile } from "rxjs/internal/operators";
import { Subject, Subscription, timer } from "rxjs";
import { ItemItemTypeInput } from "src/talosApi/api/UserV2Api";
import { OtpService } from "src/services/otp.service";

@Component({
  selector: "app-catalog",
  templateUrl: "./catalog.component.html",
  styleUrls: ["./catalog.component.scss"],
})
export class CatalogComponent extends BasePage implements OnInit, OnDestroy {
  /**
   * View child of product component
   */
  @ViewChild("errorModal") errorModal: ElementRef;
  @ViewChild("giftpopup") giftpopup: ElementRef;

  giftPopupData: { header?: string; message?: string; showCancel?: boolean };

  /**
   * Card animation delay of catalog component
   */
  private CardAnimationDelay: number = APP_CONSTANTS.CARD_ANIMATION_DELAY
    ? APP_CONSTANTS.CARD_ANIMATION_DELAY
    : 0;

  /**
   * Card animation step of catalog component
   */
  private CardAnimationStep: number = APP_CONSTANTS.CARD_ANIMATION_STEP
    ? APP_CONSTANTS.CARD_ANIMATION_STEP
    : 0;

  /**
   * Root category id of catalog component
   */
  private _rootCategoryId: number = null;

  /**
   * Root category id of catalog component
   */
  private _limitedDealsCategoryId: number = null;

  /**
   * Root category id of catalog component
   */
  private _tagsRootCategoryId: number = null;

  /**
   * Tag categories of catalog component
   */
  private _tagCategories: KpDictionary<ExtendedCategoryDTO> | null = null;
  private _tagCategoriesArray: Array<ExtendedCategoryDTO> | null = null;

  /**
   * Limmited counters of catalog component
   */
  private _limmitedCounters: KpDictionary<number> = null;

  /**
   * Reward config of catalog component
   */
  private _rewardConfig = {};

  /**
   * Selected category id of your rewards component
   */
  private _selectedCategoryID: number = null;

  /**
   * All items count of catalog component
   */
  private _allItemsCount: number = 0;

  /**
   * Paginator total pages of catalog component
   */
  public paginatorTotalItems: number = 0;

  /**
   * Paginator current page of catalog component
   */
  public paginatorRangeFrom: number = 0;

  /**
   * Paginator range to of catalog component
   */
  public paginatorRangeTo: number = 0;

  /**
   * Paging step of catalog component
   */
  private pagingStep: number = APP_CONSTANTS.DEFAULT_PAGING_STEP
    ? APP_CONSTANTS.DEFAULT_PAGING_STEP
    : 0;

  /**
   * Data error of catalog component
   */
  public dataError: boolean = false;

  /**
   * All cards of catalog component
   */
  public displayedCards: Array<any> = [];

  /**
   * Page loading of your rewards component
   */
  public pageLoading: boolean = true;

  /**
   * Items grid of your rewards component
   */
  // public itemsGrid: string = `col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-bw-card `;
  public itemsGrid: string = `col col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-bw-card `;

  /**
   * Show more button pressed of catalog component
   */
  public showMoreButtonPressed: boolean = false;

  /**
   * Show more button of catalog component
   */
  public showMoreButton: boolean = false;

  /**
   * Tag items of your rewards component
   */
  public tagItems: Array<IShorterItem> = [];

  /**
   * Shorter items of catalog component
   */
  public selectedTag: string | null = "all";

  /**
   * Cards header of catalog component
   */
  public cardsHeader: string | null = null;

  /**
   * Tag items of your rewards component
   */
  public shorterItems: Array<IShorterItem> = [];

  /**
   * Shorter items of catalog component
   */
  public selectedShorter: string | null =
    CATALOG_SHORTER_FILTER_TYPES.BY_ORDERING_ASC.VALUE;

  /**
   * Failed action of catalog component
   */
  public failedAction: boolean = false;

  /**
   * Page data of catalog component
   */
  private _pageData: RewardsPageData = null;

  private extraAction: string;

  /**
   * Fetch statistics of catalog component
   */
  private fetchStatistics: number | boolean = 4;

  // private hasBought;

  private categorySpecial;

  private currentPage: number = 1;

  private gotWithPreviousPageData: boolean = false;

  private initRequestCategoryId: number = null;

  public loadMoreEnabled = false;

  private _allProducts: KpDictionary<ExtendedBrandProductDTO> =
    new KpDictionary<ExtendedBrandProductDTO>();

  private routerSubscription: Subscription;

  private giftId: string;

  private purchaseStarted: boolean;

  private hasBoughtVoucher: boolean;

  private filterChildren: Array<CategoryDTO>;

  private totalItems: number = Infinity;

  private routeFired = false;

  /**
   * Destroy  of product component
   */
  private destroy: Subject<void> = new Subject();

  public activeProduct: LoyaltyBrandProductDTO | null = null;

  helpText: string;
  errorMessage: string = "";
  public purchaseLoading: boolean = false;

  @ViewChild("otpPopUp") otpPopUp: ElementRef;

  @ViewChild("pin1") pin1!: ElementRef<HTMLInputElement>;
  @ViewChild("pin2") pin2!: ElementRef<HTMLInputElement>;
  @ViewChild("pin3") pin3!: ElementRef<HTMLInputElement>;
  @ViewChild("pin4") pin4!: ElementRef<HTMLInputElement>;
  @ViewChild("pin5") pin5!: ElementRef<HTMLInputElement>;
  pins: ElementRef<HTMLInputElement>[] = [];
  activeInputIndex: number | null = null;

  public otpTimeRemaining = {
    time: 0,
    text: "",
  };
  private otpCountDownSub: Subscription;

  // public contextMenuButtons: Array<{ label: string, action_id: number }> = [{label:this.translateSrv.instant('CATALOG_CONTEXT_MENU_1'), action_id: 1 }, {label:this.translateSrv.instant('CATALOG_CONTEXT_MENU_2'), action_id: 2 }];

  /**
   * Creates an instance of catalog component.
   * @param rewardsSrv
   * @param translateSrv
   * @param userService
   * @param brandService
   * @param cardsContainer
   * @param renderer
   */
  constructor(
    public rewardsSrv: RewardsService,
    public translateSrv: TranslateService,
    public userService: UserService,
    public brandService: BrandsService,
    public cardsContainer: ElementRef,
    public profileService: ProfileService,
    public renderer: Renderer2,
    public userSrv: UserService,
    public utilsSrv: UtilsService,
    protected appGlobalsSrv: AppGlobalsService,
    public slugSrv: SlugService,
    public route: ActivatedRoute,
    public router: Router,
    public goBackSrv: GoBackService,
    private cachingSrv: CachingService,
    private otpSrv: OtpService
  ) {
    super(userSrv, utilsSrv);
    /***** DETECT IF SAME PAGE RELOADS *****/
    this.router.events.pipe(takeUntil(this.destroy)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        // initialize ui settings
        this.selectedTag = "all";
        this._selectedCategoryID = null;
        this.selectedShorter =
          CATALOG_SHORTER_FILTER_TYPES.BY_ORDERING_ASC.VALUE;
      }
    });
  }

  /**
   * on init
   */
  ngOnInit() {
    if (this.appGlobalsSrv && this.appGlobalsSrv.config) {
      try {
        this._rewardConfig =
          this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
          {};
        if (this._rewardConfig) {
          this._rootCategoryId = !isNaN(
            parseInt(
              this._rewardConfig[
                APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
              ]
            )
          )
            ? parseInt(
                this._rewardConfig[
                  APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
                ]
              )
            : null;
          this._limitedDealsCategoryId = !isNaN(
            parseInt(
              this._rewardConfig[
                APPLICATION_SETTING_KEYS.REWARDS_LIMITED_CATEGORY_ID
              ]
            )
          )
            ? parseInt(
                this._rewardConfig[
                  APPLICATION_SETTING_KEYS.REWARDS_LIMITED_CATEGORY_ID
                ]
              )
            : null;
          this._tagsRootCategoryId = !isNaN(
            parseInt(
              this._rewardConfig[
                APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
              ]
            )
          )
            ? parseInt(
                this._rewardConfig[
                  APPLICATION_SETTING_KEYS.REWARDS_TAGS_CATEGORY_ID
                ]
              )
            : null;
        }
      } catch (e) {
        logger.error(e);
      }
    }
    this.routerSubscription = this.router.events.subscribe((event) => {
      logger.log(event);
      if (event instanceof NavigationEnd) {
        // do some logic again when same url is clicked
        if (
          !this.pageLoading &&
          event &&
          event.url == "/rewards/catalog" &&
          event.urlAfterRedirects == "/rewards/catalog"
        ) {
          this.currentPage = 1;
          this.initiate();
        }
      }
    });
    this.initiate();
  }

  ngAfterViewInit() {
    this.pins = [this.pin1, this.pin2, this.pin3, this.pin4, this.pin5];
    this.setFocusOnFirstEmptyPin();
    this.helpText = this.translateSrv.instant("OTP_HELP_TEXT");
    const isMobile = this.appGlobalsSrv.isMobile;
    this.setPhoneNumberBehavior(isMobile);
  }

  handlePhoneNumberClick(event: Event): void {
    const isMobile = this.appGlobalsSrv.isMobile;
    if (!isMobile) {
      event.preventDefault();
      const phoneNumber = this.extractPhoneNumberFromText(this.helpText);
      this.utilsSrv.copyTextToClipboard(phoneNumber);
    }
  }

  extractPhoneNumberFromText(text: string): string {
    const phoneNumberRegex = /tel:(\d+)/;
    const matches = phoneNumberRegex.exec(text);
    return matches && matches[1] ? matches[1] : "";
  }

  setPhoneNumberBehavior(isMobile: boolean): void {
    const phoneNumberLink = document.querySelector(
      '.small-text a[href^="tel:"]'
    );
    if (phoneNumberLink) {
      phoneNumberLink.addEventListener("click", (event) => {
        this.handlePhoneNumberClick(event);
      });

      if (!isMobile) {
        phoneNumberLink.classList.add("desktop-mode");
      }
    }
  }

  initiate() {
    if (this._rootCategoryId) {
      logger.log("_rootCategoryId", this._rootCategoryId);
      this.clearPageData();
      this.setShorterItems();
      this.initPageData();
    } else {
      this.ShowError();
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();

    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Sets shorter items
   */
  private setShorterItems() {
    if (this.shorterItems.length > 0) return;

    this.shorterItems.push({
      name: this.translateSrv.instant("NEWEST"),
      value: CATALOG_SHORTER_FILTER_TYPES.BY_ORDERING_ASC.VALUE,
    });
    this.shorterItems.push({
      name: this.translateSrv.instant("INCREASING_POINTS"),
      value: CATALOG_SHORTER_FILTER_TYPES.BY_PRICE_ASC.VALUE,
    });
    this.shorterItems.push({
      name: this.translateSrv.instant("DECREASING_POINTS"),
      value: CATALOG_SHORTER_FILTER_TYPES.BY_PRICE_DESC.VALUE,
    });
  }

  /**
   * Inits page data
   */
  private async initPageData() {
    this.fetchStatistics = this.appGlobalsSrv.config.includeStatistics
      ? 4
      : false;
    if (!isNaN(this.appGlobalsSrv.config.paging))
      this.pagingStep = this.appGlobalsSrv.config.paging;
    const previousPageData = this.goBackSrv.getPreviewPageData();
    if (
      previousPageData &&
      previousPageData.route === "/rewards/catalog/" &&
      previousPageData.page
    ) {
      this.currentPage = previousPageData.page;
      this.gotWithPreviousPageData = true;
      if (
        !isNil(previousPageData.sorter) &&
        isString(previousPageData.sorter)
      ) {
        const found = this.shorterItems.find(
          (i) => i.value === previousPageData.sorter
        );
        if (found) {
          this.selectShorterCallback(previousPageData.sorter, true);
        }
      }
      if (
        !isNil(previousPageData.category) &&
        isNumber(previousPageData.category)
      ) {
        this.initRequestCategoryId = previousPageData.category;
      }
    } else this.goBackSrv.setPreviewPageData(null);
    this.currentPage = 1;
    this.paginatorRangeFrom = (this.currentPage - 1) * this.pagingStep;
    this.paginatorRangeTo = this.currentPage * this.pagingStep;
    /* Initial Block Fetch. */
    this.fetchCurrentDataBlock(
      this.paginatorRangeFrom,
      this.paginatorRangeTo,
      true
    );
  }

  /**
   * Shows error
   */
  private ShowError(init: boolean = true) {
    if (init) this.dataError = true;
    this.pageLoading = false;
  }

  /**
   * Sets up filter bar
   * @param items
   */
  private initFilterTags(): void {
    let _tagItems = [];
    if (
      isNil(this._pageData) ||
      isNil(this._pageData.filterCategories) ||
      isNil(this._pageData.counters)
    )
      return;
    const items = this._pageData.filterCategories;
    const counters = this._pageData.counters;
    let all_counter = 0;
    this.countTotalItems();
    if (this.filterChildren) {
      this.filterChildren.forEach((f) => {
        const cat = String(f.id);
        const category = items.Item(cat);
        const counter = counters.Item(cat);
        if (category && counter) {
          all_counter += counters.Item(cat);
          let name = this.getCategoryName(category);
          if (name && name != "") {
            _tagItems.push({
              name: name,
              count: counter,
              showCounter: true,
              disabled: counter <= 0,
              value: category.id.toString(),
            });
          }
        }
      });
    }
    // for (const cat of items.Keys()) {
    //   if (!counters.ContainsKey(cat) || !counters.Item(cat)) continue;
    //   /* Skip From Select Lists. */
    //   const category = items.Item(cat);
    //   let levelEnabled = false;
    //   if (category.metadata)
    //     levelEnabled =
    //       this.utilsSrv.valueFromKeyValueDTOArray(
    //         METADATA_KEY.CATEGORY_MIN_LEVEL,
    //         category.metadata
    //       ) != "";
    //   let name = this.getCategoryName(category);
    //   if (isNull(name) || !name.length) continue;
    //   all_counter += counters.Item(cat);
    //   levelEnabled
    //     ? _tagItems.unshift({
    //         name: name,
    //         count: counters.Item(cat),
    //         showCounter: true,
    //         disabled: counters.Item(cat) <= 0,
    //         value: category.id.toString(),
    //       })
    //     : _tagItems.push({
    //         name: name,
    //         count: counters.Item(cat),
    //         showCounter: true,
    //         disabled: counters.Item(cat) <= 0,
    //         value: category.id.toString(),
    //       });
    // }
    if (_tagItems.length) {
      _tagItems.unshift({
        name: this.translateSrv.instant("ALL_PRODUCTS_FILTER_LABEL"),
        count: all_counter,
        disabled: all_counter <= 0,
        showCounter: true,
        value: "all",
      });
    }
    if (this.selectedTag) {
      const found = _tagItems.find((tag) => tag.value === this.selectedTag);
      if (found) {
        this.cardsHeader = `${found.name} (${found.count})`;
      }
    }
    this.tagItems = _tagItems;
  }

  /**
   * Clears page data
   */
  private clearPageData(): void {
    this.pageLoading = true;
    this._pageData = null;
    /* Clear Data. */
    this._allItemsCount = 0;
    this.displayedCards = [];
  }

  /**
   * Selects tag callback
   * @param event
   */
  public selectTagCallback(event: CustomEvent): void {
    logger.log(event);
    this.smoothScrollTopTop();
    this.clearPageData();
    const _selectedValue = event.detail.data.selectedValue;
    this._selectedCategoryID = parseInt(_selectedValue);
    this.selectedTag = _selectedValue;
    if (isNaN(this._selectedCategoryID) || this._selectedCategoryID < 0)
      this._selectedCategoryID = null;
    this.initPageData();
  }

  /**
   * Selects tag callback
   * @param event
   */
  public selectShorterCallback(
    event: CustomEvent | string,
    skipFetch: boolean = false
  ): void {
    logger.log(event);
    this.smoothScrollTopTop();
    this.clearPageData();
    const selectedValue = !isString(event)
      ? event.detail.data.selectedValue
      : event;
    if (
      [
        CATALOG_SHORTER_FILTER_TYPES.BY_PRICE_ASC.VALUE,
        CATALOG_SHORTER_FILTER_TYPES.BY_PRICE_DESC.VALUE,
        CATALOG_SHORTER_FILTER_TYPES.BY_ORDERING_ASC.VALUE,
      ].includes(selectedValue)
    ) {
      this.selectedShorter = selectedValue;
    }
    if (!skipFetch) this.initPageData();
  }

  /**
   * Counts total items
   * @param items
   */
  private countTotalItems(): void {
    if (
      isNil(this._pageData) ||
      isNil(this._pageData.counters) ||
      isNil(this._pageData.filterCategories)
    )
      return;
    if (isNull(this._selectedCategoryID)) {
      for (const cat of this._pageData.filterCategories.Keys()) {
        if (!this._pageData.counters.ContainsKey(cat)) continue;
        /* Skip From Select Lists. */
        this._allItemsCount += this._pageData.counters.Item(cat) as number;
      }
    } else if (
      this._pageData.counters.ContainsKey(this._selectedCategoryID.toString())
    ) {
      this._allItemsCount = this._pageData.counters.Item(
        this._selectedCategoryID.toString()
      );
    }
  }

  /**
   * Gets category name
   * @param _assocCategory
   * @returns category name
   */
  private getCategoryName(cat: ExtendedCategoryDTO): string {
    if (isNil(cat)) return null;
    let name = this.utilsSrv.getItemResource(
      cat,
      Resource_Types.NAME,
      this.translateSrv.currentLang
    );
    if (isNull(name) || !name.length) name = cat.name;
    return name;
  }

  /**
   *
   * @param product
   * @returns
   */
  private getParentProduct(
    product: LoyaltyBrandProductDTO
  ): LoyaltyBrandProductDTO | null {
    const parentBrandPorductId = product.parentBrandPorductId;
    if (!parentBrandPorductId) return null;
    return this._pageData.pageItems.ContainsKey(parentBrandPorductId)
      ? this._pageData.pageItems.Item(parentBrandPorductId)
      : null;
  }

  /**
   *
   * @param product
   * @returns
   */
  private getPointTags(product: LoyaltyBrandProductDTO): ProductPointsTag[] {
    const parent = this.getParentProduct(product);
    let achievementProduct = product;
    if (product.isBundle && !isNull(parent)) {
      achievementProduct = parent;
    }

    return this.rewardsSrv.getProductPointTags(
      product,
      parent,
      achievementProduct
    );
  }

  /**
   *
   * @param product
   * @returns
   */
  private getProductNote(product: LoyaltyBrandProductDTO): ProductNote | null {
    if (product.isDonate) return;
    const parent = this.getParentProduct(product);
    let achievementProduct = product;
    if (product.isBundle && !isNull(parent)) achievementProduct = parent;

    if (product.isBundle) {
      if (this.rewardsSrv.productIsTerminatedBundle(parent, product)) {
        return {
          message: this.translateSrv.instant("TERMINATO_BADGE_LABEL"),
          backgroundColor: `${this.translateSrv.instant(
            "TERMINATO_BADGE_BACKGROUND_COLOR"
          )}`,
          variant: "warning",
        };
      } else {
        let note: ProductNote;
        parent.prices.forEach((p) => {
          if (!note) {
            if (
              this.rewardsSrv.getAvailableCoupons(product, p.brandServiceId) ==
              0
            ) {
              note = {
                message: this.translateSrv.instant(
                  p.brandServiceId == "5A28B062-5043-47AF-D8A4-729BB0D73A7C"
                    ? "TERMINATO_ONLINE"
                    : "TERMINATO_OFFLINE"
                ),
                backgroundColor: `${this.translateSrv.instant(
                  "TERMINATO_BADGE_BACKGROUND_COLOR"
                )}`,
                variant: "warning",
              };
            }
          }
        });
        if (note) return note;
      }
    } else {
      if (this.rewardsSrv.productIsTerminatedFull(product)) {
        return {
          message: this.translateSrv.instant("TERMINATO"),
          backgroundColor: `${this.translateSrv.instant(
            "TERMINATO_BADGE_BACKGROUND_COLOR"
          )}`,
          variant: "warning",
        };
      } else {
        let note: ProductNote;
        product.prices.forEach((p) => {
          if (!note) {
            if (
              this.rewardsSrv.getAvailableCoupons(product, p.brandServiceId) ==
              0
            ) {
              note = {
                message: this.translateSrv.instant(
                  p.brandServiceId == "5A28B062-5043-47AF-D8A4-729BB0D73A7C"
                    ? "TERMINATO_ONLINE"
                    : "TERMINATO_OFFLINE"
                ),
                backgroundColor: `${this.translateSrv.instant(
                  "TERMINATO_BADGE_BACKGROUND_COLOR"
                )}`,
                variant: "warning",
              };
            }
          }
          if (note) return note;
        });
      }
    }

    /* Get Number of Points. */
    let numberPoints = this.rewardsSrv.getProductNumberOfPoints(
      product,
      parent
    );
    /* Get Countdown Value. */
    const countDownValue = this.rewardsSrv.getProductCouponCountdown(product);

    return this.rewardsSrv.productNoteConditionalOverlap(
      product,
      parent,
      achievementProduct,
      numberPoints,
      countDownValue
    );
  }

  private async getProductHref(
    product: LoyaltyBrandProductDTO
  ): Promise<string> {
    let name = "";
    const parent = this.getParentProduct(product);
    let achievementProduct = product;
    if (product.isBundle && !isNull(parent)) achievementProduct = parent;

    await this.slugSrv
      .getSlugItem(
        achievementProduct.itemIdForResourceLookup,
        Item_Types.BRAND_PRODUCT
      )
      .then((item) => {
        name = `rewards/catalog/${item}`;
      })
      .catch((error) => {
        logger.log(error);
      });

    return name;
  }

  /**
   *
   * @param product
   * @returns
   */
  private getProductButton(product: LoyaltyBrandProductDTO): IACtionButton {
    const parent = this.getParentProduct(product);
    let achievementProduct = product;
    if (product.isBundle && !isNull(parent)) achievementProduct = parent;
    /* Initial Button.  */
    const ret: IACtionButton = {
      label: this.translateSrv.instant(
        !achievementProduct.isDonate ? "DISCOVER_AND_REDEEM" : "DISCOVER"
      ),
      action: {
        action_id: EVENT_DEFINITIONS.REDEEM_PRODUCT_CLICKED.ID,
        action_name: EVENT_DEFINITIONS.REDEEM_PRODUCT_CLICKED.NAME,
        action_limit: 1,
        item_id: product.brandProductId,
        item_type_id: Item_Types.BRAND_PRODUCT,
      },
      variant: "contained",
    };

    let swapButton = false;

    if (product.isVoucher && this.hasBoughtVoucher) {
      swapButton = true;
    }
    if (
      this.rewardsSrv.productHasLimitation(product) &&
      this.rewardsSrv.isProductLimitReached(product)
    ) {
      swapButton = true;
    }
    if (
      product.isBundle &&
      !isNull(parent) &&
      this.rewardsSrv.productHasLimitation(parent) &&
      this.rewardsSrv.isProductLimitReached(parent)
    ) {
      swapButton = true;
    }
    if (
      !swapButton &&
      this.rewardsSrv.productHasRequiredAchievement(achievementProduct) &&
      !this.rewardsSrv.productAchievementCompleted(achievementProduct)
    ) {
      swapButton = true;
    }
    if (
      !swapButton &&
      this.rewardsSrv.productIsTerminatedFull(achievementProduct)
    ) {
      swapButton = true;
    }
    if (
      !swapButton &&
      !this.rewardsSrv.canUserPurchaseProduct(product, parent)
    ) {
      swapButton = true;
    }
    if (swapButton) {
      ret.label = this.translateSrv.instant("DISCOVER");
      ret.variant = "outlined";
    }
    return ret;
  }

  /**
   *
   * @param product
   * @returns
   */
  private async formatProduct(
    product: LoyaltyBrandProductDTO
  ): Promise<ProductPurchaseContext> {
    let category_name = null;
    let _assocCategory: ExtendedCategoryDTO = null;
    let categoryLoookupProduct = product;
    if (product.isBundle) {
      const parent = this.getParentProduct(product);
      if (!isNull(parent)) {
        categoryLoookupProduct = parent;
      }
    }
    /* Associate Category of Brand Product. */
    let _subCategoryId = this.rewardsSrv.productSubCategory(
      categoryLoookupProduct,
      this._pageData.filterCategories
    );
    if (
      !isNil(_subCategoryId) &&
      this._pageData.filterCategories.ContainsKey(_subCategoryId)
    ) {
      _assocCategory = this._pageData.filterCategories.Item(
        _subCategoryId
      ) as ExtendedCategoryDTO;
      if (!isNil(_assocCategory)) {
        let name = this.utilsSrv.getItemResource(
          _assocCategory,
          Resource_Types.NAME,
          this.translateSrv.currentLang
        );
        if (isString(name)) category_name = name;
      }
    }
    logger.log("Product prices", product.prices, product);

    /////////
    return {
      ...product,
      isVoucherOrWelcome: product.isVoucher,
      isDonate: product.isDonate,
      item_id: product.brandProductId,
      category: category_name,
      button: this.getProductButton(product),
      productNote: this.getProductNote(product),
      productPointsTags: this.getPointTags(product),
      badges: this.getProductBadges(product),
      name: this.utilsSrv.getItemMappedResource(
        product,
        Resource_Types.NAME,
        this.translateSrv.currentLang
      ),
      content: this.utilsSrv.getItemMappedResource(
        product,
        Resource_Types.DESCRIPTION,
        this.translateSrv.currentLang
      ),
      subtitle: this.utilsSrv.getItemMappedResource(
        product,
        Resource_Types.CONTENT_1,
        this.translateSrv.currentLang
      ),
      disabled: product.isBundle
        ? this.rewardsSrv.productIsTerminatedBundle(
            categoryLoookupProduct,
            product
          )
        : this.rewardsSrv.productIsTerminatedFull(product),
      href: await this.getProductHref(product),
    };
  }

  /**
   *
   * @param product
   * @returns
   */
  private getProductBadges(product: LoyaltyBrandProductDTO) {
    let categoryTags: Array<TagCategoryDef> = [];
    let lookupProduct = product;
    const parent = this.getParentProduct(product);
    if (product.isBundle && !isNull(parent)) lookupProduct = parent;
    // if (product.isBundle) {
    //   if (this.rewardsSrv.productIsTerminatedBundle(parent, product)) {
    //     categoryTags.push({
    //       label: this.translateSrv.instant("TERMINATO_BADGE_LABEL"),
    //       backgroundColor: `${this.translateSrv.instant(
    //         "TERMINATO_BADGE_BACKGROUND_COLOR"
    //       )}`,
    //       color: `${this.translateSrv.instant("TERMINATO_BADGE_COLOR")}`,
    //     });
    //   } else {
    //     lookupProduct.prices.forEach((p) => {
    //       if (
    //         this.rewardsSrv.getAvailableCoupons(product, p.brandServiceId) == 0
    //       ) {
    //         categoryTags.push({
    //           label: this.translateSrv.instant(
    //             p.brandServiceId == "5A28B062-5043-47AF-D8A4-729BB0D73A7C"
    //               ? "TERMINATO_ONLINE"
    //               : "TERMINATO_OFFLINE"
    //           ),
    //           backgroundColor: `${this.translateSrv.instant(
    //             "TERMINATO_BADGE_BACKGROUND_COLOR"
    //           )}`,
    //           color: `${this.translateSrv.instant("TERMINATO_BADGE_COLOR")}`,
    //         });
    //       }
    //     });
    //   }
    // } else {
    //   if (this.rewardsSrv.productIsTerminatedFull(lookupProduct)) {
    //     categoryTags.push({
    //       label: this.translateSrv.instant("TERMINATO"),
    //       backgroundColor: `${this.translateSrv.instant(
    //         "TERMINATO_BADGE_BACKGROUND_COLOR"
    //       )}`,
    //       color: `${this.translateSrv.instant("TERMINATO_BADGE_COLOR")}`,
    //     });
    //   } else {
    //     lookupProduct.prices.forEach((p) => {
    //       if (
    //         this.rewardsSrv.getAvailableCoupons(
    //           lookupProduct,
    //           p.brandServiceId
    //         ) == 0
    //       ) {
    //         categoryTags.push({
    //           label: this.translateSrv.instant(
    //             p.brandServiceId == "5A28B062-5043-47AF-D8A4-729BB0D73A7C"
    //               ? "TERMINATO_ONLINE"
    //               : "TERMINATO_OFFLINE"
    //           ),
    //           backgroundColor: `${this.translateSrv.instant(
    //             "TERMINATO_BADGE_BACKGROUND_COLOR"
    //           )}`,
    //           color: `${this.translateSrv.instant("TERMINATO_BADGE_COLOR")}`,
    //         });
    //       }
    //     });
    //   }
    // }

    if (
      lookupProduct.subcategoryIds &&
      lookupProduct.subcategoryIds.length > 0 &&
      this._tagCategoriesArray
    ) {
      this._tagCategoriesArray.forEach((c) => {
        const exists = lookupProduct.subcategoryIds.find(
          (sb) => sb == String(c.id)
        );
        if (exists) {
          const category = this._tagCategories.Item(exists);
          if (!isNil(category) && category.resourcesMapped.Count()) {
            let color = this.utilsSrv.getItemResource(
              category,
              Resource_Types.COLOR,
              this.translateSrv.currentLang
            );
            if (isString(color) && color.startsWith("#"))
              color = color.substring(1);
            else color = null;
            let bgColor = this.utilsSrv.getItemResource(
              category,
              Resource_Types.CAMPAIGN_TEXT,
              this.translateSrv.currentLang
            );
            if (isString(bgColor) && bgColor.startsWith("#"))
              bgColor = bgColor.substring(1);
            else bgColor = null;
            categoryTags.push({
              label: this.utilsSrv.getItemResource(
                category,
                Resource_Types.NAME,
                this.translateSrv.currentLang
              ),
              backgroundColor: bgColor,
              color: color,
            });
          }
        }
      });

      // lookupProduct.subcategoryIds
      //     .filter(sc => this._tagCategories.ContainsKey(sc))
      //     .map(sc => {
      //         const category = this._tagCategories.Item(sc);
      //         if (!isNil(category) && category.resourcesMapped.Count()) {
      //             let color = this.utilsSrv.getItemResource(category, Resource_Types.COLOR, this.translateSrv.currentLang);
      //             if (isString(color) && color.startsWith('#')) color = color.substring(1);
      //             else color = null;
      //             let bgColor = this.utilsSrv.getItemResource(category, Resource_Types.CAMPAIGN_TEXT, this.translateSrv.currentLang);
      //             if (isString(bgColor) && bgColor.startsWith('#')) bgColor = bgColor.substring(1);
      //             else bgColor = null;
      //             categoryTags.push({
      //                 label: this.utilsSrv.getItemResource(category, Resource_Types.NAME, this.translateSrv.currentLang),
      //                 backgroundColor: bgColor,
      //                 color: color,
      //             });
      //         }
      //     });
    }
    // let sorted = [];
    // if (categoryTags.length) {
    //     sorted = categoryTags.sort((c1, c2) => {
    //         if (c1.label > c2.label) {
    //             return 1;
    //         }
    //
    //         if (c1.label < c2.label) {
    //             return -1;
    //         }
    //
    //         return 0;
    //     });
    //
    //     logger.log(`Product ${lookupProduct.brandProductId} Tag categories`, sorted);
    // }

    // return sorted.length > 0 ? sorted : null;
    return categoryTags.length > 0 ? categoryTags : null;
  }

  /**
   * Inits cards per category
   * @param response
   */
  private async setPageContent(response: RewardsPageData) {
    if (!this._allItemsCount) return;
    /* No Items to Display. */
    for (const product of response.pageItems.Values()) {
      if (!product.isListed || product.isBundle) continue;
      if (product.isBundled && product.bundledProducts.length > 0) {
        let formated = [];
        product.bundledProducts.map(async (p) => {
          formated.push(await this.formatProduct(p as any));
        });
        product.bundledProducts = formated;
      }

      this.displayedCards.push(await this.formatProduct(product));
    }
    logger.log("displayedCards", this.displayedCards);
    this.updatePaginator();
    this.updateLoadMore();
    this.pageLoading = false;

    if (this.cachingSrv.loadedProducts) {
      setTimeout(() => {
        const view = document.getElementById(
          "product_" + this.cachingSrv.loadedProducts.itemId
        );
        if (view) {
          view.scrollIntoView();
        }
        this.cachingSrv.loadedProducts = null;
      }, 1000);
    }
  }

  /**
   *
   */
  private updateLoadMore() {
    this.loadMoreEnabled = false;
    try {
      // let totalItems = 0;
      // if (isNull(this._selectedCategoryID)) {
      //   totalItems = this._allItemsCount;
      // } else {
      //   totalItems = this._pageData.counters.Item(
      //     this._selectedCategoryID.toString()
      //   );
      // }
      // this.loadMoreEnabled = this.displayedCards.length < totalItems;
      this.loadMoreEnabled = this.totalItems > this.displayedCards.length;
    } catch (error) {
      this.loadMoreEnabled = false;
      logger.log(error);
    }
  }

  /**
   *
   */
  private updatePaginator() {
    try {
      if (isNull(this._selectedCategoryID)) {
        this.paginatorTotalItems = this._allItemsCount;
      } else {
        this.paginatorTotalItems = this._pageData.counters.Item(
          this._selectedCategoryID.toString()
        );
      }
    } catch (error) {
      this.paginatorTotalItems = 0;
      logger.log(error);
    }
  }

  /**
   *
   * @param event
   * @returns
   */
  public async productInfoClicked(event: CustomEvent) {
    logger.log(event);
    if (!event || !event.detail || !event.detail.item_id) return;
    const item_id = event.detail.item_id;
    if (!this._pageData.pageItems.ContainsKey(item_id)) return;
    let name = null;
    await this.slugSrv
      .getSlugItem(item_id, Item_Types.BRAND_PRODUCT)
      .then((item) => {
        name = item;
      })
      .catch((error) => {
        logger.log(error);
      });
    let displayError = false;
    if (!name) {
      displayError = true;
    }
    try {
      this.goBackSrv.setPreviewPageData({
        route: "/rewards/catalog/",
        targetIsRewards: true,
        label: this.translateSrv.instant("BACK_TO_CATALOG"),
        page: this.currentPage,
        sorter: this.selectedShorter,
        category: this._selectedCategoryID,
      });

      let totalItems = 0;
      if (isNull(this._selectedCategoryID)) {
        totalItems = this._allItemsCount;
      } else {
        totalItems = this._pageData.counters.Item(
          this._selectedCategoryID.toString()
        );
      }
      let iId = item_id;
      const product = this._pageData.pageItems.Item(item_id);
      if (product) {
        if (product.isDonate) {
          this.router.navigate(["donate"]);
          return;
        } else if (product.isGiftCard) {
          this.giftPopupData = {
            header: this.translateSrv.instant("GIFT_CARD_HEADER"),
            message: this.translateSrv.instant("GIFT_CARD_MESSAGE"),
            showCancel: true,
          };
          if (this.giftpopup && this.giftpopup.nativeElement)
            this.giftpopup.nativeElement.open();

          this.giftId = item_id;
        } else {
          const parent = this.getParentProduct(product);
          if (parent) {
            iId = parent.brandProductId;
            if (parent.isDonate) {
              this.router.navigate(["donate"]);
              return;
            }
          }
          this.router.navigate([`/rewards/catalog/${name}`]);
        }
      }
      this.cachingSrv.loadedProducts = {
        items: this._allProducts,
        itemId: iId,
        totalItems: totalItems,
      };
    } catch (error) {
      displayError = true;
    }
    if (displayError) {
      this.displayErrorModal({
        title: this.translateSrv.instant("MODAL_ERROR_TITLE"),
        subTitle: null,
        errorMessage: this.translateSrv.instant("MODAL_ERROR_MESSAGE"),
      });
    }
  }

  giftCardCancel() {
    if (this.purchaseStarted) {
      this.purchaseStarted = false;
      return;
    }
    if (this.giftpopup && this.giftpopup.nativeElement)
      this.giftpopup.nativeElement.close();

    this.pageLoading = true;
    setTimeout(() => {
      this.pageLoading = false;
    }, 100);

    this.giftId = null;
  }

  async giftCardOK() {
    this.purchaseStarted = true;
    if (this.giftpopup && this.giftpopup.nativeElement)
      this.giftpopup.nativeElement.close();

    if (this.giftId) {
      const product = this._pageData.pageItems.Item(this.giftId);
      if (product) {
        this.activeProduct = product;

        if (
          this.activeProduct.oTPConfig &&
          this.activeProduct.oTPConfig.prerequisiteItem
        )
          this.openOTPPopUp();
        else this.purchaseProduct();
      }
    }
  }

  /**
   * Fetchs current data block
   */
  private async fetchCurrentDataBlock(
    rangeFrom: number,
    rangeTo: number,
    init: boolean
  ) {
    let error = false;
    /* Page Items. */
    let _rootCategoryData: ExtendedCategoryDTO = null;
    let _childCategoryData: KpDictionary<ExtendedCategoryDTO> = null;
    let _countersData: KpDictionary<number> = null;
    let _selectionData: KpDictionary<ExtendedBrandProductDTO> = null;
    if (!isNil(this._tagsRootCategoryId)) {
      await this.utilsSrv
        .getRootCategoryTree(this._tagsRootCategoryId, false, true, false)
        .then((resp) => {
          if (!isNil(resp.childrenMapped)) {
            logger.log("tagCategories", resp.childrenMapped);
            this._tagCategories = resp.childrenMapped;
          }
          if (!isNil(resp.children)) {
            this._tagCategoriesArray = resp.children;
          }
        })
        .catch((error) => {
          logger.log(error);
        });
    }
    if (!isNil(this._limitedDealsCategoryId)) {
      this.rewardsSrv
        .getCategoriesCouponCounters(this._limitedDealsCategoryId, [
          COUPON_STATUS.PURCHASED,
          COUPON_STATUS.REDEEMED,
        ])
        .then((data) => {
          this._limmitedCounters = data;
          logger.log("_limmitedCounters", this._limmitedCounters);
        });
    }
    if (!init) {
      this.initRequestCategoryId = null;
      this.goBackSrv.setPreviewPageData(null);
      this.gotWithPreviousPageData = false;
      const _getProductSelection = await to(
        this.rewardsSrv.getProductSelection(
          this._pageData.filterCategories,
          this._selectedCategoryID,
          this.selectedShorter,
          rangeFrom,
          rangeTo,
          this.fetchStatistics,
          true
        )
      );
      if (isNil(_getProductSelection.data)) error = true;
      else {
        this._pageData.pagging = {
          rangeFrom: rangeFrom,
          rangeTo: rangeTo,
        };
        this.ExtendData(
          _getProductSelection.data as KpDictionary<LoyaltyBrandProductDTO>
        );
      }
    } else {
      let promoBought = true;
      let referralPromoBought = true;
      let hasReferral = true;
      let hasBought = true;

      const promoCategoryId = !isNaN(
        parseInt(
          this._rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_PROMO_CATEGORY_ID]
        )
      )
        ? parseInt(
            this._rewardConfig[
              APPLICATION_SETTING_KEYS.REWARDS_PROMO_CATEGORY_ID
            ]
          )
        : -1;
      const promoStoreCouponCategoryId = !isNaN(
        parseInt(
          this._rewardConfig[
            APPLICATION_SETTING_KEYS.REWARDS_STORE_PROMO_CATEGORY_ID
          ]
        )
      )
        ? parseInt(
            this._rewardConfig[
              APPLICATION_SETTING_KEYS.REWARDS_STORE_PROMO_CATEGORY_ID
            ]
          )
        : -1;
      const referralPromoCategoryId = !isNaN(
        parseInt(
          this._rewardConfig[
            APPLICATION_SETTING_KEYS.REWARDS_REFERRAL_PROMO_CATEGORY_ID
          ]
        )
      )
        ? parseInt(
            this._rewardConfig[
              APPLICATION_SETTING_KEYS.REWARDS_REFERRAL_PROMO_CATEGORY_ID
            ]
          )
        : -1;
      const query: ListReferralInput = {
        referredUserId: TAValues.UserId,
        rangeFrom: 0,
        rangeTo: 1,
        referralTypeIds: [
          REFERRAL_TYPES.MGM_UNICODE,
          REFERRAL_TYPES.LENDING_REFERRAL,
          REFERRAL_TYPES.LIL,
        ],
        referralStatusIds: [2, 3, 4],
        includeDetails: false,
        dateFrom: "2022-02-14T00:00:00.000Z",
      };
      const prResult = await Promise.all([
        this.rewardsSrv.checkIfHasBoughtByCategoryId(promoCategoryId),
        this.rewardsSrv.checkIfHasBoughtByCategoryId(referralPromoCategoryId),
        this.rewardsSrv.checkIfReferral(query),
        this.rewardsSrv.checkIfHasBoughtByDate(
          moment("14-02-2022", "DD-MM-YYYY").toDate().getTime()
        ),
        this.rewardsSrv.checkIfHasBoughtByCategoryId(
          promoStoreCouponCategoryId
        ),
        this.rewardsSrv.checkIfHasBoughtVoucher(),
      ]).catch((err) => {});
      if (prResult) {
        // this.hasBought = prResult[0];
        promoBought = prResult[0] || prResult[4];
        referralPromoBought = prResult[1];
        hasReferral = prResult[2];
        hasBought = prResult[3];
        this.hasBoughtVoucher = prResult[5];
      }

      logger.log(prResult);
      // this.hasBought = await this.rewardsSrv.checkIfHasBought().catch(err => {
      // });
      const _getCategoriesTreeFilter = await to(
        this.utilsSrv.getRootCategoryTree(
          this._rootCategoryId,
          false,
          true,
          false
        )
      );
      if (isNil(_getCategoriesTreeFilter.data)) error = true;
      if (!error && !isNil(_getCategoriesTreeFilter)) {
        _rootCategoryData =
          _getCategoriesTreeFilter.data as ExtendedCategoryDTO;
        this.filterChildren = _getCategoriesTreeFilter.data.children;
        if (
          !isNil(_rootCategoryData.childrenMapped) &&
          _rootCategoryData.childrenMapped.Count()
        ) {
          _childCategoryData =
            _rootCategoryData.childrenMapped as KpDictionary<ExtendedCategoryDTO>;
          const callCenterCategoryId = !isNaN(
            parseInt(
              this._rewardConfig[
                APPLICATION_SETTING_KEYS.REWARDS_CALL_CENTER_CATEGORY_ID
              ]
            )
          )
            ? parseInt(
                this._rewardConfig[
                  APPLICATION_SETTING_KEYS.REWARDS_CALL_CENTER_CATEGORY_ID
                ]
              )
            : null;
          if (callCenterCategoryId) {
            const category = _rootCategoryData.children.find((c) => {
              return c.id == callCenterCategoryId;
            });
            if (category) {
              _childCategoryData.Remove(category.id.toString());
            }
          }

          const removeCategory = (value: string) => {
            const category = _rootCategoryData.children.find((c) => {
              var result = false;
              if (c.metadata && c.metadata.length > 0) {
                const metadata = this.utilsSrv.valueFromKeyValueDTOArray(
                  METADATA_KEY.DISPLAY_BUTTON,
                  c.metadata
                );
                result = metadata && metadata == value;
              }
              return result;
            });
            if (category) {
              _childCategoryData.Remove(category.id.toString());
            }
          };

          const checkIfMustShowPromo = (force?: boolean) => {
            if (hasBought || promoBought || force) {
              removeCategory("true");
            }
          };

          const checkIfMustShowReferralPromo = (force?: boolean) => {
            if (promoBought || referralPromoBought || force) {
              removeCategory("false");
            }
          };

          _rootCategoryData.children.forEach((c) => {
            if (c.metadata && c.metadata.length > 0) {
              const levelEnabled = this.utilsSrv.valueFromKeyValueDTOArray(
                METADATA_KEY.CATEGORY_MIN_LEVEL,
                c.metadata
              );
              if (levelEnabled && levelEnabled != "") {
                const levels = levelEnabled.split(",");
                if (levels.length > 0) {
                  if (
                    levelEnabled.indexOf(
                      String(this.userService.user.badgeLevel)
                    ) >= 0
                  ) {
                    // if (this.userService.user.badgeLevel == 2) {
                    //   if (!this.userService.user.isCasting)
                    //     _childCategoryData.Remove(c.id.toString());
                    // }
                  } else {
                    _childCategoryData.Remove(c.id.toString());
                  }
                }
              }

              // const nonLevelEnabled = this.utilsSrv.valueFromKeyValueDTOArray(
              //   METADATA_KEY.CATEGORY_MAX_LEVEL,
              //   c.metadata
              // );
              // if (nonLevelEnabled && nonLevelEnabled != "") {
              //   const levels = nonLevelEnabled.split(",");
              //   if (levels.length > 0) {
              //     if (
              //       nonLevelEnabled.indexOf(
              //         String(this.userService.user.badgeLevel)
              //       ) >= 0
              //     ) {
              //       if (this.userService.user.badgeLevel == 2) {
              //         if (this.userService.user.isCasting)
              //           _childCategoryData.Remove(c.id.toString());
              //       }
              //     } else {
              //       _childCategoryData.Remove(c.id.toString());
              //     }
              //   }
              // }
            }
          });

          if (hasReferral) {
            checkIfMustShowPromo(true);
            checkIfMustShowReferralPromo();
          } else {
            checkIfMustShowReferralPromo(true);
            checkIfMustShowPromo();
          }
          // if (this.hasBought) {
          //     const category = _rootCategoryData.children.find(c => {
          //         var result = false;
          //         if (c.metadata && c.metadata.length > 0) {
          //             const metadata = this.utilsSrv.valueFromKeyValueDTOArray(METADATA_KEY.DISPLAY_BUTTON, c.metadata);
          //             result = (metadata && metadata == 'true');
          //         }
          //         return result;
          //     })
          //     if (category) {
          //         _childCategoryData.Remove(category.id.toString());
          //     }
          // }
          const categorySpecial = _rootCategoryData.children.find((c) => {
            var result = false;
            if (c.metadata && c.metadata.length > 0) {
              const metadata = this.utilsSrv.valueFromKeyValueDTOArray(
                METADATA_KEY.DISPLAY_SPECIAL_DEAL_BUTTON,
                c.metadata
              );
              result = metadata && metadata == "true";
            }
            return result;
          });
          let mustRemove = true;
          this.categorySpecial = categorySpecial;
          if (categorySpecial) {
            const userMetadata =
              this.userSrv.user.user.customUserProfileFields[
                METADATA_KEY.PERSONAL_ELIGIBLE_FOR_SPECIAL_CATALOGUE
              ];
            if (userMetadata && userMetadata == "true") {
              const _getProductCouponSelection = await to(
                this.rewardsSrv.getProductCouponSelection(
                  categorySpecial,
                  null,
                  false,
                  false,
                  false,
                  true,
                  [
                    Coupon_Types.PURCHASED,
                    Coupon_Types.REDEEMED,
                    Coupon_Types.EXPIRED,
                    Coupon_Types.EXPIRED_REQUESTED_CHARGE_BACK,
                    Coupon_Types.EXPIRED_CLEARED,
                    Coupon_Types.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
                    Coupon_Types.EXPIRED_CLEARED_FAILED,
                    Coupon_Types.EXPIRED_GIFTCARD,
                    Coupon_Types.EXPIRED_YELLOWS_CHARGED_BACK,
                  ],
                  null,
                  0,
                  1,
                  [107]
                )
              );

              if (_getProductCouponSelection) {
                const bought =
                  _getProductCouponSelection.data &&
                  _getProductCouponSelection.data.Count() > 0;
                mustRemove = bought;
              }
            }

            if (mustRemove) {
              _childCategoryData.Remove(categorySpecial.id.toString());
            }
          }
        }
      }
      if (!isNull(_childCategoryData) && !isNull(_rootCategoryData)) {
        /* Get Counters . */
        const _getCategoriesCounters = to(
          this.rewardsSrv.getCategoriesCountersNew()
        );
        /* Get Products. */
        if (!isNull(this.initRequestCategoryId)) {
          if (
            !_childCategoryData.ContainsKey(
              this.initRequestCategoryId.toString()
            )
          ) {
            rangeFrom = 0;
            rangeTo = this.pagingStep;
          } else {
            this._selectedCategoryID = this.initRequestCategoryId;
            this.selectedTag = this.initRequestCategoryId.toString();
          }
          this.initRequestCategoryId = null;
        } else if (!this.routeFired) {
          this.routeFired = true;
          const route = this.router.url;
          const instaIndex = route.indexOf("#");
          if (instaIndex >= 0) {
            const fragment = route.slice(instaIndex + 1, route.length);
            if (fragment) {
              const categoryNameToId =
                (this._rewardConfig &&
                  this._rewardConfig["categoryNameToId"]) ||
                null;
              if (categoryNameToId) {
                const catId = categoryNameToId[fragment];
                if (catId) {
                  if (!_childCategoryData.ContainsKey(String(catId))) {
                    rangeFrom = 0;
                    rangeTo = this.pagingStep;
                  } else {
                    this._selectedCategoryID = Number(catId);
                    this.selectedTag = String(catId);
                  }
                }
              }
            }
            logger.log(fragment);
          }
        }
        // const _getProductSelection = to(this.rewardsSrv.getProductSelection(
        //     _childCategoryData,
        //     this._selectedCategoryID,
        //     this.selectedShorter,
        //     rangeFrom,
        //     rangeTo,
        //     this.fetchStatistics,
        //     true
        // ));
        // const _getProductsStored = this.getProductStored();
        await Promise.all([
          _getCategoriesCounters,
          this.cachingSrv.loadedProducts
            ? this.getProductStored()
            : to(
                this.rewardsSrv.getProductSelection(
                  _childCategoryData,
                  this._selectedCategoryID,
                  this.selectedShorter,
                  rangeFrom,
                  rangeTo,
                  this.fetchStatistics,
                  true
                )
              ),
        ]).then((arrayOfResults) => {
          logger.log(arrayOfResults);
          if (!arrayOfResults) error = true;
          else {
            if (!isNil(arrayOfResults[0].data))
              _countersData = arrayOfResults[0].data as KpDictionary<number>;
            if (!isNil(arrayOfResults[1].data)) {
              _selectionData = arrayOfResults[1]
                .data as KpDictionary<ExtendedBrandProductDTO>;
              this.totalItems =
                arrayOfResults[1].data[TAConstants.TAHeaders.ITEM_COUNT] !=
                undefined
                  ? Number(
                      arrayOfResults[1].data[TAConstants.TAHeaders.ITEM_COUNT]
                    )
                  : Infinity;
              this._allProducts = arrayOfResults[1]
                .data as KpDictionary<ExtendedBrandProductDTO>;
            }
          }
        });
      }
      if (!isNil(_selectionData) && !isNil(_countersData)) {
        this._pageData = {
          filterCategories: _childCategoryData,
          counters: _countersData,
          pageItems: _selectionData as KpDictionary<LoyaltyBrandProductDTO>,
          rootCategory: _rootCategoryData,
          pagging: {
            rangeFrom: rangeFrom,
            rangeTo: rangeTo,
          },
        };
        logger.log("fetchCurrentDataBlock", this._pageData);
        this.initFilterTags();
        this.setPageContent(this._pageData);
        this.goBackSrv.setPreviewPageData(null);
      } else error = true;
    }
    if (error) this.ShowError(init);
  }

  /**
   * Shows more triggered
   * @param event
   */
  public async showMoreTriggered(clearCurrent: boolean = true) {
    this.showMoreButtonPressed = true;
    this.showMoreButton = false;
    this.pageLoading = true;
    if (clearCurrent) this.displayedCards = [];
    this.fetchCurrentDataBlock(
      this.paginatorRangeFrom,
      this.paginatorRangeTo,
      false
    );
  }

  /**
   * Extends data
   * @param data
   */
  private ExtendData(data: KpDictionary<LoyaltyBrandProductDTO>) {
    if (isNil(data)) return;
    else {
      data.Values().forEach((brandProduct) => {
        if (this._pageData.pageItems.ContainsKey(brandProduct.brandProductId)) {
          this._pageData.pageItems.Remove(brandProduct.brandProductId);
        }
        this._pageData.pageItems.Add(brandProduct.brandProductId, brandProduct);
        if (this._allProducts.ContainsKey(brandProduct.brandProductId)) {
          this._allProducts.Remove(brandProduct.brandProductId);
        }
        this._allProducts.Add(brandProduct.brandProductId, brandProduct);
      });
      this.setPageContent(
        this._pageData === null
          ? null
          : {
              rootCategory: this._pageData.rootCategory,
              filterCategories: this._pageData.filterCategories,
              counters: this._pageData.counters,
              pageItems: data,
              pagging: this._pageData.pagging,
            }
      );
    }
  }

  public handlePageChange(event: RangeFromToPaginatorPayload) {
    try {
      logger.log(event);
      this.paginatorRangeFrom = this.displayedCards.length;
      this.paginatorRangeTo = this.displayedCards.length + this.pagingStep;
      this.currentPage = 1;
      this.showMoreTriggered(false);
    } catch (error) {
      logger.error(error);
    }
  }

  private getProductStored(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cachingSrv.loadedProducts.items)
        resolve({ data: this.cachingSrv.loadedProducts.items });
      else resolve({ data: new KpDictionary<LoyaltyBrandProductDTO>() });
    });
  }

  validatePin() {
    if (this.purchaseLoading) return;

    const enteredPIN = this.pins.map((pin) => pin.nativeElement.value).join("");
    const input: ItemItemTypeInput = {
      itemId: this.activeProduct.oTPConfig.prerequisiteItem.itemId,
      itemTypeId: this.activeProduct.oTPConfig.prerequisiteItem.itemTypeId,
      refItemId: enteredPIN,
      reference: this.activeProduct.brandProductId,
    };
    // this.togglePurchaseLoader(true);
    this.errorMessage = "";
    this.otpSrv
      .validatePin(input)
      .then((res) => {
        this.purchaseProduct();
        this.resetOTPSubscriptions();
        this.otpPopUp.nativeElement.close();
      })
      .catch((err) => {
        //TODO show error
        this.errorMessage = this.translateSrv.instant("OTP_VALIDATE_PIN_ERROR");
        this.resetOTPValue();
      });
  }

  requestPin() {
    const input: ItemItemTypeInput = {
      itemId: this.activeProduct.oTPConfig.prerequisiteItem.itemId,
      itemTypeId: this.activeProduct.oTPConfig.prerequisiteItem.itemTypeId,
      reference: this.activeProduct.brandProductId,
    };
    this.otpSrv
      .requestPin(input)
      .then((res) => {
        this.getResendText(true);
        if (!this.otpPopUp.nativeElement.isOpen)
          this.otpPopUp.nativeElement.open();
      })
      .catch((err) => {
        if (err && err.error) {
          const error = JSON.parse(err.error);
          if (error) {
            const error_Code = error.code;
            if (error_Code == 3009) {
              this.purchaseProduct();
              this.resetOTPSubscriptions();
            } else if (error_Code == 3010) {
              this.getResendText();
              if (!this.otpPopUp.nativeElement.isOpen)
                this.otpPopUp.nativeElement.open();
            } else {
              this.displayErrorModal({
                title: this.translateSrv.instant("MODAL_ERROR_TITLE"),
                subTitle: null,
                errorMessage: this.translateSrv.instant(
                  "OTP_REQUEST_PIN_ERROR"
                ),
              });
            }
          }
        }
      });
  }
  async openOTPPopUp() {
    if (
      this.otpPopUp &&
      this.otpPopUp.nativeElement &&
      this.otpPopUp.nativeElement.open
    ) {
      this.requestPin();
    }
  }

  areAllInputsFilled(): boolean {
    return this.pins.every((pin) => this.isFilled(pin));
  }

  private setFocusOnFirstEmptyPin() {
    const emptyPin = this.pins.find((pin) => pin.nativeElement.value === "");
    if (emptyPin) {
      emptyPin.nativeElement.focus();
    }
  }

  @HostListener("input", ["$event.target", "$event.inputType"])
  onInput(input: HTMLInputElement, inputType: string) {
    const maxLength = parseInt(input.getAttribute("maxlength")!, 10);
    const currentPinIndex = this.pins.findIndex(
      (pin) => pin.nativeElement === input
    );

    if (input.value.length === maxLength) {
      const nextPinIndex = currentPinIndex + 1;
      if (nextPinIndex < this.pins.length) {
        this.pins[nextPinIndex].nativeElement.focus();
      }
    } else if (
      input.value.length === 0 &&
      currentPinIndex > 0 &&
      inputType === "deleteContentBackward"
    ) {
      const prevPinIndex = currentPinIndex - 1;
      this.pins[prevPinIndex].nativeElement.focus();
    } else if (input.value.length > maxLength) {
      input.value = input.value.split("").pop(); // get last character entered
    }
  }

  onLastPinInput(event: KeyboardEvent) {
    const invalidChars = ["-", "+", "e", "E"];
    if (invalidChars.includes(event.key)) event.preventDefault();

    const input = event.target as HTMLInputElement;
    if (event.key === "Backspace" && input.value.length === 0) {
      const currentPinIndex = this.pins.findIndex(
        (pin) => pin.nativeElement === input
      );
      const prevPinIndex = currentPinIndex - 1;
      if (prevPinIndex >= 0) {
        this.pins[prevPinIndex].nativeElement.focus();
      } else {
        this.pins[0].nativeElement.focus();
      }
      event.preventDefault();
    }
    // input.value = event.key;
    this.errorMessage = "";
  }

  isFilled(pin: ElementRef<HTMLInputElement>) {
    return pin.nativeElement && pin.nativeElement.value.length === 1;
  }

  isActive(index: number) {
    return index === this.activeInputIndex;
  }

  onPinInput(event: KeyboardEvent, nextInput: HTMLInputElement) {
    const invalidChars = ["-", "+", "e", "E"];
    if (invalidChars.includes(event.key)) event.preventDefault();

    const input = event.target as HTMLInputElement;
    const maxLength = parseInt(input.getAttribute("maxlength")!, 10);
    if (input.value.length === maxLength) {
      nextInput.focus();
    }
    this.errorMessage = "";
  }

  onFocus(pin: ElementRef<HTMLInputElement>) {
    this.activeInputIndex = this.pins.findIndex(
      (pinElement) => pinElement.nativeElement === pin.nativeElement
    );
    this.errorMessage = "";
  }

  public isDisabled = (pointTag): boolean => pointTag.disabled === true;

  public cancelOTP() {
    if (this.errorMessage) {
      this.togglePurchaseLoader(false);
      this.resetOTPValue();
      this.errorMessage = "";
    }
  }

  private getResendText(startTimer?: boolean) {
    if (startTimer) {
      this.otpSrv.startOTPCountdown();
      this.otpTimeRemaining = {
        time: 0,
        text: "",
      };
    }

    if (this.otpTimeRemaining.time == 0) {
      if (!startTimer && this.otpSrv.firstVisit) {
        // on page refresh & error 3010 -> start timer
        this.getResendText(true);
      } else {
        const text = this.translateSrv.instant("OTP_RESENT_SMS_NEW");
        this.otpCountDownSub = this.otpSrv.countDown$
          .pipe(
            takeUntil(this.destroy),
            takeWhile((n: number) => n >= 0)
          )
          .subscribe((value) => {
            const minutes = Math.floor(value / 60);
            const seconds = value - minutes * 60;
            this.otpTimeRemaining = {
              time: value,
              text: text.replace(
                "{{value}}",
                `${minutes} min e ${seconds} sec`
              ),
            };
          });
      }
    }
  }

  private resetOTPSubscriptions() {
    if (this.otpCountDownSub) this.otpCountDownSub.unsubscribe();
    if (this.otpSrv.otpTimerSub) this.otpSrv.otpTimerSub.unsubscribe();
    this.otpTimeRemaining = {
      time: 0,
      text: "",
    };
  }

  private resetOTPValue() {
    this.pins.map((pin) => (pin.nativeElement.value = ""));
  }

  private togglePurchaseLoader(value) {
    this.purchaseLoading = value;
    this.purchaseLoading === true
      ? this.renderer.setStyle(document.body, "overflow", "hidden")
      : this.renderer.removeStyle(document.body, "overflow");
  }

  async purchaseProduct() {
    if (!this.activeProduct) return;

    this.pageLoading = true;

    let _points = !this.activeProduct.prices
      ? 0
      : this.activeProduct.prices[0].prices[0].amount.value;
    let _pointsScale = !this.activeProduct.prices
      ? 0
      : this.activeProduct.prices[0].prices[0].amount.scale;
    let _unitTypeId = !this.activeProduct.prices
      ? null
      : this.activeProduct.prices[0].prices[0].unitTypeId;
    let _normal_price =
      _points >= 0 ? _points / Math.pow(10, _pointsScale) : null;

    const metadatas: Array<{ key: string; value: string }> = [
      {
        key: METADATA_KEY.BRAND_PRODUCT_COUPON_PURCHASE_TYPE,
        value: "eCommerce",
      },
    ];

    const input: IPurchaseInput = {
      brandProductId: this.giftId,
      quantity: 1,
      amountsToPay: [
        {
          amount: _normal_price,
          unitTypeId: _unitTypeId,
          brandServiceId: this.activeProduct.prices[0].brandServiceId,
        },
      ],
      metadatas: metadatas && metadatas.length > 0 ? metadatas : null,
    };
    await this.brandService
      .purchaseDealCoupon(this.userSrv.user.user.userId, input)
      .then(async (res: any) => {
        this.userSrv
          .updateUserStatus()
          .then((result) => logger.log(result))
          .catch((err) => logger.log(err));
        this.giftId = null;
        this.pageLoading = false;
        this.giftPopupData = {
          header: this.translateSrv.instant("GIFT_CARD_HEADER_SUCCESS"),
          message: this.translateSrv.instant("GIFT_CARD_MESSAGE_SUCCESS"),
          showCancel: false,
        };

        if (this.giftpopup && this.giftpopup.nativeElement)
          this.giftpopup.nativeElement.open();
      })
      .catch((err) => {
        this.giftId = null;
        this.pageLoading = false;
        this.giftPopupData = {
          header: this.translateSrv.instant("GIFT_CARD_HEADER_FAILURE"),
          message: this.translateSrv.instant("GIFT_CARD_MESSAGE_FAILURE"),
          showCancel: false,
        };

        if (this.giftpopup && this.giftpopup.nativeElement)
          this.giftpopup.nativeElement.open();
      });
  }

  // public handlePageChange(event: RangeFromToPaginatorPayload) {
  //     try {
  //         logger.log(event)
  //         this.paginatorRangeFrom = event.rangeFrom;
  //         this.paginatorRangeTo = event.rangeTo;
  //         this.currentPage = event.page;
  //         this.showMoreTriggered(false);
  //     } catch (error) {
  //         logger.error(error)
  //     }
  // }
}

/**
 * Ishorter item
 */
export interface IShorterItem {
  name: string;
  value: string;
  count?: number;
  showCounter?: boolean;
  disabled?: boolean;
}

export const CATALOG_SHORTER_FILTER_TYPES = Object.freeze({
  BY_PRICE_ASC: {
    NAME: "BY_PRICE_ASC",
    VALUE: "BY_PRICE_ASC",
  },
  BY_PRICE_DESC: {
    NAME: "BY_PRICE_DESC",
    VALUE: "BY_PRICE_DESC",
  },
  BY_RECENT: {
    NAME: "BY_RECENT",
    VALUE: "BY_RECENT",
  },
  BY_ORDERING_DESC: {
    NAME: "BY_ORDERING_DESC",
    VALUE: "BY_ORDERING_DESC",
  },
  BY_ORDERING_ASC: {
    NAME: "BY_ORDERING_ASC",
    VALUE: "BY_ORDERING_ASC",
  },
});

export interface RewardsPageData {
  rootCategory: ExtendedCategoryDTO;
  filterCategories: KpDictionary<ExtendedCategoryDTO>;
  counters: KpDictionary<number>;
  pageItems: KpDictionary<LoyaltyBrandProductDTO>;
  pagging: RewardPagging;
}

export type ProductPurchaseContext = LoyaltyBrandProductDTO & {
  category: string;
  name: string;
  content: string;
  subtitle: string;
  button: IACtionButton;
  productNote: ProductNote;
  productPointsTags: ProductPointsTag[];
};

export type TagCategoryDef = {
  label: string;
  color?: string;
  backgroundColor?: string;
};
